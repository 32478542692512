<div class="content-page">
    <div class="page-title-group">
        <div class="page-title-group__left">
            <h2 class="title">Resources</h2>
        </div>
        <div class="page-title-group__right">
            <div class="icon-contianer">
                <button type="button" class="btn btn-clear btn-icon active">
                    <span class="light-icon light-icon-list"></span>
                </button>
                <button type="button" class="btn btn-clear btn-icon">
                    <span class="light-icon light-icon-eye"></span>
                </button>
            </div>
        </div>
    </div>
    <div class="content-page__body">
        <div class="row">
            <div *ngFor="let resource of resources" class="col col-12 col-sm-6 col-md-6 col-lg-4 mb-4">
                <div class="card card-icon">
                    <div class="card-body">
                        <div class="text">
                            <h3 class="number number-sm">{{ resource.name }}</h3>
                            <label class="label">{{ resource.size }}</label>
                        </div>
                        <span class="icon-box">
                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="24" viewBox="0 0 22 24"
                                fill="none">
                                <path
                                    d="M6.52282 13.9318C6.29274 14.1573 6.28904 14.5266 6.51449 14.7567L10.5876 18.8298C10.6968 18.9393 10.8453 19.0009 11 19.0007C11.1547 19.0008 11.3031 18.9393 11.4124 18.8298L15.4855 14.7567C15.7078 14.5299 15.7078 14.167 15.4855 13.9402C15.26 13.7101 14.8907 13.7064 14.6606 13.9318L11.5833 17.0091V0.917318C11.5833 0.595174 11.3221 0.333984 11 0.333984C10.6779 0.333984 10.4167 0.595174 10.4167 0.917318V17.0091L7.33928 13.9318C7.11256 13.7096 6.74961 13.7096 6.52282 13.9318ZM18 8.50065H16.25C15.9279 8.50065 15.6667 8.76184 15.6667 9.08399C15.6667 9.40613 15.9279 9.66732 16.25 9.66732H18C19.288 9.66896 20.3317 10.7126 20.3333 12.0007V20.1673C20.3317 21.4553 19.288 22.499 18 22.5007H4C2.712 22.499 1.6683 21.4553 1.66667 20.1673V12.0007C1.6683 10.7126 2.712 9.66896 4 9.66732H6.91667C7.23881 9.66732 7.5 9.40613 7.5 9.08399C7.5 8.76184 7.23881 8.50065 6.91667 8.50065H4C2.06785 8.50279 0.502136 10.0685 0.5 12.0007V20.1673C0.502136 22.0995 2.06785 23.6652 4 23.6673H18C19.9321 23.6652 21.4979 22.0995 21.5 20.1673V12.0007C21.4979 10.0685 19.9321 8.50279 18 8.50065Z"
                                    fill="white" />
                            </svg>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>