import { ClientManagementService } from './../../../../services/client-management.service';
import { Options } from '@angular-slider/ngx-slider';
import { Component, OnDestroy, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ToastrService } from 'ngx-toastr';
import * as p5 from 'p5';
import { debounceTime, delay, takeWhile, tap } from 'rxjs/operators';
import { environment } from './../../../../../environments/environment';
import { ImageService } from './../../../../services/image.service';
import { LiveDemoService } from './../../../../services/live-demo.service';
import { DraftVirtualDemo, IClient, IClientList, IPagination, LightMetadata } from './../../../../utils/models';
import heic2any from 'heic2any';
import { Subject } from 'rxjs';
import { NgxImageCompressService } from 'ngx-image-compress';
import imageCompression from 'browser-image-compression';
import domtoimage from 'dom-to-image';
import html2canvas from 'html2canvas';

//import * as screenshot from 'image-screenshot'
//import * as rasterizeHTML from 'rasterizehtml';
import { NgxCaptureService } from 'ngx-capture';


import { compress, compressAccurately, EImageType } from 'image-conversion';
import * as imageConversion from 'image-conversion';
//import { Ng2ImgMaxService } from 'ng2-img-max';
@Component({
  selector: 'app-virtual-demo',
  templateUrl: './virtual-demo.component.html',
  styleUrls: ['./virtual-demo.component.scss']
})
export class VirtualDemoComponent implements OnInit, OnDestroy {
  @ViewChild('uploadedImg') divClick: ElementRef;
  @ViewChild('beforeSave', { static: true }) beforeSave: ElementRef;
  @ViewChild('uploadedImg', { static: true }) screen: any;

  brightness: number = 20;
  imgWidth = 301;
  imgHeight = 638;

  opacity: number = 1;
  video: any;
  hue: number = 50;
  
  size: number = 0;

  angle: number = 0;

  imgURL: any;// = "./assets/images/virtual-banner.png";
  showLights = false;
  listOfLightsEffect: LightMetadata[] = [];
  listOfDraftLightsEffect: DraftVirtualDemo[] = [];

  showlamps: boolean;
  draftsavingInProgress: boolean;
  file: File;
  fileName: string;
  componentActive: boolean = true;
  draftItem: DraftVirtualDemo;
  isMouseReleased: boolean = false;
  isDemoDirty = false;
  oldBrightness: number;
  searchIsLoading: boolean;
  clientsList: IClient[];
  searchClient: string;
  selectedClientId?: string;
  delaysubject = new Subject();
  selectedClientName: string;
  get getBrighteness() {

    //    this.p5.tint(0,0,this.brightness/255*100,126);
    // this.brightnessvalue = 'filter:brightness(' + `${this.brightness}%)`;
    // this.nightMode ? this.canvasp5?.style(this.brightnessvalue) : this.canvasp5?.style('filter: brightness(100%) ');
    return true;
  }
  get getLightTransparency() {
    this.selectedLightEffect?.style('opacity', this.opacity);
    if (this.listOfLightsEffect.length > 0) {
      const id = this.selectedLightEffect?.id();
      const item: any = this.listOfLightsEffect?.find(x => x.id === id);
      if (item) {
        item.opacity = this.opacity
      }
    }
    return true;
  }
  get getLightHight() {
    const t: any = this.selectedLightEffect?.size('auto', this.lightheight);
    this.selectedLightEffect?.style('height', `${t.height}px`);
    this.selectedLightEffect?.style('width', `${t.width}px`);

    if (this.listOfLightsEffect.length > 0) {
      const id = this.selectedLightEffect?.id();

      const item: any = this.listOfLightsEffect?.find(x => x.id === id);
      if (item) {
        item.height = t.height;
        item.width = t.width;
      }
    }
    return true;
  }

  opacitySliderOptions: Options = {
    floor: 0.1,
    ceil: 1,
    step: 0.1
  };
  p5: p5;
  selectedLightEffect: p5.Element | null;
  imgp5: p5.Image;
  canvasp5: p5.Element;
  nightMode: boolean;
  showLightControls: boolean;
  lightheight: number;
  lightHeightSliderOptions: Options = {} as Options;
  brightnessSliderOptions: Options = {
    floor: 0,
    ceil: 100,
  };
  brightnessvalue: string;

  constructor(private router: Router,
    private liveDemoService: LiveDemoService,
    private toastrService: ToastrService,
    private imageService: ImageService,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private deviceService: DeviceDetectorService,
    private clientManagementService: ClientManagementService,
    private imageCompress: NgxImageCompressService,
    private captureService: NgxCaptureService,
    //private ng2ImgMaxService: Ng2ImgMaxService

  ) {

    
    this.delaysubject.pipe(debounceTime(300)).subscribe((search: any) => {
      this.searchIsLoading = true;
      const pagination: IPagination = {
        limit: 20,
        page: 0,
        total_records: 0
      }
      this.clientsList = [];
      this.clientManagementService.readAllClient(pagination, search)
        .toPromise()
        .then((res: IClientList) => {
          this.clientsList = res.clients;
        })
        .catch(() => this.toastrService.error('Failed to fetch client list.'))
        .finally(() => this.searchIsLoading = false);
    });
  }

  ngOnInit() {
    if (this.deviceService.isTablet()) {
      document.body.scrollTop = document.documentElement!.scrollTop = 0;

      document.getElementsByTagName('body')[0].style.setProperty('overflow', 'hidden');
    }
    this.activatedRoute.params
      .pipe(takeWhile(() => this.componentActive))
      .subscribe((param: any) => {
        if (!_.isEmpty(param)) {
          this.onLoadDraft(param.id);
        } else {
          this.activatedRoute.queryParams.subscribe((qparam) => {
            if (qparam.client_id) {
              this.selectedClientId = qparam.client_id;
              this.selectedClientName = qparam?.first_name + ' ' + qparam?.last_name;
            } else {
              this.modalService.open(this.beforeSave,
                {
                  ariaLabelledBy: 'modal-basic-title',
                  backdrop: 'static',
                  windowClass: 'add-appointment-modal'
                });
            }
          })
          //this.initp5();
        }
      });
  }

  saveImage(img: any) {
    console.log(img);
  }



  deleteSelectedObj() {
    const item = this.listOfLightsEffect.find(x => x.isSelected);
    if (item) {
      const itemIndex = this.listOfLightsEffect.findIndex(x => x.isSelected);
      this.listOfLightsEffect.splice(itemIndex, 1);
      this.selectedLightEffect?.remove();
      this.selectedLightEffect = null;
    }
  }

  changeBrightness() {
    this.p5.redraw = () => {

      this.p5.image(this.imgp5, 0, 0, this.imgWidth, this.imgHeight);

      this.p5.tint(this.brightness / 100 * 255, 150);
      // let v =this.p5.brightness(c);
      // this.p5.fill(v);
      this.p5.image(this.imgp5, 0, 0, this.imgWidth, this.imgHeight);
      this.p5.noLoop();
    }
    this.p5.loop();


    this.focusImage();
  }

  focusImage() {


    if (this.deviceService.isTablet()) {
      (document.getElementById('uploadedImg') as HTMLElement).focus()
      this.canvasp5.elt.click()
      this.divClick.nativeElement.click();
      this.isMouseReleased = false;
    }
  }
  ngOnDestroy(): void {
    
    if (this.deviceService.isTablet()) {
      document.getElementsByTagName('body')[0].style.removeProperty('overflow');
    }
    if (this.p5) {
      this.destroyCanvas();
    }
    this.modalService.dismissAll();
  }
  toggleLights() {
    this.showlamps = false;
    this.showLights = !this.showLights;
    this.isDemoDirty = true;
  }
  toggleLamp() {
    this.showLights = false;
    this.showlamps = !this.showlamps;
    this.isDemoDirty = true;
  }
  public initp5 = () => {
    if (this.p5) {
      this.p5.remove();
    }
    
  }

  private destroyCanvas = () => {
    this.p5.noCanvas();
    this.listOfLightsEffect.length > 0 && this.listOfLightsEffect.forEach((light) => {
      this.selectedLightEffect = this.p5.select('#' + light.id);
      this.selectedLightEffect?.remove();
    });
  }
  getLightId(): string {
    return _.uniqueId('light_');
  }
  onClickLight(path: string, isLamp = false, draft?: LightMetadata) {

    if (this.selectedLightEffect) {

      this.selectedLightEffect?.style('border: 0px solid red');
    }
    this.selectedLightEffect?.style('pointer-events: unset');

    this.selectedLightEffect = this.p5.createImg(path);
    if (!draft) {
      this.selectedLightEffect?.style('pointer-events: none');
    }
    let lightId: any;
    // if (draft) {
    //   lightId = draft.id;
    // } else {
    //   lightId = this.getLightId();
    // }

    lightId = this.getLightId();


    this.selectedLightEffect.attribute('id', lightId);
    const size: any = this.selectedLightEffect?.size();

    if (this.listOfLightsEffect.length > 0) {
      this.listOfLightsEffect.map(x => x.isSelected = false);
    }
    this.lightheight = size.height;
    this.listOfLightsEffect.push({
      id: lightId,
      maxWidth: draft ? draft.maxWidth : size.width,
      maxHeight: draft ? draft.maxHeight : size.height,
      mouseX: draft ? draft.mouseX : 300,
      mouseY: draft ? draft.mouseY : 180,
      isSelected: draft ? false : true,
      opacity: draft ? draft.opacity : 1,
      height: draft ? draft.height : size.height,
      width: draft ? draft.width : size.width,
      imageUrl: draft ? draft.imageUrl : path
    });
    if (draft) {
      this.selectedLightEffect?.position(draft.mouseX, draft.mouseY);
      this.selectedLightEffect?.style('opacity', draft.opacity);
      this.selectedLightEffect?.style('width', draft.width + 'px');
      this.selectedLightEffect?.style('height', draft.height + 'px');
      // this.selectedLightEffect?.style('border: 1px solid red');
      this.selectedLightEffect = null;
      if (this.brightness !== 100) {
        this.nightMode = true;
      }
      this.showLightControls = false;

    } else {
      this.selectedLightEffect?.style('width', size.width + 'px');
      this.selectedLightEffect?.style('height', size.height + 'px');
      // if (this.listOfLightsEffect.length > 1) {
      this.selectedLightEffect?.style('border: 1px solid red');
      // }
      const item = this.listOfLightsEffect.find(x => x.id === lightId);

      this.lightHeightSliderOptions = {
        floor: 50,
        ceil: item?.maxHeight,
      };

      // this.selectedLightEffect.style('filter: invert(45%) sepia(120%) saturate(375%) hue-rotate( 350deg ) brightness(125%) contrast(85%);');
      this.opacity = 1;
      this.selectedLightEffect?.style('opacity', 1);
      this.selectedLightEffect?.position(250, 200);
      if (isLamp) {
        this.showlamps = false;
      } else {
        this.showLights = false;
      }
      this.showLightControls = true;
    }
  }
  resetControls() {
    this.showLightControls = false;
    this.showLights = false;
  }

  private createCanvas = (p: p5) => {
    this.p5 = p;
    p.preload = () => {
      console.log("pre load");
    console.log(this.imgURL);
      this.imgp5 = p.loadImage(this.imgURL);

      this.imgp5.width = this.imgWidth; // screen.width;
      
      this.imgp5.height = this.imgHeight; //screen.height;
      console.log(this.imgp5.width);
    }

    p.setup = () => {
      console.log("setup");
      
      this.canvasp5 = p.createCanvas(this.imgWidth, this.imgHeight).parent('uploadedImg');
      this.canvasp5.style('width',this.imgp5.width);
      this.canvasp5.style('height',this.imgp5.height);

      //fit the object to device width as the image taken from camera will be in same resolution to screen resolution
       this.canvasp5.style('object-fit', 'fill');
      // this.canvasp5.style('padding-left', '195px');
      //  this.canvasp5.position(185);
      this.canvasp5.touchMoved((event: any) => {
        console.log("moved", event.srcElement.id);
        // removed !this.isMouseReleased && condition as mouse released is true sometimes
        if (this.selectedLightEffect &&
          (event.srcElement.id === 'defaultCanvas0' || event.srcElement?.id.includes('light'))) {

          const size: any = this.selectedLightEffect?.size();
          const w = (size.width / 2);
          const h = (size.height / 2);
          const id = this.selectedLightEffect?.id();
          this.selectedLightEffect?.position(p.winMouseX - 195, p.winMouseY);
          const item: any = this.listOfLightsEffect.find(x => x.id === id);
          if (item) {
            item.mouseX = p.winMouseX - 195;
            item.mouseY = p.winMouseY;
          }
        }

      });
      this.canvasp5.touchEnded((event: any) => {
        // if (this.selectedLightEffect && event.srcElement.id !== 'defaultCanvas0') {
        //this.isMouseReleased = !this.isMouseReleased;
        // }
      });

      this.canvasp5.mouseReleased((event: any) => {
        console.log("mouse released", this.isMouseReleased);
        // if (this.selectedLightEffect && event.srcElement.id !== 'defaultCanvas0') {
        this.isMouseReleased = !this.isMouseReleased;
        // }
      });
      this.canvasp5.mouseMoved((event: any) => {
        console.log("mouse move")
        if (this.selectedLightEffect && !this.isMouseReleased &&
          (event.srcElement.id === 'defaultCanvas0' || event.srcElement?.id.includes('light'))) {
          this.isDemoDirty = true;
          const size: any = this.selectedLightEffect?.size();
          const w = (size.width / 2);
          const h = (size.height / 2);
          const id = this.selectedLightEffect?.id();
          this.selectedLightEffect?.position(p.winMouseX - 150, p.winMouseY - 70);
          const item: any = this.listOfLightsEffect.find(x => x.id === id);
          if (item) {
            item.mouseX = p.winMouseX - 150;
            item.mouseY = p.winMouseY - 70;
          }
        }
      });
      // this.canvasp5.mousePressed((event: any) => {
      //   if (this.selectedLightEffect && (event.srcElement?.id === 'defaultCanvas0' || event.srcElement?.id.includes('light'))) {
      //     if (event.srcElement?.id.includes('light') && this.listOfLightsEffect.length > 1) {
      //       this.selectedLightEffect?.style('border: 0px solid red');
      //       this.selectedLightEffect = p.select('#' + event.srcElement.id);
      //       this.listOfLightsEffect.map(x => x.isSelected = false);
      //       const item: any = this.listOfLightsEffect.find(x => x.id === event.srcElement.id);
      //       const size: any = this.selectedLightEffect?.size();
      //       item.isSelected = true;
      //       this.lightheight = size.height;
      //       this.showLightControls = false;
      //       this.opacity = this.selectedLightEffect?.elt.style.opacity;
      //       setTimeout(() => {
      //         this.showLightControls = true;
      //       }, 100);
      //       this.lightHeightSliderOptions.ceil = item.height;
      //       this.selectedLightEffect?.style('border: 1px solid red');

      //     }
      //   }

      // })
    }

    // p.mouseDragged = (event: any) => {
    //   if (this.selectedLightEffect && (event.srcElement.id === 'defaultCanvas0' || event.srcElement?.id.includes('light'))) {
    //     console.log('mouseDragged' + event.srcElement?.id)

    //     this.selectedLightEffect.position(p.mouseX, p.mouseY);
    //   }
    // }

    // p.touchStarted = (event: any) => {

    //   if (event.srcElement?.id === 'defaultCanvas0' || event.srcElement?.id.includes('light')) {

    //     if (event.srcElement?.id.includes('light')) {
    //       console.log("touch started");
    //       this.isMouseReleased = !this.isMouseReleased;
    //       this.selectedLightEffect?.style('border: 0px solid red');
    //       this.selectedLightEffect = p.select('#' + event.srcElement.id);
    //       this.listOfLightsEffect.map(x => x.isSelected = false);
    //       const item: any = this.listOfLightsEffect.find(x => x.id === event.srcElement.id);
    //       const size: any = this.selectedLightEffect?.size();
    //       if (item) {
    //         item.isSelected = true;
    //       }
    //       this.lightheight = size.height;
    //       this.showLightControls = false;
    //       this.opacity = parseFloat(this.selectedLightEffect?.elt.style.opacity);
    //       setTimeout(() => {
    //         this.showLightControls = true;
    //       }, 100);
    //       this.lightHeightSliderOptions.ceil = item.maxHeight;
    //       this.selectedLightEffect?.style('border: 1px solid red');
    //       //   this.selectedLightEffect?.position(p.winMouseX, p.winMouseY);

    //     }
    //   } else if (event.srcElement?.id === 'effect') {
    //     this.isMouseReleased = false;
    //   }
    // }

    p.mouseClicked = (event: any) => {
      if (event.srcElement?.id === 'defaultCanvas0' || event.srcElement?.id.includes('light')) {
        this.isDemoDirty = true;
        console.log(this.listOfLightsEffect)
        if (event.srcElement?.id.includes('light')) {

          console.log(event.srcElement?.id);
          this.isMouseReleased = !this.isMouseReleased;
          this.selectedLightEffect?.style('border: 0px solid red');
          this.selectedLightEffect?.style('pointer-events: unset');

          this.selectedLightEffect = p.select('#' + event.srcElement.id);
          this.listOfLightsEffect.map(x => x.isSelected = false);
          const item: any = this.listOfLightsEffect.find(x => x.id === event.srcElement.id);
          const size: any = this.selectedLightEffect?.size();
          if (item) {
            item.isSelected = true;
          }
          this.lightheight = size.height;
          this.showLightControls = false;
          this.opacity = parseFloat(this.selectedLightEffect?.elt.style.opacity);
          setTimeout(() => {
            this.showLightControls = true;
          }, 100);
          this.lightHeightSliderOptions.ceil = item.maxHeight;
          this.selectedLightEffect?.style('border: 1px solid red');
          //   this.selectedLightEffect?.position(p.winMouseX, p.winMouseY);

        }
      } else if (event.srcElement?.id === 'effect') {
        this.isMouseReleased = false;
      }
    }
    p.draw = () => {
      p.image(this.imgp5, 0, 0, this.imgWidth, this.imgHeight);

    }
  }
  resetCanvas() {
    this.initp5();
    this.nightMode = false;
    this.resetControls();
    this.listOfLightsEffect = [];
  }
  onAddLight(): void {
    const paylod = {
      brightness: this.brightness,
      opacity: this.opacity,
      hue: this.hue,
      size: this.size,
      angle: this.angle
    }
  }

  async onFileChange(event: any): Promise<void> {
    this.isDemoDirty = true;
    const files: FileList = event.target.files;

    if (files.length === 0) {
      return;
    }

    const mimeType = files[0].type;

    if (mimeType.match(/image\/*/) == null) {
      return;
    }

    const reader = new FileReader();
    this.file = files[0];
    

    if (mimeType === 'image/heic') {
      this.file = await this.heictoJPEG(this.file);
    }

    // if (mimeType === 'image/png') {
    //   this.file = await this.convertJPEG(this.file);
    // }


    const options = {
      maxSizeMB: 2,
      maxWidthOrHeight: 1920,
      useWebWorker: true,

    }
    try {

      const compressedFile = await imageCompression(this.file, options);
      // await uploadToServer(compressedFile); // write your own logic


        this.file = compressedFile
      this.fileName = this.file.name;
      reader.readAsDataURL(this.file);
      this.listOfDraftLightsEffect = [];
      this.listOfLightsEffect = [];
      this.selectedLightEffect = null;
      reader.onload = async (_event) => {
        this.imgURL = reader.result;
        console.log(this.imgURL)
        let imgz = new Image();
        imgz.src = this.imgURL;
        let self = this;
        imgz.onload = function () {
          
          self.CalculateDimensions(imgz.naturalWidth,imgz.naturalHeight,parseInt((screen.width* 0.9).toString()),parseInt((screen.height* 0.9).toString()));

          //self.imgWidth = imgz.naturalWidth;
          //self.imgHeight = imgz.naturalHeight;
          //alert(self.imgWidth);
          //alert(self.imgHeight);
          let node = document.getElementById('uploadedImg')!;
          node.style.width = `${self.imgWidth}px`;

          self.p5 = new p5(self.createCanvas, node);
        };
          // var dc = document.getElementById('uploadedImg') as HTMLElement;
          
          // dc.setAttribute("style","width:"+self.imgWidth+"px, height:"+self.imgHeight+"px");
          //  dc.setAttribute("style","height:");

        //alert(imgz.height+" "+imgz.width)
        // this.imgURL = compressedFile;
        this.nightMode = false;
        this.initp5();



        // this.imageCompress
        //   .compressFile(reader.result as string, 0, 70, 100) // 50% ratio, 50% quality
        //   .then(
        //     (compressedImage) => {
        //      // this.imgURL = compressedImage;
        //       this.nightMode = false;
        //       this.initp5();
        //     }

        //   );


      }

    } catch (error) {
      console.log(error)
    }
  }
  onShadows() {
    this.nightMode = !this.nightMode;
    if(this.nightMode)
    {
      
    this.isDemoDirty = true;
    
    this.brightness = 20;
    this.p5.redraw = () => {
      this.p5.image(this.imgp5, 0, 0, this.imgWidth, this.imgHeight);

      //this.nightMode ? this.p5.background(0, 0, 0, 220) : this.p5.background(0, 0, 0, 0);
      // let c = this.p5.color('hsb(60, 100%, 50%)');
      // this.p5.tint(175,200,238,128);
      //this.p5.tint(50,150);
      // let v =this.p5.brightness(c);
      // this.p5.fill(v);
      this.p5.image(this.imgp5, 0, 0, this.imgWidth, this.imgHeight);
      this.p5.tint(this.brightness / 100 * 255, 150);


      //this.nightMode ? this.canvasp5.style('filter: brightness(20%) ') : this.canvasp5.style('filter: brightness(100%) ');
      //this.p5.noLoop();

    }
    this.p5.loop();
  }
  else
  {
   
    this.brightness = 255;
    this.p5.image(this.imgp5, 0, 0, this.imgWidth, this.imgHeight);
    this.changeBrightness();
    this.p5.tint(255,255);
  }
  }

 
    
 CalculateDimensions( Width:number, Height:number,  maxWidth: number,  maxHeight: number) {
    if(Width < maxWidth && Height < maxHeight )
    {
      this.imgWidth = Width;
    this.imgHeight = Height;
    return
    }

  var sourceWidth = Width;
    var sourceHeight = Height;

    var widthPercent = maxWidth / sourceWidth;
    var heightPercent = maxHeight / sourceHeight;
    
    var percent = heightPercent < widthPercent
                   ? heightPercent
                   : widthPercent;

    var destWidth = (sourceWidth * percent);
    var destHeight = (sourceHeight * percent);
    
    this.imgWidth = parseInt(destWidth.toString());
    this.imgHeight = parseInt(destHeight.toString());
    //alert(this.imgWidth+" "+ this.imgHeight);
    
    
}
  


  async onSaveProject() {
    //alert("save clicked");
    this.draftsavingInProgress = true;
    this.onClickPreview();
    const canvas = document.getElementById('uploadedImg') as HTMLElement;
    //await this.imgp5.filter(this.p5.BLUR,20);
    let h2c = await html2canvas(canvas);
    // let cnvs: HTMLCanvasElement=document.createElement('canvas');
    // rasterizeHTML.drawHTML(canvas.outerHTML,cnvs);
    // let dataUrl =  cnvs.toDataURL()
    console.log(h2c.toDataURL());
    //dom-to-image

    var options = {
      quality: 0.8,
    };

    
    //let dataUrl = await domtoimage.toJpeg(canvas,{style: {  transformOrigin: 'top left'}})
    var imga = new Image(this.imgWidth, this.imgHeight);
    imga.src = h2c.toDataURL("image/png");
    imga.width = this.imgWidth;
    imga.height = this.imgHeight;
   // alert(imga.height);
    console.log(imga.src)

    // this.ng2ImgMaxService.resize([imga], this.imgWidth, this.imgHeight).subscribe((result)=>{
    // });
    let dataUrl = imga.src;
   // alert(dataUrl);
    var arr = dataUrl!.split(','), mime = arr[0].match(/:(.*?);/)![1],
    bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    
    
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    
    let img = new File([u8arr], Date.now().toString(), { type: mime });
    
    //    var img = new Image();
    // img.src = h2c.toDataURL();

    //    document.body.appendChild(imgz);
    // return;

    //let img;
    this.showLightControls = false;
    this.selectedLightEffect = null;
    this.showLights = false;
    this.showlamps = false;
    if (this.draftItem) {

      this.uploadImage(img,true);
      // if (this.imgURL !== this.draftItem.imageUrl) {
      //   this.uploadImage(img);
      // } else {

      //   alert("something here");
      //   this.draftsavingInProgress = true;
      //   const demo: DraftVirtualDemo = {
      //     _id: this.draftItem._id,
      //     name: this.draftItem.name,
      //     imageUrl: dataUrl,
      //     lights: [],// this.listOfLightsEffect,

      //     brightness: this.nightMode ? this.brightness : 100
      //   }
      //   this.updateDemo(demo);
      // }
    } else {
      this.uploadImage(img);
    }

  }
  onLoadDraft(demoId: string) {
    
    this.liveDemoService.readVirtualDemo(demoId)
      .toPromise()
      .then((draft: DraftVirtualDemo) => {

        console.log("loading");
        this.draftItem = draft;
        this.brightness = draft.brightness;
        this.oldBrightness = draft.brightness;
        //
        this.fileName = Number(new Date()).toString();//draft.name;
        
        if (this.brightness !== 100) {
          this.nightMode = true;
        } else {
          this.nightMode = false;
        }
        this.imgURL = draft.imageUrl;
        
        var img = new Image(); 
        img.src = this.imgURL; 
        img.crossOrigin = "Anonymous";

        let self = this;     
        img.onload = function(this){
  //self.imgWidth = img.width
  //self.imgHeight = img.height
  self.CalculateDimensions(img.naturalWidth,img.naturalHeight,parseInt((screen.width* 0.9).toString()),parseInt((screen.height* 0.9).toString()));
  self.initp5();
  let node = document.getElementById('uploadedImg')!;
  node.style.width = `${self.imgWidth}px`;

  self.p5 = new p5(self.createCanvas, node);
};

        
        this.selectedClientName = draft.client_id.first_name + ' ' + draft.client_id?.last_name;
        this.selectedClientId = draft.client_id?.id;

        draft.lights.length > 0 && draft.lights.forEach(element => {
          console.log(element);
          this.onClickLight(element.imageUrl, false, element)
        });
      })
      .catch(() => this.toastrService.error('Failed to delete'))
      .finally();

  }
  createVirtualDemo(draft: DraftVirtualDemo) {
    this.draftsavingInProgress = true;
    this.liveDemoService.createVirtualDemo(draft)
      .toPromise()
      .then((res) => {
        this.toastrService.success('Virtual demo saved successfully');
        this.router.navigateByUrl('demo-list');
      })
      .catch(() => this.toastrService.error('Failed to save, try again.'))
      .finally(() => {
        this.draftsavingInProgress = false;
        this.modalService.dismissAll();
      });
  }
  async uploadImage(img: any, isEdit = false) {
    
    if (this.imgURL === './assets/images/virtual-banner.png') {
      const demo: DraftVirtualDemo = {
        name: this.file ? this.file.name : 'virtual-banner.png',
        imageUrl: this.imgURL,
        lights: [],// this.listOfLightsEffect,
        brightness: this.nightMode ? this.brightness : 100
      }
      if (isEdit) {
        demo._id = this.draftItem._id;
        this.updateDemo(demo);
      } else {
        demo.client_id = this.selectedClientId,
          demo.type = 'virtual',
          this.createVirtualDemo(demo);
      }
    } else {
      
      //const res = await imageConversion.dataURLtoFile(this.imgURL, imageConversion.EImageType.JPEG);
      
      this.imageService.uploadImage(img, this.fileName)
        .toPromise()
        .then(() => {
          this.imgURL = `${environment.imageUploadUrl}${this.fileName}`;

         
          const demo: DraftVirtualDemo = {
            client_id: this.selectedClientId,
            type: 'virtual',
            name: this.fileName,
            imageUrl: this.imgURL,
            lights: [],//this.listOfLightsEffect,
            brightness: this.nightMode ? this.brightness : 100
          }

          debugger
         
          if (isEdit) {
            demo._id = this.draftItem._id;
            this.updateDemo(demo);
          } else {
            this.createVirtualDemo(demo);
          }
        })
        .catch(() => this.toastrService.error('Failed to save, try again.'))
      // .finally(() => this.draftsavingInProgress = false);
    }
  }
  onClickSave(content: any) {
    if (this.draftItem) {
      this.onSaveProject();
    } else {
      this.modalService.open(content,
        {
          ariaLabelledBy: 'modal-basic-title',
          backdrop: 'static',
          windowClass: 'add-appointment-modal'
        });
    }

  }
  onClickCloseDemo(content: any) {
    if (this.isDemoDirty || this.brightness !== this.oldBrightness) {
      this.modalService.open(content,
        {
          ariaLabelledBy: 'modal-basic-title',
          backdrop: 'static',
          windowClass: 'add-appointment-modal'
        });
    } else {
      this.router.navigateByUrl('demo-list');
    }

  }
  onConfirmDelete() {
    this.modalService.dismissAll();
    this.router.navigateByUrl('demo-list');
  }
  onCancelDemo() {
    this.modalService.dismissAll();
  }
  async updateDemo(draft: DraftVirtualDemo) {

    //  const res = await imageConversion.dataURLtoFile(draft.imageUrl, imageConversion.EImageType.JPEG);


    this.liveDemoService.updateVirtualDemo(draft)
      .toPromise()
      .then((draft: DraftVirtualDemo) => {
        this.toastrService.success(' Virtual demo updated successfully');
        this.router.navigateByUrl('demo-list');
      })
      .catch(() => this.toastrService.error('Failed to update'))
      .finally(() => this.draftsavingInProgress = false);

  }
  addClient(client?: IClient) {

    this.selectedClientId = client?._id;
    this.selectedClientName = client?.first_name + ' ' + client?.last_name;
    this.modalService.dismissAll();
    if (this.deviceService.isTablet()) {
      document.body.scrollTop = document.documentElement!.scrollTop = 0;

      document.getElementsByTagName('body')[0].style.setProperty('overflow', 'hidden');
    }
  }
  onblurSearchClient(search: string) {
    this.delaysubject.next(search);


  }
  onClickPreview() {
    if (this.selectedLightEffect) {
      this.selectedLightEffect?.style('pointer-events: unset');

      this.selectedLightEffect?.style('border: 0px solid red');
      this.selectedLightEffect = null;
    }
  }

  onClickCance() {
    this.router.navigateByUrl('demo-list')
  }


  async convertJPEG(blob: File) {

    let fil = await imageConversion.compressAccurately(blob, Math.floor((blob.size / 1024) / 7));
    console.log(fil);

    return new File([fil], new Date().getTime().toString() + ".png");
  }
  public heictoJPEG(blob: File): Promise<any> {
    const promise = new Promise((resolve, reject) => {
      heic2any({ blob, toType: 'image/jpeg', quality: 1 }).then((jpgBlob: any) => {
        const file: File = this.blobToFile(jpgBlob, this.file.name);
        resolve(file);
      }).catch((err: any) => {
        reject(err)
        //Handle error
      });
    });
    return promise;
  }



  private blobToFile = (theBlob: Blob, fileName: string): File => {
    let b: any = theBlob;
    b.lastModified = new Date();
    b.name = fileName;
    return <File>theBlob;
  }
}
