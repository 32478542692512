<!-- == Start Page Content here == -->
<div class="content-page">
  <div class="page-title-group">
    <div class="page-title-group__left">
      <h2 class="title">{{ client?.first_name }} {{ client?.last_name }} &nbsp; <i class="fas fa-spinner fa-spin"
          *ngIf="loading"></i></h2>
      <!-- <p class="description">Lorem Ipsum is not simply random text. It has roots in a piece of classical<br />Latin
        literature from 45 BC, making it over 2000 years old.</p> -->
    </div>
    <div class="page-title-group__right ms-sm-auto">
      <button class="btn btn-primary me-2" type="button" (click)="onCreateVirtualDemo()" [disabled]="!client">Create Virtual Demo</button>
      <button class="btn btn-primary me-2" type="button" (click)="createLiveDemo()" [disabled]="!client">Schedule Live Demo</button>
      <button class="btn btn-primary me-2" type="button" (click)="createInstalltion()" [disabled]="!client">Schedule Installation</button>
      <button class="btn btn-primary" type="button" [routerLink]="['/client-management']">Back</button>
    </div>
  </div>
  <div class="content-page__body" *ngIf="!loading && !client">
    <h4 class="text-center">No client found.</h4>
  </div>
  <div class="content-page__body" *ngIf="!loading">
    <ul class="nav nav-client nav-custom" id="client-tab" role="tablist">
      <li class="nav-item" role="presentation">
        <button class="nav-link" [ngClass]="{ 'active': selectedTab === 'client' }" (click)="onTabClick('client')"
          id="client-create-tab" data-bs-toggle="pill" data-bs-target="#client-create" type="button" role="tab"
          aria-controls="client-create" aria-selected="true">Client Details</button>
      </li>
      <li class="nav-item" role="presentation">
        <button class="nav-link" [ngClass]="{ 'active': selectedTab === 'quote' }" (click)="onTabClick('quote')"
          id="quote-tab" data-bs-toggle="pill" data-bs-target="#quote-list" type="button" role="tab"
          aria-controls="quote-list" aria-selected="false">Quote</button>
      </li>
      <!-- <li class="nav-item" role="presentation">
        <button class="nav-link" id="maintenance-agreement-tab" data-bs-toggle="pill"
          data-bs-target="#maintenance-agreement" type="button" role="tab" aria-controls="maintenance-agreement"
          aria-selected="false">Maintenance Agreement</button>
      </li> -->
      <li class="nav-item" role="presentation">
        <button class="nav-link" [ngClass]="{ 'active': selectedTab === 'agreement' }" (click)="onTabClick('agreement')"
          id="agreement-list" id="agreement-list-tab" data-bs-toggle="pill" data-bs-target="#agreement-list"
          type="button" role="tab" aria-controls="agreement-list" aria-selected="false">Agreement</button>
      </li>
      <li class="nav-item" role="presentation">
        <button class="nav-link" [ngClass]="{ 'active': selectedTab === 'invoice' }" (click)="onTabClick('invoice')"
          id="invoice-list-tab" data-bs-toggle="pill" data-bs-target="#invoice-list" type="button" role="tab"
          aria-controls="invoice-list" aria-selected="false">Invoice</button>
      </li>
      <li class="nav-item" role="presentation">
        <button class="nav-link" [ngClass]="{ 'active': selectedTab === 'payment' }" (click)="onTabClick('payment')"
          id="payment-list-tab" data-bs-toggle="pill" data-bs-target="#payment-list" type="button" role="tab"
          aria-controls="payment-list" aria-selected="false">Payment List</button>
      </li>
      <li class="nav-item" role="presentation">
        <button class="nav-link" [ngClass]="{ 'active': selectedTab === 'virtual-demo' }" (click)="onTabClick('virtual-demo')"
          id="virtual-demo-tab" data-bs-toggle="pill" data-bs-target="#virtual-demo" type="button" role="tab"
          aria-controls="virtual-demo" aria-selected="false">Virtual Demo</button>
      </li>
      <li class="nav-item" role="presentation">
        <button class="nav-link" [ngClass]="{ 'active': selectedTab === 'map' }" (click)="onTabClick('map')"
          id="google-map-tab" data-bs-toggle="pill" data-bs-target="#google-map" type="button" role="tab"
          aria-controls="google-map" aria-selected="false">Show Google map</button>
      </li>
    </ul>
    <div class="tab-content" id="client-tabContent">
      <div class="tab-pane fade" [ngClass]="{ 'active show': selectedTab === 'client' }" id="client-create"
        role="tabpanel" aria-labelledby="client-create-tab">
        <form class="row" [formGroup]="clientForm">
          <div class="col col-12 col-md-6 col-lg-6">
            <div class="client-management__content">
              <div>
                <div class="form-group mb-4">
                  <input type="text" name="first_name" formControlName="first_name"
                    class="form-control form-control-white" placeholder="First Name" trimwhitespacestart />
                </div>
                <div class="form-group mb-4">
                  <input type="text" name="last_name" formControlName="last_name"
                    class="form-control form-control-white" placeholder="Last Name" trimwhitespacestart />
                </div>
                <div class="form-group mb-4">
                  <input type="email" name="email" formControlName="email" class="form-control form-control-white"
                    placeholder="Client Email" />
                </div>
                <div class="form-group mb-4">
                  <input type="text" name="line" formControlName="line"
                    class="address-input form-control form-control-white" placeholder="Address" trimwhitespacestart />
                </div>
                <div class="form-group mb-4">
                  <input type="text" name="state" formControlName="state" class="form-control form-control-white"
                    placeholder="State" trimwhitespacestart />
                </div>
                <div class="form-group mb-4">
                  <input type="text" name="city" formControlName="city" class="form-control form-control-white"
                    placeholder="City" trimwhitespacestart />
                </div>
                <div class="form-group mb-4">
                  <input type="text" name="country" formControlName="country" class="form-control form-control-white"
                    placeholder="Country" trimwhitespacestart />
                </div>
                <div class="form-group mb-4">
                  <input type="text" name="postal" formControlName="postal" class="form-control form-control-white"
                    placeholder="Postal" numbersOnly />
                </div>
                <div class="form-group mb-4">
                  <!-- <input type="text" name="phone_number" formControlName="phone_number"
                    class="form-control form-control-white" placeholder="Phone Number" numbersOnly /> -->
                  <input id="tel" formControlName="phone_number" class="form-control form-control-white"
                    placeholder="Phone Number" phoneMask [preValue]="clientForm.value.phone_number"
                    [phoneControl]="clientForm.controls['phone_number']" />
                </div>
                <div class="text-start">
                  <button class="btn btn-primary px-5" type="button"
                    [disabled]="clientForm.invalid || updateClientInProgress" (click)="updateClient()">Update
                    &nbsp; <i class="fas fa-spinner fa-spin" *ngIf="updateClientInProgress"></i></button>
                </div>
              </div>
            </div>
          </div>
          <div class="col col-12 col-md-6 col-lg-6">
            <div class="live-demo-content mb-4">
              <div class="live-demo-content__head mb-4">
                <ul class="nav nav-pills nav-live-demo ms-auto" id="pills-tab" role="tablist">
                  <li class="nav-item" role="presentation">
                    <button class="nav-link active" id="pills-liveDemos-tab" data-bs-toggle="pill"
                      data-bs-target="#pills-liveDemos" type="button" role="tab" aria-controls="pills-liveDemos"
                      aria-selected="true">Live Demos</button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button class="nav-link" id="pills-installations-tab" data-bs-toggle="pill"
                      data-bs-target="#pills-installations" type="button" role="tab" aria-controls="pills-installations"
                      aria-selected="false">Installations</button>
                  </li>
                </ul>
              </div>
              <div class="live-demo-content__body">
                <div class="tab-content" id="pills-tabContent">
                  <div class="tab-pane fade show active" id="pills-liveDemos" role="tabpanel"
                    aria-labelledby="pills-liveDemos-tab">
                    <ul class="list-unstyled appointment-list">
                      <div *ngIf="!appointmentsLoading && liveDemoAppointments.length < 1"
                        class="live-demo__item text-center justify-content-center">
                        <h4>No live demo appointments found.</h4>
                      </div>
                      <li class="appointment-list-item live-demo-appointment"
                        *ngFor="let appointment of liveDemoAppointments">
                        <div class="appointment-detail"><i class="far fa-clock me-1"></i>
                          {{ appointment?.datetime | date: 'MMM dd YYYY': 'UTC' }} , {{ appointment?.datetime | date:
                          'hh:mm a': 'UTC' }} ,
                          {{ appointment?.client_id?.first_name }} {{
                          appointment?.client_id?.last_name }}</div>
                        <div class="appointment-category">{{ appointment.type }} </div>
                      </li>
                    </ul>
                  </div>
                  <div class="tab-pane fade" id="pills-installations" role="tabpanel"
                    aria-labelledby="pills-installations-tab">
                    <ul class="list-unstyled appointment-list">
                      <div *ngIf="!appointmentsLoading && installationAppointments.length < 1"
                        class="live-demo__item text-center justify-content-center">
                        <h4>No installation appointments found.</h4>
                      </div>
                      <li class="appointment-list-item installation-appointment pe-3"
                        *ngFor="let appointment of installationAppointments">
                        <button *ngIf="appointment?.status?.toLowerCase() !== 'completed'" type="button"
                          class="btn btn-primary btn-complete float-end px-3" [disabled]="appointment.loading"
                          (click)="onInstallationComplete(appointment)">Complete &nbsp; <i
                            class="fas fa-spinner fa-spin" *ngIf="appointment.loading"></i></button>

                        <span class="btn float-end" [style.color]="'green'"
                          *ngIf="appointment?.status?.toLowerCase() === 'completed'">{{ appointment?.status | titlecase
                          }}</span>

                        <div class="appointment-detail pe-3"><i class="far fa-clock me-1"></i>
                          {{ appointment?.datetime | date: 'MMM dd YYYY': 'UTC' }} , {{ appointment?.datetime | date:
                          'hh:mm a': 'UTC' }} ,
                          {{ appointment?.client_id?.first_name }} {{
                          appointment?.client_id?.last_name }}</div>
                        <div class="appointment-category">{{ appointment.type }} </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <textarea class="form-control form-control-white form-control-notes" formControlName="notes"
                placeholder="Notes" trimwhitespacestart></textarea>
            </div>
          </div>
        </form>
      </div>
      <div class="tab-pane fade" [ngClass]="{ 'active show': selectedTab === 'quote' }" id="quote-list" role="tabpanel"
        aria-labelledby="quote-list">
        <div class="client-management__content quote-list-container">
          <app-quote-list [clientId]="client?._id"></app-quote-list>
        </div>
      </div>
      <!-- <div class="tab-pane fade" id="maintenance-agreement" role="tabpanel" aria-labelledby="maintenance-agreement-tab">
        <div class="client-management__content">
          <div class="terms-condition-content">
            <h2 class="title">Terms</h2>
            <div class="description">Placeholder text is the label for possible content in a text box. It can normally
              be found when there are prompts to fill out a form. It’s the hint that tells you ‘Last name’ or the format
              with which to enter your birthdate or phone number. Placeholder text typically exists as a hint to fill in
              actual text. Placeholder text is the label for possible content in a text box. It can normally be found
              when there are prompts to fill out a form.</div>

            <h2 class="title">Conditions</h2>
            <div class="description">Placeholder text is the label for possible content in a text box. It can normally
              be found when there are prompts to fill out a form. It’s the hint that tells you ‘Last name’ or the format
              with which to enter your birthdate or phone number. Placeholder text typically exists as a hint to fill in
              actual text. Placeholder text is the label for possible content in a text box. It can normally be found
              when there are prompts to fill out a form. It’s the hint that tells you</div>
          </div>
        </div>
      </div> -->
      <div class="tab-pane fade" [ngClass]="{ 'active show': selectedTab === 'agreement' }" id="agreement-list"
        role="tabpanel" aria-labelledby="agreement-list-tab">
        <div class="client-management__content">
          <app-sales-agreement-list [clientId]="client?._id"></app-sales-agreement-list>
        </div>
      </div>
      <div class="tab-pane fade" [ngClass]="{ 'active show': selectedTab === 'invoice' }" id="invoice-list"
        role="tabpanel" aria-labelledby="invoice-list-tab">
        <div class="client-management__content">
          <app-invoice-list [clientId]="client?._id" [isTabContent]="true"></app-invoice-list>
        </div>
      </div>
      <div class="tab-pane fade" [ngClass]="{ 'active show': selectedTab === 'payment' }" id="payment-list"
        role="tabpanel" aria-labelledby="payment-list-tab">
        <div class="client-management__content">
          <div class="mt-4">
            <app-payment-list [clientId]="client?._id"></app-payment-list>
            <!-- <app-transaction-list [clientId]="client?._id" [isTabContent]="true"></app-transaction-list> -->
          </div>
        </div>
      </div>
      <div class="tab-pane fade" [ngClass]="{ 'active show': selectedTab === 'virtual-demo'}" id="virtual-demo"
      role="tabpanel" aria-labelledby="virtual-demo'-tab">
        <div class="client-management__content">
          <div class="mt-4">
            <app-virtual-demo-list  [clientId]="client?._id"></app-virtual-demo-list>
          </div>
        </div>
      </div>
      <div class="tab-pane fade" [ngClass]="{ 'active show': selectedTab === 'map' }" id="google-map" role="tabpanel"
        aria-labelledby="google-map-tab">
        <div class="client-management__content">
          <div class="google-map__iframe">
            <agm-map #gm [latitude]="marker.lat" [longitude]="marker.lng">
              <agm-marker [latitude]="marker.lat" [longitude]="marker.lng" (mouseOver)="onMouseOver(infoWindow, gm)">
                <agm-info-window #infoWindow [disableAutoPan]="false">
                  <div class="map-tool-tip">
                    <h5>{{ client.first_name }} {{ client.last_name }}</h5>
                    <p>{{ client.address.line }}</p>
                    <p>{{ client.address.state}} {{ client.address.city}}</p>
                    <p>{{ client.address.country}} {{ client.address.postal}}</p>
                  </div>
                </agm-info-window>
              </agm-marker>
            </agm-map>
          </div>
          <div class="text-start">
            <!-- <button class="btn btn-primary px-5" type="button" [disabled]="updateClientInProgress"
              (click)="onUpdateLocation()">Update Location &nbsp; <i class="fas fa-spinner fa-spin"
                *ngIf="updateClientInProgress"></i></button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- == End Page content == -->

<ng-template #actionTemplate let-row="row" let-value="value">
  <div class="dropdown">
    <button class="btn btn-off-primary dropdown-toggle btn-sm" type="button" id="dropdownMenuButton1"
      data-bs-toggle="dropdown" aria-expanded="false">
      Pending
    </button>
    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
      <li><a class="dropdown-item" href="#">Pending</a></li>
      <li><a class="dropdown-item" href="#">Accept</a></li>
      <li><a class="dropdown-item" href="#">Reject</a></li>
    </ul>
  </div>
</ng-template>

<ng-template #ellipsisTemplate let-row="row" let-value="value">

  <div class="dropdown">
    <button class="btn btn-icon btn-sm" type="button" id="dropdownMenumore" data-bs-toggle="dropdown"
      aria-expanded="false">
      <i class="fas fa-ellipsis-v"></i>
    </button>
    <ul class="dropdown-menu" aria-labelledby="dropdownMenumore">
      <li><a class="dropdown-item" href="#">Pending</a></li>
      <li><a class="dropdown-item" href="#">Accept</a></li>
      <li><a class="dropdown-item" href="#">Reject</a></li>
    </ul>
  </div>
</ng-template>