import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';
import { API_PATH } from './../utils/constants';
import { ILead, ISettings, IBankAccount } from './../utils/models';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  baseUrl: string = environment.baseUrl;

  constructor(
    private httpClient: HttpClient) {
  }

  readSettings(): Observable<ISettings> {
    let url = `${this.baseUrl}${API_PATH.settings}`;

    return this.httpClient.get<ISettings>(url);
  }

  saveSettings(payload: ISettings): Observable<ISettings> {
    let url = `${this.baseUrl}${API_PATH.settings}`;

    return this.httpClient.post<ISettings>(url, payload);
  }

  saveBankAccount(payload: IBankAccount): Observable<IBankAccount> {
    let url = `${this.baseUrl}${API_PATH.setupaccount}`;

    return this.httpClient.post<IBankAccount>(url, payload);
  }

  getBankAccount(): Observable<{ status: boolean, bankAccount: any }> {
    let url = `${this.baseUrl}${API_PATH.bankAccount}`;

    return this.httpClient.get<{ status: boolean, bankAccount: any }>(url);
  }

  getConnectAccount(): Observable<any> {
    let url = `${this.baseUrl}${API_PATH.connectaccount}`;

    return this.httpClient.get<any>(url);
  }
}


