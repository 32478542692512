// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  name: 'local',
  firebase: {
    apiKey: 'AIzaSyC8nG4UaTFPls-A7hFqmmfjCKfjDAwVXLw',
    authDomain: 'lightsapp-df3db.firebaseapp.com',
    projectId: 'lightsapp-df3db',
    storageBucket: 'lightsapp-df3db.appspot.com',
    messagingSenderId: '360505456107',
    appId: '1:360505456107:web:284c2a62378c5d2ddad5ae',
    measurementId: 'G-H28P8MK69Y',
  },
  stripePublicKey: 'pk_test_51JorX3GOfDbwpPMr5tfD8sAU4wJrG00ObyEclYwiC0w8Nm79z24ZCy9AsdxagUGaqO55G6y8BG4xai2RiHL0VCTK00bukpqDdH',
  baseUrl: 'https://inipo5vnh6.execute-api.us-west-2.amazonaws.com/dev/api',
  imageUploadUrl: 'https://lightsbucket-dev.s3.us-west-2.amazonaws.com/',
  bankDocUploadUrl: 'https://files.stripe.com/v1/files',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
