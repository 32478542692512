import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { environment } from './../../../../../environments/environment';
import { ImageService } from './../../../../services/image.service';
import { SettingsService } from './../../../../services/settings.service';
import { UserService } from './../../../../services/user.service';
import { ISettings, IUser } from './../../../../utils/models';

@Component({
  selector: 'app-account-settings',
  templateUrl: './account-settings.component.html',
  styleUrls: ['./account-settings.component.scss']
})
export class AccountSettingsComponent implements OnInit {
  loading: boolean = false;

  settings: ISettings;

  selectedColor: '#061D3D' | '#4CAF51' | '#6E22D0' = '#061D3D';

  saveSettingsInProgress: boolean = false;

  selectedCoverImage: any;

  selectedCoverImageFile: any;

  selectedCoverImageFileName: any;

  selectedProfileImage: any;

  selectedProfileImageFile: any;

  selectedProfileImageFileName: any;

  imageUploadInProgress: boolean = false;

  user: IUser;

  constructor(
    private toastrService: ToastrService,
    private settingsService: SettingsService,
    private imageService: ImageService,
    private userService: UserService
  ) {
    this.userService.user.subscribe((user) => {
      if (user) {
        this.user = user
      }
    });
  }

  ngOnInit(): void {
    this.fetch();
  }

  fetch(): void {
    this.loading = true;

    this.settingsService.readSettings()
      .toPromise()
      .then((res: any) => {
        this.settings = res;

        this.selectedColor = this.settings?.color || '#061D3D';
      })
      .catch((err) => {
        console.log(err);
        this.toastrService.error('Failed to fetch settings details.');
      })
      .finally(() => this.loading = false);
  }

  onColorChange(color: string) {
    const payload = {
      color: color,
      profile_image_url: this.settings?.cover_image_url,
      cover_image_url: this.settings?.cover_image_url
    }

    this.saveSettings(payload);
  }

  onProfileImageChange(event: any) {
    const files: FileList = event.target.files;

    if (files.length === 0) {
      return;
    }

    const file = files[0];
    const mimeType = file.type;

    if (mimeType.match(/image\/*/) == null) {
      this.toastrService.error('Please select image file');
      return;
    }

    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = (_event) => {
      this.selectedProfileImageFile = file;
      this.selectedProfileImageFileName = file.name;
      this.selectedProfileImage = reader.result;
    }
  }

  onSaveProfileImage() {
    this.imageUploadInProgress = true;
    this.imageService.uploadImage(this.selectedProfileImageFile, this.selectedProfileImageFileName)
      .toPromise()
      .then(() => {
        const profile_image_url = `${environment.imageUploadUrl}${this.selectedProfileImageFileName}`;

        const payload = {
          color: this.settings?.color,
          profile_image_url: profile_image_url,
          cover_image_url: this.settings?.cover_image_url
        }

        return this.settingsService.saveSettings(payload).toPromise();
      })
      .then((res) => {
        this.settings.profile_image_url = res.profile_image_url;

        this.selectedProfileImageFile = null;

        this.selectedProfileImage = null;

        this.selectedProfileImageFileName = null;

        this.user.settings = res;

        this.userService.user.next(this.user);

        this.toastrService.success('Avatar image uploaded successfully.')
      })
      .catch(() => this.toastrService.error('Failed to upload avatar image, Try again.'))
      .finally(() => this.imageUploadInProgress = false);
  }

  onCoverImageChange(event: any) {
    const files: FileList = event.target.files;

    if (files.length === 0) {
      return;
    }

    const file = files[0];
    const mimeType = file.type;

    if (mimeType.match(/image\/*/) == null) {
      this.toastrService.error('Please select image file');
      return;
    }

    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = (_event) => {
      this.selectedCoverImageFile = file;
      this.selectedCoverImageFileName = file.name;
      this.selectedCoverImage = reader.result;
    }
  }

  onSaveCoverImage() {
    this.imageUploadInProgress = true;
    this.imageService.uploadImage(this.selectedCoverImageFile, this.selectedCoverImageFileName)
      .toPromise()
      .then(() => {
        const cover_image_url = `${environment.imageUploadUrl}${this.selectedCoverImageFileName}`;

        const payload = {
          color: this.settings?.color,
          profile_image_url: this.settings?.profile_image_url,
          cover_image_url: cover_image_url
        }

        return this.settingsService.saveSettings(payload).toPromise();
      })
      .then(() => {
        this.settings.cover_image_url = `${environment.imageUploadUrl}${this.selectedCoverImageFileName}`;

        this.selectedCoverImageFile = null;

        this.selectedCoverImage = null;

        this.selectedCoverImageFileName = null;

        this.toastrService.success('Cover image uploaded successfully.')
      })
      .catch(() => this.toastrService.error('Failed to upload cover image, Try again.'))
      .finally(() => this.imageUploadInProgress = false);
  }

  onAvatarChange() {
    const payload = {
      color: this.settings?.color,
      profile_image_url: this.settings?.cover_image_url,
      cover_image_url: this.settings?.cover_image_url
    }

    this.saveSettings(payload);
  }

  saveSettings(payload: any) {
    this.saveSettingsInProgress = true;

    this.settingsService.saveSettings(payload)
      .toPromise()
      .then(() => this.toastrService.success('Settings saved successfully.'))
      .catch(() => this.toastrService.error('Failed to save settings details.'))
      .finally(() => this.saveSettingsInProgress = false);
  }

}
