import { Location } from '@angular/common';
import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { ImageService } from 'src/app/services/image.service';
import { AgreementService } from '../../../../services/agreement-service';
import { IClient, IProduct, IQuote, ISalesAgreement } from '../../../../utils/models';
import { environment } from './../../../../../environments/environment';
import { PurchaseOrderService } from './../../../../services/purchase-order-service';
import { QuoteService } from './../../../../services/quote-service';
import { PURCHASE_ORDER_STATUS } from './../../../../utils/constants';
import { IPurchaseOrder, IUser, IVendor } from './../../../../utils/models';

@Component({
  selector: 'app-purchase-order-template',
  templateUrl: './purchase-order-template.component.html',
  styleUrls: ['./purchase-order-template.component.scss']
})
export class PurchaseOrderTemplateComponent implements OnInit {

  client_id: string;

  purchaseOrderId: string;

  salesAgreementId: string;

  quote: IQuote;

  products: IProduct[] = [];

  client: IClient;

  user: IUser;

  salesAgreement: ISalesAgreement;

  purchaseOrder: IPurchaseOrder;

  // salesTotal: number = 0;

  loading: boolean = false;

  createPurchaseOrderInprogress: boolean = false;

  updatePurchaseOrderInprogress: boolean = false;

  // due_date: FormControl = new FormControl(null);

  description: FormControl = new FormControl(null);

  routeParam: any;

  isCreateFlow: boolean = false;

  isViewMode: boolean = false;

  isEditMode: boolean = false;

  currentDate = new Date();

  updateStatusInProgress: boolean = false;

  purchaseOrderStatus = PURCHASE_ORDER_STATUS;

  vendor: IVendor;

  productForm: FormGroup;

  emailTemplateModalRef: NgbModalRef;

  Editor: any = ClassicEditor;

  editorData: string;

  config = {};

  @ViewChild('emailTemplate', { static: true })
  emailTemplate: TemplateRef<any>;

  subject: FormControl = new FormControl(null, Validators.required);

  sendToClientInProgress: boolean = false;

  @ViewChild('pdfContent', { static: false }) pdfContent: ElementRef;

  constructor(
    private agreementService: AgreementService,
    private purchaseOrderService: PurchaseOrderService,
    private toastrService: ToastrService,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private router: Router,
    private formBuilder: FormBuilder,
    private quoteService: QuoteService,
    private modalService: NgbModal,
    private imageService: ImageService
  ) {
    this.productForm = this.formBuilder.group({
      item_id: new FormControl({ value: null, disabled: true }, Validators.required),
      name: new FormControl({ value: null, disabled: true }, Validators.required),
      quantity: new FormControl({ value: null, disabled: true }, Validators.required),
      description: new FormControl({ value: null, disabled: true }, Validators.required),
      price: new FormControl({ value: null, disabled: true }, Validators.required),
      amount: new FormControl({ value: null, disabled: true }, Validators.required),
    });
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((param) => {
      this.routeParam = param;

      if (this.router.url.includes('create-purchase-order')) {
        this.isCreateFlow = true;

        this.client_id = param.clientId;

        this.salesAgreementId = param.salesAgreementId;

        this.fetchAgreement();
      }

      if (this.router.url.includes('edit-purchase-order')) {
        this.isEditMode = true;

        this.purchaseOrderId = param.purchaseOrderId;

        this.fetchPurchaseOrder();
      }

      if (this.router.url.includes('view-purchase-order')) {
        this.isViewMode = true;

        this.purchaseOrderId = param.purchaseOrderId;

        this.fetchPurchaseOrder();
      }
    });
  }

  fetchAgreement() {
    this.loading = true;

    this.agreementService.readSalesAgreement(this.salesAgreementId)
      .toPromise()
      .then((res) => {
        this.salesAgreement = res as ISalesAgreement;

        this.client = this.salesAgreement.client_id;

        this.quote = this.salesAgreement.quote_id as IQuote;

        this.products = this.quote.items;

        // this.updateSalesTotal();
      })
      .catch(() => this.toastrService.error('Failed to fetch quote details.'))
      .finally(() => this.loading = false);
  }

  fetchPurchaseOrder() {
    this.loading = true;

    this.purchaseOrderService.getPurchaseOrder(this.purchaseOrderId)
      .toPromise()
      .then((res) => {
        this.purchaseOrder = res;

        this.client = this.purchaseOrder.client_id;

        this.products = this.purchaseOrder.items;

        this.vendor = this.purchaseOrder.vendor_id;

        this.user = this.purchaseOrder?.user_id;

        this.description.setValue(this.purchaseOrder.description);

        if (this.isViewMode) {
          this.description.disable();
        }

        // this.due_date.setValue(moment(this.invoice.due_date).format('yyyy-MM-DD'));

        // this.updateSalesTotal();
      })
      .catch(() => this.toastrService.error('Failed to fetch quote details.'))
      .finally(() => this.loading = false);
  }

  // updateSalesTotal() {
  //   let total = 0;

  //   this.products.forEach((r: any) => total = total + parseFloat(r.amount));

  //   this.salesTotal = total;
  // }

  onCancel() {
    this.location.back();
  }

  onSave() {
    if (!this.description.value) {
      this.toastrService.error('Please enter the description.');
      return;
    }

    if (!this.vendor) {
      this.toastrService.error('Please select vendor');
      return;
    }

    this.createPurchaseOrderInprogress = true;

    const payload = {
      client_id: this.client_id,
      items: this.products,
      description: this.description.value,
      salesagreement_id: this.salesAgreementId,
      vendor_id: this.vendor._id,
    } as IPurchaseOrder;

    const operation = this.isCreateFlow ? 'created' : 'update';

    this.purchaseOrderService.createPurchaseOrder(payload, this.purchaseOrder._id)
      .toPromise()
      .then(() => {
        this.location.back();

        this.toastrService.success(`Purchase order ${operation} successfully.`);
      })
      .catch(() => this.toastrService.error(`Failed to ${operation} purchase order, try again.`))
      .finally(() => this.createPurchaseOrderInprogress = false);
  }


  onUpdateStatus() {
    this.updateStatusInProgress = true;

    this.purchaseOrderService.updatePurchaseOrderStatus(this.purchaseOrderId as string, { status: 'received' })
      .toPromise()
      .then((res) => {
        this.purchaseOrder.status = res.status;

        this.toastrService.success(`Purchase order status updated successfully.`)
      })
      .catch(() => this.toastrService.error('Failed to update purchase orde status.'))
      .finally(() => this.updateStatusInProgress = false);
  }

  onSelectVendor(vendor: any): void {
    this.vendor = vendor;
  }

  onSelectOption(product: any): void {
    let data = product;

    data = Object.assign({}, { quantity: 1, amount: product.price }, product);

    this.productForm.controls.quantity.enable();

    this.productForm.patchValue(data);

    this.onAdd();
  }

  onDeleteProduct(product: IProduct) {
    this.products = this.products.filter((r) => r.item_id !== product.item_id);

    setTimeout(() => this.setTotalProductCost());
  }

  onAdd() {
    const formData = this.productForm.getRawValue();

    const product = this.products.find((p) => p.item_id === formData.item_id);

    if (product) {
      product.quantity = product.quantity + formData.quantity;

      product.amount = parseFloat((product.quantity as number * product.price).toFixed(2));
    } else {
      const data: IProduct = {
        user_id: formData.user_id,
        name: formData.name,
        description: formData.description,
        price: formData.price,
        item_id: formData.item_id,
        quantity: formData.quantity,
        tax: 0,
        amount: formData.amount
      }

      this.products.push(data);
    }

    this.productForm.reset();

    this.productForm.controls.quantity.disable();

    this.quoteService.clearproduct.next();

    setTimeout(() => this.setTotalProductCost());
  }

  setTotalProductCost() {
    let totalProductCost: number = 0;

    this.products.forEach((r) => totalProductCost = totalProductCost + parseFloat(r.amount));

    this.purchaseOrder.total_amount = parseFloat(totalProductCost.toFixed(2));
  }

  openEmailTemplateModal() {
    this.editorData = this.getMessageTemplate();

    this.emailTemplateModalRef = this.modalService.open(
      this.emailTemplate, { size: 'lg', windowClass: 'create-transaction-modal' });
  }

  generatePurchaseAgreement() {
    this.sendToClientInProgress = true;

    html2canvas(this.pdfContent.nativeElement, {
      allowTaint: true,
      useCORS: true,
      onclone: (doc: Document, ele: HTMLElement) => {
        ele.classList.add('white-background');
      }
    })
      .then(canvas => {
        const imgWidth = 208;

        const imgHeight = canvas.height * imgWidth / canvas.width;

        const contentDataURL = canvas.toDataURL('image/png')

        let pdf: jsPDF = new jsPDF('p', 'mm', 'a4');

        pdf.addImage(contentDataURL, 'PNG', 0, 0, imgWidth, imgHeight);

        const blob = pdf.output('blob');

        const file = new File([blob], `purchase_order_${this.purchaseOrder._id}.pdf`, { type: 'application/pdf' });

        return this.imageService.uploadPdf(file, `purchase_order_${this.purchaseOrder._id}.pdf`).toPromise()
      })
      .then((res) => {
        const docUrl = `${environment.imageUploadUrl}purchase_order_${this.purchaseOrder._id}.pdf`;

        const payload = {
          url: docUrl,
          email_html_body: this.getFullInvoiceTemplate().trim(),
          email_subject: this.subject.value
        }

        return this.purchaseOrderService.sendPurchaseorderToClient(this.purchaseOrder._id as string, payload).toPromise()
      })
      .then(() => this.purchaseOrderService.getPurchaseOrder(this.purchaseOrder._id as string).toPromise())
      .then((res) => this.purchaseOrder = res)
      .then(() => {
        this.emailTemplateModalRef.dismiss();

        this.toastrService.success('Purchase order generated and send to client successfully.');
      })
      .catch((err) => {
        console.log(err);
        this.toastrService.error('Failed to generate purchase order and send to client.');
      })
      .finally(() => this.sendToClientInProgress = false);
  }

  getFullInvoiceTemplate(): string {
    return this.getHeadTemplate() + this.editorData + this.getFooterTemplate();
  }

  getMessageTemplate(): string {
    const purchaseOrder = this.purchaseOrder;
    return `<h2 style='font-weight: bold;font-size: 32px;line-height: normal;color: #000;margin-top: 0;margin-bottom: 30px;'>Hello ${this.vendor?.name}</h2><p style='font-weight: normal; font-size: 15px; line-height: 20px; margin-bottom: 16px; color: #6d7296;'>Please see the attached purchase order for $${purchaseOrder.total_amount}, created on ${moment(purchaseOrder.created_at, 'YYYY-MM-DD HH:mm').format('MMM DD YYYY')}.</p><br/>`;
  }

  getHeadTemplate(): string {
    return `<!DOCTYPE html><html><head><meta charset='utf-8'><meta http-equiv='Content-Type' content='text/html charset=UTF-8' /><title>10xLighting</title><link href='https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap' rel='stylesheet'></head><body style='margin: 0;box-sizing: border-box;'><table style='width: 100%; max-width: 750px; margin: auto; font-family: DM Sans, sans-serif'><tr><td style='text-align: center;padding-top: 30px;padding-bottom: 30px;font-weight: bold;font-size: 44px;line-height: 54px;color: #000000;'> ${this.getUserNameOrEmail()} </td></tr><tr><td style='padding-left: 30px; padding-right: 30px;'><table style='width: 100%; max-width: 750px; margin: auto; background: #f9f9f9; border-radius: 28px;'><tr><td style='padding: 50px 50px'>`;
  }

  getUserNameOrEmail(): string {
    if (this.user.first_name) {
      return `${this.user.first_name} ${this.user.last_name}`;
    }

    return this.user.email;
  }

  getFooterTemplate(): string {
    let template = ``;

    // const invoice = this.invoice;

    // const check_out_url = `${environment.baseUrl}${API_PATH.invoiceCheckout}/${invoice._id}`;

    // if (invoice.status?.toLowerCase() !== this.invoiceStatus.PAID) {
    //   template = `<a href='${check_out_url}' style='padding: 13px 20px; transition: all ease-in-out 0.2s; border-radius: 10px; font-weight: 600; font-size: 16px; line-height: 22px; display: inline-flex; align-items: center; justify-content: center; border: none; color: #fff; background-color: #6e22d0; box-shadow: -14px 19px 30px rgba(118, 132, 255, 0.15%); text-decoration: none; width: 150px;'>Pay Now</a>`;
    // }

    template = template + `</td></tr></table></td></tr><tr><td style='font-weight: 500; font-size: 18px; line-height: normal; text-align: center; color: #000; padding-top: 20px; padding-bottom: 60px; padding-left: 30px; padding-right: 30px;'>If you have questions or trouble logging in, please do not reply to this email,<br/> instead please contact <span style='text-decoration: none;color: #6e22d0;'>${this.user.email}</span></td></tr></table></body></html>`;

    return template;
  }


}
