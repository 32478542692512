import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { API_PATH } from '../utils/constants';
import { IAppoinments, IAppointmentList, IPagination, ISummary } from './../utils/models';

@Injectable({
  providedIn: 'root'
})
export class AppointmentService {
  baseUrl: string = environment.baseUrl;

  constructor(
    private httpClient: HttpClient) {
  }

  getSummary(from: string, to: string): Observable<ISummary> {
    let url = `${this.baseUrl}${API_PATH.dashboard}?from=${from}&to=${to}`;

    return this.httpClient.get<ISummary>(url);
  }

  getAppointments(from: string, to: string): Observable<IAppoinments[]> {
    let url = `${this.baseUrl}${API_PATH.appointment}?from=${from}&to=${to}`;

    return this.httpClient.get<IAppoinments[]>(url);
  }

  getAppointmentsByType(type: string): Observable<IAppoinments[]> {
    let url = `${this.baseUrl}${API_PATH.appointment}?type=${type}`;

    return this.httpClient.get<IAppoinments[]>(url);
  }

  getLiveDemoAppointmentsbyClient(id: string): Observable<IAppoinments[]> {
    let url = `${this.baseUrl}${API_PATH.appointment}?type=Live Demo&client_id=${id}`;

    return this.httpClient.get<IAppoinments[]>(url);
  }

  getInstallationAppointmentsbyClient(id: string): Observable<IAppoinments[]> {
    let url = `${this.baseUrl}${API_PATH.appointment}?type=Installation&client_id=${id}`;

    return this.httpClient.get<IAppoinments[]>(url);
  }

  createAppointment(payload: IAppoinments): Observable<IAppoinments> {
    let url = `${this.baseUrl}${API_PATH.createAppointment}`;

    return this.httpClient.post<IAppoinments>(url, payload);
  }

  updateAppointment(payload: IAppoinments, id: string): Observable<IAppoinments> {
    let url = `${this.baseUrl}${API_PATH.updateAppointment}/${id}`;

    return this.httpClient.post<IAppoinments>(url, payload);
  }

  deleteAppointment(id: string): Observable<IAppoinments> {
    let url = `${this.baseUrl}${API_PATH.appointment}/${id}`;

    return this.httpClient.delete<IAppoinments>(url);
  }
}


