import { ToastrService } from 'ngx-toastr';
import { takeWhile } from 'rxjs/operators';
import { LiveDemoService } from './../../../../services/live-demo.service';
import { IDemo } from './../../../../utils/models';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-live-demo-scheduling',
  templateUrl: './live-demo-scheduling.component.html',
  styleUrls: ['./live-demo-scheduling.component.scss']
})
export class LiveDemoSchedulingComponent implements OnInit, OnDestroy {
  demo: IDemo;

  loading: boolean = false;

  componentActive = true;

  constructor(
    private toastrService: ToastrService,
    private activatedRoute: ActivatedRoute,
    private liveDemoService: LiveDemoService) { }

  ngOnInit(): void {
    this.loading = true;

    this.activatedRoute.params
      .pipe(takeWhile(() => this.componentActive))
      .subscribe((param) => {
        const id = param.id;

        this.fetch(id);
      })

  }

  fetch(id: string) {
    this.liveDemoService.readLiveDemoDetails(id)
      .toPromise()
      .then((res) => this.demo = res)
      .catch(() => this.toastrService.error('Failed to fetch demo details'))
      .finally(() => this.loading = false);
  }

  ngOnDestroy(): void {
    this.componentActive = false
  }
}
