import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { API_PATH } from '../utils/constants';
import { IClient, IClientList, IPagination } from './../utils/models';

@Injectable({
  providedIn: 'root'
})
export class ClientManagementService {
  baseUrl: string = environment.baseUrl;

  clearproduct: Subject<any> = new Subject<any>();

  constructor(
    private httpClient: HttpClient) {
  }

  createClient(client: IClient): Observable<IClient> {
    let url = `${this.baseUrl}${API_PATH.createClient}`;

    return this.httpClient.post<IClient>(url, client);
  }

  readAllClient(pagination?: IPagination, search?: string): Observable<IClientList> {
    let url = `${this.baseUrl}${API_PATH.client}`;

    if (pagination) {
      url = `${url}?limit=${pagination?.limit}&page=${pagination?.page + 1}`;
    }

    if (search) {
      url = `${url}&search=${search}`;
    }

    return this.httpClient.get<IClientList>(url);
  }

  readClient(id: string): Observable<IClient> {
    let url = `${this.baseUrl}${API_PATH.client}/${id}`;

    return this.httpClient.get<IClient>(url);
  }

  updateClient(id: string, payload: IClient): Observable<any> {
    let url = `${this.baseUrl}${API_PATH.client}/${id}`;

    return this.httpClient.post<any>(url, payload);
  }
}


