import { UserService } from './../../../../services/user.service';
import { Location } from '@angular/common';
import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { environment } from './../../../../../environments/environment';
import { AgreementService } from './../../../../services/agreement-service';
import { ImageService } from './../../../../services/image.service';
import { InvoiceService } from './../../../../services/invoice-service';
import { API_PATH, INVOICE_STATUS } from './../../../../utils/constants';
import { IClient, IInvoice, IProduct, IQuote, ISalesAgreement, IUser } from './../../../../utils/models';


@Component({
  selector: 'app-invoice-template',
  templateUrl: './invoice-template.component.html',
  styleUrls: ['./invoice-template.component.scss']
})
export class InvoiceTemplateComponent implements OnInit {

  client_id: string;

  invoice_id: string;

  salesAgreementId: string;

  quote: IQuote;

  products: IProduct[] = [];

  client: IClient;

  salesAgreement: ISalesAgreement;

  invoice: IInvoice;

  // salesTotal: number = 0;

  loading: boolean = false;

  createInvoiceInprogress: boolean = false;

  due_date: FormControl = new FormControl(null);

  routeParam: any;

  isCreateFlow: boolean = false;

  isUpdateFlow: boolean = false;

  isViewMode: boolean = false;

  currentDate = new Date();

  sendToClientInProgress: boolean = false;

  invoiceStatus = INVOICE_STATUS;

  updateStatusInProgress: boolean = false;

  todayDate: string;

  @ViewChild('pdfContent', { static: false }) pdfContent: ElementRef;

  @ViewChild('transactionTemplate', { static: true })
  transactionTemplate: TemplateRef<any>;

  @ViewChild('invoiceTemplate', { static: true })
  invoiceTemplate: TemplateRef<any>;

  createTransactionModalRef: NgbModalRef;

  transactionForm: FormGroup;

  createTransactionInprogress: boolean = false;

  total_amount: FormControl = new FormControl(0, [Validators.required]);

  deposit_amount: FormControl = new FormControl(0, [Validators.required]);

  balance: number;

  invoiceTemplateModalRef: NgbModalRef;

  Editor: any = ClassicEditor;

  editorData: string;

  config = {};

  subject: FormControl = new FormControl(null, Validators.required);

  transactionPlaceHolder: string = 'Notes';

  user: IUser;

  constructor(
    private agreementService: AgreementService,
    private invoiceService: InvoiceService,
    private toastrService: ToastrService,
    private activatedRoute: ActivatedRoute,
    public location: Location,
    private router: Router,
    private imageService: ImageService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private userService: UserService,
  ) {
    this.userService.user.subscribe((user) => {
      if (user) {
        this.user = user;
      }
    });
  }

  ngOnInit(): void {
    this.todayDate = moment().format('YYYY-MM-DD');

    this.activatedRoute.params.subscribe((param) => {
      this.routeParam = param;

      if (this.router.url.includes('create-invoice')) {
        this.isCreateFlow = true;

        this.client_id = param.clientId;

        this.salesAgreementId = param.salesAgreementId;

        this.fetchAgreement();
      }

      if (this.router.url.includes('update-invoice')) {
        this.isUpdateFlow = true;

        this.invoice_id = param.invoiceId;

        this.fetchInvoice();
      }

      if (this.router.url.includes('view-invoice')) {
        this.isViewMode = true;

        this.invoice_id = param.invoiceId;

        this.fetchInvoice();
      }
    });

    this.activatedRoute.params.subscribe((param) => {
      if (param.clientId) {

      }
    });

    this.deposit_amount.valueChanges.subscribe((val) => {
      if (this.salesAgreement?.total_amount && val && val > this.salesAgreement?.total_amount) {
        this.toastrService.error('Deposit amount cannot be greater than total amount');

        this.deposit_amount.setValue(0);
      } else if (this.salesAgreement?.total_amount) {
        const amt: any = (this.salesAgreement?.total_amount - val).toFixed(2);
        this.total_amount.setValue(amt);
      }
    });

    // this.total_amount.valueChanges.subscribe((val) => {
    //   if (this.salesAgreement) {
    //     this.balance = this.salesAgreement.total_balance as number - val;
    //   }
    // })
  }

  fetchAgreement() {
    this.loading = true;

    this.agreementService.readSalesAgreement(this.salesAgreementId)
      .toPromise()
      .then((res) => {
        this.salesAgreement = res as ISalesAgreement;

        this.client = this.salesAgreement.client_id;

        this.quote = this.salesAgreement.quote_id as IQuote;

        this.products = this.quote.items;

        this.total_amount.setValue(this.salesAgreement.total_amount);

        // if (this.salesAgreement.total_balance) {
        //   this.balance = this.salesAgreement.total_balance - this.total_amount.value;
        // }


        // this.updateSalesTotal();
      })
      .catch(() => this.toastrService.error('Failed to fetch quote details.'))
      .finally(() => this.loading = false);
  }

  fetchInvoice() {
    this.loading = true;

    this.invoiceService.getInvoice(this.invoice_id)
      .toPromise()
      .then((res) => {
        this.invoice = res;

        this.client = this.invoice.client_id;

        this.products = this.invoice.items;

        this.salesAgreementId = this.invoice.salesagreement_id;

        this.due_date.setValue(moment(this.invoice.due_date).format('yyyy-MM-DD'));

        this.editorData = this.getInvoiceMessageTemplate();

        this.deposit_amount.setValue(this.invoice.deposit_amount);

        this.total_amount.setValue(this.invoice.due_amount);

        // this.updateSalesTotal();
      })
      .catch(() => this.toastrService.error('Failed to fetch quote details.'))
      .finally(() => this.loading = false);
  }

  // updateSalesTotal() {
  //   let total = 0;

  //   this.products.forEach((r: any) => total = total + parseFloat(r.amount));

  //   this.salesTotal = total;
  // }

  onCancel() {
    this.location.back();
  }

  onSave() {
    if (!this.due_date.value) {
      this.toastrService.error('Please select due date.');
      return;
    }

    if (!this.total_amount.value || isNaN(this.total_amount.value) || this.total_amount.value <= 0) {
      this.toastrService.error('Invoice total amount should be greater than 0.');
      return;
    }

    if (this.salesAgreement.total_balance && this.total_amount.value > this.salesAgreement.total_balance) {
      this.toastrService.error(`Invoice amount should not greater than balance ${this.salesAgreement.total_balance.toFixed(2)}`);
      return;
    }

    this.createInvoiceInprogress = true;

    const payload = {
      client_id: this.client_id,
      salesagreement_id: this.salesAgreementId,
      items: this.products,
      due_date: this.due_date.value,
      total_amount: parseFloat(this.total_amount.value),
      deposit_amount: parseFloat(this.deposit_amount.value),
    } as IInvoice;

    this.invoiceService.createInvoice(payload, this.invoice_id)
      .toPromise()
      .then(() => {
        this.router.navigate(['client-update', this.client._id], { queryParams: { tab: 'invoice' } });

        this.toastrService.success('Invoice created successfully.')
      })
      .catch(() => this.toastrService.error('Failed to create invoice, Try again.'))
      .finally(() => this.createInvoiceInprogress = false);
  }

  // sendToClient() {
  //   this.sendToClientInProgress = true;

  //   this.invoiceService.sendInvoiceToClient(this.invoice_id as string)
  //     .toPromise()
  //     .then(() => this.toastrService.success('Invoice sent successfully.'))
  //     .catch(() => this.toastrService.error('Failed to sent invoice.'))
  //     .finally(() => this.sendToClientInProgress = false);
  // }

  onUpdateStatus() {
    this.updateStatusInProgress = true;

    this.invoiceService.updateInvoiceStatus(this.invoice_id as string, { status: 'paid' })
      .toPromise()
      .then((res) => {
        this.invoice.status = res.status;

        this.toastrService.success('Invoice status updated successfully')
      })
      .catch(() => this.toastrService.error('Failed to update invoice status'))
      .finally(() => this.updateStatusInProgress = false);
  }

  openInvoiceTemplateModal() {
    this.invoiceTemplateModalRef = this.modalService.open(
      this.invoiceTemplate, { size: 'lg', windowClass: 'create-transaction-modal' });
  }

  generateInvoice() {
    this.sendToClientInProgress = true;

    html2canvas(this.pdfContent.nativeElement, {
      onclone: (doc: Document, ele: HTMLElement) => {
        ele.classList.add('white-background');
      }
    })
      .then(canvas => {
        const imgWidth = 208;

        const imgHeight = canvas.height * imgWidth / canvas.width;

        const contentDataURL = canvas.toDataURL('image/png')

        let pdf: jsPDF = new jsPDF('p', 'mm', 'a4');

        pdf.addImage(contentDataURL, 'PNG', 0, 0, imgWidth, imgHeight);

        const blob = pdf.output('blob');

        const file = new File([blob], `invoice_${this.invoice_id}.pdf`, { type: 'application/pdf' });

        return this.imageService.uploadPdf(file, `invoice_${this.invoice_id}.pdf`).toPromise()
      })
      .then((res) => {
        const invoiceUploadUrl = `${environment.imageUploadUrl}invoice_${this.invoice_id}.pdf`;

        const payload = {
          url: invoiceUploadUrl,
          email_html_body: this.getFullInvoiceTemplate().trim(),
          email_subject: this.subject.value
        }

        return this.invoiceService.sendInvoiceToClient(this.invoice_id as string, payload).toPromise()
      })
      .then(() => this.invoiceService.getInvoice(this.invoice_id).toPromise())
      .then((res) => this.invoice = res)
      .then(() => {
        this.invoiceTemplateModalRef.dismiss();

        this.toastrService.success('Invoice generated and sent to client successfully.');
      })
      .catch((err) => {
        this.toastrService.error('Failed to generate invoice and send to client.');
      })
      .finally(() => this.sendToClientInProgress = false);
  }

  openCreateTransactionModal() {
    this.transactionForm = this.formBuilder.group({
      client_id: new FormControl(this.client._id, [Validators.required]),
      invoice_id: new FormControl(this.invoice_id, [Validators.required]),
      status: new FormControl('Completed', [Validators.required]),
      description: new FormControl(null, [Validators.required]),
      payment_method: new FormControl(null, [Validators.required]),
      date: new FormControl({ value: this.todayDate, disabled: true }, [Validators.required]),
      amount: new FormControl(this.invoice.due_amount, [Validators.required]),
    });

    this.transactionForm.controls.amount.valueChanges.subscribe((val) => {
      if (this.invoice?.due_amount && val && val > this.invoice?.due_amount) {
        this.toastrService.error('Amount cannot be greater than due amount');
        this.transactionForm.controls.amount.setValue(this.invoice?.due_amount);
      }
    });

    this.transactionPlaceHolder = 'Notes';

    this.transactionForm.controls.payment_method.valueChanges.subscribe((v) => {
      if (v === 'check') {
        this.transactionPlaceHolder = 'Check Number';
      } else {
        this.transactionPlaceHolder = 'Notes';
      }
    });

    this.createTransactionModalRef = this.modalService.open(this.transactionTemplate, { size: 'lg', windowClass: 'create-transaction-modal' });
  }

  createTransaction() {
    const formData = this.transactionForm.getRawValue();

    const transactionAmount = formData.amount;

    if (!transactionAmount || isNaN(transactionAmount) || transactionAmount <= 0) {
      this.toastrService.error('Invoice total amount should be greater than 0.');
      return;
    }

    if (this.invoice.total_amount && transactionAmount > this.invoice.total_amount) {
      this.toastrService.error(`Transaction amount should not greater than invoice amount ${this.invoice.total_amount.toFixed(2)}`);
      return;
    }

    this.createTransactionInprogress = true;

    formData.amount = parseFloat(formData.amount);

    this.invoiceService.createTransaction(formData)
      .toPromise()
      .then(() => {
        this.fetchInvoice();

        this.createTransactionModalRef.dismiss();

        this.toastrService.success('Transaction created successfully.');
      })
      .catch(() => this.toastrService.error('Failed to create transaction.'))
      .finally(() => this.createTransactionInprogress = false);
  }

  getFullInvoiceTemplate(): string {
    return this.getInvoiceHeadTemplate() + this.editorData + this.getIvoiceFooterTemplate();
  }

  getInvoiceMessageTemplate(): string {
    const invoice = this.invoice;

    if (invoice.deposit_amount && invoice.deposit_amount > 0) {
      return `<h2 style='font-weight: bold;font-size: 32px;line-height: normal;color: #000;margin-top: 0;margin-bottom: 30px;'>Hello ${invoice.client_id.first_name} ${invoice.client_id.last_name}</h2><p style='font-weight: normal; font-size: 15px; line-height: 20px; margin-bottom: 16px; color: #6d7296;'>You have a new Invoice #${invoice._id} for $${invoice.total_amount} with deposit amount $${invoice.deposit_amount} and balance is $${invoice.due_amount}, due on ${moment(invoice.due_date, 'YYYY-MM-DD HH:mm').format('MMM DD YYYY')}.</p><br/>`;
    }

    return `<h2 style='font-weight: bold;font-size: 32px;line-height: normal;color: #000;margin-top: 0;margin-bottom: 30px;'>Hello ${invoice.client_id.first_name} ${invoice.client_id.last_name}</h2><p style='font-weight: normal; font-size: 15px; line-height: 20px; margin-bottom: 16px; color: #6d7296;'>You have a new Invoice #${invoice._id} for $${invoice.total_amount}, due on ${moment(invoice.due_date, 'YYYY-MM-DD HH:mm').format('MMM DD YYYY')}.</p><br/>`;
  }

  getInvoiceHeadTemplate(): string {
    return `<!DOCTYPE html><html><head><meta charset='utf-8'><meta http-equiv='Content-Type' content='text/html charset=UTF-8' /><title>10xLighting</title><link href='https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap' rel='stylesheet'></head><body style='margin: 0;box-sizing: border-box;'><table style='width: 100%; max-width: 750px; margin: auto; font-family: DM Sans, sans-serif'><tr><td style='text-align: center;padding-top: 30px;padding-bottom: 30px;font-weight: bold;font-size: 44px;line-height: 54px;color: #000000;'> ${this.getUserNameOrEmail()} </td></tr><tr><td style='padding-left: 30px; padding-right: 30px;'><table style='width: 100%; max-width: 750px; margin: auto; background: #f9f9f9; border-radius: 28px;'><tr><td style='padding: 50px 50px'>`;
  }

  getUserNameOrEmail(): string {
    if (this.user.first_name) {
      return `${this.user.first_name} ${this.user.last_name}`;
    }

    return this.user.email;
  }

  getIvoiceFooterTemplate(): string {
    let template = ``;

    const invoice = this.invoice;

    const check_out_url = `${environment.baseUrl}${API_PATH.invoiceCheckout}/${invoice._id}`;

    if (invoice.status?.toLowerCase() !== this.invoiceStatus.PAID) {
      template = `<a href='${check_out_url}' style='padding: 13px 20px; transition: all ease-in-out 0.2s; border-radius: 10px; font-weight: 600; font-size: 16px; line-height: 22px; display: inline-flex; align-items: center; justify-content: center; border: none; color: #fff; background-color: #6e22d0; box-shadow: -14px 19px 30px rgba(118, 132, 255, 0.15%); text-decoration: none; width: 150px;'>Pay Now</a>`;
    }

    template = template + `</td></tr></table></td></tr><tr><td style='font-weight: 500; font-size: 18px; line-height: normal; text-align: center; color: #000; padding-top: 20px; padding-bottom: 60px; padding-left: 30px; padding-right: 30px;'>If you have questions or trouble logging in, please do not reply to this email,<br/> instead please contact <span style='text-decoration: none;color: #6e22d0;'>${this.user.email}</span></td></tr></table></body></html>`;

    return template;
  }
}
