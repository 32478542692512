<!-- == Start Page Content here == -->
<div [ngClass]="{ 'content-page': isFullListPage }">
  <div class="page-title-group">
    <div class="page-title-group__left" *ngIf="isFullListPage">
      <h2 class="title">Sales Agreement List &nbsp; <i class="fas fa-spinner fa-spin" *ngIf="loading"></i></h2>
    </div>

    <div class="page-title-group__right d-flex" *ngIf="isFullListPage || !clientId">
      <div class="search-bar">
        <span class="searchbar-icon">
          <span class="light-icon light-icon-search"></span>
        </span>
        <input type="search" name="search" class="search-control form-control form-control-white" placeholder="Search"
          [formControl]="searchControl" />
        <span class="searchbar-close-icon d-lg-none cursor-pointer">
          <span class="light-icon light-icon-close cursor-pointer"></span>
        </span>
      </div>
    </div>
  </div>
  <div class="content-page__body">
    <div class="tab-content" id="generate-invoice_tabContent">
      <div class="tab-pane fade show active" id="generate-invoice" role="tabpanel"
        aria-labelledby="generate-invoice-tab">
        <div class="generate-invoice__content">
          <div *ngIf="loading && !isFullListPage"><i class="fas fa-spinner fa-spin"></i></div>
          <div class="mt-4" *ngIf="rows.length || !loading">
            <ngx-datatable [rows]="rows" [columns]="columns" [limit]="10" rowHeight="auto" [headerHeight]="58" [footerHeight]="50" [columnMode]="'force'" [loadingIndicator]="loading" [externalPaging]="true"
              [count]="pagination.total_records" [limit]="pagination.limit" [offset]="pagination.page"
              (page)="setPage($event)" [scrollbarH]="true"></ngx-datatable>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- == End Page content == -->

<!-- NGX TABLE CELL TEMPLATES START -->
<ng-template #dateTemplate let-row="row" let-value="value">
  <span>{{ value | date: 'dd MMM YYYY': 'UTC' }}</span>
</ng-template>

<ng-template #currencyTemplate let-row="row" let-value="value">
  <span>{{ value | currency : 'USD' : 'symbol' }}</span>
</ng-template>

<ng-template #installtionTemplate let-row="row" let-value="value">
  <div class="table-actions">
    <button *ngIf="!row.appointment" class="btn btn-off-primary btn-sm" type="button" aria-expanded="false" ngbTooltip="Create Installation"
      (click)="createInstalltion(row)" container="body">
      Create
    </button>

    <span *ngIf="row.appointment">{{ row.appointment.status | titlecase }}</span>
  </div>
</ng-template>

<ng-template #selectTemplate let-row="row" let-value="value">
  <div class="table-actions">
    <button *ngIf="!row.appointment" class="btn btn-off-primary btn-sm" type="button" aria-expanded="false" ngbTooltip="Select"
      (click)="selectAgreement(row)" container="body">
      Select
    </button>

    <span *ngIf="row.appointment">{{ row.appointment.status | titlecase }}</span>
  </div>
</ng-template>

<ng-template #actionsTemplate let-row="row" let-value="value">
  <div class="table-actions">
    <button class="btn btn-icon btn-off-primary btn-sm" type="button" aria-expanded="false" ngbTooltip="Edit Agreement"
      (click)="onEdit(row)" container="body">
      <i class="light-icon light-icon-eye"></i>
    </button>
    <!-- <button class="btn btn-icon btn-off-primary btn-sm" type="button" aria-expanded="false" ngbTooltip="View"
      (click)="viewSalesAgreement(row)" container="body">
      <i class="light-icon light-icon-eye"></i>
    </button>

    <button class="btn btn-icon btn-off-primary btn-sm" *ngIf="row.status.toLowerCase() !== 'completed'" type="button"
      aria-expanded="false" ngbTooltip="Pending" container="body">
      <i class="light-icon light-icon-check light-icon-primary"></i>
    </button>

    <button class="btn btn-icon btn-off-primary btn-sm" *ngIf="row.status.toLowerCase() === 'completed'" type="button"
      aria-expanded="false" ngbTooltip="Completed" container="body">
      <i class="light-icon light-icon-check light-icon-success"></i>
    </button> -->
  </div>
</ng-template>

<ng-template #nameTemplate let-row="row" let-value="value">
  <span>{{ value.first_name }} {{ value.last_name }}</span>
</ng-template>

<ng-template #statusTemplate let-row="row" let-value="value">
  <span>{{ value | titlecase }}</span>
</ng-template>

<!-- NGX TABLE CELL TEMPLATES END -->