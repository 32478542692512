<!-- <ul class="nav justify-content-end">
  <li class="nav-item">
    <a class="nav-link active" [routerLink]="['/virtual-demo']">Virtual Demo</a>
  </li>
  <li class="nav-item">
    <a class="nav-link active">Link1</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" >Setting</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" (click)="signOut()">SignOut</a>
  </li>
</ul> -->

<header class="main-header">
  <button class="btn btn-icon btn-menu d-xl-none" type="button" (click)="onHambergurClick()">
    <span class="light-icon light-icon-menu"></span>
  </button>
  <div class="left-header">
    <div class="header-logo cursor-pointer" (click)="onHeaderLogoClick()"><img src="assets/images/gloscape-logo.png" alt="Logo" class="img-fluid"></div>
  </div>
  <div class="right-header">
    <!-- <div class="search-bar">
      <span class="searchbar-icon">
        <span class="light-icon light-icon-search"></span>
      </span>
      <input type="search" name="search" class="search-control" placeholder="Search" />
      <span class="searchbar-close-icon d-lg-none" (click)="onSearchOffClick()">
        <span class="light-icon light-icon-close"></span>
      </span>
    </div> -->
    <div class="header-menu">
      <ul class="list-unstyled header-menu__list">
        <!-- <li class="header-menu__item d-lg-none">
          <a class="header-menu__link"  (click)="onSearchClick()">
            <span class="light-icon light-icon-search"></span>
          </a>
        </li> -->
        <!-- <li class="header-menu__item">
          <a class="header-menu__link">
            <span class="light-icon light-icon-notification notification-dot"></span>
          </a>
        </li> -->

        <!-- <li class="header-menu__item d-none d-lg-inline-flex">
          <a class="header-menu__link">
            <span class="light-icon light-icon-help"></span>
          </a>
        </li> -->

        <li *ngIf="!isPaymentScreen" class="header-menu__item d-none d-lg-inline-flex">
          <a class="header-menu__link" [routerLink]="['/account-settings']"
            routerLinkActive="active">
            <span class="light-icon light-icon-setting"></span>
          </a>
        </li>

        <li class="header-menu__item" (click)="signOut()">
          <a class="header-menu__link">
            <span class="light-icon light-icon-signout"></span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</header>

<ng-template #signoutConfirmation let-modal="modal">
  <!-- <div class="modal-header text-center border-0">
    <h5 class="modal-title w-100" id="modalBasicLabel">Are you sure you want to close?</h5>
  </div> -->
  <div class="modal-body">
    <div class="client-management__content text-center mt-5">
      <h5>Are you sure you want to logout?</h5>
    </div>

    <div class="col col-12 col-sm-12 col-md-12 text-center mt-5">
      <button class="btn btn-off-primary px-5 mr-2" type="button" (click)="onNo()">No</button>

      <button class="btn btn-primary ms-3 px-5" type="button" (click)="onYes()">Yes</button>
    </div>
  </div>
</ng-template>