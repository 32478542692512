import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import firebase from 'firebase/compat/app';
import { ToastrService } from 'ngx-toastr';
import { FirebaseAuthService } from './../../../../services/firebase-auth.service';
import { UserService } from './../../../../services/user.service';
import { FederatedLogin } from './../../../../utils/enum';
import { AuthModel, IUserStatus } from './../../../../utils/models';

@Component({
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit, OnDestroy {
  authModel: AuthModel = {} as AuthModel;

  signinInProgress: boolean;

  showResetPwd = false;

  resetPwdInProgress: boolean;

  canAcativate: boolean = true;

  modalRef: NgbModalRef;

  @ViewChild('content')
  content: TemplateRef<any>;

  showPassword: boolean = false;

  constructor(
    private firebaseAuthService: FirebaseAuthService,
    private router: Router,
    private toastrService: ToastrService,
    private userService: UserService,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    ''
  }

  async onSubmit() {
    if (this.showResetPwd) {
      this.resetPwdInProgress = true;
      this.firebaseAuthService.sendPasswordResetEmail(this.authModel.email).then(res => {
        this.toastrService.success('We have sent reset password link on ' + this.authModel.email);
        this.resetPwdInProgress = false;
      }).catch(err => {
        this.resetPwdInProgress = false;
        this.firebaseAuthService.manageAuthError(err);
      });
    } else {
      this.signinInProgress = true;
      await this.firebaseAuthService.setPersistence(this.authModel.isRememberMe ?
        firebase.auth.Auth.Persistence.LOCAL : firebase.auth.Auth.Persistence.SESSION);

      this.firebaseAuthService.signInWithEmailAndPassword(this.authModel.email, this.authModel.password)
        .then(() => this.firebaseAuthService.isEmailVerified())
        .then((value) => {
          if (value) {
            return this.userService.checkUserStatus(this.authModel.email).toPromise()
          } else {
            this.firebaseAuthService.sendVerificationEmail();

            this.firebaseAuthService.signOut();

            this.modalRef = this.modalService.open(this.content, { windowClass: 'create-client-modal' });

            return null;
          }
        })
        .then((userInfo: IUserStatus | null) => {
          if (userInfo != null) {
            if (userInfo.exists) {
              this.signinInProgress = false;

              if (!userInfo.status) {
                this.router.navigate(['/payment']);
                return;
              }

              if (!userInfo.training_completed) {
                this.router.navigate(['/training']);
                return;
              }

              this.router.navigate(['/dashboard']);
            } else {
              this.firebaseAuthService.signOut();

              this.signinInProgress = false;

              this.toastrService.error('User is not registered. Please signup');
            }
          }
        })
        .catch(err => {
          this.signinInProgress = false;
          this.firebaseAuthService.manageAuthError(err);
        })
        .finally(() => this.signinInProgress = false);
    }
  }

  federatedSignin(type: FederatedLogin) {
    let email: string;

    this.firebaseAuthService.federatedLogin(type)
      .then((res: any) => {
        this.signinInProgress = true;
        email = res.additionalUserInfo.profile.email || res.user.multiFactor.user.email;
        return this.userService.checkUserStatus(email).toPromise();
      })
      .then((userInfo: IUserStatus) => {
        if (userInfo.exists) {
          this.signinInProgress = false;

          if (!userInfo.status) {
            this.router.navigate(['/payment']);
            return;
          }

          if (!userInfo.training_completed) {
            this.router.navigate(['/training']);
            return;
          }

          this.router.navigate(['/dashboard']);
        } else {
          this.userService.createUser(email)
            .toPromise()
            .then(res => {
              this.signinInProgress = false;
              this.router.navigateByUrl('payment');
            })
            .catch(err => {
              this.signinInProgress = false;
              this.toastrService.error('Failed to sigin in try again.');
            });
        }
      })
      .catch(() => this.toastrService.error('Unable to signin please try again.'))
      .finally(() => this.signinInProgress = false);
  }

  onOk() {
    this.modalRef.close();
    this.router.navigate(['/signin']);
  }

  ngOnDestroy() {
    this.canAcativate = false;
  }
}
