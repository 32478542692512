import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { debounceTime } from 'rxjs/operators';
import { IPagination } from '../../../../utils/models';
import { AgreementService } from './../../../../services/agreement-service';
import { ISalesAgreement } from './../../../../utils/models';

@Component({
  selector: 'app-sales-agreement-list',
  templateUrl: './sales-agreement-list.component.html',
  styleUrls: ['./sales-agreement-list.component.scss']
})
export class SalesAgreementListComponent implements OnInit {
  @Input()
  clientId: string | undefined;

  @Input()
  isFullListPage: boolean = false;

  @Input()
  isSelectAgreementFlow: boolean = false;

  loading: boolean = false;

  rows: ISalesAgreement[] = [];

  @ViewChild('dateTemplate', { static: true })
  dateTemplate: TemplateRef<any>;

  @ViewChild('currencyTemplate', { static: true })
  currencyTemplate: TemplateRef<any>;

  @ViewChild('actionsTemplate', { static: true })
  actionsTemplate: TemplateRef<any>;

  @ViewChild('nameTemplate', { static: true })
  nameTemplate: TemplateRef<any>;

  @ViewChild('installtionTemplate', { static: true })
  installtionTemplate: TemplateRef<any>;

  @ViewChild('selectTemplate', { static: true })
  selectTemplate: TemplateRef<any>;

  @ViewChild('statusTemplate', { static: true })
  statusTemplate: TemplateRef<any>;

  @Output()
  onselectagreement: EventEmitter<ISalesAgreement> = new EventEmitter<ISalesAgreement>();

  columns: any[] = [];

  promise: Promise<any>;

  pagination: IPagination = {
    page: 0,
    limit: 10,
    total_records: 0,
  };

  searchControl: FormControl = new FormControl(null);

  constructor(
    private toastrService: ToastrService,
    private agreementService: AgreementService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.fetch(this.pagination);

    if (this.isSelectAgreementFlow) {
      this.columns = [
        { name: 'Created Date', prop: 'created_at', sortable: false, cellTemplate: this.dateTemplate },
        { name: 'Requirements', prop: 'quote_id.client_requirements', sortable: false },
        { name: 'Total', prop: 'total_amount', sortable: false, cellTemplate: this.currencyTemplate },
        { name: 'Status', prop: 'status', cellTemplate: this.statusTemplate, sortable: false },
        { name: 'View/Edit <br/>Agreement', cellTemplate: this.selectTemplate, sortable: false }
      ];
    } else {
      this.columns = [
        { name: 'Client Name', prop: 'client_id', cellTemplate: this.nameTemplate, sortable: false, width: 120 },
        { name: 'Requirements', prop: 'quote_id.client_requirements', sortable: false },
        { name: 'Created Date', prop: 'created_at', sortable: false, cellTemplate: this.dateTemplate },
        { name: 'Total', prop: 'total_amount', sortable: false, cellTemplate: this.currencyTemplate, width: 100 },
        { name: 'Status', prop: 'status', cellTemplate: this.statusTemplate, sortable: false, width: 100 },
        { name: 'Installation', cellTemplate: this.installtionTemplate, sortable: false },
        { name: 'View/Edit <br/>Agreement', cellTemplate: this.actionsTemplate, sortable: false, width: 120 }
      ];
    }

    this.searchControl.valueChanges
      .pipe(debounceTime(500))
      .subscribe(() => {
        this.pagination = {
          page: 0,
          limit: 10,
          total_records: 0,
        };

        this.fetch(this.pagination);
      });
  }

  fetch(pagination: IPagination) {
    this.loading = true;

    const searchText = this.searchControl.value || null;

    this.agreementService.readSalesAgreementList(pagination, this.clientId as string, searchText)
      .toPromise()
      .then((res) => {
        this.rows = res.salesAgreements;

        this.pagination.total_records = res.total_records;
      })
      .catch(() => this.toastrService.error('Failed to read sales agreement'))
      .finally(() => this.loading = false);
  }

  setPage(pagination: any) {
    this.pagination.page = pagination.offset;

    this.fetch(this.pagination);
  }

  viewSalesAgreement(record: ISalesAgreement) {
    this.router.navigate(['view-sales-agreement', record.client_id.id, record._id]);
  }

  onEdit(record: ISalesAgreement) {
    this.router.navigate(['edit-sales-agreement', record.client_id.id, record._id]);
  }

  createInstalltion(record: ISalesAgreement) {
    this.router.navigate(['calender'], { queryParams: { type: 'Installation', salesagreement_id: record._id } });
  }

  selectAgreement(record: ISalesAgreement) {
    this.onselectagreement.emit(record);
  }
}
