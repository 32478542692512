import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';
import { API_PATH } from './../utils/constants';
import { IInventory, IInventoryList, IInvoice, IInvoiceList, ITransaction, IPagination, ISalesAgreement, ITransactionList } from './../utils/models';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {
  baseUrl: string = environment.baseUrl;

  constructor(
    private httpClient: HttpClient) {
  }

  getAllInvoice(pagination: IPagination, clientId?: string, search?: string): Observable<IInvoiceList> {
    let url = `${this.baseUrl}${API_PATH.invoice}?limit=${pagination?.limit}&page=${pagination?.page + 1}`;

    if (clientId) {
      url = `${url}&client_id=${clientId}`;
    }

    if (search) {
      url = `${url}&search=${search}`;
    }

    return this.httpClient.get<IInvoiceList>(url);
  }

  getAllPayments(pagination: IPagination, clientId?: string, search?: string): Observable<any> {
    let url = `${this.baseUrl}${API_PATH.clientPayment}/${clientId}?limit=${pagination?.limit}&page=${pagination?.page + 1}`;

    return this.httpClient.get<any>(url);
  }


  getInvoice(id: string): Observable<IInvoice> {
    let url = `${this.baseUrl}${API_PATH.invoice}/${id}`;

    return this.httpClient.get<IInvoice>(url);
  }

  createInvoice(payload: IInvoice, invoiceId?: string): Observable<IInvoice> {
    let url = `${this.baseUrl}${API_PATH.invoice}`;

    if (invoiceId) {
      url = `${url}/${invoiceId}`;
    }

    return this.httpClient.post<IInvoice>(url, payload);
  }

  sendInvoiceToClient(invoiceId: string, payload: any): Observable<IInvoice> {
    let url = `${this.baseUrl}${API_PATH.invoice}/${invoiceId}/send`;

    return this.httpClient.post<IInvoice>(url, payload);
  }

  updateInvoiceStatus(invoiceId: string, payload: { status: string }): Observable<IInvoice> {
    let url = `${this.baseUrl}${API_PATH.invoice}/${invoiceId}`;

    return this.httpClient.post<IInvoice>(url, payload);
  }

  getAllTransaction(pagination: IPagination, clientId?: string, search?: string): Observable<ITransactionList> {
    let url = `${this.baseUrl}${API_PATH.transaction}?limit=${pagination?.limit}&page=${pagination?.page + 1}`;

    if (clientId) {
      url = `${url}&client_id=${clientId}`;
    }

    if (search) {
      url = `${url}&search=${search}`;
    }

    return this.httpClient.get<ITransactionList>(url);
  }

  getAllInventory(pagination: IPagination, clientId?: string, search?: string): Observable<IInventoryList> {
    let url = `${this.baseUrl}${API_PATH.inventory}?limit=${pagination?.limit}&page=${pagination?.page + 1}`;

    if (clientId) {
      url = `${url}&client_id=${clientId}`;
    }

    if (search) {
      url = `${url}&search=${search}`;
    }

    return this.httpClient.get<IInventoryList>(url);
  }

  updateInventoryQuantity(payload: { item_id: string, quantity: number }): Observable<IInventory> {
    let url = `${this.baseUrl}${API_PATH.inventory}`;

    return this.httpClient.post<IInventory>(url, payload);
  }

  createInventory(payload: { item_id: string, quantity: number }) {
    let url = `${this.baseUrl}${API_PATH.inventory}/create`;

    return this.httpClient.post<IInventory>(url, payload);
  }

  createTransaction(payload: ITransaction) {
    let url = `${this.baseUrl}${API_PATH.transaction}`;

    return this.httpClient.post<ITransaction>(url, payload);
  }
}


