<!-- == Start Page Content here == -->
<div [ngClass]="{ 'content-page': !isTabContent }">
  <div class="page-title-group">
    <div class="page-title-group__left" *ngIf="!isTabContent">
      <h2 class="title">Client List <i class="fas fa-spinner fa-spin ms-2" *ngIf="loading"></i></h2>
      <!-- <p class="description">Lorem Ipsum is not simply random text. It has roots in a piece of classical<br />
        Latin
        literature from 45 BC, making it over 2000 years old.</p> -->
    </div>

    <div class="page-title-group__right d-flex">
      <div class="search-bar">
        <span class="searchbar-icon">
          <span class="light-icon light-icon-search"></span>
        </span>
        <input type="search" name="search" class="search-control form-control form-control-white" placeholder="Search"
          [formControl]="searchControl" />
        <span class="searchbar-close-icon d-lg-none cursor-pointer">
          <span class="light-icon light-icon-close cursor-pointer"></span>
        </span>
      </div>
      <button class="btn btn-primary ms-2" type="button" (click)="createInstalltion()">Schedule Installation</button>

      <button class="btn btn-primary ms-2" type="button" (click)="onCreateClient()" *ngIf="!isTabContent">Create
        Client</button>
    </div>
  </div>
  <div class="content-page__body">
    <div class="tab-content" id="generate-invoice_tabContent">
      <div class="tab-pane fade show active" id="generate-invoice" role="tabpanel"
        aria-labelledby="generate-invoice-tab">
        <div class="generate-invoice__content">
          <div *ngIf="loading && isTabContent"><i class="fas fa-spinner fa-spin"></i></div>
          <div class="mt-4">
            <ngx-datatable [rows]="rows" [columns]="columns" rowHeight="auto" [headerHeight]="44" [footerHeight]="50"
              [columnMode]="'force'" [loadingIndicator]="loading" [externalPaging]="true"
              [count]="pagination.total_records" [limit]="pagination.limit" [offset]="pagination.page"
              (page)="setPage($event)" [scrollbarH]="true"></ngx-datatable>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- == End Page content == -->

<!-- NGX TABLE CELL TEMPLATES START -->
<ng-template #actionsTemplate let-row="row" let-value="value">
  <div class="table-actions">
    <button class="btn btn-icon btn-off-primary btn-sm" type="button" aria-expanded="false" ngbTooltip="View"
      (click)="onUpdateClient(row)" container="body">
      <i class="light-icon light-icon-eye"></i>
    </button>
  </div>
</ng-template>

<ng-template #createQuoteTemplate let-row="row" let-value="value">
  <div class="table-actions">
    <button class="btn btn-icon btn-off-primary btn-sm" type="button" aria-expanded="false" ngbTooltip="Create Quote"
      (click)="onCreateQuote(row)" container="body">
      <i class="light-icon light-icon-square-plus light-icon-primary"></i>
    </button>
  </div>
</ng-template>

<ng-template #addressTemplate let-row="row" let-value="value">
  {{ value?.line }}, {{ value?.city }}, {{ value?.state }}, {{ value?.country }}, {{ value?.postal }}
</ng-template>
<!-- NGX TABLE CELL TEMPLATES END -->

<!-- CLIENT CREATE TEMPLATE START -->

<ng-template #createClientTemplate let-modal="modal">
  <div class="modal-header flex-column justify-content-center border-0">
    <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()"></button>
    <h5 class="modal-title text-center" id="create-client-header">Create Client</h5>
  </div>
  <div class="modal-body">
    <div class="client-management__content">
      <form [formGroup]="clientForm">
        <div class="row">
          <div class="col col-12 col-sm-12 col-md-6 mb-4">
            <div class="form-group">
              <input type="text" name="first_name" formControlName="first_name" class="form-control form-control-white"
                placeholder="First Name" trimwhitespacestart />
            </div>
          </div>
          <div class="col col-12 col-sm-12 col-md-6 mb-4">
            <div class="form-group">
              <input type="text" name="last_name" formControlName="last_name" class="form-control form-control-white"
                placeholder="Last Name" trimwhitespacestart />
            </div>
          </div>
          <div class="col col-12 col-sm-12 col-md-6 mb-4">
            <div class="form-group">
              <input type="email" name="email" formControlName="email" class="form-control form-control-white"
                placeholder="Client Email" />
            </div>
          </div>
          <div class="col col-12 col-sm-12 col-md-12 mb-4">
            <div class="form-group">
              <input type="text" name="line" formControlName="line" class="address-input form-control form-control-white"
                placeholder="Address" trimwhitespacestart />
            </div>
          </div>
          <div class="col col-12 col-sm-12 col-md-6 mb-4">
            <div class="form-group">
              <input type="text" name="city" formControlName="city" class="form-control form-control-white"
                placeholder="City" trimwhitespacestart />
            </div>
          </div>
          <div class="col col-12 col-sm-12 col-md-6 mb-4">
            <div class="form-group">
              <input type="text" name="state" formControlName="state" class="form-control form-control-white"
                placeholder="State" trimwhitespacestart />
            </div>
          </div>
          <div class="col col-12 col-sm-12 col-md-6 mb-4">
            <div class="form-group">
              <input type="text" name="country" formControlName="country" class="form-control form-control-white"
                placeholder="Country" trimwhitespacestart />
            </div>
          </div>
          <div class="col col-12 col-sm-12 col-md-6 mb-4">
            <div class="form-group">
              <input type="text" name="postal" formControlName="postal" class="form-control form-control-white"
                placeholder="Postal" numbersOnly />
            </div>
          </div>
          <div class="col col-12 col-sm-12 col-md-6 mb-4">
            <div class="form-group">
              <!--
                <input type="text" name="phone_number" formControlName="phone_number"
                class="form-control form-control-white" placeholder="Phone Number" numbersOnly />
               -->
              <input id="tel" formControlName="phone_number" class="form-control form-control-white"
                placeholder="Phone Number" phoneMask [preValue]="clientForm.value.phone_number"
                [phoneControl]="clientForm.controls['phone_number']" />
            </div>
          </div>
          <div class="col col-12 col-sm-12 col-md-12 text-center">
            <button class="btn px-5 btn-primary" type="button" [disabled]="clientForm.invalid || createClientInprogress"
              (click)="createClient()">Save
              &nbsp; <i class="fas fa-spinner fa-spin" *ngIf="createClientInprogress"></i></button>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>
<!-- CLIENT CREATE TEMPLATE END -->
