import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_PATH } from '../utils/constants';
import { environment } from './../../environments/environment';
import { IPlan } from './../utils/models';

@Injectable({
  providedIn: 'root'
})
export class PaymentPlanService {
  baseUrl: string = environment.baseUrl;

  constructor(private httpClient: HttpClient) {
  }

  readPlans(): Observable<IPlan[]> {
    const url = `${this.baseUrl}${API_PATH.planRead}`;

    return this.httpClient.get<any>(url);
  }

  makePayment(payload: { stripe_token: string, coupon?: string, plan_id: string }): Observable<any> {
    const url = `${this.baseUrl}${API_PATH.payment}`;

    return this.httpClient.post<any>(url, payload);
  }

  applyCoupon(code: string, plan_id: string) {
    const url = `${this.baseUrl}${API_PATH.applyCoupon}/${code}?plan_id=${plan_id}`;

    return this.httpClient.get<any>(url);
  }
}


