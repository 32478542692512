<!-- == Start Page Content here == -->
<div [ngClass]="{ 'content-page': !clientId }">
  <div class="page-title-group" *ngIf="!clientId">
    <div class="page-title-group__left">
      <h2 class="title">Payment List &nbsp; <i class="fas fa-spinner fa-spin" *ngIf="loading"></i></h2>
    </div>
  </div>
  <div class="content-page__body">
    <div class="tab-content" id="generate-invoice_tabContent">
      <div class="tab-pane fade show active" id="generate-invoice" role="tabpanel"
        aria-labelledby="generate-invoice-tab">
        <div class="generate-invoice__content">
          <div *ngIf="loading && clientId"><i class="fas fa-spinner fa-spin"></i></div>
          <div class="mt-4" *ngIf="rows?.length || !loading">
            <ngx-datatable [rows]="rows" [columns]="columns" [limit]="10" rowHeight="auto" [headerHeight]="44"
              [footerHeight]="50" [columnMode]="'force'" [loadingIndicator]="loading" [externalPaging]="true"
              [count]="pagination.total_records" [limit]="pagination.limit" [offset]="pagination.page"
              (page)="setPage($event)" [scrollbarH]="true"></ngx-datatable>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- == End Page content == -->

<!-- NGX TABLE CELL TEMPLATES START -->
<ng-template #dateTemplate let-row="row" let-value="value">
  <span>{{ value | date: 'dd MMM YYYY': 'UTC' }}</span>
</ng-template>

<ng-template #currencyTemplate let-row="row" let-value="value">
  <span>{{ value || 0 | currency : 'USD' : 'symbol' }}</span>
</ng-template>

<ng-template #percentTemplate let-row="row" let-value="value">
  <span>{{ value || 0 }}%</span>
</ng-template>
<!-- NGX TABLE CELL TEMPLATES END -->