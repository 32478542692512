import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { SettingsService } from './../../../../services/settings.service';
import { UserService } from './../../../../services/user.service';
import { IUser, ISettings } from './../../../../utils/models';

@Component({
  selector: 'app-left-side-bar-menu',
  templateUrl: './left-side-bar-menu.component.html',
  styleUrls: ['./left-side-bar-menu.component.scss']
})
export class LeftSideBarMenuComponent implements OnInit {
  user: IUser;

  loading: boolean = true;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private userService: UserService) {
    this.userService.user.subscribe((user) => {
      if (user) {
        this.loading = false;

        this.user = user;
      }
    })
  }

  ngOnInit(): void {
    ''
  }

  onHambergurOffClick() {
    this.document.body.classList.remove('open-menu');
  }
}
