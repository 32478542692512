<!-- == Start Page Content here == -->
<div class="content-page content-page--full">
  <div class="user-avatar-group mb-4 mb-md-5" *ngIf="user && (user.first_name || user.settings?.profile_image_url)">
    <span class="avatar">
      <img [src]="user?.settings?.profile_image_url || 'assets/images/Avatar-1.png'"
        onerror="this.src='assets/images/Avatar-1.png';" alt="Avatar" class="img-fluid">
    </span>
    <div class="avatar-text">
      <h4 class="name">{{ user.first_name }}</h4>
      <span class="designation">{{ user?.business_name }}</span>
    </div>
  </div>

  <div class="content-page__body">
    <div class="subtitle-group">
      <div class="subtitle-group_left">
        <h3 class="title">Training Videos <i class="fas fa-spinner fa-spin" *ngIf="loading"></i></h3>
        <p class="description col-md-8 p-0">Complete the video modules below to learn about outdoor lighting, from the nuts and bolts of designing a lighting system to wiring and installation and everything in between.  You can also complete the Platform training videos to learn about the platform and how to make the most of it.  You can skip this screen with the Skip button below and come back to it any time in your Settings.</p>
      </div>
    </div>
    <div class="training-video__list row g-30" *ngIf="!loading">
      <div class="col col-12 col-sm-12 col-md-6 col-xl-4" *ngFor="let video of videos; let i = index">
        <div class="card card-video">
          <div disabled="true" class="card-video__iframe"
            [ngClass]="{ 'disable-video': i !== 0 && videos[i - 1].status.toLowerCase() !== 'completed' && videos[i].status.toLowerCase() !== 'completed' }">
            <!-- [plyrPoster]="poster" thumb nail url can be added -->
            <div plyr plyrTitle="Custom video" [plyrPlaysInline]="true" [plyrCrossOrigin]="true"
              [plyrSources]="[{ src: video.link }]" (play)="onPlay(i)" (ended)="openQuestionsModal(video)"
              id="video{{i}}" [plyrOptions]="{'fullscreen': { 'enabled': true, 'iosNative': true }}">
            </div>
          </div>
          <div class="card-video__body">
            <div class="card-video__head">
              <h3 class="title">{{ video.title }}</h3>
              <span class="badge badge-success">{{ video.status }}</span>
            </div>
            <div class="score"><label class="label">Score :</label> {{ video.score }} / 100</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="content-page__foot d-flex flex-column align-items-center align-items-md-end">
    <div class="training-video__control" *ngIf="!isTrainingCompleted">
      <button class="btn btn-primary px-5" [routerLink]="['/dashboard']">Skip Training</button>
      <a class="link link-gray mt-3 cursor-pointer" (click)="onDoLater()">(Do
        Later)</a>
    </div>
    <div class="training-video__control" *ngIf="isTrainingCompleted">
      <button class="btn btn-primary px-5" [routerLink]="['/dashboard']">Proceed</button>
    </div>
  </div>
</div>

<a class="display-none" #openModal data-bs-toggle="modal" data-bs-target="#question_modal"></a>

<!-- == End Page content == -->

<div class="modal fade modal-payment" id="question_modal" data-bs-backdrop="static" role="dialog" tabindex="-1"
  aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header flex-column justify-content-center border-0">
        <button type="button" class="btn-close" (click)="onCloseModal()"></button>
        <h5 class="modal-title text-center" id="staticBackdropLabel">Now that you are done with the videos Here are some
          quick questions for you to answer!</h5>
      </div>
      <div class="modal-body question-modal-body">
        <div class="question-group" *ngFor="let question of selectedVideo?.questions | slice: sliceStart : sliceEnd;">
          <h3 class="question-title">{{ question.question }}</h3>
          <div class="row">
            <div class="col col-12 col-sm-12 col-md-6 mb-4" *ngFor="let option of question.options">
              <div class="form-check question-check form-check-right">
                <label class="form-check-label" for="flexRadioDefault1"  (click)="onLabelClick($event)">{{ option }}</label>
                <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"
                  (click)="onSelectAnswer(option)">
              </div>
            </div>
          </div>
        </div>
        <div class="text-center question-group__btn">
          <button class="btn btn-primary" [disabled]="!selectedQuestion?.selectedAnswer"
            *ngIf="index + 1 !== selectedVideo?.questions?.length" (click)="onNextQuestion()">Next</button>

          <button class="btn btn-primary" [disabled]="!selectedQuestion?.selectedAnswer"
            *ngIf="index + 1 === selectedVideo?.questions?.length" (click)="onSubmitQuestions()">Submit &nbsp;<i
              class="fas fa-spinner fa-spin" *ngIf="scoreSubmissionInProgress"></i></button>
        </div>
      </div>
    </div>
  </div>
</div>