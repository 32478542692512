import { IVideo } from './../utils/models';
import { API_PATH } from '../utils/constants';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpHeadersService } from './http-headers.service';

@Injectable({
  providedIn: 'root'
})
export class VideoService {
  baseUrl: string = environment.baseUrl;

  constructor(
    private httpClient: HttpClient) {
  }

  getVideos(): Observable<IVideo[]> {
    let url = `${this.baseUrl}${API_PATH.video}`;

    return this.httpClient.get<IVideo[]>(url);
  }

  updateScore(id: string, score: number): Observable<IVideo[]> {
    let url = `${this.baseUrl}${API_PATH.video}/${id}${API_PATH.score}`;

    return this.httpClient.post<IVideo[]>(url, { score });
  }
}


