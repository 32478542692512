import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {
  selectedTab: string = 'transaction';

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((param) => {
      if (param.tab) {
        this.selectedTab = param.tab;
      }
    });
  }

  onTabClick(tab: string) {
    this.router.navigate([], {
      queryParams: {
        tab
      }
    });

    this.selectedTab = tab;
  }

}
