<!-- == Start Page Content here == -->
<div [ngClass]="{ 'content-page': !isTabContent }">
  <div class="page-title-group" *ngIf="!isTabContent">
    <div class="page-title-group__left">
      <h2 class="title">Inventory List &nbsp; <i class="fas fa-spinner fa-spin" *ngIf="loading"></i></h2>
      
    </div>

    <div class="page-title-group__right create-inv-container">
      <app-product-type-ahead-control (selectoption)="onSelectProduct($event)">
      </app-product-type-ahead-control>

      <input type="number" name="quantity" [formControl]="newQuantity" class="form-control form-control-white"
        placeholder="Quantity" min="1" numbersOnly />

      <button class="btn btn-primary btn-save" [disabled]="createInvInProgress || !product || !newQuantity.value"
        (click)="createInventory()">Create &nbsp; <i class="fas fa-spinner fa-spin"
          *ngIf="createInvInProgress"></i></button>
    </div>
  </div>
  <div class="content-page__body">
    <div class="search-bar">
      <span class="searchbar-icon">
        <span class="light-icon light-icon-search"></span>
      </span>
      <input type="search" name="search" class="search-control form-control form-control-white" placeholder="Search"
        [formControl]="searchControl" />
      <span class="searchbar-close-icon d-lg-none cursor-pointer">
        <span class="light-icon light-icon-close cursor-pointer"></span>
      </span>
    </div>
    <div class="tab-content" id="generate-invoice_tabContent">
      <div class="tab-pane fade show active" id="generate-invoice" role="tabpanel"
        aria-labelledby="generate-invoice-tab">
        <div class="generate-invoice__content">
          <div *ngIf="loading && isTabContent"><i class="fas fa-spinner fa-spin"></i></div>
          <div class="mt-4" *ngIf="rows.length || !loading">
            <ngx-datatable [rows]="rows" [limit]="10" rowHeight="auto" [headerHeight]="44" [footerHeight]="50"
              [columnMode]="'force'" [loadingIndicator]="loading" [externalPaging]="true"
              [count]="pagination.total_records" [limit]="pagination.limit" [offset]="pagination.page"
              (page)="setPage($event)" [scrollbarH]="true">
              <ngx-datatable-column name="Item" [sortable]=false>
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{row.item_id}}
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="Name" [sortable]=false>
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{row.products[0]?.name}}
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="Price" [sortable]=false>
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{row.products[0]?.price}}
                </ng-template>
              </ngx-datatable-column>l̥

              <ngx-datatable-column name="Quantity" [sortable]=false>
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <span *ngIf="!row.isEdit">{{row.quantity}}</span>

                  <input *ngIf="row.isEdit" type="number" name="quantity" [formControl]="quantity"
                    class="form-control form-control-white quantity-form-control" placeholder="Quantity" numbersOnly
                    min="1" />

                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column name="" [width]="40" [sortable]=false [cellTemplate]="actionsTemplate">

              </ngx-datatable-column>
            </ngx-datatable>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- == End Page content == -->

<!-- NGX TABLE CELL TEMPLATES START -->
<ng-template #dateTemplate let-row="row" let-value="value">
  <span>{{ value | date: 'dd MMM YYYY': 'UTC' }}</span>
</ng-template>

<ng-template #currencyTemplate let-row="row" let-value="value">
  <span>{{ value | currency : 'USD' : 'symbol' }}</span>
</ng-template>

<ng-template #actionsTemplate let-row="row" let-value="value">
  <div class="table-actions">
    <button class="btn btn-icon btn-off-primary btn-sm mr-2" type="button" aria-expanded="false" ngbTooltip="Edit"
      (click)="onEdit(row)" *ngIf="!row.isEdit" container="body">
      <i class="light-icon light-icon-pencil light-icon-primary"></i>
    </button>

    <button class="btn btn-icon btn-off-primary btn-sm mr-2" type="button" aria-expanded="false" ngbTooltip="Save"
      (click)="onSave(row)" [disabled]="!quantity.valid" *ngIf="row.isEdit && !quantityUpdateInProgress"
      container="body">
      <i class="fas fa-save"></i>
    </button>

    <button class="btn btn-icon btn-off-primary btn-sm mr-2" type="button" aria-expanded="false" ngbTooltip="Cancel"
      (click)="row.isEdit = false" *ngIf="row.isEdit && !quantityUpdateInProgress" container="body">
      <i class="fas fa-times"></i>
    </button>

    <!-- <button class="btn btn-icon btn-off-primary btn-sm mr-2" type="button" aria-expanded="false" ngbTooltip="Subtract"
      (click)="onSave(row, true)" *ngIf="row.isEdit && !quantityUpdateInProgress" container="body">
      <i class="fa fa-minus" *ngIf="!quantityUpdateInProgress"></i>
    </button> -->

    <button class="btn btn-icon btn-off-primary btn-sm mr-2" type="button" aria-expanded="false" ngbTooltip="Updating"
      (click)="onSave(row)" *ngIf="row.isEdit && quantityUpdateInProgress" container="body">
      <i class="fas fa-spinner fa-spin"></i>
    </button>
  </div>
</ng-template>
<!-- NGX TABLE CELL TEMPLATES END -->