import { debounceTime } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { PURCHASE_ORDER_STATUS } from './../../../../utils/constants';
import { PurchaseOrderService } from './../../../../services/purchase-order-service';
import { IPurchaseOrder } from './../../../../utils/models';
import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { IPagination, IQuote } from '../../../../utils/models';

@Component({
  selector: 'app-purchase-order-list',
  templateUrl: './purchase-order-list.component.html',
  styleUrls: ['./purchase-order-list.component.scss']
})
export class PurchaseOrderListComponent implements OnInit {
  @Input()
  clientId: string | undefined;

  loading: boolean = false;

  rows: IPurchaseOrder[] = [];

  @ViewChild('dateTemplate', { static: true })
  dateTemplate: TemplateRef<any>;

  @ViewChild('actionsTemplate', { static: true })
  actionsTemplate: TemplateRef<any>;

  @ViewChild('currencyTemplate', { static: true })
  currencyTemplate: TemplateRef<any>;

  @ViewChild('nameTemplate', { static: true })
  nameTemplate: TemplateRef<any>;

  @ViewChild('statusTemplate', { static: true })
  statusTemplate: TemplateRef<any>;

  columns: any[] = [];

  promise: Promise<any>;

  pagination: IPagination = {
    page: 0,
    limit: 10,
    total_records: 0
  };

  purchaseOrderStatus = PURCHASE_ORDER_STATUS;

  @Input()
  isTabContent: boolean = false;

  searchControl: FormControl = new FormControl(null);

  constructor(
    private toastrService: ToastrService,
    private purchaseOrderService: PurchaseOrderService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.fetch(this.pagination);

    this.columns = [
      {
        name: 'Client Name',
        prop: 'client_id',
        cellTemplate: this.nameTemplate
      },
      {
        name: 'Vendor/Distributor <br/>email',
        prop: 'vendor_id.email',
        sortable: false,
        width: 170
      },
      { name: 'Description', prop: 'description', sortable: false, width: 250 },
      {
        name: 'Total',
        prop: 'total_amount',
        cellTemplate: this.currencyTemplate,
        sortable: false
      },
      {
        name: 'Status',
        prop: 'status',
        cellTemplate: this.statusTemplate,
        sortable: false
      },
      {
        name: 'View/edit <br/>Purchase Order',
        cellTemplate: this.actionsTemplate,
        sortable: false
      }
    ];

    this.searchControl.valueChanges.pipe(debounceTime(500)).subscribe(() => {
      this.pagination = {
        page: 0,
        limit: 10,
        total_records: 0
      };

      this.fetch(this.pagination);
    });
  }

  fetch(pagination: IPagination) {
    this.loading = true;

    const searchText = this.searchControl.value || null;

    this.purchaseOrderService
      .getAllPurchaseOrder(pagination, this.clientId, searchText)
      .toPromise()
      .then(res => {
        this.rows = res.purchaseOrders;

        this.pagination.total_records = res.total_records;
      })
      .catch(() => this.toastrService.error('Failed to read Purchase Orders'))
      .finally(() => (this.loading = false));
  }

  setPage(pagination: any) {
    this.pagination.page = pagination.offset;

    this.fetch(this.pagination);
  }

  onViewPurchaseOrder(record: IQuote) {
    this.router.navigate(['/view-purchase-order', record._id]);
  }

  onEditPurchaseOrder(record: IQuote) {
    this.router.navigate(['/edit-purchase-order', record._id]);
  }
}
