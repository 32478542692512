import { MarketMaterialsListComponent } from './components/market-materials-list/market-materials-list.component';
import { VirtualDemoListComponent } from './components/virtual-demo-list/virtual-demo-list.component';
import { InstallationScheduledComponent } from './components/installation-scheduled/installation-scheduled-list.component';
import { ServicesListComponent } from './components/services-list/services-list.component';
import { NgModule } from '@angular/core';
import { AngularFireAuthGuard, redirectLoggedInTo, redirectUnauthorizedTo } from '@angular/fire/compat/auth-guard';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './../../services/auth-gaurd.service';
import { AccountSettingsComponent } from './components/account-settings/account-settings.component';
import { AgreementComponent } from './components/agreement/agreement.component';
import { CalenderComponent } from './components/calender/calender.component';
import { ClientManagmentComponent } from './components/client-managment/client-managment.component';
import { ClientStatementComponent } from './components/client-statement/client-statement.component';
import { ClientUpdateComponent } from './components/client-update/client-update.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { EmailTemplateComponent } from './components/email-template/email-template.component';
import { IntroComponent } from './components/intro/intro.component';
import { InventoryListComponent } from './components/inventory-list/inventory-list.component';
import { InvoicePaymentFailureComponent } from './components/invoice-payment-failure/invoice-payment-failure.component';
import { InvoicePaymentSuccessComponent } from './components/invoice-payment-success/invoice-payment-success.component';
import { InvoiceTemplateComponent } from './components/invoice-template/invoice-template.component';
import { LiveDemoSchedulingComponent } from './components/live-demo-scheduling/live-demo-scheduling.component';
import { LiveDemoComponent } from './components/live-demo/live-demo.component';
import { MarketingComponent } from './components/marketing/marketing.component';
import { PaymentComponent } from './components/payment/payment.component';
import { PurchaseOrderTemplateComponent } from './components/purchase-order-template/purchase-order-template.component';
import { PurchaseOrdersComponent } from './components/purchase-orders/purchase-orders.component';
import { QuoteListComponent } from './components/quote-list/quote-list.component';
import { QuoteTemplateComponent } from './components/quote-template/quote-template.component';
import { ReportsComponent } from './components/reports/reports.component';
import { ResourcesComponent } from './components/resources/resources.component';
import { SalesAgreementTemplateComponent } from './components/sales-agreement-template/sales-agreement-template.component';
import { SetupBankAccountComponent } from './components/setup-bank-account/setup-bank-account.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { TrainingVideoComponent } from './components/training-video/training-video.component';
import { UserSettingComponent } from './components/user-setting/user-setting.component';
import { UserTrainingVideosComponent } from './components/user-training-videos/user-training-videos.component';
import { VirtualDemoComponent } from './components/virtual-demo/virtual-demo.component';
const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['intro']);
const redirectLoggedInToItems = () => redirectLoggedInTo(['dashboard']);

const routes: Routes = [
  {
    path: 'intro', component: IntroComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectLoggedInToItems }
  },
  {
    path: 'dashboard', component: DashboardComponent,
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: 'livedemo', component: LiveDemoComponent,
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: 'calender', component: CalenderComponent,
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: 'orders', component: PurchaseOrdersComponent,
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: 'reports', component: ReportsComponent,
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: 'marketing', component: MarketingComponent,
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: 'setting', component: UserSettingComponent,
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: 'signin', component: SignInComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectLoggedInToItems }
  },
  {
    path: 'signup', component: SignUpComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectLoggedInToItems }
  },
  {
    path: 'virtual-demo', component: VirtualDemoComponent,
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: 'demo-list', component: VirtualDemoListComponent,
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: 'virtual-demo/:id', component: VirtualDemoComponent,
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: 'payment', component: PaymentComponent,
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: 'training', component: TrainingVideoComponent,
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: 'live-demo-scheduling/:id', component: LiveDemoSchedulingComponent,
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: 'resources', component: ResourcesComponent,
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: 'training-videos', component: TrainingVideoComponent,
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: 'account-settings', component: AccountSettingsComponent,
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: 'email-template', component: EmailTemplateComponent,
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: 'agreement', component: AgreementComponent,
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: 'client-statement', component: ClientStatementComponent,
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: 'setup-bank-account', component: SetupBankAccountComponent,
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: 'user-training-videos', component: UserTrainingVideosComponent,
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: 'create-client', component: ClientManagmentComponent,
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: 'create-client/:prefill', component: ClientManagmentComponent,
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: 'client-management', component: ClientManagmentComponent,
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: 'client-update/:clientId', component: ClientUpdateComponent,
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: 'quote-list', component: QuoteListComponent,
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: 'create-quote', component: QuoteTemplateComponent,
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: 'create-quote/:clientId', component: QuoteTemplateComponent,
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: 'update-quote/:quoteId', component: QuoteTemplateComponent,
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: 'view-quote/:quoteId', component: QuoteTemplateComponent,
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: 'create-sales-agreement/:clientId/:quoteId', component: SalesAgreementTemplateComponent,
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: 'edit-sales-agreement/:clientId/:salesAgreementId', component: SalesAgreementTemplateComponent,
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: 'view-sales-agreement/:clientId/:salesAgreementId', component: SalesAgreementTemplateComponent,
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: 'create-invoice/:clientId/:salesAgreementId', component: InvoiceTemplateComponent,
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: 'update-invoice/:invoiceId', component: InvoiceTemplateComponent,
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: 'view-invoice/:invoiceId', component: InvoiceTemplateComponent,
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: 'invoice-payment-success', component: InvoicePaymentSuccessComponent,
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: 'invoice-payment-failure', component: InvoicePaymentFailureComponent,
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: 'create-purchase-order/:clientId/:salesAgreementId', component: PurchaseOrderTemplateComponent,
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: 'view-purchase-order/:purchaseOrderId', component: PurchaseOrderTemplateComponent,
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: 'edit-purchase-order/:purchaseOrderId', component: PurchaseOrderTemplateComponent,
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: 'inventory-list', component: InventoryListComponent,
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: 'service-list', component: ServicesListComponent,
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: 'installation-scheduled-list', component: InstallationScheduledComponent,
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: 'marketing-material-list', component: MarketMaterialsListComponent,
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  // {
  //   path: 'purchase-order-list', component: PurchaseOrderListComponent,
  //   canActivate: [AngularFireAuthGuard, AuthGuard],
  //   data: { authGuardPipe: redirectUnauthorizedToLogin }
  // },
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  { path: '**', redirectTo: '/dashboard' }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CoreRoutingModule { }
