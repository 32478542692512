<!-- == Start Page Content here == -->
<div class="content-page edit-profile-content">
    <div class="page-title-group">
        <div class="page-title-group__left">
            <h2 class="title">Setup Bank Account</h2>
            <p class="description">Please go to <a href="https://stripe.com/" target="_blank" class="link link-primary">www.stripe.com</a> to create a Stripe account which will enable you to receive<br/> online payments from clients into your bank account. Once your Stripe account is approved, <br/>copy the "Secret Key" and "Publisher Key" and paste them in the boxes below."</p>
            <p class="description">In order to use Checkout for accepting client payments, you must set an account or business name at <a href="https://dashboard.stripe.com/account" target="_blank" class="link link-primary">https://dashboard.stripe.com/account</a></p>
        </div>
    </div>
    <div class="content-page__body">
        <form class="setup-account-form" [formGroup]="accountForm">
            <!-- <div class="upload-document-card">
                <h5 class="title">Upload Following Documents:</h5>

                <div class="row">
                    <div class="col col-12 col-sm-6 col-md-5 mb-4">
                        <div class="upload-box">
                            <label class="label">Government Issued ID:</label>
                            <div class="upload-card">
                                <input [disabled]="fileUploadInProgress" type="file" id="government_issued_id" (change)="onFileChange($event)">
                                <i *ngIf="fileUploadInProgress" class="fas fa-spinner fa-spin"></i>
                                <img *ngIf="!fileUploadInProgress" src="assets/images/upload-icon.png" alt="Upload Icon" class="img-fluid">
                                <span class="note">Drag and drop or<br/> <b>Browse</b> files</span>
                            </div>
                        </div>
                    </div>
                    <div class="col col-12 col-sm-6 col-md-5 mb-4">
                        <div class="upload-box">
                            <label class="label">Second Form of ID:</label>
                            <div class="upload-card">
                                <input type="file" id="second_form_id">
                                <img src="assets/images/upload-icon.png" alt="Upload Icon" class="img-fluid">
                                <span class="note">Drag and drop or<br/> <b>Browse</b> files</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="row">
                <!-- <div class="col col-12 col-sm-12 col-md-12 mb-4">
                    <div class="form-group">
                        <input type="text" name="bank_name" class="form-control form-control-white"
                            placeholder="Bank Name" />
                    </div>
                </div> -->
                <div class="col col-12 col-sm-12 col-md-6 mb-4">
                    <div class="form-group">
                        <input type="text" name="first_name" class="form-control form-control-white"
                            placeholder="Secret Key" formControlName="secret_key" />
                    </div>
                </div>
                <div class="col col-12 col-sm-12 col-md-6 mb-4">
                    <div class="form-group">
                        <input type="text" name="last_name" class="form-control form-control-white"
                            placeholder="Public Key" formControlName="public_key" />
                    </div>
                </div>
                <!-- <div class="col col-12 col-sm-12 col-md-6 mb-4">
                    <div class="form-group">
                        <input type="text" name="account_number" class="form-control form-control-white"
                            placeholder="Account Number" formControlName="accountNumber" numbersOnly />
                    </div>
                </div>
                <div class="col col-12 col-sm-12 col-md-6 mb-4">
                    <div class="form-group">
                        <input type="text" name="confirm_account_number" class="form-control form-control-white confirm-account"
                            placeholder="Confirm Account Number" formControlName="confirmAccountNumber" numbersOnly />
                    </div>
                </div>
                <div class="col col-12 col-sm-12 col-md-12 mb-4">
                    <div class="form-group">
                        <input type="text" name="ach_routing_number" class="form-control form-control-white"
                            placeholder="ACH Routing Number" formControlName="achRoutingNumber" numbersOnly />
                    </div>
                </div> -->
                <!-- <div class="col col-12 col-sm-12 col-md-6 mb-4">
                    <div class="form-group">
                        <input type="text" name="ssn" class="form-control form-control-white" placeholder="SSN" />
                    </div>
                </div>
                <div class="col col-12 col-sm-12 col-md-6 mb-4">
                    <div class="form-group">
                        <input type="date" name="date_of_birth" class="form-control form-control-white"
                            placeholder="D.O.B"  />
                    </div>
                </div> -->
                <div class="col col-12 col-sm-12 col-md-12">
                    <button class="btn btn-primary btn-save" (click)="onSave()" [disabled]="accountForm.invalid">Save &nbsp; <i class="fas fa-spinner fa-spin" *ngIf="loading"></i></button>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- == End Page content == -->