import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from './../../environments/environment';
import { API_PATH } from './../utils/constants';
import { IPagination, IProductList, IQuote, IQuoteList, ISalesAgreement, ISalesAgreementList, IInvoice } from './../utils/models';

@Injectable({
  providedIn: 'root'
})
export class QuoteService {
  baseUrl: string = environment.baseUrl;

  clearproduct: Subject<any> = new Subject<any>();

  constructor(
    private httpClient: HttpClient) {
  }

  readClientQuote(clientId?: string): Observable<IQuote[]> {
    let url = `${this.baseUrl}${API_PATH.quote}`;

    if (clientId) {
      url = url + `?client_id=${clientId}`;
    }

    return this.httpClient.get<IQuote[]>(url);
  }

  readQuote(quoteId: string): Observable<IQuote> {
    let url = `${this.baseUrl}${API_PATH.quote}/${quoteId}`;

    return this.httpClient.get<IQuote>(url);
  }

  getAllQuotes(pagination: IPagination, clientId?: string, search?: string): Observable<IQuoteList> {
    let url = `${this.baseUrl}${API_PATH.quote}?limit=${pagination?.limit}&page=${pagination?.page + 1}`;

    if (clientId) {
      url = `${url}&client_id=${clientId}`;
    }

    if (search) {
      url = `${url}&search=${search}`;
    }

    return this.httpClient.get<IQuoteList>(url);
  }

  saveQuote(payload: IQuote, quoteId?: string): Observable<IQuote> {
    let url = `${this.baseUrl}${API_PATH.quote}`;


    if (quoteId) {
      url = `${url}/${quoteId}`;
    }

    return this.httpClient.post<IQuote>(url, payload);
  }

  readproducts(pagination: IPagination, search?: string): Observable<IProductList> {
    let url = `${this.baseUrl}${API_PATH.product}?limit=${pagination?.limit}&page=${pagination?.page + 1}`;

    if (search) {
      url = `${url}&search=${search}`;
    }

    return this.httpClient.get<IProductList>(url);
  }

  sendQuoteToClient(id: string, payload: any): Observable<IQuote> {
    let url = `${this.baseUrl}${API_PATH.quote}/${id}/send`;

    return this.httpClient.post<IQuote>(url, payload);
  }
}


