<div #screen class="virtual-demo-wrapper">
  <div class="virtual-demo__header">
    <div class="logo">
      <img class="img-fluid" src="assets/images/gloscape-logo.png" alt="Logo" />
    </div>

    <div class="virtual-demo__header_filter">
      <h3 *ngIf="this.selectedClientId">Client : {{this.selectedClientName}}</h3>

      <div class="virtual-demo__filter_item" *ngIf="showLightControls && selectedLightEffect">
        <label class=""> Transparency</label>
        <ngx-slider *ngIf="getLightTransparency" (userChangeEnd)="focusImage()" [(value)]="opacity"
          [options]="opacitySliderOptions">
        </ngx-slider>
      </div>
      <div class="virtual-demo__filter_item" *ngIf="showLightControls && selectedLightEffect">
        <label class=""> Size</label>
        <ngx-slider *ngIf="getLightHight" (userChangeEnd)="focusImage()" [(value)]="lightheight"
          [options]="lightHeightSliderOptions">
        </ngx-slider>
      </div>
      <div class="virtual-demo__filter_item" *ngIf="nightMode && !draftsavingInProgress">
        <label class="">Image Brightness</label>
        <ngx-slider (userChangeEnd)="changeBrightness()" [(value)]="brightness" *ngIf="getBrighteness"
          [options]="brightnessSliderOptions">
        </ngx-slider>
      </div>
    </div>

    <div class="header-menu virtual-demo__control">
      <ul class="list-unstyled header-menu__list">
        <li *ngIf="listOfLightsEffect.length > 0 && selectedLightEffect && showLightControls" class="header-menu__item1">
            <button type="button" class="header-menu__link" (click)="deleteSelectedObj()" ngbTooltip="Delete" container="body"><i
             class="fas fa-trash-alt"></i></button>
        </li>

        <li class="header-menu__item1">
          <button type="button" class="header-menu__link" (click)="resetCanvas()" ngbTooltip="Reset" container="body"><i
              class="fas fa-undo-alt"></i></button>
        </li>

        <li class="header-menu__item1" *ngIf="imgURL">
          <button type="button" class="header-menu__link" (click)="onSaveProject()" [disabled]="draftsavingInProgress"
            ngbTooltip="Save" container="body">
            <i class="far fa-save" *ngIf="!draftsavingInProgress"></i>
            <i class="fas fa-spinner fa-spin" *ngIf="draftsavingInProgress"></i>
          </button>
        </li>
        <li class="header-menu__item1" style="cursor: pointer;">
          <a (click)="onClickCloseDemo(content)" class="header-menu__link text-danger" ngbTooltip="Close"
            container="body"> <i class="light-icon light-icon-close"></i></a>
        </li>
      </ul>
    </div>
  </div>

  <div class="virtual-demo-card">
    <div class="virtual-demo-control">
      <ul class="list-unstyled virtual-control-nav">
        <!-- <li (click)="onSaveProject()">
          <a class="virtial-control__link">
            <img src="assets/images/view_details.png" class="img-fluid" />
            Saved Drafts [ngClass]="{'disable-li': showSavedDrafts}"
          </a>
        </li> -->
        <li>
          <a (click)="toggleLights();" class="virtial-control__link cursor-pointer" [ngClass]="{'active': showLights }">
            <img src="assets/images/lights-icon.png" class="img-fluid" />
            Lights
          </a>
          <div class="virtual-control__content" *ngIf="showLights">
            <div class="virtual-control__type">
             
              
              <div class="virtual-control__item" (click)="onClickLight('/assets/images/yellow/newest-uplight-tall_yellow.png')">
                <img class="img-fluid" id="effect" src="/assets/images/yellow/newest-uplight-tall_yellow.png" alt="Light">
              </div>
              <div class="virtual-control__item" (click)="onClickLight('/assets/images/yellow/new-uplight-short-75r_yellow.png')">
                <img class="img-fluid" id="effect" src="/assets/images/yellow/new-uplight-short-75r_yellow.png" alt="Light">
              </div>
              <div class="virtual-control__item" (click)="onClickLight('/assets/images/yellow/new-uplight-short-75l_yellow.png')">
                <img class="img-fluid" id="effect" src="/assets/images/yellow/new-uplight-short-75l_yellow.png" alt="Light">
              </div>
              <div class="virtual-control__item" (click)="onClickLight('/assets/images/yellow/rev-uplight-medium_yellow.png')">
                <img class="img-fluid" id="effect" src="/assets/images/yellow/rev-uplight-medium_yellow.png" alt="Light">
              </div>
              <div class="virtual-control__item" (click)="onClickLight('/assets/images/yellow/test-005_yellow.png')">
                <img class="img-fluid" id="effect" src="/assets/images/yellow/test-005_yellow.png" alt="Light">
              </div>
              <div class="virtual-control__item" (click)="onClickLight('/assets/images/yellow/93-yellow.png')">
                <img class="img-fluid" id="effect" src="/assets/images/yellow/93-yellow.png" alt="Light">
              </div>
              <div class="virtual-control__item" (click)="onClickLight('/assets/images/yellow/downlight-medium32-yellow.png')">
                <img class="img-fluid" id="effect" src="/assets/images/yellow/downlight-medium32-yellow.png" alt="Light">
              </div>
              <div class="virtual-control__item" (click)="onClickLight('/assets/images/yellow/pathlight35_yellow.png')">
                <img class="img-fluid" id="effect" src="/assets/images/yellow/pathlight35_yellow.png" alt="Light">
              </div>
              <div class="virtual-control__item" (click)="onClickLight('/assets/images/yellow/new-pathlight-small_yellow.png')">
                <img class="img-fluid" id="effect" src="/assets/images/yellow/new-pathlight-small_yellow.png" alt="Light">
              </div>
              <div class="virtual-control__item" (click)="onClickLight('/assets/images/yellow/revised-uplightmedleft_yellow.png')">
                <img class="img-fluid" id="effect" src="/assets/images/yellow/revised-uplightmedleft_yellow.png" alt="Light">
              </div> 
              <div class="virtual-control__item" (click)="onClickLight('/assets/images/yellow/rev2-uplightmedright_copy2_yellow.png')">
                <img class="img-fluid" id="effect" src="/assets/images/yellow/rev2-uplightmedright_copy2_yellow.png" alt="Light">
              </div>
              <div class="virtual-control__item" (click)="onClickLight('/assets/images/yellow/edge-light-yellow.png')">
                <img class="img-fluid" id="effect" src="/assets/images/yellow/edge-light-yellow.png" alt="Light">
              </div>
              <div class="virtual-control__item" (click)="onClickLight('/assets/images/yellow/eave-light34-yellow.png')">
                <img class="img-fluid" id="effect" src="/assets/images/yellow/eave-light34-yellow.png" alt="Light">
              </div>
              <div class="virtual-control__item" (click)="onClickLight('/assets/images/yellow/column-round-yellow.png')">
                <img class="img-fluid" id="effect" src="/assets/images/yellow/column-round-yellow.png" alt="Light">
              </div>
              <div class="virtual-control__item" (click)="onClickLight('/assets/images/yellow/column-square-yellow.png')">
                <img class="img-fluid" id="effect" src="/assets/images/yellow/column-square-yellow.png" alt="Light">
              </div>
              <div class="virtual-control__item" (click)="onClickLight('/assets/images/yellow/tree-skinny-uplight_yellow.png')">
                <img class="img-fluid" id="effect" src="/assets/images/yellow/tree-skinny-uplight_yellow.png" alt="Light">
              </div>
              <div class="virtual-control__item" (click)="onClickLight('/assets/images/yellow/eave-1-story-yellow.png')">
                <img class="img-fluid" id="effect" src="/assets/images/yellow/eave-1-story-yellow.png" alt="Light">
              </div>
              <div class="virtual-control__item" (click)="onClickLight('/assets/images/yellow/eave-2-story_yellow.png')">
                <img class="img-fluid" id="effect" src="/assets/images/yellow/eave-2-story_yellow.png" alt="Light">
              </div>
              <div class="virtual-control__item" (click)="onClickLight('/assets/images/yellow/wall-wash-faded41_yellow.png')">
                <img class="img-fluid" id="effect" src="/assets/images/yellow/wall-wash-faded41_yellow.png" alt="Light">
              </div>
              <div class="virtual-control__item" (click)="onClickLight('/assets/images/yellow/wallwash42_yellow.png')">
                <img class="img-fluid" id="effect" src="/assets/images/yellow/wallwash42_yellow.png" alt="Light">
              </div>
              <div class="virtual-control__item" (click)="onClickLight('/assets/images/yellow/newest-left-eaveangle-a-yellow.png')">
                <img class="img-fluid" id="effect" src="/assets/images/yellow/newest-left-eaveangle-a-yellow.png" alt="Light">
              </div>
              <div class="virtual-control__item" (click)="onClickLight('/assets/images/yellow/newest-right-eaveangle-a_yellow.png')">
                <img class="img-fluid" id="effect" src="/assets/images/yellow/newest-right-eaveangle-a_yellow.png" alt="Light">
              </div>
              <div class="virtual-control__item" (click)="onClickLight('/assets/images/yellow/newest-left-eaveangle-b-yellow.png')">
                <img class="img-fluid" id="effect" src="/assets/images/yellow/newest-left-eaveangle-b-yellow.png" alt="Light">
              </div>
              <div class="virtual-control__item" (click)="onClickLight('/assets/images/yellow/newest-right-eaveangle-b_yellow.png')">
                <img class="img-fluid" id="effect" src="/assets/images/yellow/newest-right-eaveangle-b_yellow.png" alt="Light">
              </div>
              <div class="virtual-control__item" (click)="onClickLight('/assets/images/yellow/newest-left-eaveangle-c-yellow.png')">
                <img class="img-fluid" id="effect" src="/assets/images/yellow/newest-left-eaveangle-c-yellow.png" alt="Light">
              </div>
              <div class="virtual-control__item" (click)="onClickLight('/assets/images/yellow/newest-right-eaveangle-c_yellow.png')">
                <img class="img-fluid" id="effect" src="/assets/images/yellow/newest-right-eaveangle-c_yellow.png" alt="Light">
              </div>
              <div class="virtual-control__item" (click)="onClickLight('/assets/images/yellow/newest-left-eaveangle-d_yellow.png')">
                <img class="img-fluid" id="effect" src="/assets/images/yellow/newest-left-eaveangle-d_yellow.png" alt="Light">
              </div>
              <div class="virtual-control__item" (click)="onClickLight('/assets/images/yellow/newest-right-eaveangle-d_yellow.png')">
                <img class="img-fluid" id="effect" src="/assets/images/yellow/newest-right-eaveangle-d_yellow.png" alt="Light">
              </div>
              <div class="virtual-control__item" (click)="onClickLight('/assets/images/yellow/newest-left-eaveangle-e_yellow.png')">
                <img class="img-fluid" id="effect" src="/assets/images/yellow/newest-left-eaveangle-e_yellow.png" alt="Light">
              </div>
              <div class="virtual-control__item" (click)="onClickLight('/assets/images/yellow/newest-right-eaveangle-e_yellow.png')">
                <img class="img-fluid" id="effect" src="/assets/images/yellow/newest-right-eaveangle-e_yellow.png" alt="Light">
              </div>
              <div class="virtual-control__item" (click)="onClickLight('/assets/images/yellow/newest-left-eaveangle-f_yellow.png')">
                <img class="img-fluid" id="effect" src="/assets/images/yellow/newest-left-eaveangle-f_yellow.png" alt="Light">
              </div>
              <div class="virtual-control__item" (click)="onClickLight('/assets/images/yellow/newest-right-eaveangle-f_yellow.png')">
                <img class="img-fluid" id="effect" src="/assets/images/yellow/newest-right-eaveangle-f_yellow.png" alt="Light">
              </div>
              <div class="virtual-control__item" (click)="onClickLight('/assets/images/yellow/new-eave-light-yellow.png')">
                <img class="img-fluid" id="effect" src="/assets/images/yellow/new-eave-light-yellow.png" alt="Light">
              </div>
              <div class="virtual-control__item" (click)="onClickLight('/assets/images/yellow/uplight-medium38_yellow.png')">
                <img class="img-fluid" id="effect" src="/assets/images/yellow/uplight-medium38_yellow.png" alt="Light">
              </div>
              
              <div class="virtual-control__item" (click)="onClickLight('/assets/images/yellow/new-downlight-narrow80-yellow.png')">
                <img class="img-fluid" id="effect" src="/assets/images/yellow/new-downlight-narrow80-yellow.png" alt="Light">
              </div> 
              <div class="virtual-control__item" (click)="onClickLight('/assets/images/yellow/new-uplight-tall-115L_yellow.png')">
                <img class="img-fluid" id="effect" src="/assets/images/yellow/new-uplight-tall-115L_yellow.png" alt="Light">
              </div>
              <div class="virtual-control__item" (click)="onClickLight('/assets/images/yellow/new-uplight-tall-115R85_yellow.png')">
                <img class="img-fluid" id="effect" src="/assets/images/yellow/new-uplight-tall-115R85_yellow.png" alt="Light">
              </div>
              <div class="virtual-control__item" (click)="onClickLight('/assets/images/yellow/overhead-spot-overlay_yellow.png')">
                <img class="img-fluid" id="effect" src="/assets/images/yellow/overhead-spot-overlay_yellow.png" alt="Light">
              </div>

             
              

              
              
             
              
              
                  

            
             
            





             
              
             
             
              
              
   
              
            </div>
          </div>
        </li>
        <li>
          <a (click)="toggleLamp();" class="virtial-control__link cursor-pointer" [ngClass]="{'active': showlamps}">
            <img src="assets/images/lights-icon.png" class="img-fluid" />
            Fixture
          </a>
          <div class=" virtual-control__content" *ngIf="showlamps">
            <div class="virtual-control__type">
              <div class="virtual-control__item" (click)="onClickLight('/assets/images/LED-Garden-Light.png',true)">
                <img class="img-fluid" id="effect" src="/assets/images/LED-Garden-Light.png" alt="Lamps">
              </div>
              <div class="virtual-control__item" (click)="onClickLight('/assets/images/pngwing.png',true)">
                <img class="img-fluid" id="effect" src="/assets/images/pngwing.png" alt="Light">
              </div>
              <!--<div class="virtual-control__item" (click)="onClickLight('/assets/images/LED2.png',true)">
                  <img class="img-fluid" src="/assets/images/LED2.png" alt="Light">
                </div>
                <div class="virtual-control__item" (click)="onClickLight('/assets/images/LED3.png',true)">
                  <img class="img-fluid" src="/assets/images/LED3.png" alt="Light">
                </div>-->
            </div>
          </div>
        </li>
        <li (click)="onShadows()">
          <a class="virtial-control__link cursor-pointer" [ngClass]="{'active': nightMode}">
            <img src="assets/images/shadows-icon.png" class="img-fluid" />
            Night Mode
          </a>
        </li>
        <li>
          <a class="virtial-control__link cursor-pointer" (click)="focusImage()">
            <img src="assets/images/image_levels-icon.png" class="img-fluid" />
            Image Levels
          </a>
        </li>
        <li for="file">
          <label class="virtial-control__link cursor-pointer">
            <img src="assets/images/upload.png" class="img-fluid" />
            Upload
            <input id="file-input" style="display: none" accept="image/jpeg,image/jpg,image/png,image/heic" type="file"
              (change)="onFileChange($event)" />
          </label>

        </li>
        <li class="Preview">
          <label class="virtial-control__link cursor-pointer" (click)="onClickPreview()">
            <img src="assets/images/view-icon.png" class="img-fluid" />
            Preview
          </label>
        </li>

      </ul>
    </div>
    <div class="upload" style="width: calc(100% - 184px);overflow: hidden;">

    <div #uploadedImg id="uploadedImg" style="position: static; overflow: hidden;" class="virtual-demo-card__iframe">
      <h1 *ngIf="!imgURL" class="text-center"> Please upload image</h1>
    </div>
    </div>
  </div>
  
</div>
<!-- Modal Template Start -->
<ng-template #content let-modal>
  <div class="modal-content ">
    <div class="modal-header text-center border-0">
      <h5 class="modal-title w-100" id="modalBasicLabel">Are you sure you want to close?</h5>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body d-flex justify-content-center border-0">
      <button type="button" class="btn btn-off-primary me-3 px-5" (click)="onCancelDemo()">No</button>
      <button type="button" class="btn btn-primary ms-3 px-5" (click)="onConfirmDelete()">Yes</button>
    </div>
  </div>
</ng-template>
<ng-template #beforeSave let-modal>
  <div class="modal-content ">
    <div class="modal-header text-center border-0 pt-5">
      <h5 class="modal-title w-100" id="modalBasicLabel">Client Info</h5>
      <!-- <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button> -->
    </div>
    <div class="modal-body pt-3 pb-5">
      <div class="d-flex position-relative">
        <div class="w-100">
          <input type="text" class="form-control form-control-white" [(ngModel)]="searchClient"
            (keyup)="onblurSearchClient(searchClient)" placeholder="Client Name" trimwhitespacestart />

        </div>
        <div class="position-absolute pe-3 end-0 custom-loading" *ngIf="searchIsLoading">
          <i class="fas fa-spinner fa-spin"></i>
        </div>
      </div>
      <div class="d-flex justify-content-end p-1">
        <a  style="text-decoration: none;cursor: pointer !important;" [routerLink]="['/create-client/true']">Create Client</a>
      </div>
      <ul class="searchresult-dropdown list-group pt-1">
        <li *ngFor="let client of clientsList" class="list-group-item"
          [ngClass]="{'active': selectedClientId === client._id}" (click)="addClient(client)">
          <span>
            {{client.first_name}}&nbsp;{{client.last_name}}
          </span>
        </li>
      </ul>
      <div class="text-center mt-4">
        <button type="button" class="btn btn-off-primary me-3 px-5" (click)="onClickCance()">Cancel</button>
      </div>
    </div>
  </div>
</ng-template>
<!-- Modal Template End -->
<!-- <ngx-capture [target]="screen" (resultImage)="saveImage($event)"></ngx-capture> -->