import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import firebase from 'firebase/compat/app';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { FederatedLogin } from '../utils/enum';

@Injectable({
  providedIn: 'root'
})
export class FirebaseAuthService {
  isLoggedIn: boolean;

  private helper: JwtHelperService = new JwtHelperService();

  constructor(
    private readonly auth: AngularFireAuth,
    private router: Router,
    private toastr: ToastrService) {

  }
  async signInWithEmailAndPassword(email: string, password: string): Promise<firebase.auth.UserCredential | void> {
    await this.auth.signInWithEmailAndPassword(email, password);
  }

  async createUserWithEmailAndPassword(email: string, password: string): Promise<firebase.auth.UserCredential | void> {
    await this.auth.createUserWithEmailAndPassword(email, password);
  }

  async sendVerificationEmail(): Promise<any> {
    const currentUser = await this.auth.currentUser;

    return currentUser?.sendEmailVerification();
  }

  async isEmailVerified(): Promise<boolean | undefined> {
    const currentUser = await this.auth.currentUser;

    return Promise.resolve(currentUser?.emailVerified);
  }

  async federatedLogin(type: FederatedLogin): Promise<firebase.auth.UserCredential | void> {
    let provider: any;
    if (type === FederatedLogin.google) {
      provider = new firebase.auth.GoogleAuthProvider();
    } else if (type === FederatedLogin.facebook) {
      provider = new firebase.auth.FacebookAuthProvider();
    } else if (type === FederatedLogin.twittter) {
      provider = new firebase.auth.TwitterAuthProvider();
    }

    provider.addScope('email');

    return await this.auth.signInWithPopup(provider);
  }

  getCurrentUser(): Observable<firebase.User | null> {
    return this.auth.authState;
  }

  getAuth(): AngularFireAuth {
    return this.auth;
  }
  async signOut(): Promise<void> {
    await this.auth.signOut();
    this.router.navigateByUrl('signin');
  }
  async sendPasswordResetEmail(email: string) {
    await this.auth.sendPasswordResetEmail(email);
  }
  async setPersistence(persistence: firebase.auth.Auth.Persistence) {
    await this.auth.setPersistence(persistence);
  }

  async getAccessToken(): Promise<string | undefined> {
    const currentUser = await this.auth.currentUser;

    const token = await currentUser?.getIdToken(false);

    const isExpired: boolean = this.helper.isTokenExpired(token);

    // console.log(token);

    return currentUser?.getIdToken(isExpired);
  }

  public manageAuthError(error: any) {
    if (error.toString().includes('auth/wrong-password')) {
      this.toastr.error('The password is invalid or the user does not have a password');
    } else if (error.toString().includes('(auth/user-not-found)')) {
      this.toastr.error('user not registered');
    } else if (error.toString().includes('(auth/email-already-in-use)')) {
      this.toastr.error('The email address is already in use by another account');
    } else if (error.toString().includes('(auth/weak-password)')) {
      this.toastr.error('Password should be at least 6 characters');
    } else if (error.toString().includes('(auth/invalid-email)')) {
      this.toastr.error('Invalid email');
    } else if (error.toString().includes('(auth/too-many-requests)')) {
      // eslint-disable-next-line max-len
      this.toastr.error('Access to this account has been temporarily disabled due to many failed login attempts.You can immediately restore it by resetting your password or you can try again later.');
    }
    else {
      this.toastr.error(error);
    }
  }
}
