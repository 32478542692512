<div class="content-page">
    <div class="page-title-group">
        <div class="page-title-group__left">
            <h2 class="title mb-0">Live Demo Scheduling &nbsp; <i class="fas fa-spinner fa-spin" *ngIf="loading"></i></h2>
        </div>
    </div>
    <div class="content-page__body" *ngIf="!loading">
        <div class="user-profile-card">
            <div class="user-profile-photo">
                <div class="cover-photos">
                    <img src="assets/images/cover-image.png" alt="Cover Image" class="img-fluid">
                </div>
                <div class="profile-photo">
                    <img src="assets/images/Avatar-1.png" alt="" class="img-fluid">
                </div>
            </div>
            <div class="user-profile-detail">
                <ul class="list-unstyled mb-0">
                    <li>
                        <label class="label">Name :</label> {{ demo.name }}
                    </li>
                    <li>
                        <label class="label">Date Created :</label> {{ demo.created_at | date: 'dd MMM YYYY': 'UTC' }}
                    </li>
                    <li>
                        <label class="label">Status :</label> {{ demo.status }}
                    </li>
                    <li>
                        <label class="label">Appointment :</label> {{ demo.from | date: 'dd MMM': 'UTC' }} | {{ demo.from | date: 'hh:mm': 'UTC' }} {{ demo.to | date: 'hh:mm a': 'UTC' }}
                     </li>
                </ul>
            </div>
        </div>
    </div>
</div>
