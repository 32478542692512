<!-- == Start Page Content here == -->
<div class="content-page">
  <div class="page-title-group">
    <div class="page-title-group__left">
      <button class="btn btn-primary" type="button" (click)="createLiveDemo()">Schedule Live Demo</button>
    </div>
    <div class="page-title-group__right ms-sm-auto">
      <button class="btn btn-primary" type="button" [routerLink]="'/dashboard'">Back</button>
    </div>
  </div>
  <div class="content-page__body" *ngIf="!loading">
    <div class="row">
      <div class="col col-12 col-md-12 col-lg-6">
        <div class="live-demo-content">
          <div class="live-demo-content__head mb-4">
            <h3 class="title">Live Demos</h3>
            <ul class="nav nav-pills nav-live-demo" id="pills-tab" role="tablist">
              <li class="nav-item" role="presentation">
                <button class="nav-link active" id="pills-scheduled-tab" data-bs-toggle="pill" data-bs-target="#pills-scheduled" type="button" role="tab" aria-controls="pills-scheduled" aria-selected="true">Scheduled</button>
              </li>
              <li class="nav-item" role="presentation">
                <button class="nav-link" id="pills-completed-tab" data-bs-toggle="pill" data-bs-target="#pills-completed" type="button" role="tab" aria-controls="pills-completed" aria-selected="false">Completed</button>
              </li>
            </ul>
          </div>
          <div class="live-demo-content__body">
            <div class="tab-content" id="pills-tabContent">
              <div class="tab-pane fade show active" id="pills-scheduled" role="tabpanel" aria-labelledby="pills-scheduled-tab">
                <ul class="list-unstyled appointment-list">
                  <div *ngIf="!loading && scheduledAppointments.length < 1"
                    class="live-demo__item text-center justify-content-center">
                    <h4>No scheduled appointments found.</h4>
                  </div>
                  <li class="appointment-list-item live-demo-appointment"
                    *ngFor="let scheduledAppointment of scheduledAppointments">
                    <div class="appointment-detail"><i class="far fa-clock me-1"></i> {{ scheduledAppointment?.datetime
                      | date: 'MMM dd YYYY': 'UTC' }} , {{ scheduledAppointment?.datetime | date: 'hh:mm a': 'UTC' }} ,
                    </div>
                    <div class="appointment-category"> {{ scheduledAppointment?.client_id?.first_name }} {{
                      scheduledAppointment?.client_id?.last_name }} </div>
                  </li>
                </ul>
              </div>
              <div class="tab-pane fade" id="pills-completed" role="tabpanel" aria-labelledby="pills-completed-tab">
                <ul class="list-unstyled appointment-list">
                  <div *ngIf="!loading && completedAppointments.length < 1"
                    class="live-demo__item text-center justify-content-center">
                    <h4>No completed appointments found.</h4>
                  </div>
                  <li class="appointment-list-item" *ngFor="let completedAppointment of completedAppointments">
                    <div class="appointment-detail"><i class="far fa-clock me-1"></i> {{ completedAppointment?.datetime
                      | date: 'MMM dd YYYY': 'UTC' }} , {{ completedAppointment?.datetime | date: 'hh:mm a': 'UTC' }} ,
                    </div>
                    <div class="appointment-category"> {{ completedAppointment?.client_id?.first_name }} {{
                      completedAppointment?.client_id?.last_name }} </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- == End Page content == -->
<!-- Modal Template Start -->
<ng-template #content let-modal>
  <div class="modal-content ">
    <div class="modal-header text-center">
      <h5 class="modal-title w-100" id="modalBasicLabel">Are you sure you want to delete?</h5>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-footer d-flex justify-content-center">
      <button type="button" class="btn btn-secondary" (click)="onCancelDemo()">No</button>
      <button type="button" class="btn btn-primary" (click)="onConfirmDelete()">Yes</button>
    </div>
  </div>
</ng-template>
<!-- Modal Template End -->