import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { debounceTime } from 'rxjs/operators';
import { ImageService } from './../../../../services/image.service';
import { ResourcesService } from './../../../../services/resources.service';
import { IMarketingMaterial, IMarketingMaterialList, IPagination } from './../../../../utils/models';

@Component({
  selector: 'app-market-materials-list',
  templateUrl: './market-materials-list.component.html',
  styleUrls: ['./market-materials-list.component.scss']
})
export class MarketMaterialsListComponent implements OnInit {
  loading: boolean = false;

  rows: IMarketingMaterial[] = [];

  selectedMaterial: IMarketingMaterial | undefined;

  @ViewChild('actionsTemplate', { static: true })
  actionsTemplate: TemplateRef<any>;

  @ViewChild('linkTemplate', { static: true })
  linkTemplate: TemplateRef<any>;

  @ViewChild('dateTemplate', { static: true })
  dateTemplate: TemplateRef<any>;

  @ViewChild('createMarketingMaterialTemplate', { static: true })
  createMarketingMaterialTemplate: TemplateRef<any>;

  columns: any[] = [];

  total: number = 0;

  pagination: IPagination = {
    page: 0,
    limit: 10,
    total_records: 0,
  };

  @Input()
  isTabContent: boolean = false;

  @Input()
  userId: string;

  createMaterialModelRef: NgbModalRef;

  materialForm: FormGroup;

  createMaterialInprogress: boolean = false;

  selectedPictureFile: any;

  selectedPictureFileName: any;

  selectedPicture: any;

  fileUploadInProgress: boolean = false;

  searchControl: FormControl = new FormControl(null);

  constructor(
    private imageService: ImageService,
    private toastrService: ToastrService,
    private resourcesService: ResourcesService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
  ) {
    this.materialForm = this.formBuilder.group({
      name: new FormControl(null, Validators.required),
      description: new FormControl(null, Validators.required),
      file_url: new FormControl(null, Validators.required)
    });
  }

  ngOnInit(): void {
    this.fetch(this.pagination);

    this.searchControl.valueChanges
      .pipe(debounceTime(500))
      .subscribe(() => {
        this.pagination = {
          page: 0,
          limit: 10,
          total_records: 0,
        };

        this.fetch(this.pagination);
      });

    this.columns = [
      { name: 'Date', prop: 'updated_at', cellTemplate: this.dateTemplate, sortable: false },
      { name: 'Name', prop: 'name' },
      { name: 'Description', prop: 'description', sortable: false },
      // { name: 'File', prop: 'file_url', cellTemplate: this.linkTemplate, sortable: false },
      { name: '', cellTemplate: this.actionsTemplate, sortable: false, width: 40 }
    ]
  }

  fetch(pagination: IPagination) {
    this.loading = true;

    const searchText = this.searchControl.value || null;

    this.resourcesService.getAllMarketingMaterials(pagination, searchText)
      .toPromise()
      .then((res: IMarketingMaterialList) => {
        this.rows = res.materials;

        this.pagination.total_records = res.total_records;
      })
      .catch(() => this.toastrService.error('Failed to fetch Marketing Materials list.'))
      .finally(() => this.loading = false);
  }

  setPage(pagination: any) {
    this.pagination.page = pagination.offset;

    this.fetch(this.pagination);
  }

  downloadFile(row: any): void {
    window.open(row.file_url, '_blank');
  }
}
