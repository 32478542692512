import { ClientManagementService } from './../../../../services/client-management.service';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { CalendarOptions, DayCellMountArg } from '@fullcalendar/common';
import { Calendar } from '@fullcalendar/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { AgreementService } from './../../../../services/agreement-service';
import { AppointmentService } from './../../../../services/appointment.service';
import { IAppoinments, IClient, ISalesAgreement } from './../../../../utils/models';

@Component({
  selector: 'app-calender',
  templateUrl: './calender.component.html',
  styleUrls: ['./calender.component.scss']
})
export class CalenderComponent implements OnInit {
  loading: boolean = false;

  createAppointmentInProgress: boolean = false;

  appointments: IAppoinments[] = [];

  currentAppointmentsList: IAppoinments[] = [];

  label: string;

  appointmentForm: FormGroup;

  calendarOptions: CalendarOptions;

  // events: EventInput[] = [];

  todayDate: string;

  selectedAppointment: IAppoinments | undefined;

  selectedAppointmentClient: IClient | undefined;

  isUpdateAppoitment: boolean = false;

  from: moment.Moment;

  to: moment.Moment;

  mode: 'day' | 'week' | 'month' = 'month';

  @ViewChild('fullcalendar') fullcalendar: FullCalendarComponent;

  eventModelRef: NgbModalRef;

  @ViewChild('eventTemplate', { static: true })
  eventTemplate: TemplateRef<any>;

  agreementModelRef: NgbModalRef;

  @ViewChild('agreementTemplate', { static: true })
  agreementTemplate: TemplateRef<any>;

  deleteAppointmentInProgress: boolean = false;

  salesagreement_id: string | undefined;

  selectedClient: IClient | undefined;

  selectedSalesAgreement: ISalesAgreement | undefined;

  constructor(
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private toastrService: ToastrService,
    private appointmentService: AppointmentService,
    private agreementService: AgreementService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private clientManagementService: ClientManagementService
  ) {
    this.appointmentForm = this.formBuilder.group({
      client_id: new FormControl(null, [Validators.required]),
      type: new FormControl(null, [Validators.required]),
      date: new FormControl(null, [Validators.required]),
      time: new FormControl(null, [Validators.required]),
    });
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((param) => {
      if (param.type) {
        this.appointmentForm.controls.type.setValue(param.type);
      }
      if (param.client_id) {
        this.fetchClient(param.client_id);
      }
      if (param.salesagreement_id) {
        this.salesagreement_id = param.salesagreement_id;

        this.fetchSalesAgreement(this.salesagreement_id);
      }
    });

    this.todayDate = moment().format('YYYY-MM-DD');

    this.from = moment(this.todayDate).startOf('month').subtract(1, 'M');

    this.to = moment(this.from).add(3, 'M');

    this.setAppointments();

    this.setCalendarOptions();
  }

  fetchClient(id: string) {
    this.clientManagementService.readClient(id)
      .toPromise()
      .then((result) => {
        this.selectedAppointmentClient = result;
      })
      .catch(() => this.toastrService.error('Failed to read client.'));
  }

  fetchSalesAgreement(salesagreement_id: any) {
    this.agreementService.readSalesAgreement(salesagreement_id)
      .toPromise()
      .then((result) => {
        this.selectedSalesAgreement = result;
        this.selectedAppointmentClient = result.client_id;
      })
      .catch(() => this.toastrService.error('Failed to read sales agreement.'));
  }

  setAppointments() {
    this.loading = true;

    const from = this.from.format('YYYY-MM-DD hh:mm a');

    const to = this.to.format('YYYY-MM-DD hh:mm a');

    this.appointmentService.getAppointments(from, to)
      .toPromise()
      .then((res) => this.currentAppointmentsList = res)
      .catch(() => this.toastrService.error('Failed to load current month appointments'))
      .finally(() => {
        this.setEvents()

        this.loading = false;
      });
  }

  onAddAppointment() {
    this.createAppointmentInProgress = true;
    const formData = this.appointmentForm.getRawValue();
    const dateTime = formData.date + ' ' + formData.time;
    const formatedDateTime = moment(dateTime, 'YYYY-MM-DD hh:mm').format('YYYY-MM-DD hh:mm A');

    const payload: IAppoinments = {
      client_id: formData.client_id,
      type: formData.type,
      datetime: formatedDateTime
    }

    if (formData.type === 'Installation' && !this.salesagreement_id) {
      // this.toastrService.error('Please select agreement to create installation appointment.');
      this.createAppointmentInProgress = false;

      this.openAgreementListModal();

      return;
    }

    if (formData.type === 'Installation' && this.salesagreement_id) {
      payload.salesagreement_id = this.salesagreement_id;
    }

    const promise = this.isUpdateAppoitment ?
      this.appointmentService.updateAppointment(payload, this.selectedAppointment?._id as string).toPromise() :
      this.appointmentService.createAppointment(payload).toPromise();

    promise
      .then(() => {
        this.selectedAppointment = undefined;
        const operation = this.isUpdateAppoitment ? 'updated' : 'created';
        this.selectedAppointmentClient = undefined;
        this.salesagreement_id = undefined;
        this.selectedSalesAgreement = undefined;
        this.selectedClient = undefined;
        this.toastrService.success(`Appointment ${operation} successfully`);
        this.setAppointments();
        this.onCancel();
        this.isUpdateAppoitment = false;
      })
      .catch((err) => this.toastrService.error(err.error.message || 'Appointment operation failed, Try again'))
      .finally(() => this.createAppointmentInProgress = false);
  }

  onDeleteAppointment() {
    this.deleteAppointmentInProgress = true;

    this.appointmentService.deleteAppointment(this.selectedAppointment?._id as string)
      .toPromise()
      .then(() => {
        this.toastrService.success('Appointment deleted successfully');
        this.eventModelRef.dismiss();
        this.setAppointments();
      })
      .catch((err) => this.toastrService.error('Failed to delete appointment'))
      .finally(() => this.deleteAppointmentInProgress = false);
  }

  onEditAppointment() {
    this.isUpdateAppoitment = true;

    this.selectedAppointmentClient = this.selectedAppointment?.client_id;

    if (this.selectedAppointment?.type === 'Installation') {
      this.fetchSalesAgreement(this.selectedAppointment?.salesagreement_id);
    }

    const formData = Object.assign(
      {},
      this.selectedAppointment,
      {
        date: moment(this.selectedAppointment?.datetime, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD'),
        time: moment(this.selectedAppointment?.datetime, 'YYYY-MM-DD HH:mm').format('HH:mm'),
      }
    );

    this.appointmentForm.patchValue(formData);

    this.eventModelRef.dismiss();
  }

  setEvents() {
    const calendarApi = this.fullcalendar.getApi();

    calendarApi.removeAllEvents();

    this.currentAppointmentsList.forEach((appointment) => {
      calendarApi.addEvent(
        {
          start: moment(appointment.datetime, 'YYYY-MM-DD hh:mm A').toDate(),
          id: appointment._id,
          className: this.getCustomEventClassName(appointment),
          firstName: appointment.client_id?.first_name ? appointment.client_id?.first_name : '',
          lastName: appointment.client_id?.last_name ? appointment.client_id?.last_name : '',
          appointmentType: appointment.type,
        }
      )
    });
  }

  getCustomEventClassName(appointment: IAppoinments): string {
    if (appointment.type === 'Telephone Appointment') {
      return 'event-telephone';
    }

    if (appointment.type === 'On Site Appointment') {
      return 'event-client-meeting';
    }

    if (appointment.type === 'Live Demo') {
      return 'event-live-demo';
    }

    if (appointment.type === 'Installation') {
      return 'event-installtion';
    }

    if (appointment.type === 'Maintenance/Repair') {
      return 'event-maintenance';
    }

    return 'event-other';
  }

  getEventColor(appointment: IAppoinments): string {
    if (appointment.type === 'Telephone Appointment') {
      return '#008000'; // green
    }

    if (appointment.type === 'On Site Appointment') {
      return '#FFFF00'; // yellow
    }

    if (appointment.type === 'Live Demo') {
      return '#0000FF'; // blue 
    }

    if (appointment.type === 'Installation') {
      return '#FF0000'; // red
    }

    if (appointment.type === 'Maintenance/Repair') {
      return '#800080'; // purple
    }

    return '#808080'; // grey
  }

  setCalendarOptions() {
    this.calendarOptions = {
      allDaySlot: false,
      slotMinTime: "06:00:00",
      headerToolbar: {
        left: 'prev,next',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay'
      },
      dateClick: (arg) => {
        this.onDateClick(arg.date);
      },
      eventClick: (arg) => {
        this.onEventClick(arg.event);
      },
      initialView: 'dayGridMonth',
      customButtons: {
        prev: {
          click: this.onPrevious.bind(this),
        },
        next: {
          click: this.onNext.bind(this),
        },
      },
      eventContent: (event: any, ele: any, view: any) => this.getEvenContent(event),
      eventTimeFormat: {
        hour: 'numeric',
        minute: '2-digit',
        hour12: true,
      },
    };
  }

  getEvenContent(e: any) {
    const timeText = e.timeText.toLowerCase();

    const props = e.event._def.extendedProps;

    const view = e.view.type;

    let html;

    if (view === 'timeGridDay') {
      html = `
        <div class="fc-event-time">${timeText}, ${props.firstName} ${props.lastName}</div>
        <div class="fc-event-title">${props.appointmentType}</div>
      `;
    } else {
      html = `
        <div class="fc-event-time">${timeText}, ${props.firstName.charAt(0)}${props.lastName.charAt(0)}</div>
        <div class="fc-event-title">${props.appointmentType}</div>
       `;
    }

    return { html };
  }

  onDateClick(event: any) {

    const date = event;

    if (!moment(date).isSameOrAfter(moment()) && !moment(date).isSame(moment(), 'day')) {
      return;
    }

    const calendarApi = this.fullcalendar.getApi();

    const view = calendarApi.view.type;

    this.appointmentForm.controls.date.setValue(moment(date).format('YYYY-MM-DD'));

    if (view === 'dayGridMonth') {
      this.appointmentForm.controls.time.setValue('09:00');
    } else {
      this.appointmentForm.controls.time.setValue(moment(date).format('HH:mm'));
    }
  }

  onEventClick(event: any) {
    this.selectedAppointment = this.currentAppointmentsList.find((a) => a._id === event.id);

    this.eventModelRef = this.modalService.open(this.eventTemplate, { size: 'lg', windowClass: 'event-modal' });
  }

  onPrevious() {
    const calendarApi: Calendar = this.fullcalendar.getApi();

    calendarApi.prev();

    this.fetchAppointments();
  }

  onNext() {
    const calendarApi = this.fullcalendar.getApi();

    calendarApi.next();

    this.fetchAppointments();
  }

  fetchAppointments() {
    const calendarApi = this.fullcalendar.getApi();

    const calendarDate = calendarApi.getDate();

    // console.log(moment(calendarDate).isBetween(this.from, this.to));

    if (!moment(calendarDate).isBetween(this.from, this.to)) {
      this.from = moment(calendarDate).startOf('month').subtract(1, 'M');

      this.to = moment(this.from).add(3, 'M');

      // console.log('calendarDate', calendarDate);

      // console.log('this.from', this.from.toDate());

      // console.log('this.to', this.to.toDate());

      this.setAppointments();
    }
  }


  onDayCellMount(ele: DayCellMountArg) {
    const currentDate = moment(ele.date).format('DD/MM/YYY');

    if (this.currentAppointmentsList.find((a) => moment(a.datetime).format('DD/MM/YYY') === currentDate)
      && !ele.el.classList.contains('fc-day-selected')) {
      ele.el.classList.add('fc-day-selected')
    }
  }

  onSelectOption(client: any): void {
    this.appointmentForm.controls.client_id.setValue(client._id);

    if (this.selectedSalesAgreement?.client_id._id !== client._id) {
      this.salesagreement_id = undefined;
      this.selectedSalesAgreement = undefined;
    }

    this.selectedClient = client;
  }

  onViewClient() {
    this.router.navigate(['client-update', this.selectedAppointment?.client_id?._id], { queryParams: { tab: 'client' } });

    this.eventModelRef.dismiss();
  }

  onCancel() {
    this.isUpdateAppoitment = false;

    this.selectedAppointment = undefined;

    this.selectedAppointmentClient = undefined;

    this.appointmentForm.reset();
  }

  openAgreementListModal() {
    this.agreementModelRef = this.modalService.open(this.agreementTemplate, { size: 'lg', windowClass: 'agreement-list-modal' });
  }

  onSlectAgreement(event: any) {
    this.salesagreement_id = event.id;

    this.selectedSalesAgreement = event;

    this.agreementModelRef.dismiss();
  }

  getDataTimeStr() {
    return `${moment(this.selectedAppointment?.datetime, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD')} ${moment(this.selectedAppointment?.datetime, 'YYYY-MM-DD HH:mm').format('hh:mm')}`
  }
}
