import {
  HttpEvent, HttpHandler, HttpInterceptor, HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { environment } from './../../environments/environment';
import { FirebaseAuthService } from './firebase-auth.service';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private auth: FirebaseAuthService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.auth.getAccessToken()).pipe(
      mergeMap((token: any) => {
        if (request.url.includes(environment.bankDocUploadUrl)) {
          return next.handle(request);
        }

        if (token && !(request.url.includes(environment.imageUploadUrl) && request.method === 'PUT')) {
          request = request.clone({ setHeaders: { Authorization: `Bearer ${token}` } });
        }

        return next.handle(request);
      }));
  }
}