import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { debounceTime } from 'rxjs/operators';
import { IPagination } from '../../../../utils/models';
import { ImageService } from './../../../../services/image.service';
import { ResourcesService } from './../../../../services/resources.service';
import { IServices, IServicesList } from './../../../../utils/models';

@Component({
  selector: 'app-services-list',
  templateUrl: './services-list.component.html',
  styleUrls: ['./services-list.component.scss']
})
export class ServicesListComponent implements OnInit {
  loading: boolean = false;

  fetchingReqServices: boolean = false;

  rows: IServices[] = [];

  selectedServices: IServices[] = [];

  @ViewChild('actionsTemplate', { static: true })
  actionsTemplate: TemplateRef<any>;

  @ViewChild('pictureTemplate', { static: true })
  pictureTemplate: TemplateRef<any>;

  @ViewChild('dateTemplate', { static: true })
  dateTemplate: TemplateRef<any>;

  @ViewChild('createServicesTemplate', { static: true })
  createServicesTemplate: TemplateRef<any>;

  @ViewChild('statusTemplate', { static: true })
  statusTemplate: TemplateRef<any>;

  columns: any[] = [];

  total: number = 0;

  pagination: IPagination = {
    page: 0,
    limit: 10,
    total_records: 0,
  };

  requestedServicePagination: IPagination = {
    page: 0,
    limit: 100000000,
    total_records: 0,
  };

  @Input()
  isTabContent: boolean = false;

  @Input()
  userId: string;

  createServicesModelRef: NgbModalRef;

  servicesForm: FormGroup;

  createServicesInprogress: boolean = false;

  selectedPictureFile: any;

  selectedPictureFileName: any;

  selectedPicture: any;

  fileUploadInProgress: boolean = false;

  selectedService: IServices | undefined;

  searchControl: FormControl = new FormControl(null);

  constructor(
    private toastrService: ToastrService,
    private resourcesService: ResourcesService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private imageService: ImageService,
  ) {
    this.servicesForm = this.formBuilder.group({
      _id: new FormControl(null, Validators.required),
      name: new FormControl(null, Validators.required),
      description: new FormControl({ value: null, disabled: true }, Validators.required),
      price: new FormControl({ value: null, disabled: true }, Validators.required),
    });
  }

  ngOnInit(): void {
    this.fetch(this.pagination);

    // this.fetchRequestedServices();

    // this.columns = [
    //   { name: 'Picture', prop: 'picture_url', cellTemplate: this.pictureTemplate, sortable: false },
    //   { name: 'Name', prop: 'name' },
    //   { name: 'Price', prop: 'price', sortable: false },
    //   { name: 'Description', prop: 'description', sortable: false },
    //   { name: 'Status', prop: 'status', cellTemplate: this.statusTemplate, sortable: false },
    //   // { name: '', cellTemplate: this.actionsTemplate, sortable: false, width: 40 }
    // ]

    this.searchControl.valueChanges
      .pipe(debounceTime(500))
      .subscribe(() => {
        this.pagination = {
          page: 0,
          limit: 10,
          total_records: 0,
        };

        this.fetch(this.pagination);
      });
  }

  fetch(pagination: IPagination) {
    this.loading = true;

    const searchText = this.searchControl.value || null;

    this.resourcesService.getAllService(pagination, searchText)
      .toPromise()
      .then((res: IServicesList) => {
        this.rows = this.userId ? res.service_requests : res.services;

        this.pagination.total_records = res.total_records;
      })
      .catch(() => this.toastrService.error('Failed to fetch services list.'))
      .finally(() => this.loading = false);
  }

  fetchRequestedServices() {
    this.fetchingReqServices = true;

    this.resourcesService.getAllRequestedService(this.requestedServicePagination)
      .toPromise()
      .then((res: IServicesList) => {
        this.selectedServices = res.services || [];

        this.requestedServicePagination.total_records = res.total_records;
      })
      .catch(() => this.toastrService.error('Failed to fetch services list.'))
      .finally(() => this.fetchingReqServices = false);
  }

  setPage(pagination: any) {
    this.pagination.page = pagination.offset;

    this.fetch(this.pagination);
  }

  openModal() {

    this.createServicesModelRef = this.modalService.open(
      this.createServicesTemplate,
      { size: 'lg', windowClass: 'create-client-modal' }
    );
  }

  onCreateUpdateService() {
    this.createServicesInprogress = true;

    const isUpdateFlow = this.selectedService?._id ? true : false;

    const seviceIds: any[] = [];

    this.selectedServices.forEach((service) => seviceIds.push(service._id));

    const payload = {
      service_ids: seviceIds
    }
    this.resourcesService.createUpdateService(payload, this.selectedService?._id)
      .toPromise()
      .then((res) => {
        this.toastrService.success(`Service requested successfully.`);

        // this.fetchRequestedServices();

        this.selectedService = undefined;

        this.servicesForm.reset();

        this.createServicesModelRef.close();
      })
      .catch(() => this.toastrService.error(`Failed to  request service`))
      .finally(() => this.createServicesInprogress = false);
  }

  createServices() {
    this.selectedServices = [];

    this.servicesForm.reset();

    this.openModal();
  }


  onAdd() {
    const formData = this.servicesForm.getRawValue();

    const service = this.selectedServices.find((p) => p._id === formData._id);

    if (service) {
      return;
    } else {
      const data: any = {
        _id: formData._id,
        name: formData.name,
        description: formData.description,
        price: formData.price,
      }

      this.selectedServices.push(data);
    }

    this.servicesForm.reset();


    this.resourcesService.clearproduct.next();
  }

  onSelectOption(service: any): void {
    let data = service;

    this.servicesForm.patchValue(data);
  }

  onDeleteService(service: any) {
    this.selectedServices = this.selectedServices.filter((r) => r._id !== service._id);
  }
}
