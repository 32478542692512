import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { InvoiceService } from '../../../../services/invoice-service';
import { IPagination } from '../../../../utils/models';
import { IClientPayment } from './../../../../utils/models';

@Component({
  selector: 'app-payment-list',
  templateUrl: './payment-list.component.html',
  styleUrls: ['./payment-list.component.scss']
})
export class PaymentListComponent implements OnInit {
  @Input()
  clientId: string | undefined;

  loading: boolean = false;

  rows: IClientPayment[] = [];

  @ViewChild('dateTemplate', { static: true })
  dateTemplate: TemplateRef<any>;

  @ViewChild('actionsTemplate', { static: true })
  actionsTemplate: TemplateRef<any>;

  @ViewChild('statusTemplate', { static: true })
  statusTemplate: TemplateRef<any>

  @ViewChild('currencyTemplate', { static: true })
  currencyTemplate: TemplateRef<any>

  @ViewChild('percentTemplate', { static: true })
  percentTemplate: TemplateRef<any>

  columns: any[] = [];

  promise: Promise<any>;

  pagination: IPagination = {
    page: 0,
    limit: 10,
    total_records: 0,
  };

  constructor(
    private toastrService: ToastrService,
    private invoiceService: InvoiceService,
  ) { }

  ngOnInit(): void {
    this.fetch(this.pagination);

    this.columns = [
      { name: 'Id', prop: 'id' },
      { name: 'Due Date', prop: 'due_date', sortable: false, cellTemplate: this.dateTemplate },
      { name: 'Charges', prop: 'charges', sortable: false },
      { name: 'Payment', prop: 'payment', sortable: false, cellTemplate: this.percentTemplate },
      { name: 'Balance', prop: 'balance', sortable: false, cellTemplate: this.currencyTemplate },
    ]
  }

  fetch(pagination: IPagination) {
    this.loading = true;

    this.invoiceService.getAllPayments(pagination, this.clientId)
      .toPromise()
      .then((res) => {
        this.rows = res.transactions_payment;

        this.pagination.total_records = res.total_records;
      })
      .catch(() => this.toastrService.error('Failed to read payment list'))
      .finally(() => this.loading = false);
  }

  setPage(pagination: any) {
    this.pagination.page = pagination.offset;

    this.fetch(this.pagination);
  }
}
