<!-- == Start Page Content here == -->
<div class="content-page" #pdfContent>
  <div class="page-title-group">
    <div class="page-title-group__left">
      <h2 class="title"> {{ client?.first_name }} {{ client?.last_name }} Sales Agreement &nbsp; <i
          class="fas fa-spinner fa-spin" *ngIf="loading"></i>
      </h2>
    </div>
    <div class="page-title-group__right">
      <button class="btn btn-primary px-5" type="button" (click)="location.back()">Back</button>
    </div>
  </div>
  <div class="content-page__body">
    <span *ngIf="salesAgreement && salesAgreement.status.toLowerCase() === salesAgreementStatus.PURCHASE_ORDER_CREATED"
      class="badge badge-primary badge-lg badge-invoice-status" type="button">
      Purchase Order Created</span>

    <span *ngIf="salesAgreement && salesAgreement.status.toLowerCase() === salesAgreementStatus.COMPLETED"
      class="badge badge-primary badge-lg badge-invoice-status" type="button">
      Status : Completed</span>

    <span *ngIf="salesAgreement && salesAgreement.status.toLowerCase() === salesAgreementStatus.CANCELLED"
      class="badge badge-primary badge-lg badge-invoice-status" type="button">
      Status : Cancelled</span>
    <div class="lighting-sales__content" *ngIf="!loading && (quote || salesAgreement)">
      <div class="bill-template">
        <label class="label">Bill to</label>
        <div class="row mb-4">
          <div class="col col-12 col-sm-12 col-md-6">
            <ul class="list-unstyled bill-template__list">
              <li><label for="" class="label">Customer Name :</label> {{ client.first_name }} {{ client.last_name }}
              </li>
              <li><label for="" class="label">Address :</label> {{ client.address.line }}</li>
              <li><label for="" class="label">City :</label> {{ client.address.city }}</li>
              <li><label for="" class="label">Country :</label> {{ client.address.country }}</li>
              <li><label for="" class="label">Postal :</label> {{ client.address.postal }}</li>
            </ul>
          </div>
          <div class="col col-12 col-sm-12 col-md-6">
            <ul class="list-unstyled bill-template__list">
              <li><label for="" class="label">Email :</label> {{ client.email }}</li>
              <li *ngIf="!isViewMode && !isEditMode"><label for="" class="label">Date :</label> {{ currentDate | date :
                'MMMM, dd, yyyy'}}</li>
              <li *ngIf="isViewMode || isEditMode"><label for="" class="label">Date :</label> {{
                salesAgreement.created_at | date :
                'MMMM, dd, yyyy'}}</li>
              <!-- <li><label for="" class="label">Quote create Date :</label>
                {{ quote.created_at | date : 'MMMM, dd, yyyy'}}</li>
              <li><label for="" class="label">Quote valid until :</label>
                {{ quote.valid_till | date : 'MMMM, dd, yyyy'}}</li> -->
            </ul>
          </div>
        </div>
        <label class="label">Clients Requirement Summary:</label>
        <div class="description">
          <p>{{ quote.client_requirements }}</p>
        </div>

        <div class="table-responsive mt-4">
          <table class="table common-table">
            <thead>
              <tr>
                <th>Items</th>
                <th>Description</th>
                <th>Placement</th>
                <th>Quantity</th>
                <!-- <th>Price</th> -->
                <!-- <th>Tax</th> -->
                <!-- <th>Amount</th> -->
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let row of products">
                <td>{{ row.item_id }}</td>
                <td>{{ row.description }}</td>
                <td>{{ row.placement || 'NA' }}</td>
                <td>{{ row.quantity }}</td>
                <!-- <td>{{ row.price | currency : 'USD' : 'symbol' }}</td> -->
                <!-- <td>{{ row.tax | currency : 'USD' : 'symbol' }}</td> -->
                <!-- <td>{{ row.amount | currency : 'USD' : 'symbol' }}</td> -->
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td class="pb-0"></td>
                <td colspan="2" class="text-end pb-0">
                  <label class="total-label">Discount Amount:</label>
                </td>
                <td colspan="" class="text-end pb-0">
                  <h2 class="total-label fw-bold">{{ quote?.discountAmount || 0 | currency : 'USD' }}</h2>
                </td>
              </tr>
              <tr>
                <td></td>
                <td colspan="2" class="text-end">
                  <label class="total-label">Total <small>(Incl Sales Tax, Discount and Labour)</small></label>
                </td>
                <td colspan="" class="text-end">
                  <h2 class="total-price" *ngIf="isViewMode || isEditMode">{{ salesAgreement.total_amount | currency :
                    'USD' : 'symbol'
                    }}</h2>
                  <h2 class="total-price" *ngIf="!isViewMode && !isEditMode">{{ quote.customerPrice | currency : 'USD' :
                    'symbol' }}</h2>
                </td>
              </tr>
              
            </tfoot>
          </table>
        </div>
      </div>
      <div class="lighting-sales-box mb-5">
        <h2 class="card-title">Generate Agreement</h2>
        <div class="terms-condition-content">
          <h2 class="title">Terms</h2>
          <div class="description">
            <textarea name="terms" class="form-control form-control-white" id="terms" rows="3" placeholder="Terms"
              [formControl]="terms" trimwhitespacestart></textarea>
          </div>

          <h2 class="title">Conditions</h2>
          <div class="description">
            <textarea name="conditions" class="form-control form-control-white" id="conditions" rows="3"
              placeholder="Conditions" [formControl]="conditions" trimwhitespacestart></textarea>
          </div>
          <div class="form-check mb-4">
            <input class="form-check-input" type="checkbox" id="flexCheckDefault" [formControl]="acceptTermsAndService">
            <label class="form-check-label" for="flexCheckDefault">
              I Accept to HQ Terms and Services agreement.
            </label>
          </div>

          <div class="lighting-sales-box">
            <h2 class="card-title">Signature</h2>

            <div *ngIf="isViewMode || isEditMode" class="signature-container col col-12 col-sm-12 col-md-6">
              <img [src]="base64SignatureUrl" alt="">
            </div>

            <ng-container *ngIf="!isViewMode && !isEditMode">
              <div class="signature-container col col-12 col-sm-12 col-md-6">
                <signature-pad [options]="signaturePadOptions"></signature-pad>
              </div>

              <button *ngIf="!signUploadUrl" class="btn btn-white btn-upload-signature me-3"
                [disabled]="signaturePad?.isEmpty() || signUploadInProgress" (click)="onUploadSign()">
                <i class="light-icon light-icon-upload me-3"></i>
                Upload Signature <i class="fas fa-spinner fa-spin ms-2" *ngIf="signUploadInProgress"></i>
              </button>

              <button *ngIf="!signUploadUrl" class="btn btn-white btn-upload-signature"
                [disabled]="signaturePad?.isEmpty() || signUploadInProgress" (click)="onClearSign()">
                Clear
              </button>
            </ng-container>
          </div>

          <button *ngIf="!isViewMode && !isEditMode" class="btn btn-primary px-5 mt-4" type="button"
            [disabled]="createSalesOrderInProgress" (click)="onAccept()">Accept &nbsp; <i class="fas fa-spinner fa-spin"
              *ngIf="createSalesOrderInProgress"></i></button>

          <ng-container *ngIf="isViewMode || isEditMode">
            <div class="d-flex flex-wrap">
              <button *ngIf="isViewMode || isEditMode" class="btn btn-primary px-5 mt-4 mr-2" type="button"
                (click)="openEmailTemplateModal()">Email Agreement
              </button>

              <button class="btn btn-primary px-5 mt-4 mr-2" type="button" (click)="onUpdate()">Update &nbsp; <i
                  class="fas fa-spinner fa-spin" *ngIf="updateAgreementInProgress"></i></button>

              <button *ngIf="!salesAgreement.invoice_id" class="btn btn-primary px-5 mt-4 mr-2" type="button"
                (click)="onCreateInvoice()">Create
                Invoice</button>

              <button *ngIf="salesAgreement.status.toLowerCase() !== salesAgreementStatus.CANCELLED"
                class="btn btn-white px-5 mt-4 ms-auto" type="button" (click)="onCancelAgreement()" [disabled]="updateStatusInProgress">
                Cancel Agreement &nbsp; <i class="fas fa-spinner fa-spin" *ngIf="updateStatusInProgress"></i></button>




              <!-- <button *ngIf="salesAgreement.status.toLowerCase() === salesAgreementStatus.IN_PROGRESS"
                class="btn btn-primary px-5 mt-4 mr-2" type="button" (click)="onCreatePurchaseOrder()">Create
                Purchase Order</button> -->

              <!-- <button *ngIf="salesAgreement.status.toLowerCase() === salesAgreementStatus.PURCHASE_ORDER_CREATED"
                class="btn btn-primary px-5 mt-4 mr-2 btn-green" type="button">
                Purchase Order Created</button> -->

              <!-- <button *ngIf="salesAgreement.status.toLowerCase() === salesAgreementStatus.PURCHASE_ORDER_CREATED"
                class="btn btn-primary px-5 mt-4 mr-2" type="button" (click)="onUpdateStatus()"
                [disabled]="updateStatusInProgress">
                Update Status &nbsp; <i class="fas fa-spinner fa-spin" *ngIf="updateStatusInProgress"></i></button> -->

              <!-- <button *ngIf="salesAgreement.status.toLowerCase() === salesAgreementStatus.COMPLETED"
                class="btn btn-primary px-5 mt-4 mr-2 btn-green" type="button">
                Completed </button> -->
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- == End Page content == -->

<!-- EMAIL TEMPLATE START -->
<ng-template #emailTemplate let-modal="modal">
  <div class="modal-header flex-column justify-content-center border-0">
    <button type="button" class="btn-close" aria-label="Close" (click)="emailTemplateModalRef.dismiss()"></button>
    <h5 class="modal-title text-center" id="create-client-header">Email Template</h5>
  </div>
  <div class="modal-body">
    <div class="client-management__content mt-4">
      <div class="form-group mb-4">
        <input type="text" name="subject" class="form-control form-control-white" placeholder="Subject"
          [formControl]="subject" trimwhitespacestart />
      </div>

      <div class="form-group">
        <ckeditor [editor]="Editor" [(ngModel)]="editorData" [config]="config"></ckeditor>
      </div>
    </div>

    <div class="col col-12 col-sm-12 col-md-12 text-start mt-4">
      <button *ngIf="isViewMode || isEditMode" class="btn btn-primary" type="button"
        [disabled]="!subject.valid || sendToClientInProgress || !editorData" (click)="generateSalesAgreement()">
        {{ salesAgreement.sent_at ? 'Resend' : 'Send' }}
        &nbsp; <i class="fas fa-spinner fa-spin" *ngIf="sendToClientInProgress"></i></button>
    </div>
  </div>
</ng-template>
<!-- EMAIL TEMPLATE END -->