import { Component, OnInit } from '@angular/core';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-email-template',
  templateUrl: './email-template.component.html',
  styleUrls: ['./email-template.component.scss']
})
export class EmailTemplateComponent implements OnInit {
  public Editor: any = ClassicEditor;

  public model = {
    editorData: '<p>Hello, world!</p>'
  };

  config = {
  }

  constructor() { }

  ngOnInit(): void {
    ''
  }

}
