import { SettingsService } from './../../../../services/settings.service';
import { UserService } from './../../../../services/user.service';
import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { PlyrComponent } from 'ngx-plyr';
import { ToastrService } from 'ngx-toastr';
import { VideoService } from './../../../../services/video.service';
import { IQuestion, IVideo, IUser, ISettings } from './../../../../utils/models';

@Component({
  selector: 'app-training-video',
  templateUrl: './training-video.component.html',
  styleUrls: ['./training-video.component.scss']
})
export class TrainingVideoComponent implements OnInit {
  @ViewChildren(PlyrComponent)
  playerComponents: QueryList<PlyrComponent>;

  currentPlayer: PlyrComponent | undefined;

  videos: IVideo[] = [];

  loading: boolean = false;

  submitQuestionInprogress: boolean = false;

  @ViewChild('openModal') modalToggle: ElementRef;

  selectedVideo: IVideo;

  selectedQuestion: IQuestion;

  sliceStart: number = 0;

  sliceEnd: number = 1;

  index: number = 0;

  scoreSubmissionInProgress: boolean = false;

  isTrainingCompleted: boolean = false;

  user: IUser;

  constructor(
    private router: Router,
    private toastrService: ToastrService,
    private videoService: VideoService,
    private userService: UserService) { }

  ngOnInit(): void {
    this.fetch();

    this.userService.user.subscribe((user: IUser | null) => {
      if (user) {
        this.user = user;
      }
    });
  }

  fetch(): void {
    this.loading = true;

    this.videoService.getVideos()
      .toPromise()
      .then((res: IVideo[]) => {
        // This videos can be use if we need to show completed videos at end
        // this.videos = res.sort((a, b) => a.status === b.status ? 1 : -1);

        this.videos = res;

        this.isTrainingCompleted = this.videos.every((v) => v.score === 100);
      })
      .catch((err) => this.toastrService.error('Failed to fetch training videos, Try after some time.'))
      .finally(() => this.loading = false);
  }

  openQuestionsModal(video: IVideo) {
    this.currentPlayer?.player.fullscreen.exit();

    this.selectedVideo = Object.assign({}, video);

    this.selectedVideo.questions.forEach((q) => q.selectedAnswer = undefined);

    this.selectedQuestion = this.selectedVideo.questions[0];

    this.modalToggle.nativeElement.click();
  }

  onSelectAnswer(option: string): void {
    this.selectedQuestion.selectedAnswer = option;
  }

  onNextQuestion(): void {
    this.index = this.index + 1;
    this.sliceStart = this.sliceStart + 1;
    this.sliceEnd = this.sliceEnd + 1;
    this.selectedQuestion = this.selectedVideo.questions[this.index];
  }

  onPreviousQuestion(): void {
    this.index = this.index - 1;
    this.sliceStart = this.sliceStart - 1;
    this.sliceEnd = this.sliceEnd - 1;
    this.selectedQuestion = this.selectedVideo.questions[this.index];
  }

  onCloseModal(): void {
    this.modalToggle.nativeElement.click();
    this.index = 0;
    this.sliceStart = 0;
    this.sliceEnd = 1;
  }

  onDoLater(): void {
    this.router.navigate(['/dashboard']);
  }

  onSubmitQuestions(): void {
    this.scoreSubmissionInProgress = true;

    const correctlyAnsweredQuestions: IQuestion[] = [];

    const questions = this.selectedVideo.questions;

    const totalNumberOfQuestions = questions.length;

    let score: number = 0

    questions.forEach((q) => {
      if (q.selectedAnswer === q.answer) {
        correctlyAnsweredQuestions.push(q);
      }
    })

    if (correctlyAnsweredQuestions.length) {
      score = Math.round((correctlyAnsweredQuestions.length / totalNumberOfQuestions) * 100);
    }

    this.videoService.updateScore(this.selectedVideo._id, score)
      .toPromise()
      .then(() => {
        this.fetch();

        this.toastrService.success('Score update successfully');
      })
      .catch(() => this.toastrService.error('Failed to update the score'))
      .finally(() => {
        this.scoreSubmissionInProgress = false;

        this.onCloseModal();
      });
  }

  onPlay(index: number) {
    this.currentPlayer = this.playerComponents.get(index);

    this.playerComponents.forEach((p, i) => {
      if (i != index && p.player.playing) {
        p.player.pause();
      }
    })

    if (this.currentPlayer?.player.fullscreen.active === false) {
      this.currentPlayer.player.fullscreen.enter();
    }
  }

  onLabelClick(event: any) {
    event.preventDefault();
  }
}
