<!-- == Start Page Content here == -->
<div class="content-page">
  <div class="page-title-group">
    <div class="page-title-group__left">
      <h2 class="title">Generate Invoice</h2>
      <p class="description">Lorem Ipsum is not simply random text. It has roots in a piece of classical<br /> Latin
        literature from 45 BC, making it over 2000 years old.</p>
    </div>
  </div>
  <div class="content-page__body">
    <ul class="nav nav-sales nav-custom col-md-7 p-0" id="generate-invoice_tab" role="tablist">
      <li class="nav-item" role="presentation">
        <button class="nav-link active" id="generate-invoice-tab" data-bs-toggle="pill" data-bs-target="#generate-invoice"
          type="button" role="tab" aria-controls="generate-invoice" aria-selected="true">Generate Invoice</button>
      </li>
      <li class="nav-item" role="presentation">
        <button class="nav-link" id="transaction-history-tab" data-bs-toggle="pill" data-bs-target="#transaction-history"
          type="button" role="tab" aria-controls="transaction-history" aria-selected="false">Transaction History</button>
      </li>
      <li class="nav-item" role="presentation">
        <button class="nav-link" id="deposit-tab" data-bs-toggle="pill" data-bs-target="#deposit"
          type="button" role="tab" aria-controls="deposit" aria-selected="false">Payment Deposit</button>
      </li>
    </ul>
    <div class="tab-content" id="generate-invoice_tabContent">
      <div class="tab-pane fade show active" id="generate-invoice" role="tabpanel" aria-labelledby="generate-invoice-tab">
        <div class="generate-invoice__content">
          <div class="table-responsive mt-4 mb-4">
            <table class="table common-table">
              <thead>
                <tr>
                  <th>Items</th>
                  <th>Description</th>
                  <th>Quantity</th>
                  <th>Price</th>
                  <th>Tax</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Items</td>
                  <td>Description</td>
                  <td>1</td>
                  <td>$ 0.00</td>
                  <td>0 %</td>
                  <td>$ 000.00</td>
                </tr>
                <tr>
                  <td>Items</td>
                  <td>Description</td>
                  <td>1</td>
                  <td>$ 0.00</td>
                  <td>0 %</td>
                  <td>$ 000.00</td>
                </tr>
                <tr>
                  <td>Items</td>
                  <td>Description</td>
                  <td>1</td>
                  <td>$ 0.00</td>
                  <td>0 %</td>
                  <td>$ 000.00</td>
                </tr>
                <tr>
                  <td>Items</td>
                  <td>Description</td>
                  <td>1</td>
                  <td>$ 0.00</td>
                  <td>0 %</td>
                  <td>$ 000.00</td>
                </tr>
                <tr>
                  <td>Items</td>
                  <td>Description</td>
                  <td>1</td>
                  <td>$ 0.00</td>
                  <td>0 %</td>
                  <td>$ 000.00</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="generate-invoice__btn-group">
            <button class="btn btn-white">Add More Item</button>
            <button class="btn btn-primary">Generate</button>
          </div>
        </div>
      </div>
      <div class="tab-pane fade" id="transaction-history" role="tabpanel" aria-labelledby="transaction-history-tab">
        <div class="generate-invoice__content">
          <div class="table-responsive mt-4 mb-4">
            <table class="table common-table">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Description</th>
                  <th>Client Name</th>
                  <th>Status</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>3 Dec 21</td>
                  <td>Demo subject goes here</td>
                  <td>Jadon Smith</td>
                  <td>Completed</td>
                  <td>$ 000.00</td>
                </tr>
                <tr>
                  <td>3 Dec 21</td>
                  <td>Demo subject goes here</td>
                  <td>Jadon Smith</td>
                  <td>Completed</td>
                  <td>$ 000.00</td>
                </tr>
                <tr>
                  <td>3 Dec 21</td>
                  <td>Demo subject goes here</td>
                  <td>Jadon Smith</td>
                  <td>Completed</td>
                  <td>$ 000.00</td>
                </tr>
                <tr>
                  <td>3 Dec 21</td>
                  <td>Demo subject goes here</td>
                  <td>Jadon Smith</td>
                  <td>Completed</td>
                  <td>$ 000.00</td>
                </tr>
                <tr>
                  <td>3 Dec 21</td>
                  <td>Demo subject goes here</td>
                  <td>Jadon Smith</td>
                  <td>Completed</td>
                  <td>$ 000.00</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="tab-pane fade" id="deposit" role="tabpanel" aria-labelledby="deposit-tab">
        <div class="generate-invoice__content">
          <div class="row g-5">
            <div class="col col-12 col-sm-12 col-md-6">
              <div class="deposit-card">
                <h3 class="title">Current Balance</h3>

                <div class="payment-card">
                  <div class="payment-card__body">
                    <span class="visa-icon">
                      <img src="assets/images/visa-icon.png" class="img-fluid" />
                    </span>
                    <span class="credit-card-icon">
                      <img src="assets/images/card-icon.png" class="img-fluid" />
                    </span>
                    <h3 class="amount">$ 4500.89</h3>
                    <p class="card-number mb-0">4556 3325 8590 3732</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col col-12 col-sm-12 col-md-6">
              <div class="deposit-card">
                <h3 class="title">Current Balance</h3>
                <div class="card card-icon mb-4">
                  <div class="card-body">
                    <span class="icon-box">
                      <i class="light-icon light-icon-card"></i>
                    </span>
                    <div class="text ms-3">
                      <h2 class="title mb-0">Visa/Debit cart</h2>
                    </div>
                  </div>
                </div>
                <div class="card card-icon mb-4">
                  <div class="card-body">
                    <span class="icon-box">
                      <i class="light-icon light-icon-paypal"></i>
                    </span>
                    <div class="text ms-3">
                      <h2 class="title mb-0">Paypal</h2>
                    </div>
                  </div>
                </div>
                <div class="card card-icon mb-4">
                  <div class="card-body">
                    <span class="icon-box">
                      <i class="light-icon light-icon-square-plus"></i>
                    </span>
                    <div class="text ms-3">
                      <h2 class="title mb-0">Add payment method</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- == End Page content == -->