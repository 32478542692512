<!-- == Start Page Content here == -->
<div class="content-page" #pdfContent>
  <div class="page-title-group">
    <div class="page-title-group__left">
      <h2 class="title" *ngIf="!isViewQuoteFlow">{{ isEditQuoteFlow ? 'Edit Quote' : 'Create Quote' }} &nbsp; <i
          class="fas fa-spinner fa-spin" *ngIf="loading"></i></h2>

      <h2 class="title" *ngIf="isViewQuoteFlow">Preview Quote &nbsp; <i class="fas fa-spinner fa-spin"
          *ngIf="loading"></i></h2>
    </div>
    <div class="page-title-group__right" *ngIf="isViewQuoteFlow">
      <button class="btn btn-primary ms-2" type="button" (click)="isViewQuoteFlow = false">Close</button>
    </div>
  </div>
  <div class="content-page__body">
    <div class="col col-12 col-sm-12 col-md-6 mb-4" *ngIf="!isViewQuoteFlow && (client || createQuoteWithoutClientId)">
      <app-client-type-ahead-control (selectoption)="onSelectClient($event)" [client]="client">
      </app-client-type-ahead-control>
    </div>

    <div class="bill-template" *ngIf="!loading && (client || quote )">
      <label class="label">Bill to</label>
      <div class="row mb-4">
        <div class="col col-12 col-sm-12 col-md-6">
          <ul class="list-unstyled bill-template__list">
            <li><label for="" class="label">Customer Name :</label> {{ client.first_name }} {{ client.last_name }}</li>
            <li><label for="" class="label">Address :</label> {{ client.address.line }}</li>
            <li><label for="" class="label">City :</label> {{ client.address.city }}</li>
            <li><label for="" class="label">Country :</label> {{ client.address.country }}</li>
            <li><label for="" class="label">Postal :</label> {{ client.address.postal }}</li>
          </ul>
        </div>
        <div class="col col-12 col-sm-12 col-md-6 col-lg-5 ms-auto">
          <ul class="list-unstyled bill-template__list">
            <li><label for="" class="label">Email :</label> {{ client.email }}</li>
            <li><label for="" class="label">Date :</label> {{ quoteDate | date : 'MMMM, dd, yyyy' }}</li>
            <li class="datepicker-container"><label for="" class="label">Quote valid until :</label>
              <input *ngIf="!isViewQuoteFlow" type="date" name="name" class="form-control form-control-white"
                placeholder="Date" [formControl]="valid_till" [min]="todayDate" />
              <span *ngIf="isViewQuoteFlow"> {{ quote.valid_till | date : 'MMMM, dd, yyyy' }}</span>
            </li>
          </ul>
        </div>
      </div>
      <label class="label">Description:</label>
      <div class="description mb-3">
        <textarea *ngIf="!isViewQuoteFlow" name="client_requirements" class="form-control form-control-white"
          id="client_requirements" rows="3" placeholder="Description" [formControl]="description"
          trimwhitespacestart></textarea>

        <p *ngIf="isViewQuoteFlow">{{ quote.description }}</p>
      </div>

      <label class="label">Clients Requirement Summary:</label>
      <div class="description">
        <textarea *ngIf="!isViewQuoteFlow" name="client_requirements" class="form-control form-control-white"
          id="client_requirements" rows="3" placeholder="Client Requirements" [formControl]="client_requirements"
          trimwhitespacestart></textarea>

        <p *ngIf="isViewQuoteFlow">{{ quote.client_requirements }}</p>
      </div>

      <div class="table-responsive-md mt-4">
        <table class="table common-table client-add-table">
          <thead>
            <tr>
              <th>Product Name</th>
              <th>Placement</th>
              <th>Quantity</th>
              <th>Description</th>
              <!-- <th>Price</th> -->
              <th>Amount</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let row of rows; let i=index">
              <td>{{ row.item_id }}</td>
              <td>
                <input *ngIf="!isViewQuoteFlow" type="text" name="placement" [(ngModel)]="row.placement"
                  class="form-control form-control-white" placeholder="Placement" trimwhitespacestart />

                <span *ngIf="isViewQuoteFlow">{{ row.placement }} </span>
              </td>
              <td>
                <input *ngIf="!isViewQuoteFlow" type="number" name="quantity" [(ngModel)]="row.quantity" 
                (ngModelChange)="onQuantityChange(row)" (blur)="onQuantityBlur(row)"
                  class="form-control form-control-white" placeholder="Quantity" min="1" />

                <span *ngIf="isViewQuoteFlow">{{ row.quantity }} </span>
              </td>
              <td>{{ row.description }}</td>
              <!-- <td>{{ row.price }}</td> -->
              <td>{{ row.amount }}</td>
              <td>
                <div class="table-actions" *ngIf="!isViewQuoteFlow">
                  <button class="btn btn-icon btn-off-primary btn-sm" type="button" aria-expanded="false"
                    (click)="onDeleteProduct(row, i)">
                    <i class="light-icon light-icon-delete"></i>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr [formGroup]="productForm" *ngIf="!isViewQuoteFlow">
              <td>
                <app-product-type-ahead-control (selectoption)="onSelectOption($event)">
                </app-product-type-ahead-control>
              </td>
              <!-- <td>
                <input type="number" name="quantity" formControlName="quantity" class="form-control form-control-white"
                  placeholder="Quantity" min="1" numbersOnly />
              </td>
              <td>
                <input type="text" name="description" formControlName="description"
                  class="form-control form-control-white" trimwhitespacestart />
              </td>
              <td>
                <input type="text" name="amount" formControlName="amount" class="form-control form-control-white" />
              </td> -->
              <!-- <td>
                <button class="btn btn-icon btn-primary" type="button" [disabled]="!productForm.valid"
                  (click)="onAdd()">
                  <i class="light-icon light-icon-square-plus"></i>
                </button>
              </td> -->
            </tr>
            <tr class="quote-summay">
              <td colspan="5">
                <table class="quote-summary-table">
                  <tbody>
                    <tr *ngIf="rows.length && !isViewQuoteFlow">
                      <td><label class="label">Total Product Cost</label></td>
                      <td> {{ totalProductCost | currency : 'USD' : 'symbol' }} </td>
                    </tr>
                    <tr *ngIf="rows.length && !isViewQuoteFlow">
                      <td><label class="label">Total Product After {{ defaultDiscountPercent }}% Discount</label></td>
                      <td> {{ totalProductCostAfterDiscount | currency : 'USD' : 'symbol' }} </td>
                    </tr>
                    <tr *ngIf="rows.length && !isViewQuoteFlow">
                      <td colspan="2">
                        <label class="label mb-2">How much profit do you want to make in dollars? </label>
                        <div class="row g-1">
                          <div class="col col-4 col-sm-3">
                            <input type="number" name="profitWantToMake" [formControl]="profitWantToMake"
                              class="form-control form-control-white" placeholder="Profit" min="1" numbersOnly />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr *ngIf="rows.length && !isViewQuoteFlow">
                      <td colspan="2">
                        <label class="label mb-2">Add labor charges in dollars </label>
                        <div class="row g-1">
                          <div class="col col-4 col-sm-3">
                            <input type="number" name="hourlyRate" [formControl]="hourlyRate"
                              class="form-control form-control-white" placeholder="Hourly Rate" min="1"
                              appTwoDigitDecimaNumber />
                          </div>
                          <div class="col col-4 col-sm-3">
                            <input type="number" name="hours" [formControl]="hours"
                              class="form-control form-control-white" placeholder="Hours" min="1" numbersOnly />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr *ngIf="rows.length && !isViewQuoteFlow">
                      <td><label class="label">Labor</label></td>
                      <td>
                        {{ labor | currency : 'USD' : 'symbol' }}
                      </td>
                    </tr>
                    <tr *ngIf="rows.length && !isViewQuoteFlow">
                      <td><label class="label">Client Price</label></td>
                      <td>
                        {{ clientPrice | currency : 'USD' : 'symbol' }}
                      </td>
                    </tr>
                    <tr *ngIf="rows.length && !isViewQuoteFlow">
                      <td colspan="2">
                        <label class="label mb-2">Discount Amount </label>
                        <input type="number" name="discountAmount" [formControl]="discountAmount"
                          class="form-control form-control-white" placeholder="Discount Amount" min="1"
                          appTwoDigitDecimaNumber />
                      </td>
                    </tr>
                    <tr *ngIf="rows.length && !isViewQuoteFlow">
                      <td><label class="label">New Client Price</label></td>
                      <td>
                        {{ newClientPrice | currency : 'USD' : 'symbol' }}
                      </td>
                    </tr>
                    <tr *ngIf="rows.length && !isViewQuoteFlow">
                      <td>
                        <label class="label">Sales Tax</label>
                        <input type="number" name="discountAmount" [formControl]="salesTaxPercentControl"
                          class="form-control form-control-white" placeholder="Percentage" min="1"
                          appTwoDigitDecimaNumber />
                      </td>
                      <td>
                        {{ salesTax | currency : 'USD' : 'symbol' }}
                      </td>
                    </tr>
                    <tr *ngIf="rows.length">
                      <td><label class="label"><b>Customer Price</b></label></td>
                      <td>
                        <b>{{ customerPrice | currency : 'USD' : 'symbol' }}</b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>

            </tr>


          </tfoot>
        </table>
      </div>

      <div class="bill-btn-group">
        <button *ngIf="!isViewQuoteFlow" class="btn btn-primary" type="button" [disabled]="createQuoteInprogress"
          (click)="onSave()">{{
          isEditQuoteFlow ? 'Update' : 'Save' }} &nbsp;
          <i class="fas fa-spinner fa-spin" *ngIf="createQuoteInprogress"></i></button>

        <button *ngIf="isViewQuoteFlow && quote?.status?.toLowerCase() === 'pending'" class="btn btn-primary"
          type="button" [disabled]="createQuoteInprogress" (click)="onGenerateSalesAgreement()">Generate
          Agreement</button>

        <button *ngIf="isViewQuoteFlow && quote?.status?.toLowerCase() !== 'pending'" class="btn btn-primary"
          type="button" [disabled]="createQuoteInprogress" (click)="onEditSalesAgreement()">Edit Agreement</button>

        <button *ngIf="!isViewQuoteFlow" class="btn btn-primary" type="button" [disabled]="previewInProgress"
          (click)="onPreviewQuote()">Preview Quote &nbsp;
          <i class="fas fa-spinner fa-spin" *ngIf="previewInProgress"></i></button>

        <button *ngIf="isViewQuoteFlow" class="btn btn-primary" type="button" [disabled]="createQuoteInprogress"
          (click)="openEmailTemplateModal()">Send Quote to Client &nbsp;
          <i class="fas fa-spinner fa-spin" *ngIf="createQuoteInprogress"></i></button>
        <button class="btn btn-white" type="button" (click)="onCancel()">Cancel</button>
      </div>
    </div>
  </div>
</div>
<!-- == End Page content == -->


<!-- EMAIL TEMPLATE START -->
<ng-template #emailTemplate let-modal="modal">
  <div class="modal-header flex-column justify-content-center border-0">
    <button type="button" class="btn-close" aria-label="Close" (click)="emailTemplateModalRef.dismiss()"></button>
    <h5 class="modal-title text-center" id="create-client-header">Email Template</h5>
  </div>
  <div class="modal-body">
    <div class="client-management__content mt-4">
      <div class="form-group mb-4">
        <input type="text" name="subject" class="form-control form-control-white" placeholder="Subject"
          [formControl]="subject" trimwhitespacestart />
      </div>

      <div class="form-group">
        <ckeditor [editor]="Editor" [(ngModel)]="editorData" [config]="config"></ckeditor>
      </div>
    </div>

    <div class="col col-12 col-sm-12 col-md-12 text-start mt-4">
      <button class="btn btn-primary" type="button" [disabled]="!subject.valid || sendToClientInProgress || !editorData"
        (click)="generateQuote()">
        {{ quote.sent_at ? 'Resend' : 'Send' }}
        &nbsp; <i class="fas fa-spinner fa-spin" *ngIf="sendToClientInProgress"></i></button>
    </div>
  </div>
</ng-template>
<!-- EMAIL TEMPLATE END -->