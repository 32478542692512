import { FormControl } from '@angular/forms';
import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { InvoiceService } from '../../../../services/invoice-service';
import { TRANSACTION_STATUS } from '../../../../utils/constants';
import { IPagination } from '../../../../utils/models';
import { ITransaction } from './../../../../utils/models';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-transaction-list',
  templateUrl: './transaction-list.component.html',
  styleUrls: ['./transaction-list.component.scss']
})
export class TransactionListComponent implements OnInit {
  @Input()
  clientId: string | undefined;

  loading: boolean = false;

  rows: ITransaction[] = [];

  @ViewChild('dateTemplate', { static: true })
  dateTemplate: TemplateRef<any>;

  @ViewChild('nameTemplate', { static: true })
  nameTemplate: TemplateRef<any>;

  @ViewChild('actionsTemplate', { static: true })
  actionsTemplate: TemplateRef<any>;

  @ViewChild('paidTemplate', { static: true })
  paidTemplate: TemplateRef<any>;

  @ViewChild('statusTemplate', { static: true })
  statusTemplate: TemplateRef<any>

  @ViewChild('currencyTemplate', { static: true })
  currencyTemplate: TemplateRef<any>;

  columns: any[] = [];

  promise: Promise<any>;

  pagination: IPagination = {
    page: 0,
    limit: 10,
    total_records: 0,
  };

  transactionStatus = TRANSACTION_STATUS;

  @Input()
  isTabContent: boolean = false;

  searchControl: FormControl = new FormControl(null);

  constructor(
    private toastrService: ToastrService,
    private invoiceService: InvoiceService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.fetch(this.pagination);

    this.columns = [
      { name: 'Client Name', prop: 'client_id', cellTemplate: this.nameTemplate, sortable: false },
      { name: 'Email', prop: 'client_id.email', sortable: false },
      { name: 'Notes', prop: 'description', sortable: false },
      { name: 'Payment Method', prop: 'payment_method', sortable: false, width: 180 },
      { name: 'Amount', prop: 'amount', sortable: false, cellTemplate: this.currencyTemplate },
      { name: 'View Invoices', cellTemplate: this.actionsTemplate, sortable: false },
      { name: 'Paid', cellTemplate: this.paidTemplate, sortable: false }
    ];

    this.searchControl.valueChanges
      .pipe(debounceTime(500))
      .subscribe(() => {
        this.pagination = {
          page: 0,
          limit: 10,
          total_records: 0,
        };

        this.fetch(this.pagination);
      });
  }

  fetch(pagination: IPagination) {
    this.loading = true;

    const searchText = this.searchControl.value || null;

    this.invoiceService.getAllTransaction(pagination, this.clientId, searchText)
      .toPromise()
      .then((res) => {
        this.rows = res.transactions;

        this.pagination.total_records = res.total_records;
      })
      .catch(() => this.toastrService.error('Failed to read Invoice'))
      .finally(() => this.loading = false);
  }

  setPage(pagination: any) {
    this.pagination.page = pagination.offset;

    this.fetch(this.pagination);
  }

  onViewInvoice(record: ITransaction) {
    this.router.navigate(['view-invoice', record.invoice_id]);
  }
}
