import { QuoteService } from 'src/app/services/quote-service';
import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { InvoiceService } from '../../../../services/invoice-service';
import { TRANSACTION_STATUS } from '../../../../utils/constants';
import { IPagination } from '../../../../utils/models';
import { IInventory, IProduct } from './../../../../utils/models';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-inventory-list',
  templateUrl: './inventory-list.component.html',
  styleUrls: ['./inventory-list.component.scss']
})
export class InventoryListComponent implements OnInit {
  @Input()
  clientId: string | undefined;

  loading: boolean = false;

  rows: IInventory[] = [];

  @ViewChild('dateTemplate', { static: true })
  dateTemplate: TemplateRef<any>;

  @ViewChild('actionsTemplate', { static: true })
  actionsTemplate: TemplateRef<any>;

  @ViewChild('statusTemplate', { static: true })
  statusTemplate: TemplateRef<any>

  @ViewChild('currencyTemplate', { static: true })
  currencyTemplate: TemplateRef<any>;

  columns: any[] = [];

  promise: Promise<any>;

  pagination: IPagination = {
    page: 0,
    limit: 10,
    total_records: 0,
  };

  transactionStatus = TRANSACTION_STATUS;

  @Input()
  isTabContent: boolean = false;

  quantity: FormControl = new FormControl(null, Validators.required);

  newQuantity: FormControl = new FormControl();

  quantityUpdateInProgress: boolean = false;

  createInvInProgress: boolean = false;

  product: IProduct | undefined;

  searchControl: FormControl = new FormControl(null);

  constructor(
    private toastrService: ToastrService,
    private invoiceService: InvoiceService,
    private quoteService: QuoteService,
  ) { }

  ngOnInit(): void {
    this.fetch(this.pagination);

    this.columns = [
      { name: 'Item', prop: 'item_id' },
      { name: 'Quantity', prop: 'quantity', sortable: false },
      { name: 'Amount', prop: 'amount', sortable: false },
      { name: '', cellTemplate: this.actionsTemplate, sortable: false, width: 60 }
    ];

    this.searchControl.valueChanges
      .pipe(debounceTime(500))
      .subscribe(() => {
        this.pagination = {
          page: 0,
          limit: 10,
          total_records: 0,
        };

        this.fetch(this.pagination);
      });
  }

  fetch(pagination: IPagination) {
    this.loading = true;

    const searchText = this.searchControl.value || null;

    this.invoiceService.getAllInventory(pagination, this.clientId, searchText)
      .toPromise()
      .then((res) => {
        // res.inventories.forEach((inv: IInventory) => {
        //   let amount = 0;

        //   inv.products.forEach((product: IProduct) => amount = amount + product.price);

        //   inv.amount = amount;
        // });

        this.rows = res.inventories;

        this.pagination.total_records = res.total_records;
      })
      .catch(() => this.toastrService.error('Failed to read Inventory'))
      .finally(() => this.loading = false);
  }

  setPage(pagination: any) {
    this.pagination.page = pagination.offset;

    this.fetch(this.pagination);
  }

  onEdit(record: IInventory) {
    this.rows.forEach((r) => r.isEdit = false);

    record.isEdit = true;

    this.quantity.setValue(record.quantity);
  }

  onSave(record: IInventory, isSubtract?: boolean) {
    if (record.quantity === this.quantity.value) {
      record.isEdit = false;

      return;
    }

    this.quantityUpdateInProgress = true;

    // const qty = isSubtract ? this.quantity.value * -1 : this.quantity.value;

    const qty = this.quantity.value;

    this.invoiceService.updateInventoryQuantity({ item_id: record.item_id, quantity: qty })
      .toPromise()
      .then((res) => {
        record.quantity = res.quantity;
        record.isEdit = false;
        this.toastrService.success('Inventory quantity updated successfully');
      })
      .catch(() => this.toastrService.error('Failed to update inventory quantity'))
      .finally(() => this.quantityUpdateInProgress = false);
  }

  createInventory() {
    this.createInvInProgress = true;

    this.invoiceService.createInventory({ item_id: this.product?.item_id, quantity: this.newQuantity.value })
      .toPromise()
      .then((res) => {
        this.toastrService.success('Inventory created successfully.');

        this.fetch(this.pagination);
      })
      .catch((err) => {
        let errMsg;
        if (err && err.error && err.error.message) {
          errMsg = err.error.message;
        }
        this.toastrService.error(errMsg || 'Failed to create inventory.');
      })
      .finally(() => {
        this.quoteService.clearproduct.next(true);

        this.product = undefined;

        this.newQuantity.setValue(null);

        this.createInvInProgress = false;
      });
  }

  onSelectProduct(product: any) {
    this.product = product;
  }
}
