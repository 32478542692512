<div class="auth-wrapper container-fluid">
  <div class="row">
    <div class="col col-12 col-md-12 col-lg-6">
      <div class="auth-content__card">
        <div class="brand-logo">
          <a class="brand-logo__link d-inline-block">
            <img src="assets/images/gloscape-logo.png" class="img-fluid auth-logo" alt="Logo">
          </a>
        </div>
        <div class="auth-content__form">
          <div class="d-flex justify-content-between">
            <h2 class="title">Welcome <img src="assets/images/wave-icon.png" class="img-fluid ml-3" alt="Wave Icon" />
            </h2>
            <span *ngIf="showResetPwd" class="pt-4 pointer cursor-pointer" (click)="showResetPwd = false">
              <i class="fas fa-long-arrow-alt-left"></i> Back
            </span>
          </div>
          <form (ngSubmit)="onSubmit()" #signin="ngForm">
            <div class="form-group mb-4">
              <input type="email" name="email" pattern="^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$"
                [(ngModel)]="authModel.email" required #email="ngModel" class="form-control" placeholder="Email" />
              <small class="form-text error text-danger mb-2 text-left"
                *ngIf="email.touched && email.errors?.required">Email is required</small>
              <small class="form-text error text-danger mb-2 text-left"
                *ngIf="email.touched && email.errors?.pattern">Invalid
                email</small>
            </div>
            <div class="form-group mb-3" *ngIf="!showResetPwd">
              <div class="input-group mb-0 input-group-password">
                <input type="{{ showPassword ? 'text' : 'password' }}" name="password" [(ngModel)]="authModel.password" required #password="ngModel"
                  class="form-control" placeholder="Password" />
                  <button type="button" class="input-group-append">
                    <i class="light-icon light-icon-eye" *ngIf="!showPassword" (click)="showPassword = true"></i>
                    <i class="light-icon light-icon-eye-slash" *ngIf="showPassword" (click)="showPassword = false"></i>
                  </button>
              </div>
              <small class="form-text error text-danger mb-2 text-left"
                *ngIf="password.touched && password.errors?.required">Required</small>
            </div>
            <div class="auth-login__action" *ngIf="!showResetPwd">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" name="password" [(ngModel)]="authModel.isRememberMe"
                  value="" id="remember_me">
                <label class="form-check-label" for="remember_me"> Remember me</label>
              </div>
              <a class="link link-gray pointer cursor-pointer" (click)="showResetPwd = true">Forgot Password?</a>
            </div>
            <div class="auth-btn-group text-center">
              <button type="submit" *ngIf="!showResetPwd" [disabled]="signin.invalid || signinInProgress || !authModel.password"
                [ngClass]="{'disable': signin.invalid || signinInProgress || !authModel.password}" class="btn btn-primary btn-auth">
                <i class="fas fa-spinner fa-spin me-2" *ngIf="signinInProgress"></i>
                <span> Next </span>
              </button>

              <button type="submit" *ngIf="showResetPwd" [disabled]="resetPwdInProgress || !authModel.email || email.errors"
                [ngClass]="{'disable': resetPwdInProgress || !authModel.email || email.errors}" class="btn btn-primary btn-auth">
                <i class="fas fa-spinner fa-spin me-2" *ngIf="resetPwdInProgress"></i>
                <span>
                  Reset
                </span>
              </button>
            </div>
            <div class="social-login text-center" *ngIf="!showResetPwd">
              <label class="label">or sign in with</label>
              <div class="social-login__btn-group">
                <button class="btn btn-icon btn-google btn-white" (click)="federatedSignin(1)" [disabled]="signinInProgress"
                [ngClass]="{'disable': signinInProgress}">
                  <img src="assets/images/google-icon.png" class="img-fluid" alt="Google" />
                </button>
                <button class="btn btn-icon btn-facebook btn-white" (click)="federatedSignin(0)" [disabled]="signinInProgress"
                [ngClass]="{'disable': signinInProgress}">
                  <img src="assets/images/facebook-icon.png" class="img-fluid" alt="Facebook" />
                </button>
              </div>
            </div>
        </form>
          <div class="auth-foot">
            <p class="text-center mb-0">Don’t have an account?
              <a class="link link-primary" [routerLink]="['/signup']">Sign up</a>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="col col-12 col-md-12 col-lg-6">
      <div class="auth-img__slider d-none d-lg-flex">
        <div class="auth-img__slider_item">
          <img src="assets/images/Saly-1.png" class="img-fluid" alt="sign In">
        </div>
        <!-- <ul class="list-unstyled dots-nav dots-nav-white">
          <li class="active">
            <button type="button" class="dot"></button>
          </li>
          <li>
            <button type="button" class="dot"></button>
          </li>
          <li>
            <button type="button" class="dot"></button>
          </li>
          <li>
            <button type="button" class="dot"></button>
          </li>
        </ul> -->
      </div>
    </div>
  </div>
</div>

<ng-template #content let-modal="modal">
  <div class="modal-header flex-column justify-content-center border-0">
    <h5 class="modal-title text-center" id="create-client-header">Email Verification</h5>
  </div>
  <div class="modal-body">
    <div class="client-management__content">
      <h5>Your email is not yet verified, Verification email has been sent to your mail id. Please verify and then
        signin.</h5>
    </div>

    <div class="col col-12 col-sm-12 col-md-12 text-center mt-5">
      <button class="btn btn-primary px-5" type="button" (click)="onOk()">Ok</button>
    </div>
  </div>
</ng-template>