import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AppointmentService } from './../../../../services/appointment.service';
import { LiveDemoService } from './../../../../services/live-demo.service';
import { IDemo, ILiveDemo, IAppoinments } from './../../../../utils/models';
import * as moment from 'moment';

@Component({
  selector: 'app-live-demo',
  templateUrl: './live-demo.component.html',
  styleUrls: ['./live-demo.component.scss']
})
export class LiveDemoComponent implements OnInit {
  closeResult = '';

  liveDemo: ILiveDemo;

  completedDemos: IDemo[] = [];

  nonCompletedDemos: IDemo[] = [];

  inProgressDemos: IDemo[] = [];

  pendingDemos: IDemo[] = [];

  nonCompletedDemosFilter: 'pending' | 'inprogress' = 'inprogress';

  allDemos: IDemo[] = [];

  loading: boolean = false;

  demoTobeDeleted: string;

  rows: IAppoinments[] = [];

  scheduledAppointments: IAppoinments[] = [];

  completedAppointments: IAppoinments[] = [];

  constructor(
    private router: Router,
    private toastrService: ToastrService,
    private liveDemoService: LiveDemoService,
    private appointmentService: AppointmentService,
    private modalService: NgbModal) { }

  ngOnInit(): void {
    this.fetch();
  }

  fetch(): void {
    this.loading = true;

    this.appointmentService.getAppointmentsByType('Live Demo')
      .toPromise()
      .then((res) => {
        this.rows = res || [];

        this.scheduledAppointments = this.rows.filter((r) => moment(r?.datetime, 'YYYY-MM-DD HH:mm').isAfter(moment()));

        this.completedAppointments = this.rows.filter((r) => moment(r?.datetime, 'YYYY-MM-DD HH:mm').isBefore(moment()));
      })
      .catch(() => this.toastrService.error('Failed to read appointments'))
      .finally(() => this.loading = false);
  }

  setDemoData(response: ILiveDemo): void {
    this.liveDemo = response;

    this.allDemos = response.demos.slice();

    this.completedDemos = response.demos.filter((d) => d.status.toLowerCase() === 'completed');

    this.inProgressDemos = response.demos.filter((d) => d.status.toLowerCase() === 'inprogress');

    this.pendingDemos = response.demos.filter((d) => d.status.toLowerCase() === 'pending');

    this.setNonCompletedDemos();
  }

  setNonCompletedDemos(): void {
    this.nonCompletedDemos = this.nonCompletedDemosFilter === 'inprogress' ? this.inProgressDemos : this.pendingDemos;
  }

  navigateToDetail(demo: IDemo): void {
    this.router.navigate(['live-demo-scheduling', demo._id]);
  }
  onEditPendingDemo(demo: IDemo) {
    this.router.navigate(['virtual-demo', demo._id]);
  }
  onDeletePendingDemo(demoId: string) {
    this.loading = true;

    this.liveDemoService.deleteVirtualDemo(demoId)
      .toPromise()
      .then((res: { status: boolean }) => {
        if (res.status) {
          this.fetch();
          this.toastrService.success('Virtual demo deleted successfully');
        }
      })
      .catch(() => this.toastrService.error('Failed to delete'))
      .finally(() => this.loading = false);
  }
  openModal(content: any, id: string) {
    this.demoTobeDeleted = id;
    this.modalService.open(content,
      {
        ariaLabelledBy: 'modal-basic-title',
        size: 'xl',
        backdrop: 'static',
        windowClass: 'add-appointment-modal'
      });

  }
  onConfirmDelete() {
    this.onDeletePendingDemo(this.demoTobeDeleted);
    this.modalService.dismissAll();
  }
  onCancelDemo() {
    this.demoTobeDeleted = '';
    this.modalService.dismissAll();
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  createLiveDemo() {
    this.router.navigate(['calender'], { queryParams: { type: 'Live Demo' } });
  }

}
