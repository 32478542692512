<!-- == Start Page Content here == -->
<div [ngClass]="{ 'content-page': !isTabContent }">
  <div class="page-title-group align-items-start" *ngIf="!isTabContent">
    <div class="page-title-group__left">
      <h2 class="title">Available La Carte Services &nbsp; <i class="fas fa-spinner fa-spin ms-2" *ngIf="loading"></i></h2>
      <p class="description">Please explore these a la carte options to order <br/> our help with a variety of marketing strategies. <br/>(Billed separately from your subscription.)</p>
    </div>
    <div class="page-title-group__right d-flex">
      <div class="search-bar">
        <span class="searchbar-icon">
          <span class="light-icon light-icon-search"></span>
        </span>
        <input type="search" name="search" class="search-control form-control form-control-white" placeholder="Search"
          [formControl]="searchControl" />
        <span class="searchbar-close-icon d-lg-none cursor-pointer">
          <span class="light-icon light-icon-close cursor-pointer"></span>
        </span>
      </div>

      <button class="btn btn-primary ms-3" (click)="createServices()" [disabled]="fetchingReqServices">Request &nbsp; <i
          class="fas fa-spinner fa-spin ms-2" *ngIf="fetchingReqServices"></i></button>
    </div>
  </div>
  <div class="content-page__body">
    <div class="tab-content" id="generate-invoice_tabContent">
      <div class="tab-pane fade show active" id="generate-invoice" role="tabpanel"
        aria-labelledby="generate-invoice-tab">
        <div class="generate-invoice__content">
          <div *ngIf="loading && isTabContent"><i class="fas fa-spinner fa-spin"></i></div>
          <div class="service-list row g-30" *ngIf="rows.length || !loading">
            <div class="col col-12 col-sm-12 col-md-6 col-xl-4" *ngFor="let row of rows">
              <div class="card card-service">
                <div class="card-service__img">
                  <img [src]="row?.picture_url || '../../../../../assets/images/Avatar-1.png'"
                    onerror="this.src='../../../../../assets/images/Avatar-1.png';" class="img-fluid"
                    alt="Service Image" />
                </div>
                <div class="card-service__body">
                  <div class="card-service__head">
                    <div class="score"><label class="label">Price :</label>{{ row?.price | currency : 'USD' : 'symbol'
                      }}</div>
                    <span class="badge badge-success">{{ row?.status ? 'ACTIVE' : 'NOT ACTIVE' }}</span>
                  </div>
                  <h3 class="title">{{ row?.name }}</h3>
                  <div class="description">{{ row?.description }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-4" *ngIf="rows.length || !loading">
            <ngx-datatable [rows]="rows" [columns]="columns" rowHeight="auto" [headerHeight]="44" [footerHeight]="50"
              [columnMode]="'force'" [loadingIndicator]="loading" [externalPaging]="true"
              [count]="pagination.total_records" [limit]="pagination.limit" [offset]="pagination.page"
              (page)="setPage($event)" [scrollbarH]="true"></ngx-datatable>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- == End Page content == -->

<!-- NGX TABLE CELL TEMPLATES START -->
<ng-template #actionsTemplate let-row="row" let-value="value">
  <div class="table-actions">
    <!-- <button type="button" class="btn btn-icon btn-off-primary btn-sm" (click)="onEditService(row)">
      <i class="light-icon light-icon-pencil light-icon-primary"></i>
    </button> -->
  </div>
</ng-template>

<ng-template #dateTemplate let-row="row" let-value="value">
  <span>{{ value ? (value | date: 'dd MMM YYYY': 'UTC') : 'NA' }}</span>
</ng-template>

<ng-template #statusTemplate let-row="row" let-value="value">
  <span>{{ value ? 'ACTIVE' : 'IN ACTIVE' }}</span>
</ng-template>

<ng-template #linkTemplate let-row="row" let-value="value">
  <a href="value" target="_blank">{{ value }}</a>
</ng-template>

<ng-template #pictureTemplate let-row="row" let-value="value">
  <div class="img-gallery__group">
    <span class="img-gallery__item">
      <img [src]="value || 'assets/images/Avatar-1.png'" onerror="this.src='assets/images/Avatar-1.png';"
        class="img-fluid" alt="Avatar">
    </span>
  </div>
</ng-template>
<!-- NGX TABLE CELL TEMPLATES END -->

<ng-template #createServicesTemplate>
  <div class="modal-header flex-column justify-content-center border-0">
    <button type="button" class="btn-close" aria-label="Close" (click)="createServicesModelRef.dismiss()"></button>
    <h5 class="modal-title text-center" id="create-client-header">Request Service</h5>
  </div>
  <div class="modal-body">
    <div class="client-management__content">
      <div class="table-responsive-md mt-4">
        <table class="table common-table client-add-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Price</th>
              <th>Description</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let row of selectedServices">
              <td>{{ row.name }}</td>
              <td>{{ row.price }}</td>
              <td>{{ row.description }}</td>
              <td>
                <div class="table-actions">
                  <button class="btn btn-icon btn-off-primary btn-sm" type="button" aria-expanded="false"
                    (click)="onDeleteService(row)">
                    <i class="light-icon light-icon-delete"></i>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr [formGroup]="servicesForm">
              <td>
                <app-services-type-ahead-control (selectoption)="onSelectOption($event)">
                </app-services-type-ahead-control>
              </td>
              <td>
                <input type="text" name="price" formControlName="price" class="form-control form-control-white" />
              </td>
              <td>
                <input type="text" name="description" formControlName="description"
                  class="form-control form-control-white" trimwhitespacestart />
              </td>
              <td>
                <button class="btn btn-icon btn-primary" type="button" [disabled]="!servicesForm.valid"
                  (click)="onAdd()">
                  <i class="light-icon light-icon-square-plus"></i>
                </button>
              </td>
            </tr>
          </tfoot>
        </table>
        <div class="col col-12 col-sm-12 col-md-12 text-start">
          <button class="btn btn-primary px-5" type="button"
            [disabled]="!selectedServices.length || createServicesInprogress" (click)="onCreateUpdateService()">Request
            &nbsp; <i class="fas fa-spinner fa-spin" *ngIf="createServicesInprogress"></i></button>
        </div>
      </div>
    </div>
  </div>
</ng-template>