import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { FirebaseAuthService } from './services/firebase-auth.service';
import { UserService } from './services/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'mylightdemo';
  showHeaderSidebar = false;

  constructor(
    public router: Router,
    private auth: FirebaseAuthService,
    private userService: UserService) {
    this.auth.getCurrentUser().subscribe(res => {
      if (res) {
        this.userService.readUser().subscribe();
      }
      setTimeout(() => {
        this.showHeaderSidebar = true;
      });
    })
  }
}
