import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { loadStripe, Stripe } from '@stripe/stripe-js';

@Injectable({
  providedIn: 'root'
})
export class StripeService {
  stripe: Stripe | null;

  constructor() {

  }
  async init() {
    this.stripe = await loadStripe(environment.stripePublicKey);
  }
}


