import { MarketMaterialsListComponent } from './components/market-materials-list/market-materials-list.component';
import { InstallationScheduledComponent } from './components/installation-scheduled/installation-scheduled-list.component';
import { AgmCoreModule } from '@agm/core';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SignaturePadModule } from 'angular2-signaturepad';
import { PlyrModule } from 'ngx-plyr';
import { AccountSettingsComponent } from './components/account-settings/account-settings.component';
import { AgreementComponent } from './components/agreement/agreement.component';
import { CalenderComponent } from './components/calender/calender.component';
import { ClientManagmentComponent } from './components/client-managment/client-managment.component';
import { ClientStatementComponent } from './components/client-statement/client-statement.component';
import { ClientTypeAheadControlComponent } from './components/client-type-ahead-control/client-type-ahead-control.component';
import { ClientUpdateComponent } from './components/client-update/client-update.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { EmailTemplateComponent } from './components/email-template/email-template.component';
import { IntroComponent } from './components/intro/intro.component';
import { InventoryListComponent } from './components/inventory-list/inventory-list.component';
import { InvoiceListComponent } from './components/invoice-list/invoice-list.component';
import { InvoicePaymentFailureComponent } from './components/invoice-payment-failure/invoice-payment-failure.component';
import { InvoicePaymentSuccessComponent } from './components/invoice-payment-success/invoice-payment-success.component';
import { InvoiceTemplateComponent } from './components/invoice-template/invoice-template.component';
import { LiveDemoSchedulingComponent } from './components/live-demo-scheduling/live-demo-scheduling.component';
import { LiveDemoComponent } from './components/live-demo/live-demo.component';
import { MarketingComponent } from './components/marketing/marketing.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { PaymentListComponent } from './components/payment-list/payment-list.component';
import { PaymentComponent } from './components/payment/payment.component';
import { ProductTypeAheadControlComponent } from './components/product-type-ahead-control/product-type-ahead-control.component';
import { PurchaseOrderListComponent } from './components/purchase-order-list/purchase-order-list.component';
import { PurchaseOrderTemplateComponent } from './components/purchase-order-template/purchase-order-template.component';
import { PurchaseOrdersComponent } from './components/purchase-orders/purchase-orders.component';
import { QuoteListComponent } from './components/quote-list/quote-list.component';
import { QuoteTemplateComponent } from './components/quote-template/quote-template.component';
import { ReportsComponent } from './components/reports/reports.component';
import { ResourcesComponent } from './components/resources/resources.component';
import { SalesAgreementListComponent } from './components/sales-agreement-list/sales-agreement-list.component';
import { SalesAgreementTemplateComponent } from './components/sales-agreement-template/sales-agreement-template.component';
import { ServicesListComponent } from './components/services-list/services-list.component';
import { ServicesTypeAheadControlComponent } from './components/services-type-ahead-control/services-type-ahead-control.component';
import { SetupBankAccountComponent } from './components/setup-bank-account/setup-bank-account.component';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { TrainingVideoComponent } from './components/training-video/training-video.component';
import { TransactionListComponent } from './components/transaction-list/transaction-list.component';
import { UserSettingComponent } from './components/user-setting/user-setting.component';
import { UserTrainingVideosComponent } from './components/user-training-videos/user-training-videos.component';
import { VendorTypeAheadControlComponent } from './components/vendor-type-ahead-control/vendor-type-ahead-control.component';
import { VirtualDemoComponent } from './components/virtual-demo/virtual-demo.component';
import { CoreRoutingModule } from './core-routing.module';
import { DynamicPlaceHolderDirective } from './directive/dynamic-place-holder.directive';
import { NumberDirective } from './directive/numbers-only.directive';
import { PhoneMaskDirective } from './directive/phone-mask.directive';
import { TrimWhiteSpaceStartDirective } from './directive/trim-white-space.directive';
import { TwoDigitDecimaNumberDirective } from './directive/two-digit-decimal-number.directive';
import { VirtualDemoListComponent } from './components/virtual-demo-list/virtual-demo-list.component';

FullCalendarModule.registerPlugins([
  dayGridPlugin,
  timeGridPlugin,
  interactionPlugin
]);

@NgModule({
  declarations: [
    SignUpComponent,
    SignInComponent,
    PaymentComponent,
    DashboardComponent,
    PageNotFoundComponent,
    LiveDemoComponent,
    CalenderComponent,
    ClientManagmentComponent,
    PurchaseOrdersComponent,
    ReportsComponent,
    MarketingComponent,
    UserSettingComponent,
    VirtualDemoComponent,
    TrainingVideoComponent,
    IntroComponent,
    LiveDemoSchedulingComponent,
    InvoiceTemplateComponent,
    QuoteTemplateComponent,
    ResourcesComponent,
    EmailTemplateComponent,
    AgreementComponent,
    ClientStatementComponent,
    SetupBankAccountComponent,
    UserTrainingVideosComponent,
    AccountSettingsComponent,
    ClientUpdateComponent,
    QuoteListComponent,
    ClientTypeAheadControlComponent,
    ProductTypeAheadControlComponent,
    SalesAgreementTemplateComponent,
    SalesAgreementListComponent,
    InvoiceListComponent,
    PaymentListComponent,
    InvoicePaymentSuccessComponent,
    InvoicePaymentFailureComponent,
    PurchaseOrderTemplateComponent,
    PurchaseOrderListComponent,
    NumberDirective,
    TrimWhiteSpaceStartDirective,
    VendorTypeAheadControlComponent,
    TransactionListComponent,
    InventoryListComponent,
    TwoDigitDecimaNumberDirective,
    DynamicPlaceHolderDirective,
    ServicesListComponent,
    ServicesTypeAheadControlComponent,
    PhoneMaskDirective,
    InstallationScheduledComponent,
    VirtualDemoListComponent,
    MarketMaterialsListComponent,
  ],
  imports: [
    CommonModule,
    CoreRoutingModule,
    FormsModule,
    NgxSliderModule,
    FullCalendarModule,
    PlyrModule,
    CKEditorModule,
    ReactiveFormsModule,
    NgxDatatableModule,
    NgbModule,
    SignaturePadModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDuvPT05fPxi89k3F9sKqgKCYlieHcnwec'
    })
  ]
})
export class CoreModule { }
