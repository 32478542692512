import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import { AppointmentService } from './../../../../services/appointment.service';
import { ClientManagementService } from './../../../../services/client-management.service';
import { IAppoinments, IClient } from './../../../../utils/models';
import { MapsAPILoader } from "@agm/core";
declare var google: { maps: { Geocoder: new () => any; }; };

@Component({
  selector: 'app-client-update',
  templateUrl: './client-update.component.html',
  styleUrls: ['./client-update.component.scss']
})
export class ClientUpdateComponent implements OnInit {
  loading: boolean = false;

  clientForm: FormGroup;

  updateClientInProgress: boolean = false;

  client: IClient;

  // google maps zoom level
  zoom: number = 8;

  // initial center position for the map
  lat: number = 38.9072;
  lng: number = 77.0369;

  // Inital marker position
  marker: any = {};

  selectedTab: string = 'client';

  appointmentsLoading: boolean = false;

  liveDemoAppointments: IAppoinments[] = [];

  installationAppointments: IAppoinments[] = [];

  constructor(
    private clientManagementService: ClientManagementService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private appointmentService: AppointmentService,
    private mapsAPILoader: MapsAPILoader
  ) {
    this.clientForm = this.formBuilder.group({
      first_name: new FormControl(null, [Validators.required]),
      last_name: new FormControl(null, [Validators.required]),
      email: new FormControl(null, [Validators.required, Validators.email]),
      line: new FormControl(null, [Validators.required]),
      state: new FormControl(null, [Validators.required]),
      city: new FormControl(null, [Validators.required]),
      postal: new FormControl(null, [Validators.required, Validators.pattern('[0-9]*'), Validators.minLength(2), Validators.maxLength(8)]),
      country: new FormControl(null, [Validators.required]),
      // phone_number: new FormControl(null, [Validators.required, Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')]),
      phone_number: ['', [Validators.pattern(/^\(\d{3}\)\s\d{3}-\d{4}$/), Validators.required]],
      notes: new FormControl(null),
    })
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((param) => {
      if (param.clientId) {
        this.fetchClient(param.clientId);

        this.fetchAppointments(param.clientId);
      }
    });

    this.activatedRoute.queryParams.subscribe((param) => {
      if (param.tab) {
        this.selectedTab = param.tab;
      }
    });
  }

  onTabClick(tab: string) {
    this.router.navigate([], {
      queryParams: {
        tab
      }
    });

    this.selectedTab = tab;
  }

  fetchClient(id: string): void {
    this.loading = true;

    this.clientManagementService.readClient(id)
      .toPromise()
      .then((client: IClient) => {
        const data = {
          client: client,
          first_name: client.first_name,
          last_name: client.last_name,
          email: client.email,
          line: client.address.line,
          city: client.address.city,
          state: client.address.state,
          postal: client.address.postal,
          country: client.address.country,
          phone_number: client.phone_number,
          notes: client.notes
        };

        this.client = client;

        this.setLocation(this.client);

        this.clientForm.patchValue(data, { onlySelf: true, emitEvent: false });
      })
      .catch(() => this.toastrService.error('Failed to fetch client information.'))
      .finally(() => this.loading = false)
  }

  fetchAppointments(id: string): void {
    this.appointmentsLoading = true;

    forkJoin(
      [
        this.appointmentService.getInstallationAppointmentsbyClient(id),
        this.appointmentService.getLiveDemoAppointmentsbyClient(id)
      ]
    ).toPromise()
      .then((results) => {
        this.installationAppointments = results[0];
        this.liveDemoAppointments = results[1];
      })
      .catch(() => this.toastrService.error('Failed to fetch appointments.'))
      .finally(() => this.appointmentsLoading = false);
  }

  updateClient(marker?: any) {
    this.updateClientInProgress = true;

    const formData = this.clientForm.getRawValue();

    const payload: IClient = {
      first_name: formData.first_name,
      last_name: formData.last_name,
      email: formData.email,
      address: {
        line: formData.line,
        postal: formData.postal,
        state: formData.state,
        city: formData.city,
        country: formData.country,
      },
      phone_number: formData.phone_number,
      notes: formData.notes
    }

    if (marker || this.client.location) {
      const location = marker || this.client.location;

      payload.location = location;
    }

    this.clientManagementService.updateClient(this.client._id as string, payload)
      .toPromise()
      .then(() => this.toastrService.success(marker ? 'Location updated successfully' : 'Client updated successfully.'))
      .catch((err) => this.toastrService.error(err?.error?.message || 'Failed to create client.'))
      .finally(() => this.updateClientInProgress = false);
  }

  mapClicked(event: any) {
    event.preventDefault();
    // this.marker = {
    //   lat: $event.coords.lat,
    //   lng: $event.coords.lng,
    // };
  }

  onUpdateLocation() {
    this.updateClient(this.marker);
  }

  onInstallationComplete(record: IAppoinments) {
    record.loading = true;

    this.appointmentService.updateAppointment(Object.assign({}, record, { status: 'Completed' }), record._id as string)
      .toPromise()
      .then(() => {
        this.toastrService.success('Installation status updated');

        this.fetchAppointments(this.client._id as string);
      })
      .catch((err) => this.toastrService.error(err.error.message || 'Installation status update failed.'))
      .finally(() => record.loading = false);
  }

  createLiveDemo() {
    this.router.navigate(['calender'], { queryParams: { type: 'Live Demo', client_id: this.client._id } });
  }
  onCreateVirtualDemo() {
    this.router.navigate(['virtual-demo'], { queryParams: { client_id: this.client._id, first_name: this.client.first_name, last_name: this.client.last_name } });
  }
  createInstalltion() {
    this.router.navigate(['calender'], { queryParams: { type: 'Installation', client_id: this.client._id } });
  }
  setLocation(client: IClient) {
    this.mapsAPILoader.load().then(() => {
      const geocoder = new google.maps.Geocoder();

      geocoder.geocode({ address: `${client.address.line},${client.address.city},${client.address.state},${client.address.country},${client.address.postal}` },
        (results: any, status: any) => {
          const result = results[0];

          this.marker = {
            lat: result.geometry.location.lat(),
            lng: result.geometry.location.lng(),
            draggable: false
          }
        })
    });
  }

  onMouseOver(infoWindow: any, gm: any) {
    if (gm.lastOpen != null) {
      gm.lastOpen.close();
    }

    gm.lastOpen = infoWindow;

    infoWindow.open();
  }
}


