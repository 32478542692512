<!-- == Start Page Content here == -->
<div class="content-page edit-profile-content">
  <div class="page-title-group">
    <div class="page-title-group__left">
      <h2 class="title">Email Template</h2>
    </div>
  </div>
  <div class="content-page__body">
    <form class="email-template-form">
      <div class="row">
        <div class="col col-12 col-sm-12 col-md-6 mb-4">
          <div class="form-group">
            <input type="text" name="recipient" class="form-control form-control-white" placeholder="Recipient" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col col-12 col-sm-12 col-md-6 mb-4">
          <div class="form-group">
            <input type="text" name="subject" class="form-control form-control-white" placeholder="Subject" />
          </div>
        </div>
        <div class="col col-12 col-sm-12 col-md-12 mb-4">
          <div class="form-group">
            <ckeditor [editor]="Editor" [(ngModel)]="model.editorData"  [config]="config"></ckeditor>
          </div>
        </div>
        <div class="col col-12 col-sm-12 col-md-12">
          <button class="btn btn-primary btn-save" type="submit">Save Template</button>
        </div>
      </div>
    </form>
  </div>
</div>
<!-- == End Page content == -->
