import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { LiveDemoService } from './../../../../services/live-demo.service';
import { IDemo, IPagination } from './../../../../utils/models';

@Component({
  selector: 'app-virtual-demo-list',
  templateUrl: './virtual-demo-list.component.html',
  styleUrls: ['./virtual-demo-list.component.scss']
})
export class VirtualDemoListComponent implements OnInit {
  @Input()
  clientId: string | undefined;

  virtualDemo: IDemo[] = [];
  loading: boolean;
  demoTobeDeleted: string;
  columns: any[] = [];
  pagination: IPagination = {} as IPagination;

  @ViewChild('actionsTemplate', { static: true })
  actionsTemplate: TemplateRef<any>;
  searchControl: string;
  constructor(
    private router: Router,
    private toastrService: ToastrService,
    private liveDemoService: LiveDemoService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.getAllVirtualDemo(1);
    this.columns = [
      { name: 'Client Name', prop: 'clientName' },
      { name: 'Name', prop: 'name' },
      { name: 'Created At', prop: 'created_at', sortable: false },
      { name: 'Action', cellTemplate: this.actionsTemplate, sortable: false, width: 100 }

    ];
  }
  setPage(pagination: any) {
    this.pagination.page = pagination.offset;
    this.getAllVirtualDemo(this.pagination.page + 1);
  }
  getAllVirtualDemo(page: number) {
    this.loading = true;
    this.liveDemoService.getAllDemo(this.pagination.limit, page, 'virtual', this.clientId)
      .toPromise()
      .then((res) => {
        res.demos.forEach(element => {
          element.clientName = element.client_id?.first_name + ' ' + element.client_id?.last_name;
          element.created_at = new Date(element?.created_at).toLocaleString('en-US', { hour12: false });
        });
        this.virtualDemo = res?.demos;
        this.pagination.total_records = res?.total_records;
        this.pagination.page = res?.page > 0 ? res?.page - 1 : 0;
        this.pagination.limit = 10 // res?.limit;

      })
      .catch((err) => {
        this.toastrService.error(err)
      })
      .finally(() => this.loading = false);
  }

  onEditPendingDemo(demo: IDemo) {
    this.router.navigate(['virtual-demo', demo._id]);
  }
  onDeletePendingDemo(demoId: string) {
    this.loading = true;

    this.liveDemoService.deleteVirtualDemo(demoId)
      .toPromise()
      .then((res: { status: boolean }) => {
        if (res.status) {
          this.toastrService.success('Virtual demo deleted successfully');
          this.getAllVirtualDemo(this.pagination.page + 1);

        }
      })
      .catch(() => {
        console.log("err")
        // this.toastrService.error('Failed to delete')
      })
      .finally(() => this.loading = false);
  }
  openModal(content: any, id: string) {
    this.demoTobeDeleted = id;
    this.modalService.open(content,
      {
        ariaLabelledBy: 'modal-basic-title',
        size: 'xl',
        backdrop: 'static',
        windowClass: 'add-appointment-modal'
      });

  }
  onConfirmDelete() {
    this.onDeletePendingDemo(this.demoTobeDeleted);
    this.modalService.dismissAll();
  }
  onCreateVirtualDemo() {
    this.router.navigate(['virtual-demo']);

  }

  onCancelDemo() {
    this.demoTobeDeleted = '';
    this.modalService.dismissAll();
  }
}
