import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { environment } from './../../../../../environments/environment';
import { ImageService } from './../../../../services/image.service';
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { takeWhile } from 'rxjs/operators';
import { FirebaseAuthService } from './../../../../services/firebase-auth.service';
import { UserService } from './../../../../services/user.service';
import { FederatedLogin } from './../../../../utils/enum';
import { AuthModel, IUserStatus } from './../../../../utils/models';

@Component({
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit, OnDestroy {
  authModel: AuthModel = {} as AuthModel;

  signupInProgress: boolean;

  canActivate: boolean = true;

  selectedProfileImage: any;

  selectedProfileImageFile: any;

  selectedProfileImageFileName: any;

  modalRef: NgbModalRef;

  @ViewChild('content')
  content: TemplateRef<any>;

  showPassword: boolean = false;

  constructor(
    public firebaseAuthService: FirebaseAuthService,
    private router: Router,
    private userService: UserService,
    private toastrService: ToastrService,
    private imageService: ImageService,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    'lint rule'
  }

  signUp() {
    this.signupInProgress = true;

    this.userService.checkUserStatus(this.authModel.email)
      .pipe(takeWhile(() => this.canActivate))
      .subscribe(
        (res: IUserStatus) => {
          if (!res.exists) {
            const promise: Promise<any> = this.selectedProfileImageFile ?
              this.imageService
                .uploadImage(this.selectedProfileImageFile, this.selectedProfileImageFileName)
                .toPromise()
                .then(() => {
                  const profile_image_url = `${environment.imageUploadUrl}${this.selectedProfileImageFileName}`;

                  return this.userService.createUser(this.authModel.email, profile_image_url).toPromise();
                }) :
              this.userService.createUser(this.authModel.email)
                .toPromise();

            promise.then(() => this.firebaseAuthService.createUserWithEmailAndPassword(this.authModel.email, this.authModel.password))
              .then(() => this.firebaseAuthService.sendVerificationEmail())
              .then(res => {
                this.firebaseAuthService.signOut();

                this.modalRef = this.modalService.open(this.content, { windowClass: 'create-client-modal' });
              })
              .catch(err => {
                this.signupInProgress = false;
                this.toastrService.error(err.error.message || 'User already registed with this email.');
              });
          } else {
            this.signupInProgress = false;
            this.toastrService.error('User already registed with this email.');
          }
        },
        (error: any) => {
          this.signupInProgress = false;
          this.toastrService.error('Failed to check the status of the user');
        }
      );
  }

  federatedSignin(type: FederatedLogin) {
    let email: string;

    this.firebaseAuthService.federatedLogin(type)
      .then((res: any) => {
        this.signupInProgress = true;
        email = res.additionalUserInfo.profile.email || res.user.multiFactor.user.email;
        return this.userService.checkUserStatus(email).toPromise();
      })
      .then((userInfo: IUserStatus) => {
        if (userInfo.exists) {
          this.signupInProgress = false;
          this.toastrService.error('User already registed with this email, Please signin.');
        } else {
          this.userService.createUser(email)
            .toPromise()
            .then(res => {
              this.signupInProgress = false;
              this.router.navigateByUrl('payment');
            })
            .catch(err => {
              this.signupInProgress = false;
              this.toastrService.error('Failed to create user try again.');
            });
        }
      })
      .catch(() => {
        this.toastrService.error('Unable to signup please try again.');
      })
      .finally(() => this.signupInProgress = false);
  }

  onSignInClick() {
    this.router.navigateByUrl('signin');
  }

  onProfileImageChange(event: any) {
    const files: FileList = event.target.files;

    if (files.length === 0) {
      return;
    }

    const file = files[0];
    const mimeType = file.type;

    if (mimeType.match(/image\/*/) == null) {
      this.toastrService.error('Please select image file');
      return;
    }

    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = (_event) => {
      this.selectedProfileImageFile = file;
      this.selectedProfileImageFileName = file.name;
      this.selectedProfileImage = reader.result;
    }
  }

  onOk() {
    this.modalRef.close();
    this.router.navigate(['/signin']);
  }

  ngOnDestroy() {
    this.canActivate = false;
  }
}
