<!-- == Left Sidebar Start == -->
<div class="left-sidebar-card">
  <div class="left-sidebar-card__body">
    <button type="button" class="btn btn-icon btn-icon-close d-xl-none" (click)="onHambergurOffClick()">
      <i class="light-icon light-icon-close"></i>
    </button>

    <div class="left-sidebar__avatar text-center cursor-pointer"  (click)="onHambergurOffClick()">
      <img *ngIf="!loading" [src]="user?.settings?.profile_image_url || 'assets/images/Avatar-1.png'"  onerror="this.src='assets/images/Avatar-1.png';" class="img-fluid cursor-pointer" alt="Avatar" [routerLink]="'setting'" (click)="onHambergurOffClick()">
      <i class="fas fa-spinner fa-spin" *ngIf="loading"></i>
      <h4 class="name cursor-pointer" [routerLink]="'setting'">{{ user?.first_name }}</h4>
      <p class="designation">{{ user?.business_name }}</p>
    </div>
    <ul class="nav flex-column left-sidebar-nav">
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['/dashboard']" routerLinkActive="active" (click)="onHambergurOffClick()">
          <span class="nav-item__icon light-icon light-icon-dashboard"></span>
          <span class="nav-item__text">Dashboard</span>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['/calender']" routerLinkActive="active" (click)="onHambergurOffClick()">
          <span class="nav-item__icon light-icon light-icon-calender"></span>
          <span class="nav-item__text">Calendar</span>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['/client-management']" routerLinkActive="active" (click)="onHambergurOffClick()">
          <span class="nav-item__icon light-icon light-icon-management"></span>
          <span class="nav-item__text">Client Management</span>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['/demo-list']" routerLinkActive="active" (click)="onHambergurOffClick()">
          <span class="nav-item__icon light-icon light-icon-virtualdemo"></span>
          <span class="nav-item__text">Virtual Demo</span>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link " [routerLink]="['/livedemo']" routerLinkActive="active" (click)="onHambergurOffClick()">
          <span class="nav-item__icon light-icon light-icon-live-demo"></span>
          <span class="nav-item__text">Live Demo</span>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['/quote-list']" routerLinkActive="active" (click)="onHambergurOffClick()">
          <span class="nav-item__icon light-icon light-icon-sales"></span>
          <span class="nav-item__text">Active Quotes</span>
        </a>
      </li> 
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['/installation-scheduled-list']" routerLinkActive="active" (click)="onHambergurOffClick()">
          <span class="nav-item__icon light-icon light-icon-help-gray"></span>
          <span class="nav-item__text">Installations</span>
        </a>
      </li>
      <!-- <li class="nav-item">
        <a class="nav-link" [routerLink]="['/orders']" routerLinkActive="active" (click)="onHambergurOffClick()">
          <span class="nav-item__icon light-icon light-icon-orders"></span>
          <span class="nav-item__text">Orders</span>
        </a>
      </li> -->
      <!-- <li class="nav-item">
        <a class="nav-link" [routerLink]="['/purchase-order-list']" routerLinkActive="active" (click)="onHambergurOffClick()">
          <span class="nav-item__icon light-icon light-icon-orders"></span>
          <span class="nav-item__text">Purchase Orders</span>
        </a>
      </li> -->
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['/inventory-list']" routerLinkActive="active" (click)="onHambergurOffClick()">
          <span class="nav-item__icon light-icon light-icon-orders"></span>
          <span class="nav-item__text">Inventory</span>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['/reports']" routerLinkActive="active" (click)="onHambergurOffClick()">
          <span class="nav-item__icon light-icon light-icon-reports"></span>
          <span class="nav-item__text">Document Management</span>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['/marketing-material-list']" routerLinkActive="active" (click)="onHambergurOffClick()">
          <span class="nav-item__icon light-icon light-icon-reports"></span>
          <span class="nav-item__text">Marketing Materials</span>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['/service-list']" routerLinkActive="active" (click)="onHambergurOffClick()">
          <span class="nav-item__icon light-icon light-icon-marketing"></span>
          <span class="nav-item__text">A La Carte Services</span>
        </a>
      </li>
      <!-- <li class="nav-item">
        <a  class="nav-link">
          <span class="nav-item__icon light-icon light-icon-help-gray"></span>
          <span class="nav-item__text">Help</span>
        </a>
      </li> -->

      <li class="nav-item" >
        <a  class="nav-link" [routerLink]="['/account-settings']" routerLinkActive="active" (click)="onHambergurOffClick()">
          <span class="nav-item__icon light-icon light-icon-setting-gray"></span>
          <span class="nav-item__text">Settings</span>
        </a>
      </li>

      <li class="nav-item" >

          <span class="nav-item__text">v 2.27.0</span>

      </li>
    </ul>
    <!-- <div class="get-done-card text-center">
      <h3 class="title">Get it done for you</h3>
      <button class="btn btn-primary btn-sm" type="button" (click)="onHambergurOffClick()">Get Now</button>
    </div>
  </div> -->
</div>
<!-- == Left Sidebar End == -->
