import { UserService } from './../../../../services/user.service';
import { Location } from '@angular/common';
import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SignaturePad } from 'angular2-signaturepad';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { QuoteService } from 'src/app/services/quote-service';
import { environment } from './../../../../../environments/environment';
import { AgreementService } from './../../../../services/agreement-service';
import { ImageService } from './../../../../services/image.service';
import { SALES_AGREEMENT_STATUS } from './../../../../utils/constants';
import { IClient, IProduct, IQuote, ISalesAgreement, IUser } from './../../../../utils/models';

@Component({
  selector: 'app-sales-agreement-template',
  templateUrl: './sales-agreement-template.component.html',
  styleUrls: ['./sales-agreement-template.component.scss']
})
export class SalesAgreementTemplateComponent implements OnInit {

  client_id: string;

  quote_id: string;

  salesAgreementId: string;

  salesAgreement: ISalesAgreement;

  quote: IQuote;

  client: IClient;

  products: IProduct[] = [];

  // salesTotal: number = 0;

  terms: FormControl = new FormControl(null);

  conditions: FormControl = new FormControl(null);

  acceptTermsAndService: FormControl = new FormControl(null);

  createSalesOrderInProgress: boolean = false;

  loading: boolean;

  updateStatusInProgress: boolean = false;

  updateAgreementInProgress: boolean = false;

  @ViewChild(SignaturePad) signaturePad: SignaturePad;

  signaturePadOptions: Object = {
    'minWidth': 2,
    'canvasWidth': 700,
    'canvasHeight': 150,
    'backgroundColor': '#fff'
  };

  signatureImg: any;

  signUploadInProgress: boolean;

  signUploadUrl: string;

  isViewMode: boolean = false;

  isEditMode: boolean = false;

  currentDate = new Date();

  salesAgreementStatus = SALES_AGREEMENT_STATUS;

  emailTemplateModalRef: NgbModalRef;

  Editor: any = ClassicEditor;

  editorData: string;

  config = {};

  @ViewChild('emailTemplate', { static: true })
  emailTemplate: TemplateRef<any>;

  subject: FormControl = new FormControl(null, Validators.required);

  sendToClientInProgress: boolean = false;

  @ViewChild('pdfContent', { static: false }) pdfContent: ElementRef;

  base64SignatureUrl: any;

  user: IUser;

  constructor(
    private quoteService: QuoteService,
    private toastrService: ToastrService,
    private activatedRoute: ActivatedRoute,
    private agreementService: AgreementService,
    public location: Location,
    private imageService: ImageService,
    private router: Router,
    private modalService: NgbModal,
    private userService: UserService,
  ) {
    this.userService.user.subscribe((user) => {
      if (user) {
        this.user = user;
      }
    });
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((param) => {
      if (param.clientId) {
        this.client_id = param.clientId;

        if (this.router.url.includes('edit-sales-agreement')) {
          this.salesAgreementId = param.salesAgreementId;

          this.isEditMode = true;

          this.fetchSalesAgreement();
        } else if (this.router.url.includes('view-sales-agreement')) {
          this.salesAgreementId = param.salesAgreementId;

          this.isViewMode = true;

          this.fetchSalesAgreement();
        } else {
          this.quote_id = param.quoteId;

          this.fetchQuote();
        }
      }
    })
  }

  fetchSalesAgreement() {
    this.loading = true;

    this.agreementService.readSalesAgreement(this.salesAgreementId)
      .toPromise()
      .then((res) => {
        this.salesAgreement = res;

        this.quote = this.salesAgreement.quote_id as IQuote;

        this.products = this.quote.items;

        this.client = this.salesAgreement.client_id;

        this.acceptTermsAndService.setValue(this.salesAgreement.agreement_accepted);

        this.terms.setValue(this.salesAgreement.agreement.terms);

        this.conditions.setValue(this.salesAgreement.agreement.conditions);

        this.acceptTermsAndService.disable();

        this.editorData = this.getMessageTemplate();

        this.getBase64FromUrl(this.salesAgreement.signature_url, (url: any) => this.base64SignatureUrl = url);

        if (this.isViewMode) {
          this.terms.disable();
          this.conditions.disable();
        }

        // this.updateSalesTotal();
      })
      .catch(() => this.toastrService.error('Failed to fetch quote details.'))
      .finally(() => this.loading = false);
  }

  fetchQuote() {
    this.loading = true;

    this.quoteService.readQuote(this.quote_id)
      .toPromise()
      .then((res) => {
        this.quote = res as IQuote;

        this.products = this.quote.items;

        this.client = this.quote.client_id;

        // this.updateSalesTotal();
      })
      .catch(() => this.toastrService.error('Failed to fetch quote details.'))
      .finally(() => this.loading = false);
  }

  // updateSalesTotal() {
  //   let total = 0;

  //   this.products.forEach((r: any) => total = total + parseFloat(r.amount));

  //   this.salesTotal = total;
  // }

  onAccept() {
    if (this.quote.status.toLocaleLowerCase() === 'approved') {
      this.toastrService.error('Sales agreement already generated for this quotation');
      return;
    }

    if (!this.terms.value) {
      this.toastrService.error('Terms cannot be empty.');
      return;
    }

    if (!this.conditions.value) {
      this.toastrService.error('Conditions cannot be empty.');
      return;
    }

    if (!this.acceptTermsAndService.value) {
      this.toastrService.error('Please accept the terms and conditions.');
      return;
    }

    if (!this.signUploadUrl) {
      this.toastrService.error('Please E-Sign the agreement');
      return;
    }

    const payload = {
      client_id: this.client_id,
      quote_id: this.quote_id,
      agreement: {
        terms: this.terms.value,
        conditions: this.conditions.value,
      },
      agreement_accepted: this.acceptTermsAndService.value,
      signature_url: this.signUploadUrl,
      status: 'Completed'
    };

    this.createSalesOrderInProgress = true;

    this.agreementService.createSalesAgreement(payload)
      .toPromise()
      .then(() => {
        this.router.navigate(['client-update', this.client._id], { queryParams: { tab: 'agreement' } });

        this.toastrService.success('Sales agreement created successfully.')
      })
      .catch(() => this.toastrService.error('Failed to create sales order.'))
      .finally(() => this.createSalesOrderInProgress = false)
  }

  onEditSign() {

  }

  onUploadSign() {
    const dataURL = this.signaturePad.toDataURL('image/png');

    const data = atob(dataURL.substring('data:image/png;base64,'.length)),
      asArray = new Uint8Array(data.length);

    for (var i = 0, len = data.length; i < len; ++i) {
      asArray[i] = data.charCodeAt(i);
    }

    this.signatureImg = new Blob([asArray], { type: 'image/png' });

    this.signUploadInProgress = true;

    this.imageService.uploadImage(this.signatureImg, `${this.quote_id}.png`)
      .toPromise()
      .then(() => {
        this.toastrService.success('Sign uploaded successfully.');
        this.signUploadUrl = `${environment.imageUploadUrl}${this.quote_id}.png`;
        this.signaturePad.off();
      })
      .catch(() => this.toastrService.error('Failed to upload sign.'))
      .finally(() => this.signUploadInProgress = false);
  }

  onClearSign() {
    this.signatureImg = null;
    this.signaturePad.clear();
  }

  updateAgreement(payload: any) {
    this.agreementService.updateSalesAgreement(this.salesAgreementId as string, payload)
      .toPromise()
      .then((res) => {
        this.salesAgreement.status = res.status;

        this.toastrService.success('Sales agreement updated successfully.')

        this.location.back();
      })
      .catch(() => this.toastrService.error('Failed to update agreement.'))
      .finally(() => {
        this.updateStatusInProgress = false;
        this.updateAgreementInProgress = false;
      });
  }

  onUpdate() {
    this.updateAgreementInProgress = true;

    this.updateAgreement({ agreement: { terms: this.terms.value, conditions: this.conditions.value } });
  }

  onCancelAgreement() {
    this.updateStatusInProgress = true;

    this.agreementService.updateSalesAgreement(this.salesAgreementId as string, { status: 'cancelled' })
      .toPromise()
      .then((res) => {
        this.salesAgreement.status = res.status;

        this.toastrService.success('Sales agreement cancelled successfully.')

        this.location.back();
      })
      .catch(() => this.toastrService.error('Failed to cancel agreement.'))
      .finally(() => {
        this.updateStatusInProgress = false;
        this.updateAgreementInProgress = false;
      });
  }

  onUpdateStatus() {
    this.updateStatusInProgress = true;

    this.updateAgreement({ status: 'completed' });
  }

  onCreatePurchaseOrder() {
    this.router.navigate(['create-purchase-order', this.client._id, this.salesAgreementId]);
  }

  onCreateInvoice() {
    this.router.navigate(['create-invoice', this.client._id, this.salesAgreementId]);
  }

  openEmailTemplateModal() {
    this.emailTemplateModalRef = this.modalService.open(
      this.emailTemplate, { size: 'lg', windowClass: 'create-transaction-modal' });
  }

  generateSalesAgreement() {
    this.sendToClientInProgress = true;

    html2canvas(this.pdfContent.nativeElement, {
      allowTaint: true,
      useCORS: true,
      onclone: (doc: Document, ele: HTMLElement) => {
        ele.classList.add('white-background');
      }
    })
      .then(canvas => {
        const imgWidth = 208;

        const imgHeight = canvas.height * imgWidth / canvas.width;

        const contentDataURL = canvas.toDataURL('image/png');

        let pdf: jsPDF = new jsPDF('p', 'mm', 'a4');

        pdf.addImage(contentDataURL, 'PNG', 0, 0, imgWidth, imgHeight);

        const blob = pdf.output('blob');

        const file = new File([blob], `agreement_${this.salesAgreement._id}.pdf`, { type: 'application/pdf' });

        return this.imageService.uploadPdf(file, `agreement_${this.salesAgreement._id}.pdf`).toPromise()
      })
      .then((res) => {
        const invoiceUploadUrl = `${environment.imageUploadUrl}agreement_${this.salesAgreement._id}.pdf`;

        const payload = {
          url: invoiceUploadUrl,
          email_html_body: this.getFullInvoiceTemplate().trim(),
          email_subject: this.subject.value
        }

        return this.agreementService.sendAgreementToClient(this.salesAgreement._id as string, payload).toPromise()
      })
      .then(() => this.agreementService.readSalesAgreement(this.salesAgreement._id as string).toPromise())
      .then((res) => this.salesAgreement = res)
      .then(() => {
        this.emailTemplateModalRef.dismiss();

        this.toastrService.success('Agreement generated and send to client successfully.');
      })
      .catch((err) => {
        this.toastrService.error('Failed to generate agreement and send to client.');
      })
      .finally(() => this.sendToClientInProgress = false);
  }

  getFullInvoiceTemplate(): string {
    return this.getHeadTemplate() + this.editorData + this.getFooterTemplate();
  }

  getMessageTemplate(): string {
    const salesAgreement = this.salesAgreement;
    return `<h2 style='font-weight: bold;font-size: 32px;line-height: normal;color: #000;margin-top: 0;margin-bottom: 30px;'>Hello ${salesAgreement.client_id.first_name} ${salesAgreement.client_id.last_name}</h2><p style='font-weight: normal; font-size: 15px; line-height: 20px; margin-bottom: 16px; color: #6d7296;'>You have a new Sales agreement for $${salesAgreement.total_amount}, created on ${moment(salesAgreement.created_at, 'YYYY-MM-DD HH:mm').format('MMM DD YYYY')}.</p><br/>`;
  }

  getHeadTemplate(): string {
    return `<!DOCTYPE html><html><head><meta charset='utf-8'><meta http-equiv='Content-Type' content='text/html charset=UTF-8' /><title>10xLighting</title><link href='https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap' rel='stylesheet'></head><body style='margin: 0;box-sizing: border-box;'><table style='width: 100%; max-width: 750px; margin: auto; font-family: DM Sans, sans-serif'><tr><td style='text-align: center;padding-top: 30px;padding-bottom: 30px;font-weight: bold;font-size: 44px;line-height: 54px;color: #000000;'> ${this.getUserNameOrEmail()} </td></tr><tr><td style='padding-left: 30px; padding-right: 30px;'><table style='width: 100%; max-width: 750px; margin: auto; background: #f9f9f9; border-radius: 28px;'><tr><td style='padding: 50px 50px'>`;
  }

  getUserNameOrEmail(): string {
    if (this.user.first_name) {
      return `${this.user.first_name} ${this.user.last_name}`;
    }

    return this.user.email;
  }
  
  getFooterTemplate(): string {
    let template = ``;

    // const invoice = this.invoice;

    // const check_out_url = `${environment.baseUrl}${API_PATH.invoiceCheckout}/${invoice._id}`;

    // if (invoice.status?.toLowerCase() !== this.invoiceStatus.PAID) {
    //   template = `<a href='${check_out_url}' style='padding: 13px 20px; transition: all ease-in-out 0.2s; border-radius: 10px; font-weight: 600; font-size: 16px; line-height: 22px; display: inline-flex; align-items: center; justify-content: center; border: none; color: #fff; background-color: #6e22d0; box-shadow: -14px 19px 30px rgba(118, 132, 255, 0.15%); text-decoration: none; width: 150px;'>Pay Now</a>`;
    // }

    template = template + `</td></tr></table></td></tr><tr><td style='font-weight: 500; font-size: 18px; line-height: normal; text-align: center; color: #000; padding-top: 20px; padding-bottom: 60px; padding-left: 30px; padding-right: 30px;'>If you have questions or trouble logging in, please do not reply to this email,<br/> instead please contact <span style='text-decoration: none;color: #6e22d0;'>${this.user.email}</span></td></tr></table></body></html>`;

    return template;
  }

  getBase64FromUrl(url: string, callback: any) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      }
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }

}
