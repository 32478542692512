<div class="content-page">
  <div class="page-title-group">
    <div class="page-title-group__left">
      <h2 class="title mb-0">Settings &nbsp; <i class="fas fa-spinner fa-spin" *ngIf="loading"></i></h2>
    </div>

    
    <div class="page-title-group__right d-flex">
      <button class="btn btn-primary ms-2" type="button" [routerLink]="['/setting']">Edit Profile</button>
    </div>
  </div>
  <div class="content-page__body" *ngIf="!loading">
    <div class="user-profile-card">
      <div class="user-profile-photo">
        <div class="cover-photos">
          <img [src]="selectedCoverImage || settings?.cover_image_url || 'assets/images/cover-image.png'"
            onerror="this.src='assets/images/cover-image.png';" alt="Cover Image" class="img-fluid">

          <button class="btn btn-primary btn-sm btn-save-cover-photo" [disabled]="imageUploadInProgress"
            *ngIf="selectedCoverImage" (click)="onSaveCoverImage()">Save Cover Photo &nbsp; <i
              class="fas fa-spinner fa-spin" *ngIf="imageUploadInProgress"></i></button>

          <button class="btn btn-primary btn-sm btn-edit-cover-photo" [disabled]="imageUploadInProgress"
            (click)="coverFile.click()">Edit Cover Photo</button>

          <input id="file-input" (change)="onCoverImageChange($event)" #coverFile style="display: none"
            accept="image/jpeg,image/jpg,image/png" type="file" />
        </div>
        <div class="profile-photo">
          <img [src]="selectedProfileImage || settings?.profile_image_url || 'assets/images/Avatar-1.png'"
            onerror="this.src='assets/images/Avatar-1.png';" alt="" class="img-fluid">
          <button _ngcontent-yaw-c77="" type="button" class="profile-photo__edit" [disabled]="imageUploadInProgress"
            (click)="avatarFile.click()">
            <svg _ngcontent-yaw-c77="" width="14" height="14" viewBox="0 0 14 14" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path _ngcontent-yaw-c77=""
                d="M8.53868 2.96514L1.3646 10.1397C1.3285 10.1759 1.30244 10.2217 1.29003 10.2708L0.494855 13.4625C0.471069 13.5585 0.499302 13.6607 0.569419 13.7308C0.622472 13.7839 0.694761 13.8132 0.768705 13.8132C0.791353 13.8132 0.814519 13.8105 0.837064 13.8048L4.02874 13.0095C4.07849 12.9971 4.12368 12.9711 4.15977 12.935L11.3345 5.76093L8.53868 2.96514Z"
                fill="white"></path>
              <path _ngcontent-yaw-c77=""
                d="M13.1118 1.98679L12.3132 1.1882C11.7795 0.654457 10.8492 0.654974 10.3161 1.1882L9.33789 2.16642L12.1336 4.96211L13.1118 3.98389C13.3784 3.71738 13.5253 3.36266 13.5253 2.98539C13.5253 2.60812 13.3784 2.2534 13.1118 1.98679Z"
                fill="white"></path>
            </svg>
          </button>
          <button _ngcontent-yaw-c77="" type="button" class="profile-photo__save" [disabled]="imageUploadInProgress"
            *ngIf="selectedProfileImage" (click)="onSaveProfileImage()">
            <svg *ngIf="!imageUploadInProgress" xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="#fff"
              viewBox="0 0 24 24">
              <path
                d="M 5 3 C 3.9057453 3 3 3.9057453 3 5 L 3 19 C 3 20.094255 3.9057453 21 5 21 L 19 21 C 20.094255 21 21 20.094255 21 19 L 21 7 L 17 3 L 5 3 z M 5 5 L 16.171875 5 L 19 7.828125 L 19 19 L 5 19 L 5 5 z M 7 7 L 7 10 L 15 10 L 15 7 L 7 7 z M 11.5 12 A 2.5 2.5 0 0 0 9 14.5 A 2.5 2.5 0 0 0 11.5 17 A 2.5 2.5 0 0 0 14 14.5 A 2.5 2.5 0 0 0 11.5 12 z">
              </path>
            </svg>

            <i class="fas fa-spinner fa-spin" *ngIf="imageUploadInProgress"></i>
          </button>
          <input id="file-input" (change)="onProfileImageChange($event)" #avatarFile style="display: none"
            accept="image/jpeg,image/jpg,image/png" type="file" />
        </div>
      </div>
      <div class="user-profile-detail">
        <div class="user-profile-group">
          <label class="label">Color Skins</label>
          <div class="color-skins__group">
            <div class="form-check">
              <input class="form-check-input" type="radio" name="color_skins" id="nevy-blue-color"
                [checked]="selectedColor === '#061D3D'" (click)="onColorChange('#061D3D')">
              <label class="form-check-label" for="nevy-blue-color"></label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="color_skins" id="green-color"
                [checked]="selectedColor === '#4CAF51'" (click)="onColorChange('#4CAF51')">
              <label class="form-check-label" for="green-color"></label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" name="color_skins" id="blue-color"
                [checked]="selectedColor === '#6E22D0'" (click)="onColorChange('#6E22D0')">
              <label class="form-check-label" for="blue-color"></label>
            </div>
          </div>
        </div>
        <!-- <div class="user-profile-group">
          <label class="label">Invoice Payment Methods</label>
          <div class="row">
            <div class="col col-12 col-sm-12 col-md-4 mb-4">
              <a class="card card-white card-profile" [routerLink]="'/invoice-template'">Invoice Template</a>
            </div>
            <div class="col col-12 col-sm-12 col-md-4 mb-4">
              <a class="card card-white card-profile" [routerLink]="'/setup-bank-account'">Setup Bank Account</a>
            </div>
            <div class="col col-12 col-sm-12 col-md-4 mb-4">
              <a class="card card-white card-profile">Setup VistaPrint</a>
            </div>
          </div>
        </div> -->
        <!-- <div class="user-profile-group">
          <label class="label">Customize</label>
          <div class="row">
            <div class="col col-12 col-sm-12 col-md-4 mb-4">
              <a class="card card-white card-profile">Invoice Template</a>
            </div>
            <div class="col col-12 col-sm-12 col-md-4 mb-4">
              <a class="card card-white card-profile" [routerLink]="'/quote-template'">Quote Template</a>
            </div>
            <div class="col col-12 col-sm-12 col-md-4 mb-4">
              <a class="card card-white card-profile" [routerLink]="'/agreement'">Agreement</a>
            </div>
            <div class="col col-12 col-sm-12 col-md-4 mb-4">
              <a class="card card-white card-profile">Invoice Template</a>
            </div>
            <div class="col col-12 col-sm-12 col-md-4 mb-4">
              <a class="card card-white card-profile" [routerLink]="'/client-statement'">Client Statement</a>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</div>