// Constant File

export const API_PATH = {
  planRead: '/plan',
  userStatusCheck: '/user/check',
  userCreate: '/user/create',
  payment: '/charge',
  video: '/video',
  readUser: '/user',
  updateUser: '/user/update',
  livedemo: '/demo',
  score: '/score',
  dashboard: '/dashboard',
  appointment: '/appointment',
  createAppointment: '/appointment/create',
  updateAppointment: '/appointment',
  createLead: '/lead/create',
  readLead: '/lead/',
  createClient: '/client/create',
  client: '/client',
  settings: '/settings',
  quote: '/quote',
  salesorder: '/salesagreement',
  product: '/product',
  invoice: '/invoice',
  clientPayment: '/client_payments',
  createVirtualDemo: '/demo/create',
  purchaseOrder: '/purchaseorder',
  bankAccount: '/externalaccount',
  setupaccount: '/setupaccount',
  vendor: '/vendor',
  connectaccount: '/connectaccount',
  transaction: '/transaction',
  inventory: '/inventory',
  coupon: '/coupon',
  applyCoupon: '/apply_coupon',
  service: '/service',
  servicerequest: '/servicerequest',
  invoiceCheckout: '/checkout',
  material: '/material',
}


export enum SALES_AGREEMENT_STATUS {
  IN_PROGRESS = 'inprogress',
  PURCHASE_ORDER_CREATED = 'purchaseordercreated',
  COMPLETED = 'completed',
  CANCELLED = 'cancelled',
}

export enum PURCHASE_ORDER_STATUS {
  IN_PROGRESS = 'inprogress',
  RECEIVED = 'received',
  CANCELLED = 'cancelled',
}

export enum INVOICE_STATUS {
  UNPAID = 'unpaid',
  PAID = 'paid',
  PARTIALLY_PAID = 'partially-paid',
  CANCELLED = 'cancelled',
}

export enum TRANSACTION_STATUS {
  PENDING = 'pending',
  COMPLETED = 'completed',
}
