import { UserService } from './../../../../services/user.service';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { StripeCardNumberElement } from '@stripe/stripe-js';
import { ToastrService } from 'ngx-toastr';
import { takeWhile } from 'rxjs/operators';
import { PaymentPlanService } from './../../../../services/payment-plan.service';
import { StripeService } from './../../../../services/stripe.service';
import { IPlan, IUser } from './../../../../utils/models';
@Component({
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit, OnDestroy {
  isCardValid: any;

  cardNumberError: string;

  cardExpiryError: string;

  cardNumberValid: any;

  cardExpiryValid: any;

  cardCvcValid: any;

  cardCvcError: any;

  plans: IPlan[] = [];

  loading: boolean;

  canActivate: boolean = true;

  cardNumberElement: StripeCardNumberElement;

  paymentInProgress: boolean = false;

  closeResult: string = '';

  @ViewChild('paymentModalClose') paymentModalClose: ElementRef;

  coupon: string;

  user: IUser;

  applyCouponInprogress: boolean = false;

  discountCost: number = 0;

  constructor(
    private router: Router,
    private paymentPlanService: PaymentPlanService,
    private stripeService: StripeService,
    private toastrService: ToastrService,
    private userService: UserService) {
    this.userService.user.subscribe((user) => {
      if (user) {
        this.user = user;
      }
    });
  }

  ngOnInit(): void {
    this.loading = true;

    this.paymentPlanService.readPlans()
      .pipe(takeWhile(() => this.canActivate))
      .subscribe(
        (res: IPlan[]) => {
          const response = res;

          response.forEach((r) => r.name === 'Half Yearly Plan' ? r.selected = true : r.selected = false);

          this.plans = response;

          this.loading = false;
        },
        (error) => {
          this.loading = false;

          this.toastrService.error('Failed to fetch plan details');
        }
      );

    this.invokeStripe();
  }

  async invokeStripe(): Promise<void> {
    await this.stripeService.init();

    this.initStripe();
  }

  onChangeSelection(plan: IPlan) {
    this.plans.map(c => c.selected = false);
    const item: any = this.plans.find(c => c._id === plan._id);
    item.selected = true;
  }

  initStripe() {
    const elements = this.stripeService.stripe?.elements({
      appearance: {
        theme: 'stripe',
      }
    });
    const cardNumber = elements?.create('cardNumber', {
      showIcon: true,
      placeholder: 'Enter valid card number',
      iconStyle: 'default',
      classes: {
        base: 'form-control'
      }
    });
    cardNumber?.mount('#cardNumber');
    cardNumber?.on('change', (event: any) => {
      this.cardNumberValid = event.complete;
      if (event.error) {
        this.cardNumberError = event.error.message;
      } else {
        this.cardNumberError = '';
      }
    });

    if (cardNumber) {
      this.cardNumberElement = cardNumber;
    }
    // card Expiry
    const cardExpiry = elements?.create('cardExpiry', {
      classes: {
        base: 'form-control'
      },
    });
    cardExpiry?.mount('#cardExpiry');
    cardExpiry?.on('change', (event: any) => {
      this.cardExpiryValid = event.complete;
      if (event.error) {
        this.cardExpiryError = event.error.message;
      } else {
        this.cardExpiryError = '';
      }
    });
    // card CVC
    const cardCvc = elements?.create('cardCvc', {
      classes: {
        base: 'form-control'
      },
      placeholder: 'CVV',
    });
    cardCvc?.mount('#cardCvc');
    cardCvc?.on('change', (event: any) => {
      this.cardCvcValid = event.complete;
      if (event.error) {
        this.cardCvcError = event.error.message;
      } else {
        this.cardCvcError = '';
      }
    });
  }

  async onSubmit() {
    if (this.stripeService.stripe) {
      this.paymentInProgress = true;

      const { token, error } = await this.stripeService.stripe.createToken(this.cardNumberElement);
      const selectedPlan = this.plans.find((p: IPlan) => p.selected);

      if (error || !token) {
        this.paymentInProgress = false;

        this.toastrService.error('Failed to initiate payment. Try after sometime');
      }

      if (token && token.id && selectedPlan) {
        const payload: { stripe_token: string, coupon?: string, plan_id: string } = {
          stripe_token: token?.id,
          plan_id: selectedPlan?._id
        };

        if (this.coupon) {
          payload.coupon = this.coupon;
        }

        this.paymentPlanService
          .makePayment(payload)
          .toPromise()
          .then((res) => this.userService.readUser().toPromise())
          .then((res) => {
            this.paymentModalClose.nativeElement.click();

            this.toastrService.success(`${selectedPlan.name} enrolled successfully.`);

            this.router.navigate(['/training']);
          })
          .catch((error) => this.toastrService.error('Failed to process your request. Make sure you have entered valid card details.'))
          .then(() => this.paymentInProgress = false);
      }
    }
  }

  ngOnDestroy(): void {
    this.canActivate = false;
  }

  onApplyCoupon() {
    this.applyCouponInprogress = true;

    const plan = this.plans.find((p) => p.selected) as IPlan;

    this.paymentPlanService.applyCoupon(this.coupon, plan?._id as string)
      .toPromise()
      .then((res) => {
        plan.cost = res.discount_cost;
        this.toastrService.success('Coupon applied successfully.');
      })
      .catch((error) => {
        this.toastrService.error(error.error.message || 'Invalid Coupon.')
      })
      .finally(() => this.applyCouponInprogress = false);

  }
}
