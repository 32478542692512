import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { merge, Observable, OperatorFunction, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { IPagination } from '../../../../utils/models';
import { PurchaseOrderService } from './../../../../services/purchase-order-service';
import { IVendor, IVendorList } from './../../../../utils/models';

@Component({
  selector: 'app-vendor-type-ahead-control',
  templateUrl: './vendor-type-ahead-control.component.html',
  styleUrls: ['./vendor-type-ahead-control.component.scss']
})
export class VendorTypeAheadControlComponent implements OnInit {
  placeHolder: string = 'Search Vendors';

  data: IVendor[] = [];

  @Output()
  selectoption: EventEmitter<IVendor | null> = new EventEmitter<IVendor | null>();

  model: FormControl = new FormControl();

  loading: boolean = false;

  pagination: IPagination = {
    page: 0,
    limit: 5,
    total_records: 0,
  };

  selectedOption: string;

  focus$ = new Subject<string>();

  click$ = new Subject<string>();

  @ViewChild('instance', { static: true }) instance: NgbTypeahead;

  @Input()
  vendor: IVendor;

  initalLoad: boolean = true;

  emittedValue: IVendor;

  constructor(
    private toastrService: ToastrService,
    private purchaseOrderService: PurchaseOrderService) {

    this.model.valueChanges.pipe(debounceTime(400)).subscribe((value) => {
      if (value && typeof value === 'object') {
        this.selectoption.emit(value);

        this.emittedValue = value;
      }

      if (value && typeof value === 'string') {
        this.fetchVendors(value);
      }

      if (!value || value === '') {
        this.fetchVendors();
      }
    });

    this.purchaseOrderService.clearselection.subscribe(() => this.model.setValue(null));
  }

  ngOnInit() {
    this.fetchVendors();

    this.click$.subscribe(() => !this.instance.isPopupOpen());
  }

  fetchVendors(search?: string): void {
    this.loading = true;

    this.purchaseOrderService.getVendors(this.pagination, search)
      .toPromise()
      .then((res: IVendorList) => {
        this.data = res.vendors;

        if (this.vendor && this.initalLoad) {
          this.model.setValue(this.vendor);

          this.emittedValue = this.vendor;

          this.initalLoad = false;
        }

        if (search) {
          this.focus$.next(search);
        }
      })
      .catch(() => this.toastrService.error('Failed to fetch products information.'))
      .finally(() => this.loading = false)
  }

  formatter = (x: any) => x.name.toUpperCase();

  search: OperatorFunction<string, readonly IVendor[]> = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => (term === '' ? this.data
        : this.data.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 10))
    );
  }
}
