import { ImageService } from './../../../../services/image.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { SettingsService } from './../../../../services/settings.service';

@Component({
  selector: 'app-setup-bank-account',
  templateUrl: './setup-bank-account.component.html',
  styleUrls: ['./setup-bank-account.component.scss']
})
export class SetupBankAccountComponent implements OnInit {

  accountForm: FormGroup;

  loading: boolean = false;

  fileUploadInProgress = false;

  file: any;

  bank_verification_file_id: string;

  isUpdateFlow: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private settingsService: SettingsService,
    private imageService: ImageService) {
    this.accountForm = this.formBuilder.group({
      secret_key: new FormControl(null, Validators.required),
      public_key: new FormControl(null, Validators.required),
    });
  }

  ngOnInit(): void {
    ''
  }

  onSave() {
    const data = this.accountForm.getRawValue();

    this.loading = true;

    this.settingsService.saveBankAccount(data)
      .toPromise()
      .then((res) => this.toastrService.success('Bank account saved successfully.'))
      .catch(() => this.toastrService.error('Failed to save bank account details.'))
      .finally(() => this.loading = false);
  }

  onFileChange(event: any) {
    this.fileUploadInProgress = true;

    const file = event.target.files[0];

    const formData = new FormData();

    formData.append('file', file);

    formData.append('purpose', 'account_requirement');

    this.imageService.uploadBankDoc(formData)
      .toPromise()
      .then((res) => {
        this.bank_verification_file_id = res.id;

        this.toastrService.success('Document uploaded successfully.');
      })
      .catch(() => this.toastrService.error('Document upload failed.'))
      .finally(() => this.fileUploadInProgress = false);
  }
}
