import { Location } from '@angular/common';
import { Component, Input, OnInit, TemplateRef, ViewChild, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { debounceTime, takeWhile } from 'rxjs/operators';
import { ClientManagementService } from './../../../../services/client-management.service';
import { IClient, IClientList, IPagination } from './../../../../utils/models';

@Component({
  selector: 'app-client-managment',
  templateUrl: './client-managment.component.html',
  styleUrls: ['./client-managment.component.scss']
})
export class ClientManagmentComponent implements OnInit, OnDestroy {
  loading: boolean = false;

  rows: IClient[] = [];

  @ViewChild('addressTemplate', { static: true })
  addressTemplate: TemplateRef<any>;

  @ViewChild('actionsTemplate', { static: true })
  actionsTemplate: TemplateRef<any>;

  @ViewChild('createQuoteTemplate', { static: true })
  createQuoteTemplate: TemplateRef<any>;

  @ViewChild('createClientTemplate', { static: true })
  createClientTemplate: TemplateRef<any>;

  columns: any[] = [];

  total: number = 0;

  pagination: IPagination = {
    page: 0,
    limit: 10,
    total_records: 0,
  };

  clientForm: FormGroup;

  createClientModalRef: NgbModalRef;

  createClientInprogress: boolean = false;

  @Input()
  isTabContent: boolean = false;

  searchControl: FormControl = new FormControl(null);

  isCreateClientFlow: boolean = false;
  componentActive: boolean = true;
  prefill: boolean;

  constructor(
    private router: Router,
    private toastrService: ToastrService,
    private clientManagementService: ClientManagementService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private location: Location,
    private activatedRoute: ActivatedRoute
  ) { }
  ngOnDestroy(): void {
    this.componentActive = false;
  }

  ngOnInit(): void {
    this.fetch(this.pagination);
    this.activatedRoute.params
      .pipe(takeWhile(() => this.componentActive))
      .subscribe((param: any) => {
        if (!_.isEmpty(param)) {
          if (param.prefill === 'true') {
            this.prefill = true;
          }
        }
      });
    this.columns = [
      { name: 'First Name', prop: 'first_name' },
      { name: 'Last Name', prop: 'last_name' },
      { name: 'Email', prop: 'email', sortable: false },
      { name: 'Phone Number', prop: 'phone_number', sortable: false },
      { name: 'Address', prop: 'address', sortable: false, cellTemplate: this.addressTemplate, width: 180 },
      { name: 'View Client', cellTemplate: this.actionsTemplate, sortable: false },
      { name: 'Create Quote', cellTemplate: this.createQuoteTemplate, sortable: false }
    ];

    if (this.router.url.includes('create-client')) {
      this.isCreateClientFlow = true;
      this.onCreateClient();
    }

    this.searchControl.valueChanges
      .pipe(debounceTime(500))
      .subscribe(() => {
        this.pagination = {
          page: 0,
          limit: 10,
          total_records: 0,
        };

        this.fetch(this.pagination);
      });
  }
  createInstalltion() {
    this.router.navigate(['calender'], { queryParams: { type: 'Installation' } });
  }
  fetch(pagination: IPagination) {
    this.loading = true;

    const searchText = this.searchControl.value || null;

    this.clientManagementService.readAllClient(pagination, searchText)
      .toPromise()
      .then((res: IClientList) => {
        this.rows = res.clients;

        this.pagination.total_records = res.total_records;
      })
      .catch(() => this.toastrService.error('Failed to fetch client list.'))
      .finally(() => this.loading = false);
  }

  setPage(pagination: any) {
    this.pagination.page = pagination.offset;

    this.fetch(this.pagination);
  }

  onUpdateClient(record: IClient) {
    this.router.navigate(['client-update', record._id], { queryParams: { tab: 'client' } });
  }

  onCreateClient() {
    this.clientForm = this.formBuilder.group({
      first_name: new FormControl(null, [Validators.required]),
      last_name: new FormControl(null, [Validators.required]),
      email: new FormControl(null, [Validators.required, Validators.email]),
      line: new FormControl(this.prefill ? 'Street 1' : null, [Validators.required]),
      city: new FormControl(this.prefill ? 'San Jose' : null, [Validators.required]),
      state: new FormControl(this.prefill ? 'CA' : null, [Validators.required]),
      postal: new FormControl(this.prefill ? '515062' : null,
        [Validators.required, Validators.pattern('[0-9]*'), Validators.minLength(2), Validators.maxLength(8)]),
      country: new FormControl(this.prefill ? 'USA' : null, [Validators.required]),
      // phone_number: new FormControl(null, [Validators.required, Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')]),
      phone_number: [this.prefill ? '(123) 456-7890' : '', [Validators.pattern(/^\(\d{3}\)\s\d{3}-\d{4}$/), Validators.required]]
    });

    this.createClientModalRef = this.modalService.open(this.createClientTemplate,
      { size: 'xl', windowClass: 'create-client-modal', backdrop: 'static' });
  }

  closeModal() {
    if (this.isCreateClientFlow) {
      this.location.back();
    }
    this.createClientModalRef.dismiss();
  }


  onCreateQuote(record: IClient) {
    this.router.navigate(['create-quote', record._id]);
  }

  createClient(): void {
    this.createClientInprogress = true;

    const formData = this.clientForm.getRawValue();

    const payload: IClient = {
      first_name: formData.first_name,
      last_name: formData.last_name,
      email: formData.email,
      address: {
        line: formData.line,
        postal: formData.postal,
        city: formData.city,
        state: formData.state,
        country: formData.country,
      },
      phone_number: formData.phone_number,
    }

    this.clientManagementService.createClient(payload)
      .toPromise()
      .then(() => {
        this.createClientModalRef.dismiss();

        this.pagination = {
          page: 0,
          limit: 10,
          total_records: 0,
        };

        this.fetch(this.pagination);

        if (this.isCreateClientFlow) {
          this.location.back();
        }

        this.toastrService.success('Client created successfully.');
      })
      .catch((err) => this.toastrService.error(err?.error?.message || 'Failed to create client.'))
      .finally(() => this.createClientInprogress = false);
  }
}
