<!-- == Start Page Content here == -->
<div [ngClass]="{ 'content-page': !isTabContent }">
  <div class="page-title-group">
    <div class="page-title-group__left" *ngIf="!isTabContent">
      <h2 class="title">Marketing Materials &nbsp; <i class="fas fa-spinner fa-spin ms-2" *ngIf="loading"></i></h2>
      <!-- <p class="description">Lorem Ipsum is not simply random text. It has roots in a piece of classical<br />
            Latin
            literature from 45 BC, making it over 2000 years old.</p> -->
    </div>
    <div class="page-title-group__right d-flex">
      <div class="search-bar mr-2">
        <span class="searchbar-icon">
          <span class="light-icon light-icon-search"></span>
        </span>
        <input type="search" name="search" class="search-control form-control form-control-white" placeholder="Search"
          [formControl]="searchControl" />
        <span class="searchbar-close-icon d-lg-none cursor-pointer">
          <span class="light-icon light-icon-close cursor-pointer"></span>
        </span>
      </div>
    </div>
  </div>
  <div class="content-page__body">
    <div class="tab-content" id="generate-invoice_tabContent">
      <div class="tab-pane fade show active" id="generate-invoice" role="tabpanel"
        aria-labelledby="generate-invoice-tab">
        <div class="generate-invoice__content">
          <div *ngIf="loading && isTabContent"><i class="fas fa-spinner fa-spin"></i></div>
          <div class="mt-4" *ngIf="rows?.length || !loading">
            <ngx-datatable [rows]="rows" [columns]="columns" rowHeight="auto" [headerHeight]="44" [footerHeight]="50"
              [columnMode]="'force'" [loadingIndicator]="loading" [externalPaging]="true"
              [count]="pagination.total_records" [limit]="pagination.limit" [offset]="pagination.page"
              (page)="setPage($event)" [scrollbarH]="true"></ngx-datatable>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- == End Page content == -->

<!-- NGX TABLE CELL TEMPLATES START -->
<ng-template #actionsTemplate let-row="row" let-value="value">
  <div class="table-actions">
    <button type="button" class="btn btn-icon btn-off-primary btn-sm"  (click)="downloadFile(row)" container="body"
      ngbTooltip="Download">
      <i class="fa fa-download"></i>
    </button>
  </div>
</ng-template>

<ng-template #dateTemplate let-row="row" let-value="value">
  <span>{{ value ? (value | date: 'dd MMM YYYY': 'UTC') : 'NA' }}</span>
</ng-template>

<ng-template #linkTemplate let-row="row" let-value="value">
  <a [href]="value" target="_blank">{{ value }}</a>
</ng-template>
<!-- NGX TABLE CELL TEMPLATES END -->
