import { ResourcesService } from './../../../../services/resources.service';
import { IServices, IServicesList } from './../../../../utils/models';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable, OperatorFunction, Subject, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { QuoteService } from 'src/app/services/quote-service';
import { IPagination, IProduct, IProductList } from '../../../../utils/models';

@Component({
  selector: 'app-services-type-ahead-control',
  templateUrl: './services-type-ahead-control.component.html',
  styleUrls: ['./services-type-ahead-control.component.scss']
})
export class ServicesTypeAheadControlComponent implements OnInit {
  placeHolder: string = 'Search';

  data: IServices[] = [];

  @Output()
  selectoption: EventEmitter<IServices | null> = new EventEmitter<IServices | null>();

  model: FormControl = new FormControl();

  loading: boolean = false;

  pagination: IPagination = {
    page: 0,
    limit: 5,
    total_records: 0,
  };

  selectedOption: string;

  focus$ = new Subject<string>();

  click$ = new Subject<string>();

  @ViewChild('instance', { static: true }) instance: NgbTypeahead;

  constructor(
    private toastrService: ToastrService,
    private resourcesService: ResourcesService) {

    this.model.valueChanges.pipe(debounceTime(400)).subscribe((value) => {
      if (value && typeof value === 'object') {
        this.selectoption.emit(value);
      }

      if (value && typeof value === 'string') {
        this.fetchProducts(value);
      }

      if (!value || value === '') {
        this.fetchProducts(value);
      }
    });

    this.resourcesService.clearproduct.subscribe(() => this.model.setValue(null));
  }

  ngOnInit() {
    this.fetchProducts();

    this.click$.subscribe(() => !this.instance.isPopupOpen());
  }

  fetchProducts(search?: string): void {
    this.loading = true;

    this.resourcesService.getAllService(this.pagination, search)
      .toPromise()
      .then((res: IServicesList) => {
        this.data = res.services;

        if (search) {
          this.focus$.next(search);
        }
      })
      .catch(() => this.toastrService.error('Failed to fetch products information.'))
      .finally(() => this.loading = false)
  }

  formatter = (x: any) => x.name.toUpperCase();

  search: OperatorFunction<string, readonly IServices[]> = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => (term === '' ? this.data
        : this.data.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 10))
    );
  }
}
