import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ImageService {
  baseUrl: string = environment.imageUploadUrl;

  constructor(private httpClient: HttpClient) {}

  uploadImage(image: any, imageName: string): Observable<any> {
    let url = `${this.baseUrl}${imageName}`;
    return this.httpClient.put<any>(url, image, {
      headers: new HttpHeaders({
        'Content-Type': 'image/png'
      })
    });
  }

  uploadPdf(image: any, imageName: string): Observable<any> {
    let url = `${this.baseUrl}${imageName}`;

    return this.httpClient.put<any>(url, image, {
      headers: new HttpHeaders({
        'Content-Type': 'application/pdf'
      })
    });
  }

  uploadBankDoc(formData: any): Observable<any> {
    let url = `${environment.bankDocUploadUrl}`;

    return this.httpClient.post<any>(url, formData, {
      headers: new HttpHeaders({
        'Content-Type':
          'multipart/form-data; boundary=<calculated when request is sent>',
        Accept: '*/*',
        Authorization: `Bearer ${environment.stripePublicKey}`
      })
    });
  }
}
