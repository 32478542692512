import { UserService } from './../../../../services/user.service';
import { environment } from './../../../../../environments/environment';
import { ImageService } from './../../../../services/image.service';
import { Location } from '@angular/common';
import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { QuoteService } from 'src/app/services/quote-service';
import { ClientManagementService } from './../../../../services/client-management.service';
import { IClient, IProduct, IQuote, IUser } from './../../../../utils/models';

@Component({
  selector: 'app-quote-template',
  templateUrl: './quote-template.component.html',
  styleUrls: ['./quote-template.component.scss'],
})
export class QuoteTemplateComponent implements OnInit {
  loading: boolean = false;

  client: IClient;

  quote: IQuote;

  routeParam: any;

  isCreateQuoteFlow: boolean = false;

  isEditQuoteFlow: boolean = false;

  isViewQuoteFlow: boolean = false;

  quoteDate: any = new Date();

  valid_till: FormControl = new FormControl(null);

  client_requirements: FormControl = new FormControl(null);

  description: FormControl = new FormControl(null);

  rows: IProduct[] = [];

  createQuoteInprogress: boolean = false;

  previewInProgress: boolean = false;

  productForm: FormGroup;

  todayDate: string;

  totalProductCost: number;

  totalProductCostAfterDiscount: number;

  defaultDiscountPercent: number = 0;

  defaultSalesTaxPercent: number = 0;

  profitWantToMake: FormControl = new FormControl(0);

  hourlyRate: FormControl = new FormControl();

  hours: FormControl = new FormControl();

  discountAmount: FormControl = new FormControl(0);

  labor: number = 0;

  clientPrice: number = 0;

  newClientPrice: number = 0;

  salesTaxPercentControl: FormControl = new FormControl(this.defaultSalesTaxPercent);

  salesTax: number = 0;

  customerPrice: number = 0;

  createQuoteWithoutClientId: boolean = false;

  emailTemplateModalRef: NgbModalRef;

  Editor: any = ClassicEditor;

  editorData: string;

  config = {};

  @ViewChild('emailTemplate', { static: true })
  emailTemplate: TemplateRef<any>;

  subject: FormControl = new FormControl(null, Validators.required);

  sendToClientInProgress: boolean = false;

  @ViewChild('pdfContent', { static: false }) pdfContent: ElementRef;

  user: IUser;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private clientManagementService: ClientManagementService,
    private quoteService: QuoteService,
    private toastrService: ToastrService,
    private location: Location,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private imageService: ImageService,
    private userService: UserService,
  ) {
    this.productForm = this.formBuilder.group({
      item_id: new FormControl({ value: null, disabled: true }, Validators.required),
      name: new FormControl({ value: null, disabled: true }, Validators.required),
      quantity: new FormControl({ value: null, disabled: true }, Validators.required),
      description: new FormControl({ value: null, disabled: true }, Validators.required),
      price: new FormControl({ value: null, disabled: true }, Validators.required),
      amount: new FormControl({ value: null, disabled: true }, Validators.required),
    });

    this.productForm.controls.quantity.valueChanges.subscribe((val) => {
      this.productForm.controls.amount.setValue((val * this.productForm.controls.price.value).toFixed(2));
    });

    this.userService.user.subscribe((user) => {
      if (user) {
        this.user = user;
      }
    })
  }

  ngOnInit(): void {
    this.todayDate = moment().format('YYYY-MM-DD');

    this.activatedRoute.params.subscribe((param) => {
      this.routeParam = param;

      if (this.router.url.includes('create-quote')) {
        this.isCreateQuoteFlow = true;

        if (this.routeParam.clientId) {
          this.fetchClient();
        } else {
          this.createQuoteWithoutClientId = true;
        }

        this.salesTaxPercentControl.setValue(this.user.salesTax || this.defaultSalesTaxPercent, { onlySelf: true, emitEvent: false });
        this.defaultDiscountPercent = this.user.discount || this.defaultDiscountPercent;
      }

      if (this.router.url.includes('view-quote')) {
        this.isViewQuoteFlow = true;

        this.fetchQuote();
      }

      if (this.router.url.includes('update-quote')) {
        this.isEditQuoteFlow = true;

        this.fetchQuote();
      }
    });

    this.hourlyRate.valueChanges.subscribe(() => this.setLabour());

    this.hours.valueChanges.subscribe(() => this.setLabour());

    this.profitWantToMake.valueChanges.subscribe(() => this.setClientPrice());

    this.discountAmount.valueChanges.subscribe(() => this.setClientPrice());

    this.salesTaxPercentControl.valueChanges.subscribe(() => this.setClientPrice());
  }

  fetchClient() {
    const clientId = this.routeParam.clientId;

    this.loading = true;

    this.clientManagementService.readClient(clientId)
      .toPromise()
      .then((res) => this.client = res)
      .catch(() => this.toastrService.error('Failed to fetch client detsils.'))
      .finally(() => this.loading = false);
  }

  fetchQuote() {
    const quoteId = this.routeParam.quoteId;

    this.loading = true;

    this.quoteService.readQuote(quoteId)
      .toPromise()
      .then((res) => {
        this.quote = res;

        this.client = this.quote.client_id;

        this.salesTaxPercentControl.setValue(this.quote.salesTaxPercent || this.user.salesTax || this.defaultSalesTaxPercent, { onlySelf: true, emitEvent: false });

        this.defaultDiscountPercent = this.quote.discountPercent || this.user.discount || this.defaultDiscountPercent;

        this.client_requirements.setValue(this.quote.client_requirements);

        this.description.setValue(this.quote.description);

        this.rows = this.quote.items;

        this.hourlyRate.setValue(this.quote.hourlyRate || 0);

        this.hours.setValue(this.quote.hours || 0);

        this.profitWantToMake.setValue(this.quote.profitWantToMake || 0);

        this.discountAmount.setValue(this.quote.discountAmount || 0);

        if (this.isViewQuoteFlow) {
          this.discountAmount.disable();

          this.profitWantToMake.disable();

          this.hourlyRate.disable();

          this.hours.disable();
        }

        // this.setTotalProductCost();

        this.totalProductCost = this.quote.totalProductCost;

        this.totalProductCostAfterDiscount = this.quote.totalProductCostAfterDiscount;

        this.clientPrice = this.quote.clientPrice;

        this.newClientPrice = this.quote.newClientPrice;

        this.salesTax = this.quote.salesTax;

        this.customerPrice = this.quote.customerPrice;

        this.valid_till.setValue(moment(this.quote.valid_till).utc().format('YYYY-MM-DD'));

        this.editorData = this.getMessageTemplate();
      })
      .catch(() => this.toastrService.error('Failed to fetch client detsils.'))
      .finally(() => this.loading = false);
  }

  onSelectOption(product: any): void {
    let data = product;

    data = Object.assign({}, { quantity: 1, amount: product.price }, product);

    this.productForm.controls.quantity.enable();

    this.productForm.patchValue(data);

    this.onAdd();
  }

  onSelectClient(client: any): void {
    this.client = client;
  }

  onAdd() {
    const formData = this.productForm.getRawValue();

    const product = this.rows.find((p) => p.item_id === formData.item_id);

    const data: IProduct = {
      user_id: formData.user_id,
      name: formData.name,
      description: formData.description,
      price: formData.price,
      item_id: formData.item_id,
      quantity: formData.quantity,
      tax: 0,
      amount: formData.amount
    }

    this.rows.push(data);

    // Below logic is to update the quantity of existing product. May be future if client ask we can enable.
    // if (product) {
    //   product.quantity = product.quantity + formData.quantity;

    //   product.amount = parseFloat((product.quantity as number * product.price).toFixed(2));
    // } else {
    //   const data: IProduct = {
    //     user_id: formData.user_id,
    //     name: formData.name,
    //     description: formData.description,
    //     price: formData.price,
    //     item_id: formData.item_id,
    //     quantity: formData.quantity,
    //     tax: 0,
    //     amount: formData.amount
    //   }

    //   this.rows.push(data);
    // }

    this.productForm.reset();

    this.productForm.controls.quantity.disable();

    this.quoteService.clearproduct.next();

    setTimeout(() => this.setTotalProductCost());
  }

  setLabour() {
    const hourlyRate = this.hourlyRate.value || 0;

    const hours = this.hours.value || 0;

    this.labor = 0;

    if (hourlyRate && hours) {
      this.labor = parseFloat(hourlyRate) * parseFloat(hours);
    }

    this.setClientPrice();
  }

  setTotalProductCost() {
    let totalProductCost: number = 0;

    this.totalProductCost = 0;

    this.rows.forEach((r) => totalProductCost = totalProductCost + parseFloat(r.amount));

    this.totalProductCost = parseFloat(totalProductCost.toFixed(2));

    this.totalProductCostAfterDiscount = parseFloat((this.totalProductCost - (this.totalProductCost * this.defaultDiscountPercent) / 100).toFixed(2));

    this.setClientPrice();
  }

  setClientPrice() {
    const salesTaxPercent = this.salesTaxPercentControl.value || 0;

    this.clientPrice = this.totalProductCostAfterDiscount + parseFloat(this.profitWantToMake.value || 0) + this.labor;

    this.newClientPrice = this.clientPrice - (parseFloat(this.discountAmount.value || 0));

    this.salesTax = parseFloat(((this.newClientPrice * salesTaxPercent) / 100).toFixed(2));

    this.customerPrice = parseFloat((this.newClientPrice + this.salesTax).toFixed(2));
  }

  onDeleteProduct(product: IProduct, index: number) {
    // this.rows = this.rows.filter((r, i) => r.item_id !== product.item_id);

    this.rows = this.rows.filter((r, i) => i != index);

    setTimeout(() => this.setTotalProductCost());
  }

  onCancel() {
    this.location.back();
  }

  onSave(isPreview?: boolean) {
    if (!this.valid_till.value) {
      this.toastrService.error('Please select quote valid till date.');
      return;
    }

    if (!this.client_requirements.value) {
      this.toastrService.error('Please provide client requirements information.');
      return;
    }

    if (!this.description.value) {
      this.toastrService.error('Please provide description.');
      return;
    }

    if (!this.rows.length) {
      this.toastrService.error('Atleast one product is required to create.');
      return;
    }

    if (!this.salesTaxPercentControl.value || this.salesTaxPercentControl.value <= 0) {
      this.toastrService.error('Sales tax should be greater than 0');
      return;
    }

    this.createQuoteInprogress = !isPreview;

    this.previewInProgress = isPreview ? true : false;

    const operation = this.isCreateQuoteFlow ? 'created' : 'updated';

    const totalProductCost: number = this.totalProductCost;

    const totalProductCostAfterDiscount: number = this.totalProductCostAfterDiscount;

    const profitWantToMake = this.profitWantToMake.value || 0;

    const hourlyRate = this.hourlyRate.value || 0;

    const hours = this.hours.value || 0;

    const labor = this.labor;

    const clientPrice = this.clientPrice

    const discountAmount = this.discountAmount.value || 0;

    const newClientPrice = this.newClientPrice

    const salesTax = this.salesTax;

    const customerPrice = this.customerPrice;

    const payload = {
      items: this.rows,
      client_requirements: this.client_requirements.value,
      description: this.description.value,
      valid_till: this.valid_till.value,
      totalProductCost,
      totalProductCostAfterDiscount,
      profitWantToMake,
      hourlyRate,
      hours,
      labor,
      clientPrice,
      discountAmount,
      newClientPrice,
      salesTax,
      customerPrice,
      discountPercent: this.defaultDiscountPercent,
      salesTaxPercent: this.salesTaxPercentControl.value || 0,
    } as IQuote;

    if (this.isCreateQuoteFlow) {
      payload.client_id = this.client._id;
    }

    this.quoteService.saveQuote(payload, this.quote?._id)
      .toPromise()
      .then((res: any) => {
        if (isPreview) {
          this.quote = Object.assign({}, res, this.quote);

          this.editorData = this.getMessageTemplate();

          this.isViewQuoteFlow = true;
        } else {
          this.router.navigate(['client-update', this.client._id], { queryParams: { tab: 'quote' } });

          this.toastrService.success(`Quote ${operation} successfully`)
        }
      })
      .catch(() => this.toastrService.error('Failed to save quote details, Try again'))
      .finally(() => {
        this.createQuoteInprogress = false;

        this.previewInProgress = false;
      });
  }

  onGenerateSalesAgreement() {
    const clientId = typeof this.quote.client_id === 'string' ? this.quote.client_id : this.quote.client_id.id;
    this.router.navigate(['create-sales-agreement', clientId, this.quote._id]);
  }

  onEditSalesAgreement() {
    this.router.navigate(['edit-sales-agreement', this.quote.sales_agreement.client_id, this.quote.sales_agreement.id])
  }

  onPreviewQuote() {
    this.onSave(true);
  }

  openEmailTemplateModal() {
    this.emailTemplateModalRef = this.modalService.open(
      this.emailTemplate, { size: 'lg', windowClass: 'create-transaction-modal' });
  }

  generateQuote() {
    this.sendToClientInProgress = true;

    html2canvas(this.pdfContent.nativeElement, {
      allowTaint: true,
      useCORS: true,
      onclone: (doc: Document, ele: HTMLElement) => {
        ele.classList.add('white-background');
      }
    })
      .then(canvas => {
        const imgWidth = 208;

        const imgHeight = canvas.height * imgWidth / canvas.width;

        const contentDataURL = canvas.toDataURL('image/png');

        let pdf: jsPDF = new jsPDF('p', 'mm', 'a4');

        pdf.addImage(contentDataURL, 'PNG', 0, 0, imgWidth, imgHeight);

        const blob = pdf.output('blob');

        const file = new File([blob], `quote_${this.quote._id}.pdf`, { type: 'application/pdf' });

        return this.imageService.uploadPdf(file, `quote_${this.quote._id}.pdf`).toPromise()
      })
      .then((res) => {
        const invoiceUploadUrl = `${environment.imageUploadUrl}quote_${this.quote._id}.pdf`;

        const payload = {
          url: invoiceUploadUrl,
          email_html_body: this.getFullInvoiceTemplate().trim(),
          email_subject: this.subject.value
        }

        return this.quoteService.sendQuoteToClient(this.quote._id as string, payload).toPromise()
      })
      .then(() => this.quoteService.readQuote(this.quote._id as string).toPromise())
      .then((res) => this.quote = res)
      .then(() => {
        this.emailTemplateModalRef.dismiss();

        this.toastrService.success('Quote generated and send to client successfully.');
      })
      .catch((err) => {
        this.toastrService.error('Failed to generate quote and send to client.');
      })
      .finally(() => this.sendToClientInProgress = false);
  }

  getFullInvoiceTemplate(): string {
    return this.getHeadTemplate() + this.editorData + this.getFooterTemplate();
  }

  getMessageTemplate(): string {
    const quote = this.quote;
    return `<h2 style='font-weight: bold;font-size: 32px;line-height: normal;color: #000;margin-top: 0;margin-bottom: 30px;'>Hello ${quote.client_id.first_name} ${quote.client_id.last_name}</h2><p style='font-weight: normal; font-size: 15px; line-height: 20px; margin-bottom: 16px; color: #6d7296;'>You have a new Quote #${quote._id} for $${quote.customerPrice}, created on ${moment(quote.created_at, 'YYYY-MM-DD HH:mm').format('MMM DD YYYY')}.</p><br/>`;
  }

  getHeadTemplate(): string {
    return `<!DOCTYPE html><html><head><meta charset='utf-8'><meta http-equiv='Content-Type' content='text/html charset=UTF-8' /><title>10xLighting</title><link href='https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap' rel='stylesheet'></head><body style='margin: 0;box-sizing: border-box;'><table style='width: 100%; max-width: 750px; margin: auto; font-family: DM Sans, sans-serif'><tr><td style='text-align: center;padding-top: 30px;padding-bottom: 30px;font-weight: bold;font-size: 44px;line-height: 54px;color: #000000;'> ${this.getUserNameOrEmail()} </td></tr><tr><td style='padding-left: 30px; padding-right: 30px;'><table style='width: 100%; max-width: 750px; margin: auto; background: #f9f9f9; border-radius: 28px;'><tr><td style='padding: 50px 50px'>`;
  }

  getUserNameOrEmail(): string {
    if (this.user.first_name) {
      return `${this.user.first_name} ${this.user.last_name}`;
    }

    return this.user.email;
  }
  
  getFooterTemplate(): string {
    let template = ``;

    template = template + `</td></tr></table></td></tr><tr><td style='font-weight: 500; font-size: 18px; line-height: normal; text-align: center; color: #000; padding-top: 20px; padding-bottom: 60px; padding-left: 30px; padding-right: 30px;'>If you have questions or trouble logging in, please do not reply to this email,<br/> instead please contact <span style='text-decoration: none;color: #6e22d0;'>${this.user.email}</span></td></tr></table></body></html>`;

    return template;
  }

  onQuantityBlur(row: IProduct) {
    if (!row.quantity) {
      row.quantity = 1;

      setTimeout(() => this.setTotalProductCost());
    }
  }

  onQuantityChange(row: IProduct) {
    if (row.quantity && row.quantity >= 1) {
      row.amount = (row.quantity * row.price).toFixed(2)
    } else {
      row.amount = row.price;
    }

    setTimeout(() => this.setTotalProductCost());
  }
}
