import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[trimwhitespacestart]'
})
export class TrimWhiteSpaceStartDirective {

  constructor(private _el: ElementRef, private control: NgControl) { }

  @HostListener('input', ['$event']) onInputChange(event: any) {
    this._el.nativeElement.value = this._el.nativeElement.value.replace(/^\s/, '');

    const ctrl = this.control;

    if (ctrl && ctrl.value === ' ') {
      this.control.control?.setValue(null);
    }
  }
}
