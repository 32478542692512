import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { environment } from './../../../../../environments/environment';
import { ImageService } from './../../../../services/image.service';
import { SettingsService } from './../../../../services/settings.service';
import { UserService } from './../../../../services/user.service';
import { ISettings, IUser } from './../../../../utils/models';

@Component({
  selector: 'app-user-setting',
  templateUrl: './user-setting.component.html',
  styleUrls: ['./user-setting.component.scss']
})
export class UserSettingComponent implements OnInit {
  user: IUser;

  userForm: FormGroup;

  loading: boolean = false;

  saveInProgress: boolean = false;

  imageUploadInProgress: boolean = false;

  selectedProfileImage: any;

  selectedProfileImageFile: any;

  selectedProfileImageFileName: any;

  settings: ISettings;

  connectAccountInProgress = false;

  constructor(
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,
    private settingsService: SettingsService,
    private userService: UserService,
    private imageService: ImageService,
    private router: Router) {
    this.userForm = this.formBuilder.group({
      first_name: new FormControl(null, [Validators.required]),
      last_name: new FormControl(null, [Validators.required]),
      business_name: new FormControl(null, [Validators.required]),
      line: new FormControl(null, [Validators.required]),
      state: new FormControl(null, [Validators.required]),
      city: new FormControl(null, [Validators.required]),
      postal: new FormControl(null, [Validators.required, Validators.pattern('[0-9]*'), Validators.minLength(2), Validators.maxLength(8)]),
      country: new FormControl(null, [Validators.required]),
      // phone_number: new FormControl(null, [Validators.required, Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')]),
      phone_number: ['', [Validators.pattern(/^\(\d{3}\)\s\d{3}-\d{4}$/), Validators.required]],
      business_info: new FormControl(null, [Validators.required]),
      email: new FormControl({ value: null, disabled: true }, [Validators.required]),
      salesTax: new FormControl(),
      discount: new FormControl(),
    });
  }

  ngOnInit(): void {
    this.userService.user.subscribe((user) => {
      if (user) {
        this.user = user

        this.userForm.patchValue({
          first_name: user.first_name,
          last_name: user.last_name,
          business_name: user.business_name,
          email: user.email,
          line: user.address?.line,
          postal: user.address?.postal,
          state: user.address?.state,
          city: user.address?.city,
          country: user.address?.country,
          phone_number: user.phone_number,
          business_info: user.business_info,
          salesTax: user.salesTax || undefined,
          discount: user.discount || undefined,
        });
      }
    });

    this.fetchAccountSettings();
  }

  fetchAccountSettings(): void {
    this.loading = true;

    this.settingsService.readSettings()
      .toPromise()
      .then((res: any) => {
        this.settings = res;
      })
      .catch(() => this.toastrService.error('Failed to fetch settings details.'))
      .finally(() => this.loading = false);
  }


  onSave(): void {
    const formData = this.userForm.getRawValue();

    const payload: any = {
      first_name: formData.first_name,
      last_name: formData.last_name,
      business_name: formData.business_name,
      email: formData.email,
      address: {
        line: formData.line,
        postal: formData.postal,
        state: formData.state,
        city: formData.city,
        country: formData.country,
      },
      phone_number: formData.phone_number,
      business_info: formData.business_info,
      salesTax: formData.salesTax,
      discount: formData.discount,
    }

    this.saveInProgress = true;

    this.userService.updateUser(payload)
      .toPromise()
      .then(() => this.userService.readUser().toPromise())
      .then(() => this.toastrService.success('Profile updated successfully.'))
      .catch(() => this.toastrService.error('Failed to update profile details'))
      .finally(() => this.saveInProgress = false);
  }

  onProfileImageChange(event: any) {
    const files: FileList = event.target.files;

    if (files.length === 0) {
      return;
    }

    const file = files[0];
    const mimeType = file.type;

    if (mimeType.match(/image\/*/) == null) {
      this.toastrService.error('Please select image file');
      return;
    }

    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = (_event) => {
      this.selectedProfileImageFile = file;
      this.selectedProfileImageFileName = file.name;
      this.selectedProfileImage = reader.result;
    }
  }

  onSaveProfileImage() {
    this.imageUploadInProgress = true;
    this.imageService.uploadImage(this.selectedProfileImageFile, this.selectedProfileImageFileName)
      .toPromise()
      .then(() => {
        const profile_image_url = `${environment.imageUploadUrl}${this.selectedProfileImageFileName}`;

        const payload = {
          color: this.settings?.color,
          profile_image_url: profile_image_url,
          cover_image_url: this.settings?.cover_image_url
        }

        return this.settingsService.saveSettings(payload).toPromise();
      })
      .then((res: ISettings) => {
        if (this.settings) {
          this.settings.profile_image_url = res.profile_image_url;
        } else {
          this.settings = { profile_image_url: res.profile_image_url } as ISettings;
        }

        this.selectedProfileImageFile = null;

        this.selectedProfileImage = null;

        this.selectedProfileImageFileName = null;

        this.user.settings = res;

        this.userService.user.next(this.user);

        this.toastrService.success('Avatar image uploaded successfully.')
      })
      .catch(() => this.toastrService.error('Failed to upload avatar image, Try again.'))
      .finally(() => this.imageUploadInProgress = false);
  }

  onSetupAccounts() {
    this.router.navigate(['/setup-bank-account']);
  }
}
