import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: 'input[dynamic-place-holder]'
})
export class DynamicPlaceHolderDirective {
  @Input()
  type: string;

  private element: ElementRef;

  constructor(element: ElementRef) {
    this.element = element;
  }

  @HostListener('focus')
  handleFocus() {
    this.element.nativeElement.type = this.type;
  }


  @HostListener('blur')
  handleBlur() {
    if (this.element.nativeElement.value == "") {
      this.element.nativeElement.type = 'text';
    }
  }

}