import { PlyrComponent } from 'ngx-plyr';
import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-user-training-videos',
  templateUrl: './user-training-videos.component.html',
  styleUrls: ['./user-training-videos.component.scss']
})
export class UserTrainingVideosComponent implements OnInit {
  paymentCard = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  @ViewChild(PlyrComponent, { static: true })
  plyr: PlyrComponent;

  // or get it from plyrInit event
  player: Plyr;

  videoSources: Plyr.Source[] = [
    {
      src: 'https://cdn.plyr.io/static/demo/View_From_A_Blue_Moon_Trailer-576p.mp4',
      type: 'video/mp4',
      size: 576,
    },

  ];

  poster = 'https://cdn.plyr.io/static/demo/View_From_A_Blue_Moon_Trailer-HD.jpg';

  constructor() { }

  ngOnInit(): void {
    ''
  }

}
