<!-- == Start Page Content here == -->
<div class="content-page" #pdfContent>
  <span *ngIf="(isViewMode || isEditMode) && purchaseOrder?.status?.toLowerCase() === purchaseOrderStatus.RECEIVED"
    class="badge badge-primary badge-lg badge-invoice-status" type="button">
    Status : Received</span>

  <span *ngIf="(isViewMode || isEditMode) && purchaseOrder?.status?.toLowerCase() === purchaseOrderStatus.CANCELLED"
    class="badge badge-primary badge-lg badge-invoice-status" type="button">
    Status : Cancelled</span>
  <div class="page-title-group">
    <div class="page-title-group__left">
      <h2 class="title">Purchase Order &nbsp; <i class="fas fa-spinner fa-spin" *ngIf="loading"></i></h2>
    </div>
  </div>
  <div class="content-page__body">
    <div class="bill-template" *ngIf="!loading && (salesAgreement || purchaseOrder)">
      <label class="label">Bill to</label>
      <div class="row mb-4">
        <div class="col col-12 col-sm-12 col-md-6">
          <ul class="list-unstyled bill-template__list">
            <li><label for="" class="label">Customer Name :</label> {{ user?.first_name }} {{ user?.last_name }}</li>
            <li><label for="" class="label">Address :</label> {{ user?.address?.line }}</li>
            <li><label for="" class="label">City :</label> {{ user?.address?.city }}</li>
            <li><label for="" class="label">Country :</label> {{ user?.address?.country }}</li>
            <li><label for="" class="label">Postal :</label> {{ user?.address?.postal }}</li>
          </ul>
        </div>
        <div class="col col-12 col-sm-12 col-md-6 col-lg-5 ms-auto">
          <ul class="list-unstyled bill-template__list">
            <li><label for="" class="label">Email :</label> {{ user?.email }}</li>
            <li *ngIf="isViewMode || isEditMode"><label for="" class="label">Date :</label>
              {{ purchaseOrder?.created_at | date : 'MMMM, dd, yyyy'}}</li>
            <li *ngIf="isCreateFlow"><label for="" class="label">Date :</label>
              {{ currentDate | date : 'MMMM, dd, yyyy'}}</li>
            <li><label for="" class="label">Client Name :</label> {{ client.first_name }} {{ client.last_name }}</li>
            <!-- <li class="datepicker-container"><label for="" class="label">Due Date :</label>
              <input type="date" name="name" class="form-control form-control-white" placeholder="Date"
                [formControl]="due_date" [min]="todayDate" />
            </li> -->
          </ul>
        </div>
      </div>

      <label class="label">Description:</label>
      <div class="description">
        <textarea name="description" class="form-control form-control-white" id="description" rows="3"
          placeholder="Description" [formControl]="description" trimwhitespacestart></textarea>
      </div>

      <label class="label">Vendor:</label>
      <div class="vendor mb-4 col col-12 col-sm-12 col-md-6" *ngIf="!isEditMode && !isViewMode">
        <app-vendor-type-ahead-control (selectoption)="onSelectVendor($event)" [vendor]="vendor">
        </app-vendor-type-ahead-control>
      </div>

      <div class="row mb-4" *ngIf="vendor">
        <div class="col col-12 col-sm-12 col-md-6">
          <ul class="list-unstyled bill-template__list">
            <li><label for="" class="label">Vendor Name :</label> {{ vendor.name }}</li>
            <li><label for="" class="label">Vendor Address :</label> {{ vendor.address }}</li>
            <li><label for="" class="label">Contact Person :</label> {{ vendor.contact_person }}</li>
          </ul>
        </div>
        <div class="col col-12 col-sm-12 col-md-6 col-lg-5 ms-auto">
          <ul class="list-unstyled bill-template__list">
            <li><label for="" class="label">Email :</label> {{ vendor.email }}</li>
            <li><label for="" class="label">Phone Number :</label> {{ vendor.phone_number }}</li>
            <li><label for="" class="label">Comment :</label> {{ vendor.comment }}</li>
          </ul>
        </div>
      </div>

      <div class="table-responsive mt-4">
        <table class="table common-table client-add-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Description</th>
              <th>Quantity</th>
              <th>Price</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let row of products">
              <td>{{ row.item_id }}</td>
              <td>{{ row.description }}</td>
              <td>{{ row.quantity }}</td>
              <td>{{ row.price | currency : 'USD' : 'symbol' }}</td>
              <td>{{ row.amount | currency : 'USD' : 'symbol' }}</td>
              <td>
                <div class="table-actions" *ngIf="!isViewMode">
                  <button class="btn btn-icon btn-off-primary btn-sm" type="button" aria-expanded="false"
                    (click)="onDeleteProduct(row)">
                    <i class="light-icon light-icon-delete"></i>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr [formGroup]="productForm" *ngIf="isEditMode">
              <td>
                <app-product-type-ahead-control (selectoption)="onSelectOption($event)">
                </app-product-type-ahead-control>
              </td>
              <!-- <td>
                <input type="number" name="quantity" formControlName="quantity" class="form-control form-control-white"
                  placeholder="Quantity" min="1" numbersOnly />
              </td>
              <td>
                <input type="text" name="description" formControlName="description"
                  class="form-control form-control-white" trimwhitespacestart />
              </td>
              <td>
                <input type="text" name="amount" formControlName="amount" class="form-control form-control-white" />
              </td> -->
            </tr>
            <td colspan="8" class="text-end">
              <label class="total-label">Total </label>
              <h2 class="total-price" *ngIf="isCreateFlow">{{ salesAgreement.total_amount | currency : 'USD' : 'symbol'
                }}</h2>

              <h2 class="total-price" *ngIf="!isCreateFlow">{{ purchaseOrder.total_amount | currency : 'USD' : 'symbol'
                }}</h2>
            </td>
          </tfoot>
        </table>


        <div class="bill-btn-group d-flex flex-wrap">
          <button *ngIf="isCreateFlow || isEditMode" class="btn btn-primary my-1" type="button"
            [disabled]="createPurchaseOrderInprogress" (click)="onSave()">
            {{ isEditMode ? 'Update' : 'Save' }}
            &nbsp; <i class="fas fa-spinner fa-spin" *ngIf="createPurchaseOrderInprogress"></i></button>

          <button
            *ngIf="(isViewMode || isEditMode) && purchaseOrder.status?.toLowerCase() !== purchaseOrderStatus.RECEIVED"
            class="btn btn-primary my-1 px-4 mr-2" type="button" (click)="onUpdateStatus()"
            [disabled]="updateStatusInProgress">
            Receive Products &nbsp; <i class="fas fa-spinner fa-spin" *ngIf="updateStatusInProgress"></i></button>

          <button *ngIf="isViewMode || isEditMode" class="btn btn-primary my-1 px-5 mr-2" type="button"
            (click)="openEmailTemplateModal()">Email Purchase Order
          </button>

          <!-- <button *ngIf="isViewMode && purchaseOrder.status?.toLowerCase() === purchaseOrderStatus.COMPLETED"
            class="btn btn-primary my-1 px-5 mr-2 btn-green" type="button">
            Completed </button> -->

          <button class="btn btn-white my-1 ms-auto me-0" type="button" (click)="onCancel()">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- == End Page content == -->

<!-- INVOICE TRANSACTION START -->
<ng-template #emailTemplate let-modal="modal">
  <div class="modal-header flex-column justify-content-center border-0">
    <button type="button" class="btn-close" aria-label="Close" (click)="emailTemplateModalRef.dismiss()"></button>
    <h5 class="modal-title text-center" id="create-client-header">Email Template</h5>
  </div>
  <div class="modal-body">
    <div class="client-management__content mt-4">
      <div class="form-group mb-4">
        <input type="text" name="subject" class="form-control form-control-white" placeholder="Subject"
          [formControl]="subject" trimwhitespacestart />
      </div>

      <div class="form-group">
        <ckeditor [editor]="Editor" [(ngModel)]="editorData" [config]="config"></ckeditor>
      </div>
    </div>

    <div class="col col-12 col-sm-12 col-md-12 text-start mt-4">
      <button *ngIf="isViewMode || isEditMode" class="btn btn-primary" type="button"
        [disabled]="!subject.valid || sendToClientInProgress || !editorData" (click)="generatePurchaseAgreement()">
        {{ purchaseOrder.sent_at ? 'Resend' : 'Send' }}
        &nbsp; <i class="fas fa-spinner fa-spin" *ngIf="sendToClientInProgress"></i></button>
    </div>
  </div>
</ng-template>
<!-- Invoice CREATE TEMPLATE END -->