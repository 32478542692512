<!-- == Start Page Content here == -->
<div class="content-page" #pdfContent>
  <span *ngIf="isViewMode && invoice?.status?.toLowerCase() === invoiceStatus.PAID"
    class="badge badge-primary badge-lg badge-invoice-status" type="button">
    Status : Paid</span>

  <span *ngIf="isViewMode && invoice?.status?.toLowerCase() === invoiceStatus.PARTIALLY_PAID"
    class="badge badge-primary badge-lg badge-invoice-status" type="button">
    Status : Partially Paid</span>

  <span *ngIf="isViewMode && invoice?.status?.toLowerCase() === invoiceStatus.CANCELLED"
    class="badge badge-primary badge-lg badge-invoice-status" type="button">
    Status : Cancelled</span>
  <div class="page-title-group">
    <div class="page-title-group__left">
      <h2 class="title">Invoice &nbsp; <i class="fas fa-spinner fa-spin" *ngIf="loading"></i></h2>
    </div>
  </div>
  <div class="content-page__body">
    <div class="bill-template" *ngIf="!loading && (salesAgreement || invoice)">
      <label class="label">Bill to</label>
      <div class="row mb-4">
        <div class="col col-12 col-sm-12 col-md-6">
          <ul class="list-unstyled bill-template__list">
            <li><label for="" class="label">Customer Name :</label> {{ client.first_name }} {{ client.last_name }}</li>
            <li><label for="" class="label">Address :</label> {{ client.address.line }}</li>
            <li><label for="" class="label">City :</label> {{ client.address.city }}</li>
            <li><label for="" class="label">Country :</label> {{ client.address.country }}</li>
            <li><label for="" class="label">Postal :</label> {{ client.address.postal }}</li>
          </ul>
        </div>
        <div class="col col-12 col-sm-12 col-md-6 col-lg-5 ms-auto">
          <ul class="list-unstyled bill-template__list">
            <li><label for="" class="label">Email :</label> {{ client.email }}</li>
            <li *ngIf="isUpdateFlow || isViewMode"><label for="" class="label">Date :</label>
              {{ invoice?.created_at | date : 'MMMM, dd, yyyy'}}</li>
            <li *ngIf="isCreateFlow"><label for="" class="label">Date :</label>
              {{ currentDate | date : 'MMMM, dd, yyyy'}}</li>
            <li *ngIf="!isViewMode" class="datepicker-container"><label for="" class="label">Due Date :</label>
              <input type="date" name="name" class="form-control form-control-white" placeholder="Date"
                [formControl]="due_date" [min]="todayDate" />
            </li>
            <li *ngIf="isViewMode"><label for="" class="label">Due Date :</label>
              {{ invoice?.due_date | date : 'MMMM, dd, yyyy'}}</li>
            <li *ngIf="isViewMode"><label for="" class="label">Sent At :</label>
              {{ invoice?.sent_at | date : 'MMMM, dd, yyyy'}}</li>
          </ul>
        </div>
      </div>

      <div class="table-responsive mt-4">
        <table class="table common-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Description</th>
              <th>Quantity</th>
              <!-- <th>Price</th> -->
              <!-- <th>Amount</th> -->
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let row of products">
              <td>{{ row.item_id }}</td>
              <td>{{ row.description }}</td>
              <td>{{ row.quantity }}</td>
              <!-- <td>{{ row.price | currency : 'USD' : 'symbol' }}</td> -->
              <!-- <td>{{ row.amount | currency : 'USD' : 'symbol' }}</td> -->
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td colspan="1"></td>
              <td>
                <h2 class="total-label">Deposit Amount:</h2>
              </td>
              <td>
                <div class="total-label" *ngIf="isCreateFlow">
                  <input type="text" name="line" [formControl]="deposit_amount"
                    class="form-control form-control-sm form-control-white total-amount-input" placeholder="Amount"
                    appTwoDigitDecimaNumber />
                </div>
                <h2 class="total-label" *ngIf="!isCreateFlow">{{ invoice.deposit_amount | currency : 'USD' : 'symbol' }}
                </h2>
              </td>
            </tr>
            <tr>
              <td colspan="1"></td>
              <td>
                <h2 class="total-label">Invoice Amount:</h2>
              </td>
              <td>
                <h2 class="total-price">{{ total_amount.value | currency : 'USD' : 'symbol' }} </h2>
              </td>
            </tr>
            <tr *ngIf="salesAgreement">
              <td colspan="1"></td>
              <td>
                <h2 class="total-label">Total:</h2>
              </td>
              <td>
                <h2 class="total-price">{{ salesAgreement.total_amount | currency : 'USD' : 'symbol' }} </h2>
              </td>
            </tr>

            <!-- <tr *ngIf="salesAgreement">
              <td colspan="1"></td>
              <td>
                <h2 class="total-label">Balance:</h2>
              </td>
              <td>
                <h2 class="total-price"> {{ balance | currency : 'USD' : 'symbol' }} </h2>
              </td>
            </tr> -->

          </tfoot>
        </table>
      </div>
      <div class="bill-btn-group d-flex flex-wrap" data-html2canvas-ignore="true">
        <button *ngIf="!isViewMode" class="btn btn-primary" type="button"
          [disabled]="createInvoiceInprogress || !total_amount.valid" (click)="onSave()">
          {{ isUpdateFlow ? 'Update' : 'Save' }}
          &nbsp; <i class="fas fa-spinner fa-spin" *ngIf="createInvoiceInprogress"></i></button>

        <button *ngIf="isViewMode" class="btn btn-primary" type="button" (click)="openInvoiceTemplateModal()">
          {{ invoice.sent_at ? 'Resend To Client' : 'Send To Client' }}
        </button>

        <button *ngIf="isViewMode && invoice.status?.toLowerCase() !== invoiceStatus.PAID" class="btn btn-primary"
          type="button" [disabled]="createTransactionInprogress" (click)="openCreateTransactionModal()">
          Create Transaction</button>

        <!-- <button *ngIf="isViewMode && invoice.status?.toLowerCase() === invoiceStatus.UNPAID"
          class="btn btn-primary px-5 mr-2" type="button" (click)="onUpdateStatus()"
          [disabled]="updateStatusInProgress">
          Update Status &nbsp; <i class="fas fa-spinner fa-spin" *ngIf="updateStatusInProgress"></i></button>

        <button *ngIf="isViewMode && invoice.status?.toLowerCase() === invoiceStatus.PAID"
          class="btn btn-primary px-5 mr-2 btn-green" type="button">
          Paid </button> -->

        <button class="btn btn-white ms-auto" type="button" (click)="onCancel()">Exit</button>
      </div>
    </div>
  </div>
</div>
<!-- == End Page content == -->

<!-- CREATE TRANSACTION START -->
<ng-template #transactionTemplate let-modal="modal">
  <div class="modal-header flex-column justify-content-center border-0">
    <button type="button" class="btn-close" aria-label="Close" (click)="createTransactionModalRef.dismiss()"></button>
    <h5 class="modal-title text-center" id="create-client-header">Create Transaction</h5>
  </div>
  <div class="modal-body">
    <div class="client-management__content">
      <form [formGroup]="transactionForm">
        <div class="row">
          <div class="col col-12 col-sm-12 col-md-4 mb-4">
            <div class="form-group">
              <input type="date" name="name" class="form-control form-control-white" placeholder="Date"
                formControlName="date" [min]="todayDate" />
            </div>
          </div>
          <div class="col col-12 col-sm-12 col-md-4 mb-4">
            <div class="form-group">
              <select name="" id="" class="form-control form-control-white" formControlName="payment_method">
                <option class="placeholder" [value]="null">Payment Mode</option>
                <option [value]="'cash'">Cash</option>
                <option [value]="'check'">Check</option>
                <option [value]="'stripe'">Stripe</option>
              </select>
            </div>
          </div>
          <div class="col col-12 col-sm-12 col-md-4 mb-4">
            <div class="form-group">
              <input type="text" name="line" formControlName="amount" class="form-control form-control-white"
                placeholder="Amount" appTwoDigitDecimaNumber />
            </div>
          </div>
          <div class="col col-12 col-sm-12 col-md-12 mb-4">
            <div class="form-group">
              <input type="text" name="line" formControlName="description" class="form-control form-control-white"
                placeholder="{{ transactionPlaceHolder }}" trimwhitespacestart />
            </div>
          </div>
          <div class="col col-12 col-sm-12 col-md-12 text-start">
            <button class="btn btn-primary px-5" type="button"
              [disabled]="transactionForm.invalid || createTransactionInprogress" (click)="createTransaction()">Save
              &nbsp; <i class="fas fa-spinner fa-spin" *ngIf="createTransactionInprogress"></i></button>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>
<!-- CLIENT CREATE TEMPLATE END -->

<!-- INVOICE TRANSACTION START -->
<ng-template #invoiceTemplate let-modal="modal">
  <div class="modal-header flex-column justify-content-center border-0">
    <button type="button" class="btn-close" aria-label="Close" (click)="invoiceTemplateModalRef.dismiss()"></button>
    <h5 class="modal-title text-center" id="create-client-header">Invoice Template</h5>
  </div>
  <div class="modal-body">
    <div class="client-management__content mt-4">
      <div class="form-group mb-4">
        <input type="text" name="subject" class="form-control form-control-white" placeholder="Subject"
          [formControl]="subject" trimwhitespacestart />
      </div>

      <div class="form-group">
        <ckeditor [editor]="Editor" [(ngModel)]="editorData" [config]="config"></ckeditor>
      </div>
    </div>

    <div class="col col-12 col-sm-12 col-md-12 text-start mt-4">
      <button *ngIf="isViewMode" class="btn btn-primary" type="button"
        [disabled]="!subject.valid || sendToClientInProgress || !editorData" (click)="generateInvoice()">
        {{ invoice.sent_at ? 'Resend' : 'Send' }}
        &nbsp; <i class="fas fa-spinner fa-spin" *ngIf="sendToClientInProgress"></i></button>
    </div>
  </div>
</ng-template>
<!-- Invoice CREATE TEMPLATE END -->