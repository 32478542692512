import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { CalendarOptions, DayCellMountArg, EventInput } from '@fullcalendar/common';
import { Calendar } from '@fullcalendar/core';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { AppointmentService } from './../../../../services/appointment.service';
import { IAppoinments, IDemo, ISummary } from './../../../../utils/models';

@Component({
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  events: EventInput[] = [];

  calendarOptions: CalendarOptions;

  loading: boolean = false;

  calendarLoading: boolean = false;

  summary: ISummary;

  from: moment.Moment;

  to: moment.Moment;

  @ViewChild('fullcalendar') fullcalendar: FullCalendarComponent;

  appointments: IAppoinments[] = [];

  appointmentsFrom: moment.Moment;

  appointmentsTo: moment.Moment;

  hoveredDate: NgbDate | null = null;

  fromDate: NgbDate | null;

  toDate: NgbDate | null = null;

  dateRange: any;

  constructor(
    private toastrService: ToastrService,
    private appointmentService: AppointmentService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.from = moment().startOf('month');

    this.to = moment().endOf('month');

    this.appointmentsFrom = moment().startOf('month');

    this.appointmentsTo = moment().endOf('month');

    const from = this.from.format('YYYY-MM-DD');

    const to = this.to.format('YYYY-MM-DD');;

    this.dateRange = `${from} - ${to}`;

    this.fetchSummary();

    this.fetchAppointments();
  }

  resetDateRange() {
    this.from = moment().startOf('month');

    this.to = moment().endOf('month');

    const from = this.from.format('YYYY-MM-DD');

    const to = this.to.format('YYYY-MM-DD');;

    this.dateRange = `${from} - ${to}`;

    this.fetchSummary();
  }

  onDateSelection(date: NgbDate) {
    this.dateRange = null;

    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }

    if (this.fromDate && this.toDate) {
      const from = this.getFormattedDateString(this.fromDate);
      const to = this.getFormattedDateString(this.toDate);
      this.dateRange = `${from} - ${to}`;

      this.from = moment(from, 'YYYY-MM-DD');
      this.to = moment(to, 'YYYY-MM-DD');

      this.fetchSummary();
    }
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  getFormattedDateString(ngbDate: NgbDate) {
    const myDate: Date = new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day);

    return moment(myDate).format('YYYY-MM-DD');
  }

  fetchAppointments() {
    this.calendarLoading = true;

    const from = this.from.format('YYYY-MM-DD hh:mm a');

    const to = this.to.format('YYYY-MM-DD hh:mm a');

    this.appointmentService.getAppointments(from, to)
      .toPromise()
      .then((res) => {
        this.appointments = res;
        this.setCalendarOptions();
      })
      .catch(() => this.toastrService.error('Failed to load appointments.'))
      .finally(() => {
        this.calendarLoading = false;
      });
  }

  fetchSummary() {
    this.loading = true;

    const from = this.from.format('YYYY-MM-DD hh:mm a');

    const to = this.to.format('YYYY-MM-DD hh:mm a');

    this.appointmentService.getSummary(from, to)
      .toPromise()
      .then((res) => this.summary = res)
      .catch((err) => {
        this.toastrService.error('Failed to fetch summary details');
      })
      .finally(() => {
        this.loading = false;
      });
  }

  setEvents() {
    const calendarApi = this.fullcalendar.getApi();

    calendarApi.removeAllEvents();

    this.appointments.forEach((appointment) => {
      calendarApi.addEvent(
        {
          start: moment(appointment.datetime, 'YYYY-MM-DD hh:mm A').toDate(),
          title: `${appointment.type} - ${appointment.client_id?.first_name}`,
          id: appointment._id,
          className: 'fc-day-selected'
        }
      )
    });
  }

  navigateToDetail(demo: IDemo): void {
    this.router.navigate(['live-demo-scheduling', demo._id]);
  }

  setCalendarOptions() {
    this.calendarOptions = {
      initialDate: moment(this.from, 'YYYY-MM-DD hh:mm a').toDate(),
      initialView: 'dayGridMonth',
      eventClassNames: 'remove-event-display',
      headerToolbar: {
        start: 'title',
        center: '',
        end: 'prev,next'
      },
      showNonCurrentDates: false,
      views: {
        dayGridMonth: {
          titleFormat: { month: 'long' }
        }
      },
      customButtons: {
        prev: {
          click: this.onPrevious.bind(this),
        },
        next: {
          click: this.onNext.bind(this),
        },
      },
      dayCellDidMount: (ele: DayCellMountArg) => this.onDayCellMount(ele),
      dateClick: (info: any) => this.dateClick(info)
    };
  }

  onDayCellMount(ele: DayCellMountArg) {
    const currentDate = moment(ele.date, 'ddd MMM DD YYYY hh:mm:ss').format('DD/MM/YYYY');

    if (this.appointments.find((a) => moment(a.datetime).utc().format('DD/MM/YYYY') === currentDate) && !ele.el.classList.contains('fc-day-selected')) {
      ele.el.classList.add('fc-day-selected')
    }
  }

  onPrevious() {
    const calendarApi: Calendar = this.fullcalendar.getApi();

    calendarApi.prev();

    const calendarDate = calendarApi.getDate();

    this.from = moment(calendarDate, 'ddd MMM DD YYYY hh:mm:ss').startOf('month');

    this.to = moment(calendarDate, 'ddd MMM DD YYYY hh:mm:ss').endOf('month');

    this.fetchAppointments();
  }

  onNext() {
    const calendarApi = this.fullcalendar.getApi();

    calendarApi.next();

    const calendarDate = calendarApi.getDate();

    this.from = moment(calendarDate, 'ddd MMM DD YYYY hh:mm:ss').startOf('month');

    this.to = moment(calendarDate, 'ddd MMM DD YYYY hh:mm:ss').endOf('month');

    this.calendarLoading = true;

    this.fetchAppointments();
  }

  dateClick(event: any) {
    const currentDate = moment(event.date).format('DD/MM/YYYY');

    if (this.appointments.find((a) => moment(a.datetime).utc().format('DD/MM/YYYY') === currentDate)) {
      this.router.navigate(['/calender']);
    }
  }

  naviagteToCalendat(appointment: IAppoinments) {
    this.router.navigate(['/calender']);
  }

  navigateToInvoice() {
    this.router.navigate(['reports'], { queryParams: { tab: 'invoices' } });
  }

  getAppointmentClass(appointment: IAppoinments): string {
    if (appointment.type === 'Telephone Appointment') {
      return 'telephone-appointment';
    }

    if (appointment.type === 'On Site Appointment') {
      return 'on-site-appointment';
    }

    if (appointment.type === 'Live Demo') {
      return 'live-demo-appointment';
    }

    if (appointment.type === 'Installation') {
      return 'installation-appointment';
    }

    if (appointment.type === 'Maintenance/Repair') {
      return 'maintenance-appointment';
    }

    return 'other-appointment';
  }
}
