import { ClientManagementService } from './../../../../services/client-management.service';
import { IClient, IPagination, IClientList } from './../../../../utils/models';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { merge, Observable, OperatorFunction, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { concat } from 'lodash';

@Component({
  selector: 'app-client-type-ahead-control',
  templateUrl: './client-type-ahead-control.component.html',
  styleUrls: ['./client-type-ahead-control.component.scss']
})
export class ClientTypeAheadControlComponent implements OnInit, OnChanges {
  placeHolder: string = 'Search Clients';

  data: IClient[] = [];

  @Output()
  selectoption: EventEmitter<IClient | null> = new EventEmitter<IClient | null>();

  model: FormControl = new FormControl();

  loading: boolean = false;

  @Input()
  client: IClient | undefined;

  pagination: IPagination = {
    page: 0,
    limit: 5,
    total_records: 0,
  };

  selectedOption: string;

  focus$ = new Subject<string>();

  click$ = new Subject<string>();

  @ViewChild('instance', { static: true }) instance: NgbTypeahead;

  initalLoad: boolean = true;

  emittedValue: IClient;

  constructor(
    private toastrService: ToastrService,
    private clientManagementService: ClientManagementService) {

    this.model.valueChanges.pipe(debounceTime(400)).subscribe((value) => {
      if (value && typeof value === 'object') {
        this.selectoption.emit(value);

        this.emittedValue = value;
      }

      if (value && typeof value === 'string') {
        this.fetchData(value);
      }

      if (!value || value === '') {
        this.fetchData();
      }
    });

    this.clientManagementService.clearproduct.subscribe(() => this.model.setValue(null));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.data) {
      this.model.setValue(this.client);
    }
  }

  ngOnInit() {
    this.fetchData();

    this.click$.subscribe(() => !this.instance.isPopupOpen());
  }

  onFocusOut() {
    this.model.setValue(this.emittedValue);
  }

  fetchData(search?: string): void {
    this.loading = true;

    this.clientManagementService.readAllClient(this.pagination, search)
      .toPromise()
      .then((res: IClientList) => {
        this.data = res.clients;

        if (this.client && this.initalLoad) {
          this.model.setValue(this.client);

          this.emittedValue = this.client;

          this.initalLoad = false;
        }

        if (search) {
          this.focus$.next(search);
        }
      })
      .catch(() => this.toastrService.error('Failed to fetch clients information.'))
      .finally(() => this.loading = false)
  }

  formatter = (x: any) => `${x.first_name} ${x.last_name || ''}`;

  search: OperatorFunction<string, readonly IClient[]> = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => (term === '' ? this.data
        : this.data.filter(v => `${v.first_name} ${v.last_name || ''}`.toLowerCase().indexOf(term.toLowerCase()) > -1)).slice(0, 10))
    );
  }
}
