<!-- == Start Page Content here == -->
<div class="content-page">
  <div class="page-title-group">
    <div class="page-title-group__left">
      <h2 class="title">Calender &nbsp; <i class="fas fa-spinner fa-spin" *ngIf="loading"></i></h2>
    </div>

    <div class="page-title-group__right d-flex">
      <button class="btn btn-primary ms-2" type="button" [routerLink]="['/create-client']">Create Client</button>
    </div>
  </div>
  <div class="content-page__body">

    <div class="row">
      <div class="col col-12 col-sm-12 col-lg-8">
        <full-calendar class="calendar-main" [options]="calendarOptions" [deepChangeDetection]="true" #fullcalendar>
        </full-calendar>
      </div>

      <div class="col col-12 col-sm-12 col-lg-4 appointment-container">
        <form class="column availability-card" [formGroup]="appointmentForm">
          <h2 class="title">Set Appointment</h2>

          <div class="form-group mb-4">
            <app-client-type-ahead-control (selectoption)="onSelectOption($event)" [client]="selectedAppointmentClient">
            </app-client-type-ahead-control>
          </div>
          <div class="form-group mb-4">
            <select name="" id="" class="form-control form-control-white" formControlName="type">
              <option class="placeholder" [value]="null" disabled>Types of Appointment</option>
              <option [value]="'Telephone Appointment'">Telephone Appointment</option>
              <option [value]="'On Site Appointment'">On Site Appointment</option>
              <option [value]="'Live Demo'">Live Demo</option>
              <option [value]="'Installation'">Installation</option>
              <option [value]="'Maintenance/Repair'">Maintenance/Repair</option>
              <option [value]="'Other'">Other</option>
            </select>
          </div>
          <div class="form-group mb-4">
            <!-- dynamic-place-holder [type]="'date'" -->
            <input type="date" name="name" class="form-control form-control-white form-control-date" placeholder="Date"
              formControlName="date" [min]="todayDate" />
          </div>
          <div class="form-group mb-4">
            <input type="time" name="name" class="form-control form-control-white form-control-time" placeholder="Time"
              formControlName="time" />
          </div>
        </form>

        <ul class="list-unstyled bill-template__list" *ngIf="selectedSalesAgreement && appointmentForm.controls.type.value === 'Installation'">
          <li><label for="" class="label">Agreement Date :</label> {{ selectedSalesAgreement?.created_at | date: 'dd MMM YYYY': 'UTC' }}</li>
          <li><label for="" class="label">Agreement Total :</label> {{ selectedSalesAgreement?.total_amount }}</li>
        </ul>

        <div class="col col-12 col-sm-12 col-md-12 text-center mb-3">
          <button class="btn btn-primary btn-save btn-appointment" type="button" (click)="onAddAppointment()"
            [disabled]="appointmentForm.invalid || createAppointmentInProgress">{{ isUpdateAppoitment ? 'Update' : 'Add'
            }} Appointment &nbsp; <i class="fas fa-spinner fa-spin" *ngIf="createAppointmentInProgress"></i></button>
        </div>

        <div class="col col-12 col-sm-12 col-md-12 text-center">
          <button class="btn btn-white" type="button" *ngIf="isUpdateAppoitment" (click)="onCancel()">Cancel</button>
        </div>

      </div>
    </div>
  </div>
</div>
<!-- == End Page content == -->

<!-- CREATE TRANSACTION START -->
<ng-template #eventTemplate let-modal="modal">
  <div class="modal-header flex-column justify-content-center border-0">
    <button type="button" class="btn-close" aria-label="Close" (click)="eventModelRef.dismiss()"></button>
    <h5 class="modal-title text-center" id="create-client-header">Appointment Information</h5>
  </div>
  <div class="modal-body appointment-edit-modal">
    <div class="client-management__content">
      <div class="row">
        <div class="col col-12 col-sm-12 col-md-6">
          <ul class="list-unstyled bill-template__list">
            <li><label for="" class="label">Client Name :</label> {{ selectedAppointment?.client_id?.first_name }} {{
              selectedAppointment?.client_id?.last_name }}</li>
            <li><label for="" class="label">Address :</label> {{ selectedAppointment?.client_id?.address.line }}</li>
            <li><label for="" class="label">City :</label> {{ selectedAppointment?.client_id?.address.city }}</li>
            <li><label for="" class="label">Country :</label> {{ selectedAppointment?.client_id?.address.country }}</li>
            <li><label for="" class="label">Postal :</label> {{ selectedAppointment?.client_id?.address.postal }}</li>
          </ul>
        </div>
        <div class="col col-12 col-sm-12 col-md-6">
          <ul class="list-unstyled bill-template__list">
            <li><label for="" class="label">Event Type :</label> {{ selectedAppointment?.type }}</li>
            <li><label for="" class="label">Date Time :</label> {{ getDataTimeStr() }}
            </li>
          </ul>
        </div>
      </div>

      <div class="col col-12 col-sm-12 col-md-12 text-center mt-4">
        <button class="btn btn-white px-5 me-3" type="button" (click)="onViewClient()">View Client</button>
        <button class="btn btn-primary px-5 me-3" type="button" (click)="onDeleteAppointment()">Delete
          &nbsp; <i class="fas fa-spinner fa-spin" *ngIf="deleteAppointmentInProgress"></i></button>
        <button class="btn btn-primary px-5" type="button" (click)="onEditAppointment()">Edit</button>
      </div>
    </div>
  </div>
</ng-template>
<!-- CLIENT CREATE TEMPLATE END -->

<!-- CREATE TRANSACTION START -->
<ng-template #agreementTemplate let-modal="modal">
  <div class="modal-header flex-column justify-content-center border-0">
    <button type="button" class="btn-close" aria-label="Close" (click)="agreementModelRef.dismiss()"></button>
    <h5 class="modal-title text-center" id="create-client-header">{{ selectedClient?.first_name }} {{
      selectedClient?.last_name }} Agreements</h5>
  </div>
  <div class="modal-body">
    <div class="client-management__content">
      <div class="row">
        <app-sales-agreement-list [clientId]="selectedClient?._id" (onselectagreement)="onSlectAgreement($event)"
          [isSelectAgreementFlow]="true"></app-sales-agreement-list>
      </div>
    </div>
  </div>
</ng-template>
<!-- CLIENT CREATE TEMPLATE END -->