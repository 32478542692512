<!-- == Start Page Content here == -->
<div [ngClass]="{ 'content-page': !isTabContent }">
  <div class="page-title-group">
    <div class="page-title-group__left" *ngIf="!isTabContent">
      <h2 class="title">Transaction List &nbsp; <i class="fas fa-spinner fa-spin" *ngIf="loading"></i></h2>
    </div>

    <div class="page-title-group__right d-flex">
      <div class="search-bar">
        <span class="searchbar-icon">
          <span class="light-icon light-icon-search"></span>
        </span>
        <input type="search" name="search" class="search-control form-control form-control-white" placeholder="Search"
          [formControl]="searchControl" />
        <span class="searchbar-close-icon d-lg-none cursor-pointer">
          <span class="light-icon light-icon-close cursor-pointer"></span>
        </span>
      </div>
    </div>
  </div>
  <div class="content-page__body">
    <div class="tab-content" id="generate-invoice_tabContent">
      <div class="tab-pane fade show active" id="generate-invoice" role="tabpanel"
        aria-labelledby="generate-invoice-tab">
        <div class="generate-invoice__content">
          <div *ngIf="loading && isTabContent"><i class="fas fa-spinner fa-spin"></i></div>
          <div class="mt-4" *ngIf="rows.length || !loading">
            <ngx-datatable [rows]="rows" [columns]="columns" [limit]="10" rowHeight="auto" [headerHeight]="44"
              [footerHeight]="50" [columnMode]="'force'" [loadingIndicator]="loading" [externalPaging]="true"
              [count]="pagination.total_records" [limit]="pagination.limit" [offset]="pagination.page"
              (page)="setPage($event)" [scrollbarH]="true"></ngx-datatable>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- == End Page content == -->

<!-- NGX TABLE CELL TEMPLATES START -->
<ng-template #dateTemplate let-row="row" let-value="value">
  <span>{{ value | date: 'dd MMM YYYY': 'UTC' }}</span>
</ng-template>

<ng-template #currencyTemplate let-row="row" let-value="value">
  <span>{{ value | currency : 'USD' : 'symbol' }}</span>
</ng-template>

<ng-template #actionsTemplate let-row="row" let-value="value">
  <div class="table-actions">
    <button class="btn btn-icon btn-off-primary btn-sm mr-2" type="button" aria-expanded="false"
      ngbTooltip="View Invoice" (click)="onViewInvoice(row)" container="body">
      <i class="light-icon light-icon-eye"></i>
    </button>
  </div>
</ng-template>

<ng-template #paidTemplate let-row="row" let-value="value">
  <div class="table-actions">
    <button class="btn btn-icon btn-off-primary btn-sm" *ngIf="row.status.toLowerCase() === transactionStatus.PENDING"
      type="button" aria-expanded="false" ngbTooltip="Pending" container="body">
      <i class="light-icon light-icon-check light-icon-primary"></i>
    </button>

    <button class="btn btn-icon btn-off-primary btn-sm" *ngIf="row.status.toLowerCase() === transactionStatus.COMPLETED"
      type="button" aria-expanded="false" ngbTooltip="Paid" container="body">
      <i class="light-icon light-icon-check light-icon-success"></i>
    </button>
  </div>
</ng-template>

<ng-template #nameTemplate let-row="row" let-value="value">
  <span>{{ value.first_name }} {{ value.last_name }}</span>
</ng-template>

<!-- NGX TABLE CELL TEMPLATES END -->