import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { debounceTime } from 'rxjs/operators';
import { QuoteService } from './../../../../services/quote-service';
import { IPagination, IQuote } from './../../../../utils/models';

@Component({
  selector: 'app-quote-list',
  templateUrl: './quote-list.component.html',
  styleUrls: ['./quote-list.component.scss']
})
export class QuoteListComponent implements OnInit {
  @Input()
  clientId: string | undefined;

  loading: boolean = false;

  rows: IQuote[] = [];

  @ViewChild('dateTemplate', { static: true })
  dateTemplate: TemplateRef<any>;

  @ViewChild('actionsTemplate', { static: true })
  actionsTemplate: TemplateRef<any>;

  @ViewChild('currencyTemplate', { static: true })
  currencyTemplate: TemplateRef<any>;

  @ViewChild('nameTemplate', { static: true })
  nameTemplate: TemplateRef<any>;

  @ViewChild('agreementTemplate', { static: true })
  agreementTemplate: TemplateRef<any>;

  @ViewChild('statusTemplate', { static: true })
  statusTemplate: TemplateRef<any>;

  columns: any[] = [];

  promise: Promise<any>;

  pagination: IPagination = {
    page: 0,
    limit: 10,
    total_records: 0,
  };

  searchControl: FormControl = new FormControl(null);

  constructor(
    private toastrService: ToastrService,
    private quoteService: QuoteService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.fetch(this.pagination);

    this.columns = [
      { name: 'Client Name', prop: 'client_id', cellTemplate: this.nameTemplate, sortable: false },
      { name: 'Requirements', prop: 'client_requirements', sortable: false },
      { name: 'Created Date', prop: 'created_at', sortable: false, cellTemplate: this.dateTemplate },
      // { name: 'Price', prop: 'customerPrice', sortable: false, cellTemplate: this.currencyTemplate },
      { name: 'Valid Until', prop: 'valid_till', sortable: false, cellTemplate: this.dateTemplate },
      { name: 'Status', prop: 'status', cellTemplate: this.statusTemplate, sortable: false },
      { name: 'Agreement', cellTemplate: this.agreementTemplate, sortable: false },
      { name: 'Current Quote', cellTemplate: this.actionsTemplate, sortable: false }
    ];

    this.searchControl.valueChanges
      .pipe(debounceTime(500))
      .subscribe(() => {
        this.pagination = {
          page: 0,
          limit: 10,
          total_records: 0,
        };

        this.fetch(this.pagination);
      });
  }

  fetch(pagination: IPagination) {
    this.loading = true;

    const searchText = this.searchControl.value || null;

    this.quoteService.getAllQuotes(pagination, this.clientId, searchText)
      .toPromise()
      .then((res) => {
        this.rows = res.quotes;

        this.pagination.total_records = res.total_records;
      })
      .catch(() => this.toastrService.error('Failed to read quotation'))
      .finally(() => this.loading = false);
  }

  setPage(pagination: any) {
    this.pagination.page = pagination.offset;

    this.fetch(this.pagination);
  }

  onEditQuote(record: IQuote) {
    this.router.navigate(['update-quote', record._id]);
  }

  onViewQuote(record: IQuote) {
    this.router.navigate(['view-quote', record._id]);
  }

  onGenerateSalesAgreement(record: IQuote) {
    this.router.navigate(['create-sales-agreement', record.client_id.id, record._id]);
  }

  onEditSalesAgreement(record: IQuote) {
    this.router.navigate(['edit-sales-agreement', record.sales_agreement.client_id, record.sales_agreement.id])
  }
}
