<!-- == Start Page Content here == -->
<div class="content-page">
    <div class="page-title-group">
      <div class="page-title-group__left">
        <h2 class="title">Client Statement</h2>
      </div>
    </div>
    <div class="content-page__body">
        <div class="bill-template">
            <label class="label">Bill to</label>
            <div class="row mb-4">
              <div class="col col-12 col-sm-12 col-md-6">
                <ul class="list-unstyled bill-template__list">
                  <li><label for="" class="label">Customer Name :</label> Maria Jackson</li>
                  <li><label for="" class="label">Address :</label> 4208 Washington Street</li>
                  <li><label for="" class="label">City :</label> Corpus Christi</li>
                  <li><label for="" class="label">Country :</label> United States</li>
                  <li><label for="" class="label">Postal :</label> 12210</li>
                </ul>
              </div>
              <div class="col col-12 col-sm-12 col-md-6 col-lg-5 ms-auto">
                <ul class="list-unstyled bill-template__list">
                  <li><label for="" class="label">Email :</label> demouser@user.com</li>
                  <li><label for="" class="label">Date :</label> March, 15, 2021</li>
                  <li><label for="" class="label">Quote valid until :</label> March, 15, 2021</li>
                </ul>
              </div>
            </div>
            <label class="label">Clients Requirement Summary:</label>
            <div class="description">
              <p>Placeholder text is the label for possible content in a text box. It can normally be found when there are prompts to fill out a form. It’s the hint that tells you ‘Last name’ or the format with which to enter your birthdate or phone number.</p>
            </div>
            <div class="table-responsive mt-4">
              <table class="table common-table">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Description</th>
                    <th>Charges</th>
                    <th>Payment</th>
                    <th>Balance</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>June, 25 2021</td>
                    <td>Invoice# 1329 (Initial Consultation)</td>
                    <td>$ 0.00</td>
                    <td>0 %</td>
                    <td>$ 000.00</td>
                  </tr>
                  <tr>
                    <td>June, 25 2021</td>
                    <td>Invoice# 1329 (Initial Consultation)</td>
                    <td>$ 0.00</td>
                    <td>0 %</td>
                    <td>$ 000.00</td>
                  </tr>
                  <tr>
                    <td>June, 25 2021</td>
                    <td>Invoice# 1329 (Initial Consultation)</td>
                    <td>$ 0.00</td>
                    <td>0 %</td>
                    <td>$ 000.00</td>
                  </tr>
                  <tr>
                    <td>June, 25 2021</td>
                    <td>Invoice# 1329 (Initial Consultation)</td>
                    <td>$ 0.00</td>
                    <td>0 %</td>
                    <td>$ 000.00</td>
                  </tr>
                  <tr>
                    <td>June, 25 2021</td>
                    <td>Invoice# 1329 (Initial Consultation)</td>
                    <td>$ 0.00</td>
                    <td>0 %</td>
                    <td>$ 000.00</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="bill-btn-group">
                <button class="btn btn-primary" type="button">Save</button>
                <button class="btn btn-white" type="button">Cancel</button>
            </div>
          </div>
    </div>
  </div>
  <!-- == End Page content == -->
