<!-- <p>sign-up works!</p>
<button (click)="signUp()">SignUp</button>
<button (click)="googleLogin()">Goggle</button>
<button [routerLink]="'/signin'">Back</button> -->
<div class="auth-wrapper container-fluid">
  <div class="row">
    <div class="col col-12 col-md-12 col-lg-6">
      <div class="auth-content__card">
        <div class="brand-logo">
          <a class="brand-logo__link d-inline-block">
            <img src="assets/images/gloscape-logo.png" class="img-fluid auth-logo" alt="Logo">
          </a>
        </div>

        <div class="auth-content__form">
          <h2 class="title">Create your account</h2>
          <form #signup='ngForm' (ngSubmit)="signUp()">
            <div class="image-picker-group mb-4">
              <button type="button" class="image-picker__edit" (click)="avatarFile.click()">
                <i class="light-icon light-icon-sm light-icon-pencil"></i>
              </button>
              <div class="image-picker-preview">
                <img [src]="selectedProfileImage || 'assets/images/Avatar-1.png'"
                  onerror="this.src='assets/images/Avatar-1.png';" alt="Avatar" class="img-fluid avatar-img">
                <input id="file-input" (change)="onProfileImageChange($event)" #avatarFile style="display: none"
                  accept="image/jpeg,image/jpg,image/png" type="file" />
              </div>
            </div>

            <div class="form-group mb-4">
              <input type="email" [(ngModel)]="authModel.email"
                pattern="^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$" required #email="ngModel" name="email"
                class="form-control" placeholder="Email" />
              <small class="form-text text-danger error text-left" *ngIf="email.touched && email.errors?.required">Email
                is
                required</small>
              <small class="form-text text-danger error mb-2 text-left"
                *ngIf="email.touched && email.errors?.pattern">Invalid
                email</small>
            </div>

            <div class="form-group mb-4">
              <div class="input-group mb-0 input-group-password">
                <input type="{{ showPassword ? 'text' : 'password' }}" [(ngModel)]="authModel.password"
                  #password="ngModel" minlength="6" required name="password" class="form-control"
                  placeholder="Password" />
                <button type="button" class="input-group-append">
                  <i class="light-icon light-icon-eye" *ngIf="!showPassword" (click)="showPassword = true"></i>
                  <i class="light-icon  light-icon-eye-slash" *ngIf="showPassword" (click)="showPassword = false"></i>
                </button>
              </div>
              <small class="form-text text-danger error mb-2 text-left"
                *ngIf="password.touched && password.errors?.required">Required</small>
              <small *ngIf="password.errors?.minlength && password.touched"
                class="form-text text-danger error text-left mb-2">Password must have atleast 6 characters long.</small>
            </div>

            <div class="auth-btn-group text-center">
              <button type="submit" class="btn btn-primary btn-auth" [ngClass]="{'disable': signup.invalid}"
                [disabled]="signup.invalid || signupInProgress">
                <i class="fas fa-spinner fa-spin me-2" *ngIf="signupInProgress"></i>Next</button>
            </div>
          </form>

          <div class="social-login text-center">
            <label class="label">or sign up with</label>
            <div class="social-login__btn-group">
              <button type="button" class="btn btn-icon btn-google btn-white" (click)="federatedSignin(1)">
                <img src="assets/images/google-icon.png" class="img-fluid" alt="Google" />
              </button>

              <button type="button" class="btn btn-icon btn-facebook btn-white" (click)="federatedSignin(0)">
                <img src="assets/images/facebook-icon.png" class="img-fluid" alt="Facebook" />
              </button>
            </div>
          </div>

          <div class="auth-foot">
            <p class="text-center mb-0">Already have an account?
              <a class="link link-primary cursor-pointer" (click)="onSignInClick()">Sign in</a>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="col col-12 col-md-12 col-lg-6">
      <div class="auth-img__slider  d-none d-lg-flex">
        <div class="auth-img__slider_item">
          <img src="assets/images/Saly-1.png" class="img-fluid" alt="sign In">
        </div>
        <!-- <ul class="list-unstyled dots-nav dots-nav-white">
                  <li class="active">
                      <button type="button" class="dot"></button>
                  </li>
                  <li>
                      <button type="button" class="dot"></button>
                  </li>
                  <li>
                      <button type="button" class="dot"></button>
                  </li>
                  <li>
                      <button type="button" class="dot"></button>
                  </li>
              </ul> -->
      </div>
    </div>
  </div>
</div>
<!-- <div class="modal fade modal-email-verification" id="email_verification" data-bs-backdrop="static"
  data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header justify-content-center border-0">
        <h5 class="modal-title text-center" id="staticBackdropLabel">
          <img src="assets/images/gloscape-logo.png" class="img-fluid auth-logo mb-0" alt="Gloscape" />
        </h5>
      </div>
      <div class="modal-body text-center">
        <div class="email-verification__content">
          <h2 class="title">Check your email for Verification Link</h2>
          <p class="subtitle">Click continue once you have verifed your email</p>
          <button type="button" class="btn btn-primary">Continue</button>
        </div>
      </div>
    </div>
  </div>
</div> -->

<ng-template #content let-modal="modal">
  <div class="modal-header flex-column justify-content-center border-0">
    <h5 class="modal-title text-center" id="create-client-header">Email Verification</h5>
  </div>
  <div class="modal-body">
    <div class="client-management__content">
      <h5>Verification email has been sent to your mail id. Please verify and then signin.</h5>
    </div>

    <div class="col col-12 col-sm-12 col-md-12 text-center mt-5">
      <button class="btn btn-primary px-5" type="button" (click)="onOk()">Ok</button>
    </div>
  </div>
</ng-template>