import { ILiveDemo, IDemo, DraftVirtualDemo } from './../utils/models';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IUser } from '../utils/models';
import { environment } from './../../environments/environment';
import { API_PATH } from './../utils/constants';

@Injectable({
  providedIn: 'root'
})
export class LiveDemoService {
  baseUrl: string = environment.baseUrl;

  constructor(private httpClient: HttpClient) {}

  getAllDemo(
    limit: number,
    page: number,
    type: string,
    client_id?: string
  ): Observable<ILiveDemo> {
    let url = `${this.baseUrl}${API_PATH.livedemo}`;

    let param;

    if (client_id) {
      param = new HttpParams()
        .set('limit', limit)
        .set('page', page)
        .set('type', type)
        .set('client_id', client_id);
    } else {
      param = new HttpParams()
        .set('limit', limit)
        .set('page', page)
        .set('type', type);
    }

    return this.httpClient.get<any>(url, { params: param });
  }

  readLiveDemoDetails(id: string): Observable<IDemo> {
    let url = `${this.baseUrl}${API_PATH.livedemo}/${id}`;

    return this.httpClient.get<any>(url);
  }

  createVirtualDemo(draftVirtualDemo: DraftVirtualDemo): Observable<IDemo> {
    let url = `${this.baseUrl}${API_PATH.createVirtualDemo}`;
    return this.httpClient.post<any>(url, draftVirtualDemo);
  }
  deleteVirtualDemo(id: string): Observable<{ status: boolean }> {
    let url = `${this.baseUrl}${API_PATH.livedemo}/${id}`;
    return this.httpClient.delete<any>(url);
  }
  readVirtualDemo(id: string): Observable<DraftVirtualDemo> {
    let url = `${this.baseUrl}${API_PATH.livedemo}/${id}`;
    return this.httpClient.get<any>(url);
  }
  updateVirtualDemo(draftVirtualDemo: DraftVirtualDemo): Observable<IDemo> {
    let url = `${this.baseUrl}${API_PATH.livedemo}/${draftVirtualDemo._id}`;
    return this.httpClient.put<any>(url, draftVirtualDemo);
  }
}
