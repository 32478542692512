import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { API_PATH } from '../utils/constants';
import { IPagination } from '../utils/models';
import { IPurchaseOrder, IPurchaseOrderList, IVendorList } from './../utils/models';

@Injectable({
  providedIn: 'root'
})
export class PurchaseOrderService {
  baseUrl: string = environment.baseUrl;

  clearselection: Subject<any> = new Subject<any>();

  constructor(
    private httpClient: HttpClient) {
  }

  getAllPurchaseOrder(pagination: IPagination, clientId?: string, search?: string): Observable<IPurchaseOrderList> {
    let url = `${this.baseUrl}${API_PATH.purchaseOrder}?limit=${pagination?.limit}&page=${pagination?.page + 1}`;

    if (clientId) {
      url = `${url}&client_id=${clientId}`;
    }

    if (search) {
      url = `${url}&search=${search}`;
    }

    return this.httpClient.get<IPurchaseOrderList>(url);
  }

  updatePurchaseOrderStatus(purchaseOrderId: string, payload: { status: string }): Observable<IPurchaseOrder> {
    let url = `${this.baseUrl}${API_PATH.purchaseOrder}/${purchaseOrderId}`;

    return this.httpClient.post<IPurchaseOrder>(url, payload);
  }

  getPurchaseOrder(id: string): Observable<IPurchaseOrder> {
    let url = `${this.baseUrl}${API_PATH.purchaseOrder}/${id}`;

    return this.httpClient.get<IPurchaseOrder>(url);
  }

  createPurchaseOrder(payload: IPurchaseOrder, id?: string): Observable<IPurchaseOrder> {
    let url = `${this.baseUrl}${API_PATH.purchaseOrder}`;

    if (id) {
      url = `${url}/${id}`;
    }

    return this.httpClient.post<IPurchaseOrder>(url, payload);
  }


  getVendors(pagination: IPagination, search?: string): Observable<IVendorList> {
    let url = `${this.baseUrl}${API_PATH.vendor}?limit=${pagination?.limit}&page=${pagination?.page + 1}`;

    if (search) {
      url = `${url}&name=${search}`;
    }

    return this.httpClient.get<IVendorList>(url);
  }

  sendPurchaseorderToClient(id: string, payload: any): Observable<IPurchaseOrder> {
    let url = `${this.baseUrl}${API_PATH.purchaseOrder}/${id}/send`;

    return this.httpClient.post<IPurchaseOrder>(url, payload);
  }
}


