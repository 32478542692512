import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from './../../environments/environment';
import { API_PATH } from './../utils/constants';
import { IPagination, IProductList, IQuote, IQuoteList, ISalesAgreement, ISalesAgreementList, IInvoice } from './../utils/models';

@Injectable({
  providedIn: 'root'
})
export class AgreementService {
  baseUrl: string = environment.baseUrl;

  constructor(
    private httpClient: HttpClient) {
  }

  readSalesAgreementList(pagination: IPagination, clientId: string, search?: string): Observable<ISalesAgreementList> {
    let url = `${this.baseUrl}${API_PATH.salesorder}?limit=${pagination?.limit}&page=${pagination?.page + 1}`;


    if (clientId) {
      url = url + `&client_id=${clientId}`;
    }

    if (search) {
      url = `${url}&search=${search}`;
    }

    return this.httpClient.get<ISalesAgreementList>(url);
  }

  readSalesAgreement(salesAgreementId: string): Observable<ISalesAgreement> {
    let url = `${this.baseUrl}${API_PATH.salesorder}/${salesAgreementId}`;

    return this.httpClient.get<ISalesAgreement>(url);
  }

  createSalesAgreement(payload: any): Observable<ISalesAgreement> {
    let url = `${this.baseUrl}${API_PATH.salesorder}`;

    return this.httpClient.post<ISalesAgreement>(url, payload);
  }

  updateSalesAgreement(salesAgreementId: string, payload: { status: string }): Observable<ISalesAgreement> {
    let url = `${this.baseUrl}${API_PATH.salesorder}/${salesAgreementId}`;

    return this.httpClient.post<ISalesAgreement>(url, payload);
  }

  sendSalesAgreementToClient(salesAgreementId: string): Observable<ISalesAgreement> {
    let url = `${this.baseUrl}${API_PATH.salesorder}/${salesAgreementId}/send`;

    return this.httpClient.get<ISalesAgreement>(url);
  }

  createInvoice(payload: IInvoice): Observable<IInvoice> {

    let url = `${this.baseUrl}${API_PATH.invoice}`;

    return this.httpClient.post<IInvoice>(url, payload);
  }

  sendAgreementToClient(id: string, payload: any): Observable<ISalesAgreement> {
    let url = `${this.baseUrl}${API_PATH.salesorder}/${id}/send`;

    return this.httpClient.post<ISalesAgreement>(url, payload);
  }
}


