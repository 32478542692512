import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { debounceTime } from 'rxjs/operators';
import { IPagination } from '../../../../utils/models';
import { AppointmentService } from './../../../../services/appointment.service';
import { IAppoinments } from './../../../../utils/models';

@Component({
  selector: 'app-installtion-scheduled-list',
  templateUrl: './installation-scheduled-list.component.html',
  styleUrls: ['./installation-scheduled-list.component.scss']
})
export class InstallationScheduledComponent implements OnInit {
  @Input()
  clientId: string | undefined;

  loading: boolean = false;

  rows: IAppoinments[] = [];

  scheduledAppointments: IAppoinments[] = [];

  completedAppointments: IAppoinments[] = [];

  mode: 'scheduled' | 'completed' = 'scheduled';

  @ViewChild('dateTemplate', { static: true })
  dateTemplate: TemplateRef<any>;

  @ViewChild('dateTimeTemplate', { static: true })
  dateTimeTemplate: TemplateRef<any>;

  @ViewChild('actionsTemplate', { static: true })
  actionsTemplate: TemplateRef<any>;

  @ViewChild('statusTemplate', { static: true })
  statusTemplate: TemplateRef<any>

  @ViewChild('currencyTemplate', { static: true })
  currencyTemplate: TemplateRef<any>;

  @ViewChild('nameTemplate', { static: true })
  nameTemplate: TemplateRef<any>;

  columns: any[] = [];

  promise: Promise<any>;

  pagination: IPagination = {
    page: 0,
    limit: 10,
    total_records: 0,
  };

  @Input()
  isTabContent: boolean = false;

  searchControl: FormControl = new FormControl(null);

  constructor(
    private toastrService: ToastrService,
    private appointmentService: AppointmentService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.fetch(this.pagination);

    this.columns = [
      { name: 'Client Name', prop: 'client_id', cellTemplate: this.nameTemplate, sortable: false },
      { name: 'Installation Date', prop: 'datetime', sortable: false, cellTemplate: this.dateTimeTemplate },
      { name: 'Created Date', prop: 'created_at', sortable: false, cellTemplate: this.dateTemplate },
      // { name: '', cellTemplate: this.actionsTemplate, sortable: false }
    ];

    this.searchControl.valueChanges
      .pipe(debounceTime(500))
      .subscribe(() => {
        this.pagination = {
          page: 0,
          limit: 10,
          total_records: 0,
        };

        this.fetch(this.pagination);
      });
  }

  fetch(pagination: IPagination) {
    this.loading = true;

    const searchText = this.searchControl.value || null;

    this.appointmentService.getAppointmentsByType('Installation')
      .toPromise()
      .then((res) => {
        this.rows = res || [];

        this.scheduledAppointments = this.rows.filter((r) => r.status?.toLowerCase() === 'scheduled');

        this.completedAppointments = this.rows.filter((r) => r.status?.toLowerCase() === 'completed');
      })
      .catch(() => this.toastrService.error('Failed to read appointments'))
      .finally(() => this.loading = false);
  }

  setPage(pagination: any) {
    this.pagination.page = pagination.offset;

    this.fetch(this.pagination);
  }

  createInstalltion() {
    this.router.navigate(['calender'], { queryParams: { type: 'Installation' } });
  }

  onInstallationComplete(record: IAppoinments) {
    record.loading = true;

    this.appointmentService.updateAppointment(Object.assign({}, record, { status: 'Completed' }), record._id as string)
      .toPromise()
      .then(() => {
        this.toastrService.success('Installation status updated');

        this.fetch(this.pagination);
      })
      .catch((err) => this.toastrService.error(err.error.message || 'Installation status update failed.'))
      .finally(() => record.loading = false);
  }
}
