<!-- == Start Page Content here == -->
<div class="content-page edit-profile-content">
  <div class="page-title-group">
    <div class="page-title-group__left">
      <h2 class="title">Edit Profile &nbsp; <i class="fas fa-spinner fa-spin" *ngIf="loading"></i></h2>
      <p class="description">Edit and customize your user profile on this page.</p>
    </div>
    <div class="page-title-group__right text-end">
      <button class="btn btn-primary ms-2" type="button" [routerLink]="['/training']">Training Videos</button>
      <p class="description mb-0 mt-2">Click here to jump to training videos</p>
    </div>
  </div>
  <div class="content-page__body" *ngIf="!loading">
    <form class="edit-profile-form" [formGroup]="userForm">
      <div class="row">
        <div class="col col-12 col-sm-12 col-md-6 col-lg-6 mb-4">
          <div class="input-file-group">
            <button class="btn btn-primary btn-icon btn-icon-edit" type="button" [disabled]="imageUploadInProgress"
              (click)="avatarFile.click()">
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M8.91513 2.96509L1.77695 10.1397C1.74104 10.1759 1.71511 10.2216 1.70276 10.2707L0.911561 13.4624C0.887894 13.5585 0.915986 13.6607 0.985752 13.7308C1.03854 13.7838 1.11047 13.8132 1.18404 13.8132C1.20658 13.8132 1.22963 13.8104 1.25206 13.8047L4.42776 13.0094C4.47726 12.997 4.52222 12.9711 4.55814 12.935L11.6969 5.76088L8.91513 2.96509Z"
                  fill="white" />
                <path
                  d="M13.466 1.98679L12.6714 1.1882C12.1403 0.654457 11.2147 0.654974 10.6843 1.1882L9.71094 2.16642L12.4926 4.96211L13.466 3.98389C13.7312 3.71738 13.8774 3.36266 13.8774 2.98539C13.8774 2.60812 13.7312 2.2534 13.466 1.98679Z"
                  fill="white" />
              </svg>
            </button>

            <button class="btn btn-primary btn-icon btn-icon-save" type="button" *ngIf="selectedProfileImage"
              [disabled]="imageUploadInProgress" (click)="onSaveProfileImage()">
              <svg *ngIf="!imageUploadInProgress" xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="#fff"
                viewBox="0 0 24 24">
                <path
                  d="M 5 3 C 3.9057453 3 3 3.9057453 3 5 L 3 19 C 3 20.094255 3.9057453 21 5 21 L 19 21 C 20.094255 21 21 20.094255 21 19 L 21 7 L 17 3 L 5 3 z M 5 5 L 16.171875 5 L 19 7.828125 L 19 19 L 5 19 L 5 5 z M 7 7 L 7 10 L 15 10 L 15 7 L 7 7 z M 11.5 12 A 2.5 2.5 0 0 0 9 14.5 A 2.5 2.5 0 0 0 11.5 17 A 2.5 2.5 0 0 0 14 14.5 A 2.5 2.5 0 0 0 11.5 12 z">
                </path>
              </svg>

              <i class="fas fa-spinner fa-spin" *ngIf="imageUploadInProgress"></i>
            </button>

            <div class="input-file-preview">
              <img [src]="selectedProfileImage || settings?.profile_image_url || 'assets/images/Avatar-1.png'"
                onerror="this.src='assets/images/Avatar-1.png';" alt="Avatar" class="img-fluid">
            </div>

            <input id="file-input" (change)="onProfileImageChange($event)" #avatarFile style="display: none"
              accept="image/jpeg,image/jpg,image/png" type="file" />
          </div>
        </div>
        <div class="col col-12 col-sm-12 col-md-6 col-lg-6 mb-4">
          <div class="set-account-text">
            <button class="btn btn-primary" type="button" (click)="onSetupAccounts()">Set Up Client/Customer<br/> Payment Portal &nbsp; <i
                class="fas fa-spinner fa-spin" *ngIf="connectAccountInProgress"></i></button>
            <p class="edit-profile-note">This will take you to a screen to set up your Stripe payment portal and link it to your bank account.  This will enable you to bill customers and clients and receive payments through the portal.</p>
          </div>
        </div>
        <div class="col col-12 col-sm-12 col-md-6 mb-4">
          <div class="form-group">
            <input type="text" name="first_name" formControlName="first_name" class="form-control form-control-white"
              placeholder="First Name" trimwhitespacestart />
          </div>
        </div>
        <div class="col col-12 col-sm-12 col-md-6 mb-4">
          <div class="form-group">
            <input type="text" name="last_name" formControlName="last_name" class="form-control form-control-white"
              placeholder="Last Name" trimwhitespacestart />
          </div>
        </div>
        <div class="col col-12 col-sm-12 col-md-6 mb-4">
          <div class="form-group">
            <input type="text" name="business_name" formControlName="business_name"
              class="form-control form-control-white" placeholder="Business Name" trimwhitespacestart />
          </div>
        </div>
        <div class="form-group mb-4">
          <input type="text" name="line" formControlName="line" class="address-input form-control form-control-white"
            placeholder="Address" trimwhitespacestart />
        </div>
        <div class="col col-12 col-sm-12 col-md-6 mb-4">
          <div class="form-group mb-4">
            <input type="text" name="state" formControlName="state" class="form-control form-control-white"
              placeholder="State" trimwhitespacestart />
          </div>
        </div>
        <div class="col col-12 col-sm-12 col-md-6 mb-4">
          <div class="form-group mb-4">
            <input type="text" name="city" formControlName="city" class="form-control form-control-white"
              placeholder="City" trimwhitespacestart />
          </div>
        </div>
        <div class="col col-12 col-sm-12 col-md-6 mb-4">
          <div class="form-group mb-4">
            <input type="text" name="country" formControlName="country" class="form-control form-control-white"
              placeholder="Country" trimwhitespacestart />
          </div>
        </div>
        <div class="col col-12 col-sm-12 col-md-6 mb-4">
          <div class="form-group mb-4">
            <input type="text" name="postal" formControlName="postal" class="form-control form-control-white"
              placeholder="Postal" numbersOnly />
          </div>
        </div>
        <div class="col col-12 col-sm-12 col-md-6 mb-4">
          <div class="form-group">
            <input type="email" name="email" formControlName="email" class="form-control form-control-white"
              placeholder="Email" />
          </div>
        </div>
        <div class="col col-12 col-sm-12 col-md-6 mb-4">
          <div class="form-group">
            <!-- <input type="text" name="phone_number" formControlName="phone_number"
              class="form-control form-control-white" placeholder="Phone Number" trimwhitespacestart numbersOnly /> -->
            <input id="tel" formControlName="phone_number" class="form-control form-control-white"
              placeholder="Phone Number" phoneMask [preValue]="userForm.value.phone_number"
              [phoneControl]="userForm.controls['phone_number']" />
          </div>
        </div>
        <div class="col col-12 col-sm-12 col-md-6 mb-4">
          <div class="form-group">
            <input type="number" name="sales_tax_rate" formControlName="salesTax" class="form-control form-control-white"
              placeholder="Sales Tax Rate" appTwoDigitDecimaNumber />
          </div>
        </div>
        <div class="col col-12 col-sm-12 col-md-6 mb-4">
          <div class="form-group">
            <input type="number" name="discount" formControlName="discount" class="form-control form-control-white"
              placeholder="Discount [%]" appTwoDigitDecimaNumber />
          </div>
        </div>
        <div class="col col-12 col-sm-12 col-md-12 mb-4">
          <div class="form-group">
            <textarea name="business_info" formControlName="business_info" class="form-control form-control-white"
              placeholder="Business Info" trimwhitespacestart></textarea>
          </div>
        </div>
        
        <div class="col col-12 col-sm-12 col-md-12">
          <button class="btn btn-primary btn-save" type="button" (click)="onSave()"
            [disabled]="userForm?.invalid || saveInProgress">Save &nbsp; <i class="fas fa-spinner fa-spin"
              *ngIf="saveInProgress"></i></button>
        </div>
      </div>
    </form>
  </div>
</div>
<!-- == End Page content == -->