<!-- == Start Page Content here == -->
<div class="content-page content-page--full">
  <div class="spinner-container" *ngIf="loading">
    <i class="fas fa-spinner fa-spin"></i>
  </div>
  <ng-container *ngIf="!loading">
    <div class="user-avatar-group mb-4 mb-md-5">
      <span class="avatar">
        <img [src]="user?.settings?.profile_image_url || 'assets/images/Avatar-1.png'"
          onerror="this.src='assets/images/Avatar-1.png';" alt="Avatar" class="img-fluid">
      </span>
      <div class="avatar-text">
        <h4 class="name">{{ user?.first_name ? user.first_name : 'Welcome User' }} </h4>
        <span class="designation">{{ user?.business_info }}</span>
      </div>
    </div>

    <div class="content-page__body payment-package-content">
      <div class="subtitle-group">
        <div class="row">
          <div class="col col-12 col-sm-12 col-md-12 col-lg-6">
            <div class="subtitle-group__left">
              <h3 class="title">Select a program that works for you:</h3>
              <p class="description">Welcome to 10xLighting! In order to continue please choose one of the<br />
                following subscriptions in order to access our services.</p>
            </div>
          </div>
          <div class="col col-12 col-sm-12 col-md-12 col-lg-6">
            <div class="input-group discount-input-group">
              <input type="text" class="form-control form-control-white" [(ngModel)]="coupon"
                placeholder="Enter Discount Coupon" aria-label="Enter Discount Coupon" aria-describedby="apply">
              <button class="btn btn-primary input-group-append" id="apply"
                [disabled]="!coupon || applyCouponInprogress" (click)="onApplyCoupon()">Apply &nbsp; <i
                  class="fas fa-spinner fa-spin" *ngIf="applyCouponInprogress"></i>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="payment-package__list">
        <div class="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-xl-3 align-items-center g-4 g-md-5">
          <div class="col" *ngFor="let plan of plans">
            <div class="package-card" [ngClass]="{'active': plan.selected}">
              <div class="package-card__body">
                <div class="package-card__head">
                  <h3 class="title"> {{plan.name}}
                  </h3>
                  <h4 class="price">$ {{ plan?.cost?.toFixed(2) }}/<span>{{plan.validity}}</span></h4>

                  <div class="form-check" (click)="onChangeSelection(plan)">
                    <div class="form-check-input"></div>
                  </div>
                </div>
                <ul class="list-unstyled package-card__featured">
                  <li [ngClass]="{'selected-plan': plan.selected, 'not-selected-plan': !plan.selected}"
                    *ngFor="let feature of plan.description">
                    <i class="fas fa-check">
                      &nbsp;</i>
                    {{feature}}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="payment-package__btn text-center">
          <button class="btn btn-primary px-5" data-bs-toggle="modal" data-bs-target="#payment_modal">Next</button>

          <!-- <button class="btn btn-primary px-5" >Next</button> -->
        </div>
      </div>
    </div>
  </ng-container>
</div>
<!-- == End Page content == -->
<div class="modal fade modal-payment" id="payment_modal" data-bs-backdrop="static" data-bs-keyboard="false"
  tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header flex-column justify-content-center border-0">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" #paymentModalClose></button>
        <h5 class="modal-title text-center" id="staticBackdropLabel">Setup Payment Method</h5>
        <p class="modal-description text-center">Please enter your credit card to pay for the subscription</p>
      </div>
      <div class="modal-body text-center">
        <div class="row">
          <div class="col col-12 col-sm-12 col-md-6">
            <div class="modal-payment__img">
              <img src="assets/images/payment-card.png" class="img-fluid" alt="Payment Card" />
            </div>
          </div>
          <div class="col col-12 col-sm-12 col-md-6">
            <!-- <div id="card-element"></div> -->
            <!-- <span *ngIf="this.displayError" class="text-danger">{{displayError}}</span> -->
            <form class="payment-form" #checkout="ngForm" (ngSubmit)="onSubmit()">
              <div class="form-group mb-4">
                <div id="cardNumber"></div>
                <span *ngIf="this.cardNumberError" class="text-danger d-flex">{{cardNumberError}}</span>
              </div>
              <!-- <div class="form-group mb-4">
                 <input type="text" name="text" class="form-control" placeholder="Card Number" />
              </div> -->
              <div class="row mb-5">
                <div class="col">
                  <div class="form-group">
                    <div id=cardExpiry></div>
                    <span *ngIf="this.cardExpiryError" class="text-danger d-flex">{{cardExpiryError}}</span>

                  </div>
                </div>
                <div class="col">
                  <div class="form-group">
                    <div id=cardCvc></div>
                    <span *ngIf="this.cardCvcError" class="text-danger d-flex">{{cardCvcError}}</span>
                    <!-- <input type="text" name="text" class="form-control" placeholder="CVV" /> -->
                  </div>
                </div>
              </div>
              <div class="payment-form__btn text-center">
                <button class="btn btn-primary"
                  [disabled]="!cardCvcValid || !cardNumberValid || !cardExpiryValid || paymentInProgress"
                  type="submit">Next &nbsp; <i class="fas fa-spinner fa-spin" *ngIf="paymentInProgress"></i></button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>