import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { API_PATH } from '../utils/constants';
import { IPagination, IServices } from '../utils/models';
import { IServicesList, IMarketingMaterialList } from './../utils/models';

@Injectable({
  providedIn: 'root'
})
export class ResourcesService {
  baseUrl: string = environment.baseUrl;

  clearproduct: Subject<any> = new Subject<any>();

  clearselection: Subject<any> = new Subject<any>();

  constructor(
    private httpClient: HttpClient) {
  }

  getAllMarketingMaterials(pagination: IPagination, search?: string): Observable<IMarketingMaterialList> {
    let url = `${this.baseUrl}${API_PATH.material}?limit=${pagination?.limit}&page=${pagination?.page + 1}`;

    if (search) {
      url = `${url}&search=${search}`;
    }

    return this.httpClient.get<IMarketingMaterialList>(url);
  }


  getAllService(pagination: IPagination, search?: string): Observable<IServicesList> {
    let url = `${this.baseUrl}${API_PATH.service}?limit=${pagination?.limit}&page=${pagination?.page + 1}`;

    if (search) {
      url = `${url}&search=${search}`;
    }

    return this.httpClient.get<IServicesList>(url);
  }

  getAllRequestedService(pagination: IPagination, search?: string): Observable<IServicesList> {
    let url = `${this.baseUrl}${API_PATH.servicerequest}?limit=${pagination?.limit}&page=${pagination?.page + 1}`;

    if (search) {
      url = `${url}&search=${search}`;
    }

    return this.httpClient.get<IServicesList>(url);
  }

  createUpdateService(payload: any, id?: string): Observable<IServices> {
    let url = `${this.baseUrl}${API_PATH.servicerequest}`;

    if (id) {
      url = `${url}/${id}`;
    }

    return this.httpClient.post<IServices>(url, payload);
  }
}


