import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.scss']
})
export class ResourcesComponent implements OnInit {

  // Mock data for design purpose
  resources: any[] = [
    {
      name: 'Default.pdf',
      size: '2.5 MB'
    },
    {
      name: 'Default.pdf',
      size: '2.5 MB'
    },
    {
      name: 'Default.pdf',
      size: '2.5 MB'
    },
    {
      name: 'Default.pdf',
      size: '2.5 MB'
    },
    {
      name: 'Default.pdf',
      size: '2.5 MB'
    },
    {
      name: 'Default.pdf',
      size: '2.5 MB'
    },
    {
      name: 'Default.pdf',
      size: '2.5 MB'
    },
    {
      name: 'Default.pdf',
      size: '2.5 MB'
    },
    {
      name: 'Default.pdf',
      size: '2.5 MB'
    },
    {
      name: 'Default.pdf',
      size: '2.5 MB'
    },
    {
      name: 'Default.pdf',
      size: '2.5 MB'
    },
    {
      name: 'Default.pdf',
      size: '2.5 MB'
    },
    {
      name: 'Default.pdf',
      size: '2.5 MB'
    },
    {
      name: 'Default.pdf',
      size: '2.5 MB'
    },
    {
      name: 'Default.pdf',
      size: '2.5 MB'
    }
  ]

  constructor() { }

  ngOnInit(): void {
    ''
  }

}
