<div class="auth-wrapper container-fluid">
  <div class="row">
      <div class="col col-12 col-md-6 col-lg-6">
          <div class="auth-img__card d-none d-md-flex">
              <img src="assets/images/sign-in-bg.png" class="img-fluid" alt="sign In">
          </div>
      </div>
      <div class="col col-12 col-md-6 col-lg-6">
          <div class="auth-content__card">
              <div class="brand-logo">
                  <a  class="brand-logo__link d-inline-block">
                      <img src="assets/images/gloscape-logo.png" class="img-fluid auth-logo" alt="Logo">
                  </a>
              </div>

              <div class="auth-content__intro">
                  <h2 class="title">{{ selectedIntroInfo.title }}</h2>
                  <p class="description" [innerHTML]="selectedIntroInfo.desc"></p>

                  <div class="auth-content__intro__btn" *ngIf="currentIndex + 1 !== introInfos.length">
                      <button class="btn btn-primary" (click)="onNext()">Next</button>
                      <button class="btn btn-white" (click)="onSkip()">Skip</button>
                  </div>

                  <div class="auth-content__intro__btn justify-content-end" *ngIf="currentIndex + 1 === introInfos.length">
                    <button class="btn btn-primary" (click)="onProceed()">Proceed</button>
                </div>
              </div>

              <ul class="list-unstyled dots-nav">
                  <li *ngFor="let info of introInfos" [ngClass]="{ 'active': info.id === selectedIntroInfo.id }">
                      <button type="button" class="dot" (click)="setSelectedInfo(info)"></button>
                  </li>
              </ul>
          </div>
      </div>
  </div>
</div>
