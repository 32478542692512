<!-- == Start Page Content here == -->
<div class="content-page">
    <div class="page-title-group">
        <div class="page-title-group__left">
            <h2 class="title">Training Videos:</h2>
            <div class="description">Please complete the follwoing video modules which contain information about
                our<br />
                services and will provide you with the tools to make the most out of our platform.</div>
        </div>
    </div>
    <div class="content-page__body">
        <div class="training-video__list row g-30">
            <div class="col col-12 col-sm-12 col-md-6 col-lg-4" *ngFor="let card of paymentCard">
                <div class="card card-video">
                    <div class="card-video__iframe">
                        <div class="player" plyr plyrTitle="Custom video" [plyrPlaysInline]="true" [plyrCrossOrigin]="true"
                            [plyrPoster]="poster" [plyrSources]="videoSources" (plyrInit)="player = $event"  [plyrOptions]="{'fullscreen': { 'enabled': true, 'iosNative': true }}"></div>
                    </div>
                    <div class="card-video__body">
                        <div class="card-video__head">
                            <h3 class="title">Get Started</h3>
                            <span class="badge badge-success">Completed</span>
                        </div>
                        <div class="score"><label class="label">Score :</label> 50 / 100</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- == End Page content == -->