import { IUserStatus } from './../utils/models';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IUser } from '../utils/models';
import { environment } from './../../environments/environment';
import { API_PATH } from './../utils/constants';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  baseUrl: string = environment.baseUrl;

  user: BehaviorSubject<IUser | null> = new BehaviorSubject<IUser | null>(null);

  constructor(
    private httpClient: HttpClient) {
  }

  checkUserStatus(email?: string): Observable<IUserStatus> {
    let url = `${this.baseUrl}${API_PATH.userStatusCheck}`;

    if (email) {
      url = `${url}?email=${email}`;
    }

    return this.httpClient.get<IUserStatus>(url);
  }

  createUser(email: string, profile_image_url?: string): Observable<any> {
    let url = `${this.baseUrl}${API_PATH.userCreate}`;

    const payload: any = {
      email
    };

    if (profile_image_url) {
      payload.profile_image_url = profile_image_url;
    }

    return this.httpClient.post<any>(url, payload);
  }

  updateUser(paylod: IUser): Observable<any> {
    let url = `${this.baseUrl}${API_PATH.updateUser}`;

    return this.httpClient.post<any>(url, paylod);
  }

  readUser(): Observable<IUser | null> {
    let url = `${this.baseUrl}${API_PATH.readUser}`;

    return this.httpClient.get<IUser | null>(url).pipe(
      map((user: IUser | null) => {
        this.user.next(user);
        return user;
      }));
  }
}


