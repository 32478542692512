<div class="content-page">
    <div class="page-title-group mb-4">
      <div class="page-title-group__left">
        <h2 class="title mb-3">Agreement</h2>
        <h2 class="subtitle mb-0">Client Agreement</h2>
      </div>
    </div>

    <div class="terms-condition-content">
        <h2 class="title">Terms</h2>
        <div class="description">Placeholder text is the label for possible content in a text box. It can normally be found when there are prompts to fill out a form. It’s the hint that tells you ‘Last name’ or the format with which to enter your birthdate or phone number. Placeholder text typically exists as a hint to fill in actual text. Placeholder text is the label for possible content in a text box. It can normally be found when there are prompts to fill out a form.</div>

        <h2 class="title">Conditions</h2>
        <div class="description">Placeholder text is the label for possible content in a text box. It can normally be found when there are prompts to fill out a form. It’s the hint that tells you ‘Last name’ or the format with which to enter your birthdate or phone number. Placeholder text typically exists as a hint to fill in actual text. Placeholder text is the label for possible content in a text box. It can normally be found when there are prompts to fill out a form. It’s the hint that tells you</div>
        <div class="form-check mb-4">
            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
            <label class="form-check-label" for="flexCheckDefault">
                I Accept to HQ Terms and Services agreement.
            </label>
        </div>

        <button class="btn btn-primary px-5" type="button">Accept</button>
    </div>
</div>