import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.scss']
})
export class IntroComponent implements OnInit {
  introInfos: { id: number, title: string, desc: string }[] = [
    {
      id: 0,
      title: 'Do it All in One Place!',
      desc: 'With your unique login available only to you, you can manage your outdoor lighting business in a smart and seamless way! Designed by professionals in the outdoor lighting industry, this portal gives you everything you need to take your outdoor lighting business to the 10X Level!'
    },
    {
      id: 1,
      title: 'Create Virtual Demos!',
      desc: 'With just a few easy clicks, you can create realistic virtual demos for your prospective clients to show them how their specific house would look with lighting.'
    }, {
      id: 2,
      title: 'Manage Your Calendar and Your Clients!',
      desc: 'You can schedule and manage your lighting activities and clients, including client appointments, live demos, installations, repairs, and any other activities that you need to schedule. The client management system lets you keep track of each client and where they are in the sales process.'
    },
    {
      id: 3,
      title: 'Create Quotes and Sales Agreements, Manage Inventory and Invoicing, Collect Money!',
      desc: 'You can create fast and easy sales quotes in the field, automatically generate sales agreements and obtain client signatures, order product, keep track of inventory, invoice your customers and receive payments, all in an integrated way that will save your time and money.'
    }
  ]

  selectedIntroInfo: { id: number, title: string, desc: string };

  currentIndex: number = 0;

  constructor(private router: Router) {
    if (localStorage.getItem('skipintro') === 'true') {
      this.router.navigate(['/signin']);
    }
  }

  ngOnInit(): void {
    this.selectedIntroInfo = this.introInfos[this.currentIndex];
  }

  onNext(): void {
    this.currentIndex = this.currentIndex + 1;

    this.selectedIntroInfo = this.introInfos[this.currentIndex];
  }

  onSkip(): void {
    // localStorage.setItem('skipintro', 'true');
    this.router.navigate(['/signin']);
  }

  onProceed(): void {
    localStorage.setItem('skipintro', 'true');
    this.router.navigate(['/signin']);
  }

  setSelectedInfo(info: { id: number, title: string, desc: string }): void {
    this.selectedIntroInfo = info;

    this.currentIndex = this.selectedIntroInfo.id;
  }

}
