<div [ngClass]="{ 'content-page': !clientId }">
  <div class="page-title-group" *ngIf="!clientId">
    <div class="page-title-group__left" >
      <h2 class="title">Virtual Demos<i class="fas fa-spinner fa-spin ms-2" *ngIf="loading"></i></h2>
      <!-- <p class="description">Lorem Ipsum is not simply random text. It has roots in a piece of classical<br />
        Latin
        literature from 45 BC, making it over 2000 years old.</p> -->
    </div>

    <div class="page-title-group__right d-flex">
      <!-- <div class="search-bar">
        <span class="searchbar-icon">
          <span class="light-icon light-icon-search"></span>
        </span>
        <input type="search" name="search" class="search-control form-control form-control-white" placeholder="Search"
          [(ngModel)]="searchControl" (keyup)="onblurSearch(searchClient)" />
        <span class="searchbar-close-icon d-lg-none cursor-pointer">
          <span class="light-icon light-icon-close cursor-pointer"></span>
        </span>
      </div> -->

      <button class="btn btn-primary ms-2" type="button" (click)="onCreateVirtualDemo()" >Create
        </button>
    </div>
  </div>
  <div class="content-page__body">
    <div class="tab-content" id="generate-invoice_tabContent">
      <div class="tab-pane fade show active" id="generate-invoice" role="tabpanel"
        aria-labelledby="generate-invoice-tab">
        <div class="generate-invoice__content">
          <div *ngIf="loading && clientId"><i class="fas fa-spinner fa-spin"></i></div>
          <div class="mt-4">
            <ngx-datatable [rows]="virtualDemo" [columns]="columns" rowHeight="auto" [headerHeight]="44" [footerHeight]="50"
              [columnMode]="'force'" [loadingIndicator]="loading" [externalPaging]="true"
              [count]="pagination.total_records" [limit]="pagination.limit" [offset]="pagination.page"
              (page)="setPage($event)" [scrollbarH]="true"></ngx-datatable>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #actionsTemplate let-row="row" let-value="value">
  <div class="table-actions">
    <button class="btn btn-icon  btn-sm" type="button" aria-expanded="false" ngbTooltip="Delete"
      (click)="openModal(content,row._id)" container="body">
      <!-- <i class="light-icon light-icon-eye"></i> -->
      <i class="fas fa-trash-alt" style="color: red;"></i>
    </button>

    <button class="btn btn-icon  btn-sm" type="button" aria-expanded="false" ngbTooltip="Edit Demo"
      (click)="onEditPendingDemo(row)" container="body">
      <i class="fas fa-edit"></i>
    </button>
  </div>
</ng-template>
<!-- Modal Template Start -->
<ng-template #content let-modal>
  <div class="modal-content ">
    <div class="modal-header text-center">
      <h5 class="modal-title w-100" id="modalBasicLabel">Are you sure you want to delete?</h5>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-footer d-flex justify-content-center">
      <button type="button" class="btn btn-secondary" (click)="onCancelDemo()">No</button>
      <button type="button" class="btn btn-primary" (click)="onConfirmDelete()">Yes</button>
    </div>
  </div>
</ng-template>
<!-- Modal Template End -->
