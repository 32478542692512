import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FirebaseAuthService } from './../../../../services/firebase-auth.service';
import { UserService } from './../../../../services/user.service';

@Component({
  selector: 'app-top-header-bar-menu',
  templateUrl: './top-header-bar-menu.component.html',
  styleUrls: ['./top-header-bar-menu.component.scss']
})
export class TopHeaderBarMenuComponent implements OnInit {
  isPaymentScreen: boolean = false;

  @ViewChild('signoutConfirmation')
  signoutConfirmation: TemplateRef<any>;

  modalRef: NgbModalRef;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private firebaseAuthService: FirebaseAuthService,
    private userService: UserService,
    private modalService: NgbModal) { }

  ngOnInit(): void {
    const url = this.router.url;

    this.isPaymentScreen = url.includes('payment') ? true : false;
  }

  signOut() {
    this.modalRef = this.modalService.open(this.signoutConfirmation, { windowClass: 'bootstrap-modal' });
  }

  async onYes() {
    this.userService.user.next(null);
    await this.firebaseAuthService.signOut();
    this.modalRef.close();
  }

  onNo() {
    this.modalRef.close();
  }


  onHambergurClick() {
    this.document.body.classList.add('open-menu');
  }

  onSearchClick() {
    this.document.body.classList.add('open-search');
  }

  onSearchOffClick() {
    this.document.body.classList.remove('open-search');
  }

  onHeaderLogoClick() {
    if (this.router.url.includes('payment') || this.router.url.includes('training')) {
      return;
    }

    this.router.navigate(['/dashboard']);
  }
}
