<!-- == Start Page Content here == -->
<div class="content-page">
  <div class="page-title-group">
    <div class="page-title-group__left">
      <h2 class="title">Document Management</h2>
      <!-- <p class="description">Lorem Ipsum is not simply random text. It has roots in a piece of classical<br /> Latin
        literature from 45 BC, making it over 2000 years old.</p> -->
    </div>
  </div>
  <div class="content-page__body">
    <ul class="nav nav-sales nav-custom p-0" id="reports_tab" role="tablist">
      <li class="nav-item" role="presentation">
        <button class="nav-link" [ngClass]="{ 'active': selectedTab === 'transaction' }"
          (click)="onTabClick('transaction')" id="financial-transaction-tab" data-bs-toggle="pill"
          data-bs-target="#financial-transaction" type="button" role="tab" aria-controls="financial-transaction"
          aria-selected="true">Financial Transaction</button>
      </li>
      <li class="nav-item" role="presentation">
        <button class="nav-link" [ngClass]="{ 'active': selectedTab === 'clients' }" (click)="onTabClick('clients')"
          id="clients-list-tab" data-bs-toggle="pill" data-bs-target="#clients-list" type="button" role="tab"
          aria-controls="clients-list" aria-selected="false">Clients</button>
      </li>
      <li class="nav-item" role="presentation">
        <button class="nav-link" [ngClass]="{ 'active': selectedTab === 'agreement' }" (click)="onTabClick('agreement')"
          id="agreement-list-tab" data-bs-toggle="pill" data-bs-target="#agreement-list" type="button" role="tab"
          aria-controls="agreement-list" aria-selected="false">Sales Agreements</button>
      </li>
      <li class="nav-item" role="presentation">
        <button class="nav-link" id="invoice-list-tab" [ngClass]="{ 'active': selectedTab === 'invoices' }"
          (click)="onTabClick('invoices')" data-bs-toggle="pill" data-bs-target="#invoice-list" type="button" role="tab"
          aria-controls="invoice-list" aria-selected="false">Invoices</button>
      </li>
      <li class="nav-item" role="presentation">
        <button class="nav-link" id="purchase-orders-tab" [ngClass]="{ 'active': selectedTab === 'order' }"
          (click)="onTabClick('order')" data-bs-toggle="pill" data-bs-target="#purchase-orders" type="button" role="tab"
          aria-controls="purchase-orders" aria-selected="false">Purchase Orders</button>
      </li>
    </ul>
    <div class="tab-content" id="reports_tabContent">
      <div class="tab-pane fade" [ngClass]="{ 'active show': selectedTab === 'transaction' }" id="financial-transaction"
        role="tabpanel" aria-labelledby="financial-transaction-tab">
        <div class="financial-transaction__content">
          <app-transaction-list [isTabContent]="true"></app-transaction-list>
        </div>
      </div>
      <div class="tab-pane fade" [ngClass]="{ 'active show': selectedTab === 'order' }" id="purchase-orders"
        role="tabpanel" aria-labelledby="purchase-orders-tab">
        <div class="financial-transaction__content">
          <app-purchase-order-list [isTabContent]="true"></app-purchase-order-list>
        </div>
      </div>
      <div class="tab-pane fade" [ngClass]="{ 'active show': selectedTab === 'agreement' }" id="agreement-list"
        role="tabpanel" aria-labelledby="agreement-list-tab">
        <div class="financial-transaction__content">
          <app-sales-agreement-list [isFullListPage]="false"></app-sales-agreement-list>
        </div>
      </div>
      <div class="tab-pane fade" [ngClass]="{ 'active show': selectedTab === 'clients' }" id="clients-list"
        role="tabpanel" aria-labelledby="clients-list-tab">
        <div class="financial-transaction__content">
          <app-client-managment [isTabContent]="true"></app-client-managment>
        </div>
      </div>
      <div class="tab-pane fade" [ngClass]="{ 'active show': selectedTab === 'invoices' }" id="invoice-list"
        role="tabpanel" aria-labelledby="invoice-list-tab">
        <div class="financial-transaction__content">
          <app-invoice-list [isTabContent]="true"></app-invoice-list>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- == End Page content == -->