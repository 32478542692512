import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LeftSideBarMenuComponent } from './components/left-side-bar-menu/left-side-bar-menu.component';
import { TopHeaderBarMenuComponent } from './components/top-header-bar-menu/top-header-bar-menu.component';

const components = [
  LeftSideBarMenuComponent,
  TopHeaderBarMenuComponent
];
@NgModule({
  declarations: components,
  imports: [
    CommonModule,
    RouterModule
  ],
  exports: components,
})
export class SharedModule { }
